import { DefaultVariable, DefaultVariableValue } from '../useCreateVariableModal';
import { OptionVariable, VariableType } from '../../../interfaces/Documents/Variables/Variable';
import { ValueType, VariableValue } from '../../../interfaces/Documents/CustomVariables/CustomVariable';
import { isNumber } from '../../../helpers/Validations';
import { Option as KeyOption } from '../../../interfaces/Common';
import { LogicOperationType } from '../../../interfaces/Documents/CustomVariables/LogicCustomVariable';
import { LogicOperatorProps } from './LogicOperator';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface UseLogicOperatorInterface {
	handleChangeOperation: (value: LogicOperationType) => void;
	handleUpdateVariableValue: (name: string, value: string, order: number) => void;
	handleAddVariableValue: () => void;
	handleDeleteVariableValue: (order: number) => void;
	variableList: OptionVariable[];
	onlyTwoOptions: boolean;
	handleChangeVariableResult: (value: VariableType) => void;
	variableResultOptions: KeyOption[];
	variableTypeToCheck: VariableType;
}

const comparisonOperationTypes1: LogicOperationType[] = [LogicOperationType.Greater, LogicOperationType.Less, LogicOperationType.GreaterOrEqual, LogicOperationType.LessOrEqual];
const comparisonOperationTypes2: LogicOperationType[] = [LogicOperationType.Equal, LogicOperationType.Different];
const booleanOperationTypes: LogicOperationType[] = [LogicOperationType.And, LogicOperationType.Or];

const useLogicOperator = (props: LogicOperatorProps): UseLogicOperatorInterface => {
	const { t } = useTranslation();

	const variableTypeOptions: KeyOption[] = [
		{ key: VariableType.Boolean, value: t('variableModal:OPTION_VARIABLE_TYPE_BOOLEAN') },
		{ key: VariableType.Text, value: t('variableModal:OPTION_VARIABLE_TYPE_TEXT') },
		{ key: VariableType.Number, value: t('variableModal:OPTION_VARIABLE_TYPE_NUMBER') },
		{ key: VariableType.Date, value: t('variableModal:OPTION_VARIABLE_TYPE_DATE') },
	];

	const [variableResultOptions, setVariableResultOptions] = React.useState<KeyOption[]>(variableTypeOptions);
	const [onlyTwoOptions, setOnlyTwoOptions] = React.useState<boolean>(false);
	const [variableTypeToCheck, setVariableTypeToCheck] = React.useState<VariableType>(VariableType.Boolean);

	const setStateVariables = React.useCallback((operationType: LogicOperationType): void => {
		if (comparisonOperationTypes1.includes(operationType) || comparisonOperationTypes2.includes(operationType)) {
			setOnlyTwoOptions(true);
		} else if (booleanOperationTypes.includes(operationType)) {
			setOnlyTwoOptions(false);
		}
	}, []);

	const handleChangeOperation = (value: LogicOperationType): void => {
		if (props.variableData.specs) {
			const specs = { ...props.variableData.specs, operation: value, values: [...DefaultVariable.specs?.values || []] };

			if (booleanOperationTypes.includes(value)) {
				setVariableTypeToCheck(VariableType.Boolean);
				setVariableResultOptions(variableTypeOptions);
			} else if (comparisonOperationTypes1.includes(value)) {
				setVariableTypeToCheck(VariableType.Number);
				setVariableResultOptions(variableTypeOptions.filter(x => x.key === VariableType.Number || x.key === VariableType.Date));
			} else if (comparisonOperationTypes2.includes(value)) {
				setVariableTypeToCheck(VariableType.Number);
				setVariableResultOptions(variableTypeOptions);
			}

			props.handleUpdateVariableData({ ...props.variableData, specs: specs });
		}
	};

	const handleChangeVariableResult = (value: VariableType): void => {
		setVariableTypeToCheck(value);

		if (props.variableData.specs) {
			const specs = { ...props.variableData.specs, values: [...DefaultVariable.specs?.values || []] };

			props.handleUpdateVariableData({ ...props.variableData, specs: specs });
		}
	};

	const handleUpdateVariableValue = (name: string, value: string, order: number): void => {
		const data = { ...props.variableData };

		if (data.id && name === 'value' && props.arethereCircularReferences(data.id, value)) {
			return;
		}

		if (data.specs && data.specs.values) {
			const index = data.specs.values.findIndex(o => o.order === order);

			if (index > -1) {
				if (variableTypeToCheck === VariableType.Number && name === 'value' && data.specs.values[index].valueType === ValueType.Constant && !isNumber(value)) {
					return;
				}

				const newValue = { ...data.specs.values[index], [name]: value };

				if (name === 'valueType') {
					newValue.value = '';

					if (variableTypeToCheck === VariableType.Date && value === ValueType.Constant) {
						newValue.value = new Date().toDateString();
					}
				}

				data.specs.values[index] = newValue;
				props.handleUpdateVariableData(data);
			}
		}
	};

	const handleAddVariableValue = (): void => {
		if (props.variableData.specs && props.variableData.specs.values) {
			const newValues: VariableValue[] = [...props.variableData.specs.values];

			if (newValues) {
				if (newValues.length > 0) {
					const lastOrder = newValues[newValues.length - 1].order;

					newValues.push({ ...DefaultVariableValue, order: lastOrder + 1 });
				} else {
					newValues.push({ ...DefaultVariableValue });
				}

				props.handleUpdateVariableData({ ...props.variableData, specs: { ...props.variableData.specs, values: newValues } });
			}
		}
	};

	const handleDeleteVariableValue = (order: number): void => {
		if (props.variableData.specs && props.variableData.specs.values) {
			const oldValues: VariableValue[] = [...props.variableData.specs.values];
			const valueIndex = oldValues.findIndex(x => x.order === order);

			if (valueIndex > -1) {
				const newValues: VariableValue[] = [];
				let itemOrder = 1;

				oldValues.forEach((item) => {
					if (item.order !== order) {
						newValues.push({ ...item, order: itemOrder });
						itemOrder++;
					}
				});

				props.handleUpdateVariableData({ ...props.variableData, specs: { ...props.variableData.specs, values: newValues } });
			}
		}
	};

	React.useEffect(() => {
		if (props.variableData.specs) {
			setStateVariables(props.variableData.specs.operation);
		}
	}, [props.variableData.specs, setStateVariables]);

	return {
		handleChangeOperation,
		handleUpdateVariableValue,
		handleAddVariableValue,
		handleDeleteVariableValue,
		variableList: props.variables.filter(x => x.type === variableTypeToCheck),
		onlyTwoOptions,
		handleChangeVariableResult,
		variableResultOptions,
		variableTypeToCheck,
	};
};

export default useLogicOperator;

