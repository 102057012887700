import { Folder, FolderType } from '../../interfaces/Folder';
import DefaultInput from '../Inputs/Input';
import { FormControl } from '@material-ui/core';
import Modal from '../Modal';
import React from 'react';
import useCreateFolder from './useCreateFolder';
import { useTranslation } from 'react-i18next';

export interface CreateFolderModalProps {
	open: boolean;
	handleOnClose: () => void;
	type: FolderType;
	handleCreateFolder: (folder: Folder) => void;
	parentFolder: Folder | undefined;
	isEdit: boolean;
	folderToEdit: Folder | undefined;
	handleEditFolder: (folder: Folder) => void;
}

const CreateFolderModal: React.FC<CreateFolderModalProps> = (props) => {
	const { t } = useTranslation();
	const {
		name,
		nameError,
		nameErrorMessage,
		description,
		handleSetTitle,
		handleSetDescription,
		handleCreate,
		handleCloseModal,
		handleEdit,
	} = useCreateFolder(props);

	return (
		<div>
			<Modal
				title={props.isEdit ? t('folder:MODAL_EDIT_FOLDER_TITLE') : t('folder:MODAL_CREATE_FOLDER_TITLE')}
				open={props.open}
				handleOnClose={handleCloseModal}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: t('folder:MODAL_CREATE_PRIMARY_BUTTON'),
					handleOnclick: props.isEdit ? handleEdit : handleCreate,
					isDisabled: !name || nameError,
				}}
				isSecondaryButtonVisible={true}
				secondaryButtonProps={{
					name: t('folder:MODAL_CREATE_SECONDARY_BUTTON'),
					handleOnclick: handleCloseModal,
				}}
			>
				<FormControl className='create-folder-modal' fullWidth margin='normal'>
					<DefaultInput value={name} label={t('folder:MODAL_TITLE')} onChange={(e): void => handleSetTitle(e.target.value)} variant='outlined' isError={nameError} errorMessage={nameErrorMessage} />
					<DefaultInput value={description} label={t('folder:MODAL_DESCRIPTION')} onChange={(e): void => handleSetDescription(e.target.value)} variant='outlined' isMultiline={true} rowsMax={3} />
				</FormControl>
			</Modal>
		</div>
	);
};

export default CreateFolderModal;
