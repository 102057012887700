import { Plan } from './Plan';

export enum PaymentStatus {
	Processing = 'clientView:OPTION_PROCESSING',
	Delayed = 'clientView:OPTION_DELAYED',
	PaymentReview = 'clientView:OPTION_PAYMENT_REVIEW',
	Payed = 'clientView:OPTION_PAYED',
	Closed = 'clientView:OPTION_CLOSED',
	Canceled = 'clientView:OPTION_CANCELED',
}

export interface PanelClientInterface {
	id?: number;
	username?: string;
	planId: number;
	plan?: Plan;
	paymentStatus: PaymentStatus;
	startDate: Date;
	paymentDate: Date;
	imageId?: number;
	isDisabled: boolean;
}

export interface ClientTableInterface extends PanelClientInterface {
	imageSrc?: string;
}
