import './CreateReport.scss';
import { Add, ArrowBack, Delete, Edit, Save } from '@material-ui/icons';
import CreateVariableModal from '../../../../components/CreateVariableModal';
import Filter from '../../../../components/Filter';
import { IconButton } from '@material-ui/core';
import Modal from '../../../../components/Modal';
import React from 'react';
import ReportPreviewModal from '../../../../components/ReportPreviewModal/ReportPreviewModal';
import SubHeaderFormReport from '../../../../components/SubHeaderFormReport';
import TestCaseModal from '../../../../components/TestCaseModal';
import useCreateReport from './useCreateReport';
import { useTranslation } from 'react-i18next';
import WYSIWYGEditor from '../../../../components/WYSIWYGEditor';

export interface CreateReportProps {
	handleBack: () => void;
}

const CreateReport: React.FC<CreateReportProps> = (props) => {
	const { t } = useTranslation();

	const {
		reportContent,
		reportPreview,
		onChangeReportContent,
		variables,
		openCreateVariableModal,
		isCreateVariableModalEdit,
		handleOpenCreateVariableModal,
		handleCloseCreateVariableModal,
		handleCloseReportPreviewModal,
		handleSaveVariable,
		handleBackStep,
		getVariablesFilter,
		customVariableSelected,
		handleChangeCustomVariable,
		getListOfIdReferences,
		handleDeleteVariable,
		handleDeleteVariableAfterConfirmation,
		warningMessage,
		isPreviewButtonEnabled,
		handleCloseWarningMessage,
		testCases,
		testCaseName,
		handleChangeTestCase,
		openTestCaseModal,
		handleOpenTestCaseModal,
		openReportPreviewModal,
		handleOpenReportPreviewModal,
		handleSave,
	} = useCreateReport(props);

	return (
		<div className='form-report'>
			<SubHeaderFormReport
				title={t('formsView:REPORT_TITLE')}
				saveActionButtonProps={{
					name: t('formsView:BUTTON_SAVE'),
					onClick: handleSave,
					startIcon: <Save />,
				}}
				previewActionButtonProps={{
					onClick: handleOpenReportPreviewModal,
					name: t('formsView:BUTTON_PREVIEW'),
					isDisabled: !isPreviewButtonEnabled,
				}}
				backActionButtonProps={{
					name: t('formsView:BUTTON_BACK'),
					onClick: handleBackStep,
					startIcon: <ArrowBack />,
				}}
			/>
			<div className='report-actions'>
				<div className='variables'>
					<Filter
						formControlClassName='filter-variable-control'
						label={t('formsView:CUSTOM_VARIABLE_FILTER_LABEL')}
						onChange={(e): void => handleChangeCustomVariable(e.target.value as string)}
						value={customVariableSelected?.id || ''}
						options={variables.filter(x => x.isCustomVariable === true)}
					/>
					<IconButton
						className='variable-icon'
						onClick={(): void => handleOpenCreateVariableModal(false)}
					>
						<Add />
					</IconButton>
					<IconButton
						className='variable-icon'
						onClick={(): void => handleOpenCreateVariableModal(true)}
						disabled={customVariableSelected === undefined}
					>
						<Edit />
					</IconButton>
					<IconButton
						className='variable-icon'
						onClick={handleDeleteVariable}
						disabled={customVariableSelected === undefined}
					>
						<Delete />
					</IconButton>
				</div>
				<div className='variables'>
					<Filter
						formControlClassName='filter-variable-control'
						menuItemClass='filter-variable-control'
						label={t('formsView:REPORT_FILTER_LABEL')}
						onChange={handleChangeTestCase}
						value={testCaseName}
						options={testCases}
						placeholder={t('formsView:REPORT_FILTER_PLACEHOLDER')}
					/>
					<IconButton
						className='variable-icon'
						onClick={handleOpenTestCaseModal}
					>
						<Add />
					</IconButton>
				</div>
			</div>
			<div className='content'>
				<div className='editor'>
					<WYSIWYGEditor
						value={reportContent}
						onChange={onChangeReportContent}
						customButton={React.useMemo(() => getVariablesFilter(), [getVariablesFilter])}
					/>
				</div>
			</div>
			{
				openCreateVariableModal
				&& (
					<CreateVariableModal
						open={openCreateVariableModal}
						handleOnClose={handleCloseCreateVariableModal}
						handleSaveVariable={handleSaveVariable}
						variables={variables}
						isEdit={isCreateVariableModalEdit}
						customVariableToEdit={customVariableSelected}
						getListOfIdReferences={(variableId: string): string[] => getListOfIdReferences(variableId)}
					/>
				)
			}
			{
				warningMessage !== undefined
				&& (
					<Modal
						title={t('formsView:TITLE_WARNING')}
						open={warningMessage !== undefined}
						handleOnClose={handleCloseWarningMessage}
						isPrimaryButtonVisible={true}
						primaryButtonProps={{
							name: t('formsView:MODAL_ACCEPT_PRIMARY_BUTTON'),
							handleOnclick: handleDeleteVariableAfterConfirmation,
						}}
						isSecondaryButtonVisible={true}
						secondaryButtonProps={{
							name: t('formsView:MODAL_CREATE_SECONDARY_BUTTON'),
							handleOnclick: handleCloseWarningMessage,
						}}
						size='xs'
					>
						{warningMessage}
					</Modal>
				)
			}
			{
				openTestCaseModal &&
				<TestCaseModal
					open={openTestCaseModal}
					onClose={handleOpenTestCaseModal}
				/>
			}
			<ReportPreviewModal
				open={openReportPreviewModal}
				onClose={handleCloseReportPreviewModal}
				reportPreview={reportPreview}
			/>
		</div>
	);
};

export default CreateReport;
