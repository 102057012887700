export const isNumber = (value: string): boolean => {
	const coercedValue = Number(value);

	return !(coercedValue !== coercedValue);
};

export const isIntegerNumber = (value: string): boolean => {
	const validInteger = new RegExp('^[0-9]*$');

	return validInteger.test(value);
};

export const isDecimalNumber = (value: string): boolean => {
	const validDecimal = new RegExp('^([0-9]*\\.?[0-9]*|\\.[0-9]+)$');

	return validDecimal.test(value);
};
