import './Row.scss';
import classNames from 'classnames';
import React from 'react';
import { TableRow } from '@material-ui/core';

interface RowProps {
	children: React.ReactNode;
	isFolder?: boolean;
	onClick?: () => void;
	isDisabled?: boolean;
}

const Row: React.FC<RowProps> = ({ children, isFolder, onClick, isDisabled }) => {
	return (
		<TableRow
			onClick={onClick}
			classes={{
				root: classNames({ 'folder-table-row': isFolder, 'form-table-row': !isFolder, 'disabled-row': isDisabled }),
			}}
		>
			{children}
		</TableRow>
	);
};

export default Row;
