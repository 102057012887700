import './Modal.scss';
import { Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import ActionButton from '../Buttons/ActionButton';
import { Close } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface ButtonProps {
	name: string;
	handleOnclick: () => void;
	isDisabled?: boolean;
}

interface ModalProps {
	open: boolean;
	handleOnClose: () => void;
	title: string;
	isPrimaryButtonVisible: boolean;
	primaryButtonProps?: ButtonProps;
	isSecondaryButtonVisible: boolean;
	secondaryButtonProps?: ButtonProps;
	size?: Size;
}

const Modal: React.FC<ModalProps> = (props) => {
	const { t } = useTranslation();

	return (
		<Dialog
			classes={{
				paper: 'modal-paper',
			}}
			open={props.open}
			onClose={props.handleOnClose}
			fullWidth
			maxWidth={props.size || 'sm'}
		>
			<div className='title-section'>
				<div className='title'>
					{props.title}
				</div>
				<IconButton
					className='close-icon'
					onClick={props.handleOnClose}
				>
					<Close />
				</IconButton>
			</div>
			<DialogContent>
				{props.children}
			</DialogContent>
			<DialogActions
				className='dialog-actions'
			>
				{
					props.isSecondaryButtonVisible
					&& (
						<ActionButton
							name={props.secondaryButtonProps ? props.secondaryButtonProps.name : t('userView:MODAL_CREATE_SECONDARY_BUTTON')}
							onClick={props.secondaryButtonProps ? props.secondaryButtonProps.handleOnclick : props.handleOnClose}
							type='secondary'
							isDisabled={props.secondaryButtonProps?.isDisabled}
						/>
					)
				}
				{
					props.isPrimaryButtonVisible && props.primaryButtonProps
					&& (
						<ActionButton
							name={props.primaryButtonProps.name}
							onClick={props.primaryButtonProps.handleOnclick}
							isDisabled={props.primaryButtonProps.isDisabled}
						/>
					)
				}
			</DialogActions>
		</Dialog>
	);
};

export default Modal;
