import './CreateVariableModal.scss';
import { CustomVariableTypes, OperatorType } from '../../interfaces/Documents/CustomVariables/CustomVariable';
import DefaultInput from '../Inputs/Input';
import DefaultSelect from '../Inputs/Select';
import Modal from '../Modal';
import { OptionVariable } from '../../interfaces/Documents/Variables/Variable';
import React from 'react';
import useCreateVariableModal from './useCreateVariableModal';
import { useTranslation } from 'react-i18next';

export interface CreateVariableModalProps {
	open: boolean;
	handleOnClose: () => void;
	handleSaveVariable: (variable: CustomVariableTypes, isEdit: boolean) => void;
	variables: OptionVariable[];
	isEdit: boolean;
	customVariableToEdit?: CustomVariableTypes;
	getListOfIdReferences: (variableId: string) => string[];
}

const CreateVariableModal: React.FC<CreateVariableModalProps> = (props) => {
	const { t } = useTranslation();

	const {
		variableData,
		updateVariableData,
		changeVariableType,
		updateVariableVisible,
		renderSpecSection,
		handleSaveNewVariable,
		saveAfterConfirmation,
		handleCloseModal,
		warningMessageValidation,
		warningMessageReferences,
		handleCloseWarningMessage,
	} = useCreateVariableModal(props);

	return (
		<div className='variable-modal'>
			<Modal
				title={t('variableModal:VARIABLE_CREATE_TITLE')}
				open={props.open}
				handleOnClose={handleCloseModal}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: t('variableModal:MODAL_CREATE_PRIMARY_BUTTON'),
					handleOnclick: handleSaveNewVariable,
				}}
				isSecondaryButtonVisible={true}
				secondaryButtonProps={{
					name: t('variableModal:MODAL_CREATE_SECONDARY_BUTTON'),
					handleOnclick: handleCloseModal,
				}}
				size='md'
			>
				<div>
					<div className='common-section'>
						<div className='top-section'>
							<div className='name-section'>
								<DefaultInput value={variableData.code} label={t('variableModal:FIELD_NAME')} onChange={(e): void => updateVariableData('code', e.target.value)} variant='outlined' />
							</div>
						</div>
						<div className='bottom-section'>
							<DefaultSelect
								onChange={(e): void => changeVariableType(e.target.value as OperatorType)}
								value={variableData.operatorType}
								options={[
									{ key: 'logicOperator', value: t('variableModal:OPTION_LOGIC_OPERATOR') },
									{ key: 'mathematicalOperator', value: t('variableModal:OPTION_MATHEMATICAL_OPERATOR') },
									{ key: 'switchOperator', value: t('variableModal:OPTION_SWITCH_OPERATOR') },
									{ key: 'listOperator', value: t('variableModal:OPTION_LIST_OPERATOR') },
								]}
								isFullWidth
							/>
							<div className='public-private-variable'>
								<DefaultSelect onChange={(e): void => updateVariableVisible(e.target.value as string)} value={variableData.isVisible ? 'public' : 'private'}
									options={[
										{ key: 'public', value: t('variableModal:OPTION_PUBLIC') },
										{ key: 'private', value: t('variableModal:OPTION_PRIVATE') },
									]}
									isFullWidth
								/>
							</div>
						</div>
					</div>
					<div className='specs-section'>
						{
							renderSpecSection(variableData.operatorType || 'logicOperator')
						}
					</div>
				</div>
			</Modal>
			<Modal
				title={t('variableModal:MODAL_WARNING_TITLE')}
				open={warningMessageValidation !== undefined}
				handleOnClose={handleCloseWarningMessage}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: t('variableModal:MODAL_WARNING_PRIMARY_BUTTON'),
					handleOnclick: handleCloseWarningMessage,
				}}
				isSecondaryButtonVisible={false}
				size='xs'
			>
				{warningMessageValidation}
			</Modal>
			<Modal
				title={t('variableModal:MODAL_WARNING_REFRENCES_TITLE')}
				open={warningMessageReferences !== undefined}
				handleOnClose={handleCloseWarningMessage}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: t('variableModal:MODAL_WARNING_PRIMARY_BUTTON'),
					handleOnclick: saveAfterConfirmation,
				}}
				isSecondaryButtonVisible={true}
				secondaryButtonProps={{
					name: t('variableModal:MODAL_CREATE_SECONDARY_BUTTON'),
					handleOnclick: handleCloseWarningMessage,
				}}
				size='xs'
			>
				{warningMessageReferences}
			</Modal>
		</div>
	);
};

export default CreateVariableModal;
