import './ActionButton.scss';
import { Button } from '@material-ui/core';
import React from 'react';

export interface ActionButtonProps {
	onClick: () => void;
	name: string;
	isDisabled?: boolean;
	className?: string;
	startIcon?: JSX.Element;
	endIcon?: JSX.Element;
	variant?: 'text' | 'outlined' | 'contained';
	type?: 'primary' | 'secondary';
	fullWidth?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
	return (
		<Button
			onClick={props.onClick}
			className={props.className || (props.type === 'secondary' ? 'action-button-secondary' : 'action-button')}
			disabled={props.isDisabled}
			startIcon={props.startIcon}
			endIcon={props.endIcon}
			variant={props.variant}
			fullWidth={props.fullWidth}
		>
			{
				props.name
			}
		</Button>
	);
};

export default ActionButton;
