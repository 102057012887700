import './SectionForm.scss';
import { FormControl, IconButton, Menu, MenuItem } from '@material-ui/core';
import { BooleanQuestion } from '../../../../interfaces/Documents/Questions/BooleanQuestion';
import { CheckboxQuestion } from '../../../../interfaces/Documents/Questions/CheckboxQuestion';
import DefaultInput from '../../../../components/Inputs/Input';
import { DocumentUpdateContext } from '../../../../contexts/DocumentContext';
import { Option as KeyOption } from '../../../../interfaces/Common';
import { MoreVert } from '@material-ui/icons';
import QuestionWrapper from '../../../../components/Questions';
import { RadioQuestion } from '../../../../interfaces/Documents/Questions/RadioQuestion';
import React from 'react';
import Reorder from '../../../../components/Reorder';
import { Section } from '../../../../interfaces/Documents/Section';
import { SelectQuestion } from '../../../../interfaces/Documents/Questions/SelectQuestion';
import useSectionForm from './useSectionForm';
import { useTranslation } from 'react-i18next';

export interface SectionFormProps {
	section: Section;
	handleOpenSectionMenu: (event: React.MouseEvent<HTMLElement>) => void;
	sectionList: KeyOption[];
}

const SectionForm: React.FC<SectionFormProps> = (props) => {
	const { t } = useTranslation();
	const { moveActionsComponent } = React.useContext(DocumentUpdateContext);

	const {
		openReorderModal,
		handleOpenReorderModal,
		handleCloseReorderModal,
		updateSectionData,
		updateQuestionData,
		anchorEl,
		handleOpenQuestionMenu,
		handleCloseQuestionMenu,
		handleReorderQuestions,
		openReorderOptionModal,
		handleOpenReorderOptionModal,
		handleCloseReorderOptionModal,
		questionHasOptions,
		questionHasGoToOption,
		handleReorderOptions,
		questionSelected,
		handleEnableGoToSection,
	} = useSectionForm(props);

	return (
		<div className='section'>
			<div className='form-info' onClick={(event): void => moveActionsComponent({ top: `${event.currentTarget.offsetTop}px`, sectionOrder: props.section.order, questionOrder: -1 })}>
				<div className='form-info-title'>
					{
						`${t('formsView:SECTION_TITLE')} ${props.section.order}`
					}
				</div>
				<div className='form-info-content'>
					<FormControl className='form-info-control' fullWidth margin='normal'>
						<DefaultInput className='title-input' value={props.section.title} placeholder={t('formsView:SECTION_ADD_TITLE_PLACEHOLDER')} variant='outlined' onChange={(e): void => updateSectionData('title', e.target.value)} />
						<DefaultInput className='description-input' value={props.section.description} placeholder={t('formsView:SECTION_ADD_DESCRIPTION_PLACEHOLDER')} variant='outlined' onChange={(e): void => updateSectionData('description', e.target.value)} />
					</FormControl>
				</div>
				<div className='bottom-section'>
					<IconButton
						className='more-vert-icon'
						onClick={props.handleOpenSectionMenu}
					>
						<MoreVert className='icon' />
					</IconButton>
				</div>
			</div>
			{
				props.section.questions && props.section.questions.length > 0
				&& (
					props.section.questions.map((item, key) => {
						return (
							<div key={key} onClick={(event): void => moveActionsComponent({ top: `${event.currentTarget.offsetTop}px`, sectionOrder: props.section.order, questionOrder: item.order })}>
								<QuestionWrapper
									question={item}
									handleUpdate={updateQuestionData}
									handleOpenQuestionMenu={handleOpenQuestionMenu}
									sectionList={props.sectionList}
								/>
							</div>
						);
					})
				)
			}
			<Menu
				className='popover-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleCloseQuestionMenu}
			>
				<MenuItem className='menu-item' onClick={handleOpenReorderModal}>{t('formsView:POPOVER_OPTION_MOVE')}</MenuItem>
				{
					questionHasOptions &&
					(
						<div>
							<MenuItem className='menu-item' onClick={handleOpenReorderOptionModal}>{t('formsView:POPOVER_OPTION_RORDER_OPTIONS')}</MenuItem>
							{questionHasGoToOption && <MenuItem className='menu-item' onClick={handleEnableGoToSection}>{t('formsView:POPOVER_OPTION_GO_TO_OPTION')}</MenuItem>}
						</div>
					)
				}
			</Menu>
			{
				props.section.questions && props.section.questions.length > 0 && openReorderModal
				&& (
					<Reorder
						title={t('formsView:TITLE_REORDER_QUESTION')}
						open={openReorderModal}
						handleOnClose={handleCloseReorderModal}
						items={props.section.questions}
						handleReoder={handleReorderQuestions}
						secondaryText={t('formsView:TITLE_QUESTION_NUMBER')}
					/>
				)
			}
			{
				props.section.questions && props.section.questions.length > 0 && openReorderOptionModal
				&& (
					<Reorder
						title={t('formsView:TITLE_REORDER_OPTION')}
						open={openReorderOptionModal}
						handleOnClose={handleCloseReorderOptionModal}
						items={(questionSelected as (SelectQuestion | CheckboxQuestion | BooleanQuestion | RadioQuestion)).options}
						handleReoder={handleReorderOptions}
						secondaryText={t('formsView:TITLE_OPTION_NUMBER')}
					/>
				)
			}
		</div>
	);
};

export default SectionForm;
