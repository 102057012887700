
export default {
	es: {
		invitation: {
			ACCEPT_BUTTON: 'Aceptar',
			REJECT_BUTTON: 'Rechazar',
			INVITATION_MESSAGE: 'Fuiste invitado a unirte a Pragma',
			SIGNIN_BUTTON: 'Aceptar iniciando sesión',
			SIGNUP_BUTTON: 'Acetpar creando una nueva cuenta',
		},
	},
	en: {
		invitation: {
			ACCEPT_BUTTON: 'Accept',
			REJECT_BUTTON: 'Reject',
			INVITATION_MESSAGE: 'You have been invited to join Pragma',
			SIGNIN_BUTTON: 'Accept signing in',
			SIGNUP_BUTTON: 'Accept signing up',
		},
	},
};
