export default {
	es: {
		folder: {
			MODAL_CREATE_FOLDER_TITLE: 'Crear carpeta',
			MODAL_EDIT_FOLDER_TITLE: 'Editar carpeta',
			MODAL_TITLE: 'Nombre',
			MODAL_DESCRIPTION: 'Descripcion',
			MODAL_TITLE_ERROR: 'El nombre es invalido',
			MODAL_CREATE_PRIMARY_BUTTON: 'Guardar',
			MODAL_CREATE_SECONDARY_BUTTON: 'Cancelar',
			MODAL_MOVE_TITLE: 'Mover ',
			MODAL_MOVE_HERE: 'Mover aqui',
			MODAL_MOVE_TO: 'Mover',
			MODAL_MOVE_MY_UNIT: 'Mi unidad',
		},
	},
	en: {
		folder: {
			MODAL_CREATE_FOLDER_TITLE: 'Create folder',
			MODAL_EDIT_FOLDER_TITLE: 'Edit folder',
			MODAL_TITLE: 'Name',
			MODAL_DESCRIPTION: 'Description',
			MODAL_TITLE_ERROR: 'Invalid name',
			MODAL_CREATE_PRIMARY_BUTTON: 'Save',
			MODAL_CREATE_SECONDARY_BUTTON: 'Cancel',
			MODAL_MOVE_TITLE: 'Move ',
			MODAL_MOVE_HERE: 'Move here',
			MODAL_MOVE_TO: 'Move',
			MODAL_MOVE_MY_UNIT: 'My unit',
		},
	},
};
