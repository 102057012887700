import { createFolderByType, editFolderByType, getFoldersByType } from '../api/FolderRepository';
import { Folder, FolderAPI, FolderType } from '../interfaces/Folder';

const getParentFolderTree = (folders: Folder[], parentFolderId: number | undefined): Folder => {
	const rootFolder = folders.find(x => x.parentFolder?.id === undefined);

	if (rootFolder) {
		rootFolder.parentFolder = undefined;
		let parentTree = { ...rootFolder };
		let currentParentId = rootFolder.id;

		while (currentParentId !== parentFolderId) {
			const parent = folders.find(x => x.parentFolder?.id === currentParentId);

			if (parent) {
				parent.parentFolder = parentTree;
				parentTree = parent;
				currentParentId = parent?.id;
			} else {
				break;
			}
		}

		return parentTree;
	}

	throw new Error('Should not be reachable');
};

export const getParentFolders = (folders: Folder[], parentFolderId: number | undefined): Folder => {
	return getParentFolderTree(folders, parentFolderId);
};

const getFolderMappedToFolderExtended = (folder: FolderAPI, folders: {
	[key: number]: Folder;
}): Folder => {
	const { parentFolderId, ...rest } = folder;

	const folderExtended = {
		...rest,
	};

	if (parentFolderId) {
		return ({
			...folderExtended,
			parentFolder: getFolderMappedToFolderExtended(folders[parentFolderId], folders),
		});
	}

	return folderExtended;
};

const getFolderExtendedMappedToFolder = (folder: Folder): FolderAPI => {
	const { parentFolder, ...rest } = folder;

	const folderExtended = {
		...rest,
	};

	return ({
		...folderExtended,
		parentFolderId: parentFolder ? parentFolder.id as number : null,
	});
};

export const getFolders = async (type: FolderType, parentFolderId: number | undefined): Promise<Array<Folder>> => {
	const folders = await getFoldersByType(type, parentFolderId);

	const parentFolders = folders.filter(folder => folder.parentFolderId != parentFolderId);

	const parentFoldersDictionary: {
		[key: number]: Folder;
	} = {};

	parentFolders.forEach(folder => {
		parentFoldersDictionary[folder.id as number] = folder;
	});

	return folders
		.filter(folder => folder.parentFolderId == parentFolderId)
		.map(folder => getFolderMappedToFolderExtended(folder, parentFoldersDictionary));
};

export const createFolder = async (folder: Folder): Promise<void> => {
	return await createFolderByType(getFolderExtendedMappedToFolder(folder));
};

export const editFolder = async (folder: Folder, folderId: number): Promise<void> => {
	return await editFolderByType(getFolderExtendedMappedToFolder(folder), folderId);
};
