import './Invitation.scss';
import { Button } from '@material-ui/core';
import logo from '../../assets/images/pragma-logo.svg';
import React from 'react';
import useInvitation from './useInvitation';
import { useTranslation } from 'react-i18next';

const Invitation: React.FC = () => {
	const { t } = useTranslation();

	const {
		handleAcceptClick,
		handleRejectClick,
		handleSignInClick,
		handleSignUpClick,
		isAuth,
	} = useInvitation();

	return (
		<div className="invitation">
			<div className="invitation-container">
				<div className="invitation-logo-container">
					<img src={logo} alt={'logo'} className="invitation-logo" />
				</div>
				<span className="invitation-title">{t('invitation:INVITATION_MESSAGE')}</span>
				<div className="invitation-buttons-container">
					{
						isAuth ? (
							<>
								<Button
									className={'invitation-button'}
									onClick={handleAcceptClick}
									variant={'contained'}
								>
									{t('invitation:ACCEPT_BUTTON')}
								</Button>
							</>
						) : (
							<>
								<Button
									className={'invitation-button'}
									onClick={handleSignInClick}
									variant={'contained'}
								>
									{t('invitation:SIGNIN_BUTTON')}
								</Button>
								<Button
									className={'invitation-button'}
									onClick={handleSignUpClick}
									variant={'contained'}
								>
									{t('invitation:SIGNUP_BUTTON')}
								</Button>
							</>
						)
					}
					<Button
						className={'invitation-button'}
						onClick={handleRejectClick}
						variant={'contained'}
					>
						{t('invitation:REJECT_BUTTON')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Invitation;
