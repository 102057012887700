export const uniqueNameGenerator = (name: string, names: string[]): string => {
	const generateUniqueName = (index: number): string => {
		if (index === 0) {
			return `${name}`;
		}

		return `${name}_${index + 1}`;
	};

	let index = 0;

	while (names.indexOf(generateUniqueName(index)) > -1) {
		index++;
	}

	return generateUniqueName(index);
};
