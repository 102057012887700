import { Auth, GoogleAuthAccess } from '../interfaces/Auth';
import axios from 'axios';
import { Credentials } from '../interfaces/User';

const apiUrl = process.env.REACT_APP_API_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const signInWithPassword = async (credentials: Credentials, code: string | null): Promise<Auth> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/auth/signin`, {
			...credentials,
			'client_id': clientId,
			'client_secret': clientSecret,
			'code': code,
			'grant_type': 'password',
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const signUpWithPassword = async (name: string, credentials: Credentials, code: string): Promise<Auth> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/auth/signup`, {
			...credentials,
			'client_id': clientId,
			'client_secret': clientSecret,
			'code': code,
			'grant_type': 'password',
			'name': name,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const signInWithGoogle = (idToken: string): Promise<Auth> => {
	if (apiUrl) {
		const request: GoogleAuthAccess = {
			'client_id': clientId,
			'client_secret': clientSecret,
			'grant_type': 'google',
			idToken,
		};

		return axios.post(`${apiUrl}/auth/signin`, request);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
