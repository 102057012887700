import './CreateForm.scss';
import { Add, ArrowForward, DeleteOutline, FileCopyOutlined, Save, Title, ViewStreamOutlined, Visibility } from '@material-ui/icons';
import { DocumentContext, DocumentUpdateContext } from '../../../../contexts/DocumentContext';
import { FormControl, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import AutoCompleteTags from '../../../../components/AutoCompleteTags';
import DefaultInput from '../../../../components/Inputs/Input';
import { Option as KeyOption } from '../../../../interfaces/Common';
import Modal from '../../../../components/Modal';
import PreviewModal from '../../../../components/Preview/PreviewModal';
import React from 'react';
import Reorder from '../../../../components/Reorder';
import SectionForm from '../SectionForm/SectionForm';
import SubHeaderForm from '../../../../components/SubHeaderForm';
import useCreateForm from './useCreateForm';
import { useTranslation } from 'react-i18next';

export interface CreateFormProps {
	handleNext: () => void;
}

const CreateForm: React.FC<CreateFormProps> = (props) => {
	const { t } = useTranslation();
	const {
		tags,
		openReorderModal,
		anchorEl,
		handleOpenReorderModal,
		handleCloseReorderModal,
		handleOpenSectionMenu,
		handleCloseSectionMenu,
		handleReorderSection,
		openWarningMessage,
		handleCloseWarningMessage,
		handleDelete,
		handleDeleteSection,
		handleNextStep,
		handleSave,
		openPreviewModal,
		handleOpenPreviewModal,
		handleClosePreviewModal,
	} = useCreateForm(props);

	const {
		documentModel,
		actionData,
		isPreviewButtonEnabled,
	} = React.useContext(DocumentContext);

	const {
		handleChangeStringValues,
		handleTagValues,
		handleAddSection,
		handleAddQuestion,
		handleAddDescription,
		handleCopy,
		moveActionsComponent,
	} = React.useContext(DocumentUpdateContext);

	const sectionList: KeyOption[] = documentModel.sections.map((item) => {
		return { key: item.id, value: `${t('formsView:TITLE_SECTION_NUMBER')} ${item.order} - ${item.title}` };
	})
		.concat([{ key: 'NEXT_SECTION', value: `${t('formsView:NEXT_SECTION')}` }, { key: 'END', value: `${t('formsView:END_SECTION')}` }]);

	return (
		<>
			<div className='form-add-view'>
				<SubHeaderForm
					title={t('formsView:SUBHEADER_TITLE')}
					saveActionButtonProps={{
						name: t('formsView:BUTTON_SAVE'),
						onClick: handleSave,
						startIcon: <Save />,
						isDisabled: !isPreviewButtonEnabled,
					}}
					previewActionButtonProps={{
						name: t('formsView:BUTTON_PREVIEW'),
						onClick: handleOpenPreviewModal,
						startIcon: <Visibility />,
						variant: 'text',
						isDisabled: !isPreviewButtonEnabled,
					}}
					nextActionButtonProps={{
						name: t('formsView:BUTTON_NEXT'),
						onClick: handleNextStep,
						endIcon: <ArrowForward />,
						isDisabled: !isPreviewButtonEnabled,
					}}
				/>
				<div className='form-info' onClick={(event): void => moveActionsComponent({ top: `${event.currentTarget.offsetTop}px`, sectionOrder: -1, questionOrder: -1 })}>
					<div className='form-info-title'>
						{
							t('formsView:FORM_TITLE')
						}
					</div>
					<div className='form-info-content'>
						<FormControl className='form-info-control' fullWidth margin='normal'>
							<DefaultInput className='title-input' value={documentModel.name} placeholder={t('formsView:FORMS_ADD_TITLE_PLACEHOLDER')} variant='outlined' onChange={(e): void => handleChangeStringValues('name', e.target.value)} />
							<DefaultInput className='description-input' value={documentModel.description} placeholder={t('formsView:FORMS_ADD_DESCRIPTION_PLACEHOLDER')} variant='outlined' onChange={(e): void => handleChangeStringValues('description', e.target.value)} />
							<AutoCompleteTags className='tag-field' value={documentModel.tags || []} placeholder={t('formsView:FORMS_ADD_TAGS_PLACEHOLDER')} options={tags} setValue={(array): void => handleTagValues(array)} />
						</FormControl>

					</div>
				</div>
				{
					documentModel.sections && documentModel.sections.length > 0
					&& (
						documentModel.sections.map((item, key) => {
							return (
								<SectionForm
									key={key}
									section={item}
									handleOpenSectionMenu={handleOpenSectionMenu}
									sectionList={sectionList.filter(section => section.key !== item.id)}
								/>
							);
						})
					)
				}
				<Menu
					className='popover-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleCloseSectionMenu}
				>
					<MenuItem className='menu-item' onClick={handleOpenReorderModal}>{t('resourcesView:POPOVER_OPTION_MOVE')}</MenuItem>
				</Menu>
				{
					documentModel.sections && documentModel.sections.length > 0 && openReorderModal
					&& (
						<Reorder
							title={t('formsView:TITLE_REORDER_SECTION')}
							open={openReorderModal}
							handleOnClose={handleCloseReorderModal}
							items={documentModel.sections}
							handleReoder={handleReorderSection}
							secondaryText={t('formsView:TITLE_SECTION_NUMBER')}
						/>
					)
				}
				{
					<Modal
						title={t('formsView:TITLE_WARNING')}
						open={openWarningMessage}
						handleOnClose={handleCloseWarningMessage}
						isPrimaryButtonVisible={true}
						primaryButtonProps={{
							name: t('formsView:MODAL_ACCEPT_PRIMARY_BUTTON'),
							handleOnclick: handleDeleteSection,
						}}
						isSecondaryButtonVisible={true}
						secondaryButtonProps={{
							name: t('formsView:MODAL_CREATE_SECONDARY_BUTTON'),
							handleOnclick: handleCloseWarningMessage,
						}}
					>
						<div>{t('formsView:WARNING_MESSAGE_DELETE_SECTION')}</div>
					</Modal>
				}
				<div className='action-buttons' style={actionData}>
					{
						actionData.sectionOrder > 0 &&
						<Tooltip title={`${t('formsView:TOOLTIP_ADD_QUESTION')}`} placement='left'>
							<IconButton
								className='action-button'
								onClick={handleAddQuestion}
							>
								<Add />
							</IconButton>
						</Tooltip>
					}
					{
						actionData.sectionOrder > 0 &&
						<Tooltip title={`${t('formsView:TOOLTIP_COPY')}`} placement='left'>
							<IconButton
								className='action-button'
								onClick={handleCopy}
							>
								<FileCopyOutlined />
							</IconButton>
						</Tooltip>
					}
					{
						actionData.sectionOrder > 0 &&
						<Tooltip title={`${t('formsView:TOOLTIP_DELETE')}`} placement='left'>
							<IconButton
								className='action-button'
								onClick={handleDelete}
							>
								<DeleteOutline />
							</IconButton>
						</Tooltip>
					}
					{
						actionData.questionOrder > 0 &&
						<Tooltip title={`${t('formsView:TOOLTIP_ADD_DESCRIPTION')}`} placement='left'>
							<IconButton
								className='action-button'
								onClick={handleAddDescription}
							>
								<Title />
							</IconButton>
						</Tooltip>
					}
					<Tooltip title={`${t('formsView:TOOLTIP_ADD_SECTION')}`} placement='left'>
						<IconButton
							className='action-button'
							onClick={handleAddSection}
						>
							<ViewStreamOutlined />
						</IconButton>
					</Tooltip>
				</div>
				{openPreviewModal && <PreviewModal
					open={openPreviewModal}
					handleClose={handleClosePreviewModal}
					handleValidate={(): void => {console.log('validate');}}
				/>}
			</div>
		</>
	);
};

export default CreateForm;
