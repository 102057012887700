
export default {
	es: {
		signup: {
			SIGNUP_BUTTON: 'Registrarse',
			SIGNUP_LABEL_NAME: 'Nombre',
			SIGNUP_MESSAGE: 'Registrarse para acceder al Dashboard',
		},
	},
	en: {
		signup: {
			SIGNUP_BUTTON: 'Sign up',
			SIGNUP_LABEL_NAME: 'Name',
			SIGNUP_MESSAGE: 'Sign up to access the Dashboard',
		},
	},
};
