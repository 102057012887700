import { DocumentContext, DocumentUpdateContext } from '../../../../contexts/DocumentContext';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import { Section } from '../../../../interfaces/Documents/Section';
import { TextQuestion } from '../../../../interfaces/Documents/Questions/TextQuestion';
import { useTranslation } from 'react-i18next';

interface TextPreviewInterface {
	question: TextQuestion;
	inputLabel: string;
	errorMessage: string;
	showErrors: boolean;
	handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useTextPreview = (props: QuestionPreviewProps): TextPreviewInterface => {
	const { t } = useTranslation();
	const { documentModel, documentModel: { showErrors } } = React.useContext(DocumentContext);
	const { handleUpdateQuestion } = React.useContext(DocumentUpdateContext);
	const [inputLabel, setInputLabel] = React.useState<string>('');
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const question = documentModel.sections[props.sectionIndex].questions[props.questionIndex] as TextQuestion;

	const isValueValid = (value: string): boolean => {
		const belongsRange = value.length >= question.min && value.length <= question.max;

		if (question.required) {
			if (value.length && belongsRange) {
				return true;
			}
		} else if (value.length) {
			if (belongsRange) {
				return true;
			}
		} else {
			return true;
		}

		return false;
	};

	React.useEffect(() => {
		const isValid = isValueValid(question.value);

		setErrorMessage(t('formsView:MODAL_PREVIEW_FIELD_REQUIRED'));
		setInputLabel(`0/${question.max}`);
		handleUpdateQuestion({ ...question, isValid }, documentModel.sections[props.sectionIndex] as Section);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question.required]);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;

		if (value.length <= question.max) {
			const inputLength = `${value.length}/${question.max}`;
			const isValid = isValueValid(value);

			if (isValid) {
				setErrorMessage('');
			} else {
				setErrorMessage(value.length ? t('formsView:MODAL_PREVIEW_NUMERIC_VALIDATION') : t('formsView:MODAL_PREVIEW_FIELD_REQUIRED'));
			}

			setInputLabel(inputLength);

			handleUpdateQuestion({ ...question, value, isValid }, documentModel.sections[props.sectionIndex] as Section);
		}
	};

	return {
		question,
		inputLabel,
		errorMessage,
		showErrors,
		handleOnChange,
	};
};

export default useTextPreview;
