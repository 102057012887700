import './ForgotPassword.scss';
import ActionButton from '../../components/Buttons/ActionButton';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';
import TextField from '../../components/TextField/TextField';
import Typography from '@material-ui/core/Typography';
import useForgotPassword from './useForgotPassword';
import { useTranslation } from 'react-i18next';

const ForgotPassword: React.FC = () => {
	const { t } = useTranslation();

	const {
		email,
		emailError,
		emailErrorMessage,
		validateEmail,
		handleRecoverClick,
		recoverError,
	} = useForgotPassword();

	return (
		<div className="forgot-password">
			<div className="forgot-password-container">
				<Typography className="title" variant="h4">{t('forgotPassword:HEADER_TITLE')}</Typography>
				<Typography className="subtitle" variant="h6">{t('forgotPassword:HEADER_DESCRIPTION')}</Typography>
				<div className="forgot-password-form">
					<TextField
						id="email"
						error={emailError}
						errorMessage={emailErrorMessage}
						label={t('forgotPassword:EMAIL_LABEL')}
						onChange={validateEmail}
						required={true}
						type="email"
						value={email}
					/>
					{recoverError && (
						<div className="recover-error">
							<ErrorIcon style={{ marginRight: 5 }} />
							{recoverError}
						</div>
					)}
					<div className="forgot-password-form__button">
						<ActionButton
							name={t('forgotPassword:RECOVER_PASSWORD_PRIMARY_BUTTON')}
							onClick={handleRecoverClick}
							isDisabled={emailError || !email}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
