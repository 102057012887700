import './RightTopCorner.scss';
import Filter from '../../Filter';
import { FilterProps } from '../../Filter/Filter';
import React from 'react';

export interface RightTopCornerProps {
	isFilterVisible: boolean;
	filterProps?: FilterProps;
}

const RightTopCorner: React.FC<RightTopCornerProps> = (props) => {
	return (
		<div className="rightTopCornerSection">
			{
				props.isFilterVisible && props.filterProps
				&& (
					<Filter
						formControlClassName='filterFormControl'
						selectClassName='filterSelect'
						label={props.filterProps.label}
						onChange={props.filterProps.onChange}
						value={props.filterProps.value}
						options={props.filterProps.options}
						isDisabled={props.filterProps.isDisabled}
					/>
				)
			}
		</div>
	);
};

export default RightTopCorner;
