import './Cell.scss';
import React from 'react';
import { TableCell } from '@material-ui/core';

interface CellProps {
	align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	className?: string;
}

const Cell: React.FC<CellProps> = (props) => {
	return (
		<TableCell
			className={props.className}
			align={props.align}
			classes={{
				root: 'table-cell',
			}}
		>
			{props.children}
		</TableCell>
	);
};

export default Cell;
