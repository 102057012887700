import React, { ReactNode } from 'react';
import { getMyInfo } from '../services/UserService';
import { PanelUserExtended } from '../interfaces/User';
import { useSessionStorage } from '../helpers/Hooks/useSessionStorage';

export interface AuthProviderStore {
	isAuth: boolean;
	token: string;
	user: PanelUserExtended;
}

export interface AuthUpdateProviderStore {
	setIsAuth: (isAuth: boolean) => void;
	setToken: (token: string) => void;
	setUser: (user: PanelUserExtended) => void;
}

export const AuthContext = React.createContext({} as AuthProviderStore);
export const AuthUpdateContext = React.createContext({} as AuthUpdateProviderStore);

const mockedUser = {
	id: 1,
	name: 'John Doe',
	role: 0,
	isDisabled: true,
	updatedAt: new Date().toLocaleDateString(),
	username: '',
};

const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const { isKeyStored } = useSessionStorage('token');
	const [isAuth, setIsAuth] = React.useState<boolean>(isKeyStored());
	const [token, setToken] = React.useState<string>('');
	const [user, setUser] = React.useState<PanelUserExtended>(mockedUser);

	const store: AuthProviderStore = {
		isAuth,
		token,
		user,
	};

	const updateStore: AuthUpdateProviderStore = {
		setIsAuth,
		setToken,
		setUser,
	};

	React.useEffect(() => {
		if (isAuth) {
			(async (): Promise<void> => {
				try {
					const myUser = await getMyInfo();

					setUser(myUser);
				} catch (e) {
					console.log(e);
				}
			})();
		}
	}, [isAuth]);

	return (
		<AuthContext.Provider value={store}>
			<AuthUpdateContext.Provider value={updateStore}>
				{children}
			</AuthUpdateContext.Provider>
		</AuthContext.Provider>
	);
};

export { AuthProvider };
export const AuthConsumer = AuthContext.Consumer;
