export default {
	es: {
		subHeader: {
			PRINCIPAL_FOLDER: 'Mi unidad',
			BUTTON_NEW_FOLDER: 'Nueva carpeta',
		},
	},
	en: {
		subHeader: {
			PRINCIPAL_FOLDER: 'My unit',
			BUTTON_NEW_FOLDER: 'New folder',
		},
	},
};
