import { CreateFolderModalProps } from './CreateFolderModal';
import { Folder } from '../../interfaces/Folder';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CreateFolderInterface {
	name: string;
	nameError: boolean;
	nameErrorMessage: string;
	handleSetTitle: (value: string) => void;
	description: string;
	handleSetDescription: (value: string) => void;
	handleCreate: () => void;
	handleCloseModal: () => void;
	handleEdit: () => void;
}

const useCreateFolder = (props: CreateFolderModalProps): CreateFolderInterface => {
	const { t } = useTranslation();
	const [name, setName] = React.useState<string>('');
	const [nameError, setNameError] = React.useState<boolean>(false);
	const [nameErrorMessage, setNameErrorMessage] = React.useState<string>('');
	const [description, setDescription] = React.useState<string>('');

	const handleSetTitle = (value: string): void => {
		setName(value);
		setNameError(value.length < 1);
		setNameErrorMessage(value.length < 1 ? t('folder:MODAL_TITLE_ERROR') : '');
	};

	const handleSetDescription = (value: string): void => {
		setDescription(value);
	};

	const handleCreate = (): void => {
		const newFolder: Folder = {
			name,
			description,
			parentFolder: props.parentFolder,
			type: props.type,
			isDisabled: false,
			createdAt: new Date().toString(),
			updatedAt: new Date().toString(),
			createdBy: {
				name: '',
			},
			updatedBy: {
				name: '',
			},
		};

		props.handleCreateFolder(newFolder);
	};

	const handleEdit = (): void => {
		if (props.folderToEdit) {
			const editFolder = { ...props.folderToEdit };

			editFolder.name = name;
			editFolder.description = description;
			props.handleEditFolder(editFolder);
		}
	};

	const resetFields = (): void => {
		setName('');
		setNameError(false);
		setDescription('');
		setNameErrorMessage('');
	};

	const handleCloseModal = (): void => {
		props.handleOnClose();
	};

	React.useEffect(() => {
		if (!props.open) {
			resetFields();
		} else {
			if (props.isEdit && props.folderToEdit) {
				setName(props.folderToEdit.name);
				setDescription(props.folderToEdit.description || '');
			}
		}
	}, [props.folderToEdit, props.isEdit, props.open]);

	return {
		name,
		nameError,
		nameErrorMessage,
		description,
		handleSetTitle,
		handleSetDescription,
		handleCreate,
		handleCloseModal,
		handleEdit,
	};
};

export default useCreateFolder;
