export default {
	es: {
		dropZone: {
			text: 'Suelta tu archivo aquí o elige uno de tus carpetas',
		},
	},
	en: {
		dropZone: {
			text: 'Drag and drop a file here or click',
		},
	},
};
