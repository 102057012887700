import './ReportModal.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { enUS, es } from 'date-fns/esm/locale';
import { DateRange } from 'react-date-range';
import DefaultSelect from '../Inputs/Select';
import Modal from '../Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ReportModal = (props) => {
	const { t, i18n } = useTranslation();

	const [selectedReport, setSelectedReport] = React.useState('1');
	const [dateRange, setDateRange] = React.useState(
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		}
	);

	const handleChange = (ranges) => {
		const newRange = { ...dateRange, startDate: ranges.selection?.startDate || dateRange.startDate, endDate: ranges.selection?.endDate || dateRange.endDate };

		setDateRange(newRange);
	};

	return (
		<div>
			<Modal
				title={t('report:TITLE')}
				open={props.openModal}
				handleOnClose={() => props.setOpenModal(false)}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: t('report:BUTTON_SAVE'),
					handleOnclick: () => props.setOpenModal(false),
				}}
				isSecondaryButtonVisible={true}
				secondaryButtonProps={{
					name: t('report:BUTTON_CANCEL'),
					handleOnclick: () => props.setOpenModal(false),
				}}
			>
				<div className='calendar'>
					<DefaultSelect
						placeholder={t('report:DROPDOWN_TEXT')}
						options={[{ key: '1', value: 'Lista de terapeutas' }, { key: '2', value: 'Lista de pacientes' }]}
						value={selectedReport}
						onChange={(e) => { setSelectedReport(e.target.value); }}
						isFullWidth
						margin='dense'
						classes={{ root: 'form-select' }}
					/>
					<div className='calendar-title'>{t('report:CALENDAR_TITLE')}</div>
					<div className='date-range'>
						<DateRange
							onChange={handleChange}
							ranges={[dateRange]}
							startDatePlaceholder={t('report:CALENDAR_FROM')}
							endDatePlaceholder={t('report:CALENDAR_TO')}
							locale={i18n.language.startsWith('es') ? es : enUS}
							showMonthAndYearPickers={false}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default ReportModal;
