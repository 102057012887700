export default {
	es: {
		view: {
			SIGNIN_BUTTON: 'Ingresar',
			SIGNIN_BUTTON_GOOGLE: 'Iniciar sesión con  Google',
			SIGNIN_FORGOT_PASSWORD: '¿Olvidaste la contraseña?',
			SIGNIN_LABEL_EMAIL: 'Email',
			SIGNIN_LABEL_PASSWORD: 'Contraseña',
			SIGNIN_MESSAGE: 'Login para acceder al Dashboard',
			SIGNIN_REMEMBER_ME: 'Recuerdame',
			SIGNIN_SPAN_OR: 'o',
			SIGNIN_USER_NOT_FOUND: 'El usuario o contraseña son incorrectos.',
			SIGNIN_USER_ALREADY_EXISTS: 'Un usuario con esta cuenta ya existe.',
		},
		usernameValidationError: {
			MIN_LENGTH: 'El mínimo número de caracteres es {minRequiredLength}',
		},
	},
	en: {
		view: {
			SIGNIN_BUTTON: 'Sign in',
			SIGNIN_BUTTON_GOOGLE: 'Sign in with Google',
			SIGNIN_FORGOT_PASSWORD: 'Forgot your password?',
			SIGNIN_LABEL_EMAIL: 'Email',
			SIGNIN_LABEL_PASSWORD: 'Password',
			SIGNIN_MESSAGE: 'Login to access the Dashboard',
			SIGNIN_REMEMBER_ME: 'Remember me',
			SIGNIN_SPAN_OR: 'or',
			SIGNIN_USER_NOT_FOUND: 'Wrong username or password.',
			SIGNIN_USER_ALREADY_EXISTS: 'An user with this account exists already.',
		},
		usernameValidationError: {
			MIN_LENGTH: 'Minimum number of characters is {minRequiredLength}',
		},
	},
};
