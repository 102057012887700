import { clearSessionStorage, getAuthToken, getRefreshToken, setAuthToken, setRefreshToken } from './SessionStorageService';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

axios.interceptors.request.use(async config => {
	const authToken = getAuthToken();

	if (authToken) {
		config.headers.Authorization = 'Bearer ' + authToken;
	}

	return config;
}, error => {
	return Promise.reject(error);
});

axios.interceptors.response.use(async response => {
	return response.data;
}, async (error) => {
	const originalRequest = error.config;

	if (error.response && error.response.status === 401 && !originalRequest._retry) {
		originalRequest._retry = true;
		const refreshToken = getRefreshToken();

		if (refreshToken) {
			if (apiUrl) {
				try {
					return axios.create().post(
						`${apiUrl}/auth/signin`,
						{
							// eslint-disable-next-line @typescript-eslint/camelcase
							grant_type: 'refresh_token',
							// eslint-disable-next-line @typescript-eslint/camelcase
							client_id: clientId,
							// eslint-disable-next-line @typescript-eslint/camelcase
							client_secret: clientSecret,
							// eslint-disable-next-line @typescript-eslint/camelcase
							refresh_token: refreshToken,
						}
					).then(async result => {
						setAuthToken(result.data.access_token);
						setRefreshToken(result.data.refresh_token);

						axios.defaults.headers.Authorization = 'Bearer ' + result.data.access_token;
						originalRequest.headers['Authorization'] = 'Bearer ' + result.data.access_token;

						return axios(originalRequest);
					}).catch(() => {
						clearSessionStorage();
						window.location.href = apiUrl;
					});
				} catch {
					clearSessionStorage();
					window.location.href = apiUrl;
				}
			}

			return Promise.reject('API url not defined');
		}
	}

	if (error.response) {
		throw ({
			name: error.response.data.name,
			message: error.response.data.message,
		});
	}

	return Promise.reject(error);
});
