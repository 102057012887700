import './ProfileMenu.scss';
import ActionButton from '../Buttons/ActionButton';
import { AuthUpdateContext } from '../../contexts/AuthContext';
import { Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import { getRoleName } from '../../services/UserService';
import Menu from '@material-ui/core/Menu';
import { PanelUserExtended } from '../../interfaces/User';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import useRoutes from '../Hooks/useRoutes';
import { useSessionStorage } from '../../helpers/Hooks/useSessionStorage';
import { useTranslation } from 'react-i18next';

interface ProfileMenuProps {
	user: PanelUserExtended;
	handleOpenProfileModal: (flag: boolean) => void;
	profileAnchorEl: null | HTMLElement;
	handleClose: () => void;
}

const ProfileMenu: React.FC<ProfileMenuProps> = (props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { ROUTES } = useRoutes();
	const { removeItem } = useSessionStorage('token');
	const { setIsAuth } = React.useContext(AuthUpdateContext);

	const signOut = (): void => {
		removeItem();
		setIsAuth(false);
		history.push(ROUTES.SIGNIN);
		props.handleClose();
	};

	return (
		<Menu
			anchorEl={props.profileAnchorEl}
			classes={{
				paper: 'profile-menu',
			}}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={Boolean(props.profileAnchorEl)}
			onClose={props.handleClose}
			variant='menu'
		>
			<Card className="card">
				<div className="card-avatar-container">
					<Avatar
						src={props.user.avatarUrl}
						className="card-media"
					/>
				</div>
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						{props.user.name}
					</Typography>
					<Typography variant="body2" color="textSecondary" component="p">
						{t(`roles:${getRoleName(props.user.role)}`)}
					</Typography>
				</CardContent>
				<CardActions className="card-actions">
					<ActionButton
						name={t('profile:UPDATE_PROFILE')}
						onClick={(): void => props.handleOpenProfileModal(true)}
					/>
					<ActionButton
						name={t('profile:LOG_OUT')}
						onClick={(): void => signOut()}
						type='secondary'
					/>
				</CardActions>
			</Card>
		</Menu>
	);
};

export default ProfileMenu;
