import './DefaultCheckbox.scss';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { CheckBox as CheckBoxIcon } from '@material-ui/icons';
import React from 'react';

export interface DefaultCheckboxProps {
	onChange: () => void;
	checked: boolean;
	isDisabled?: boolean;
	name?: string;
	label?: string;
}

const DefaultCheckbox: React.FC<DefaultCheckboxProps> = (props) => {
	return (
		<>
			{
				props.label
					? (
						<FormControlLabel
							control={
								<Checkbox
									name={props.name}
									checked={props.checked}
									onChange={props.onChange}
									className="default-checkbox"
									icon={<span className="checkbox-icon-style" />}
									checkedIcon={<CheckBoxIcon className="checkbox-icon-checked-style" />}
								/>
							}
							id="rememberMe"
							label={<Typography className='checkbox-label'>{props.label}</Typography>}
						/>

					)
					: (
						<Checkbox
							name={props.name}
							checked={props.checked}
							onChange={props.onChange}
							className="default-checkbox"
							icon={<span className="checkbox-icon-style" />}
							checkedIcon={<CheckBoxIcon className="checkbox-icon-checked-style" />}
						/>
					)
			}
		</>

	);
};

export default DefaultCheckbox;
