import React from 'react';
import { useParams } from 'react-router-dom';

const UsersEdit: React.FC = () => {
	const { id } = useParams();

	return (
		<div>
			<h1>This is UsersEdit page!. About to edit id: {id}</h1>
			<h3>This is a sub page of Users page</h3>
		</div>
	);
};

export default UsersEdit;
