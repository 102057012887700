import QueryString from 'query-string';
import { useLocation } from 'react-router-dom';

export const useQueryParams = (): { [key: string]: string | string[] | null } => {
	return QueryString.parse(useLocation().search);
};

export const useQueryParam = (name: string): string | string[] | null => {
	const queryParam = useQueryParams();

	return queryParam[name];
};
