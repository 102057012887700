import { CustomVariable, CustomVariableTypes, OperatorType, ValueType, VariableValue } from '../../interfaces/Documents/CustomVariables/CustomVariable';
import { ListCustomVariable, ListOrderType, ListRowType, ListSpecs, ListType } from '../../interfaces/Documents/CustomVariables/ListCustomVariable';
import { LogicCustomVariable, LogicOperationType } from '../../interfaces/Documents/CustomVariables/LogicCustomVariable';
import { MathematicalCustomVariable, MathematicalOperationType } from '../../interfaces/Documents/CustomVariables/MathematicalCustomVariable';
import { CreateVariableModalProps } from './CreateVariableModal';
import ListOperator from './ListOperator/ListOperator';
import LogicOperator from './LogicOperator/LogicOperator';
import MathematicalOperator from './MathematicalOperator/MathematicalOperator';
import React from 'react';
import { SwitchCustomVariable } from '../../interfaces/Documents/CustomVariables/SwitchCustomVariable';
import SwitchOperator from './SwtichOperator/SwitchOperator';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { VariableType } from '../../interfaces/Documents/Variables/Variable';

export const DefaultVariableValue: VariableValue = {
	valueType: ValueType.Variable,
	value: '',
	order: 1,
};

export const DefaultVariable: LogicCustomVariable = {
	id: undefined,
	code: '',
	isVisible: true,
	operatorType: OperatorType.Logic,
	type: VariableType.Boolean,
	specs: {
		operation: LogicOperationType.And,
		values: [
			{ ...DefaultVariableValue },
			{ ...DefaultVariableValue, order: 2 },
		],
	},
};

interface CreateVariableModalInterface {
	variableData: CustomVariable;
	updateVariableData: (name: string, value: string) => void;
	changeVariableType: (operatorType: OperatorType) => void;
	updateVariableVisible: (value: string) => void;
	renderSpecSection: (operatorType: OperatorType) => JSX.Element;
	handleSaveNewVariable: () => void;
	saveAfterConfirmation: () => void;
	handleCloseModal: () => void;
	warningMessageValidation: string | undefined;
	warningMessageReferences: string | undefined;
	handleCloseWarningMessage: () => void;
}

const useCreateVariableModal = (props: CreateVariableModalProps): CreateVariableModalInterface => {
	const { t } = useTranslation();

	const [variableData, setVariableData] = React.useState<CustomVariableTypes>({ ...DefaultVariable, id: uuidv4(), specs: { ...DefaultVariable.specs, operation: LogicOperationType.And, values: [...DefaultVariable.specs?.values || []] } });
	const [warningMessageValidation, setWarningMessageValidation] = React.useState<string | undefined>(undefined);
	const [warningMessageReferences, setWarningMessageReferences] = React.useState<string | undefined>(undefined);

	const variablesOptions = props.variables.filter(x => x.key !== variableData.id);

	const changeVariableType = (operatorType: OperatorType): void => {
		switch (operatorType) {
			case OperatorType.Logic:
				setVariableData({
					...variableData,
					operatorType: OperatorType.Logic,
					specs: {
						operation: LogicOperationType.And,
						values: [...DefaultVariable.specs?.values || []],
					},
					type: VariableType.Boolean,
				} as unknown as LogicCustomVariable);
				break;
			case OperatorType.Mathematical:
				setVariableData({
					...variableData,
					operatorType: OperatorType.Mathematical,
					specs: {
						operation: MathematicalOperationType.Sum,
						values: [...DefaultVariable.specs?.values || []],
					},
					type: VariableType.Number,
				} as unknown as MathematicalCustomVariable);
				break;
			case OperatorType.Switch:
				setVariableData({
					...variableData,
					operatorType: OperatorType.Switch,
					specs: {
						defaultType: ValueType.Variable,
						defaultValue: '',
						switchValue: '',
						values: [
							{ ...DefaultVariableValue, thenType: ValueType.Variable, thenValue: '' },
							{ ...DefaultVariableValue, order: 2, thenType: ValueType.Variable, thenValue: '' },
						],
					},
				} as unknown as SwitchCustomVariable);
				break;
			case OperatorType.List:
				setVariableData({
					...variableData,
					operatorType: OperatorType.List,
					specs: {
						value: '',
						type: ListType.ORDERED,
						orderType: ListOrderType.ALPHABETIC,
					},
					type: VariableType.List,
				} as unknown as ListCustomVariable);
		}
	};

	const updateVariableData = (name: string, value: string): void => {
		if (name === 'code' && (value.includes('<') || value.includes('>'))) {
			return;
		}

		setVariableData(p => ({ ...p, [name]: value }));
	};

	const updateVariableVisible = (value: string): void => {
		setVariableData(p => ({ ...p, isVisible: value === 'public' ? true : false }));
	};

	const handleUpdateVariable = (variable: CustomVariableTypes): void => {
		setVariableData(variable);
	};

	const arethereCircularReferences = (variableId: string, newReferenceId: string): boolean => {
		let result = false;

		if (!props.isEdit) {
			return result;
		}

		const references = props.getListOfIdReferences(variableId);

		if (references.length > 0 && references.some(x => x === newReferenceId)) {
			setWarningMessageValidation(t('variableModal:MODAL_WARNING_CIRCULAR_REFERENCES'));

			result = true;
		}

		return result;
	};

	const renderSpecSection = (type: OperatorType): JSX.Element => {
		switch (type) {
			case OperatorType.Logic:
				return (
					<LogicOperator
						variableData={variableData as LogicCustomVariable}
						handleUpdateVariableData={handleUpdateVariable}
						variables={variablesOptions}
						arethereCircularReferences={arethereCircularReferences}
					/>
				);
			case OperatorType.Mathematical:
				return (
					<MathematicalOperator
						variableData={variableData as MathematicalCustomVariable}
						handleUpdateVariableData={handleUpdateVariable}
						variables={variablesOptions}
						arethereCircularReferences={arethereCircularReferences}
					/>
				);
			case OperatorType.Switch:
				return (
					<SwitchOperator
						variableData={variableData as SwitchCustomVariable}
						handleUpdateVariableData={handleUpdateVariable}
						variables={variablesOptions}
						arethereCircularReferences={arethereCircularReferences}
					/>
				);
			case OperatorType.List:
				return (
					<ListOperator
						variableData={variableData as ListCustomVariable}
						handleUpdateVariableData={handleUpdateVariable}
						variables={variablesOptions}
						arethereCircularReferences={arethereCircularReferences}
					/>
				);
			default:
				return (
					<LogicOperator
						variableData={variableData as LogicCustomVariable}
						handleUpdateVariableData={handleUpdateVariable}
						variables={variablesOptions}
						arethereCircularReferences={arethereCircularReferences}
					/>
				);
		}
	};

	const isVariableCodeUnique = (): boolean => {
		const isExist = props.variables.find(x => x.value === variableData.code.trim() && x.key !== variableData.id);

		return !isExist;
	};

	const isVariableValid = (): boolean => {
		if (variableData.code === '') {
			return false;
		}

		if (variableData.operatorType === OperatorType.Logic || variableData.operatorType === OperatorType.Mathematical) {
			if (variableData.specs && variableData.specs.values && !(variableData.specs?.values.findIndex(x => x.value === '') > -1)) {
				return true;
			}
		} else if (variableData.operatorType === OperatorType.Switch) {
			if (variableData.specs?.defaultType) {
				if (variableData.specs && variableData.specs.values && !(variableData.specs?.values.findIndex(x => x.value === '' || x.thenValue === '') > -1) && (variableData.specs.defaultValue !== '')) {
					return true;
				}
			} else {
				if (variableData.specs && variableData.specs.values && !(variableData.specs?.values.findIndex(x => x.value === '' || x.thenValue === '') > -1)) {
					return true;
				}
			}
		} else if (variableData.operatorType === OperatorType.List) {
			if ((variableData.specs as ListSpecs)?.orderType === ListRowType.ROW) {
				if ((variableData.specs as ListSpecs).value && (variableData.specs as ListSpecs).joinRow) {
					return true;
				}
			} else {
				if ((variableData.specs as (ListSpecs))?.value) {
					return true;
				}
			}
		}

		return false;
	};

	const handleCloseModal = (): void => {
		const variable = { ...DefaultVariable, id: uuidv4() };

		if (DefaultVariable.specs && DefaultVariable.specs.values) {
			const specs = { ...DefaultVariable.specs };
			const values = [...DefaultVariable.specs?.values];

			variable.specs = specs;
			variable.specs.values = values;
			setVariableData(variable);
			props.handleOnClose();
		}
	};

	const saveAfterConfirmation = (): void => {
		props.handleSaveVariable(variableData, props.isEdit);
		handleCloseModal();
	};

	const handleSaveNewVariable = (): void => {
		if (isVariableCodeUnique()) {
			if (isVariableValid()) {
				if (props.isEdit) {
					setWarningMessageReferences(t('variableModal:MODAL_WARNING_REFEFERENCES'));
				} else {
					saveAfterConfirmation();
				}
			} else {
				setWarningMessageValidation(t('variableModal:MODAL_WARNING_INCOMPLETE_FIELDS'));
			}
		} else {
			setWarningMessageValidation(t('variableModal:MODAL_WARNING_DUPLICATE_NAME'));
		}
	};

	const handleCloseWarningMessage = (): void => {
		setWarningMessageValidation(undefined);
		setWarningMessageReferences(undefined);
	};

	React.useEffect(() => {
		if (props.isEdit && props.customVariableToEdit) {
			setVariableData(props.customVariableToEdit);
		}
	}, [props.customVariableToEdit, props.isEdit]);

	return {
		variableData,
		updateVariableData,
		changeVariableType,
		updateVariableVisible,
		renderSpecSection,
		handleSaveNewVariable,
		saveAfterConfirmation,
		handleCloseModal,
		warningMessageValidation,
		warningMessageReferences,
		handleCloseWarningMessage,
	};
};

export default useCreateVariableModal;
