import { useTranslation } from 'react-i18next';

interface RouteInterface {
	ROUTES: { [KEY: string]: string };
	TABS_ROUTES: { [KEY: string]: string };
}

const useRoutes = (): RouteInterface => {
	const { t } = useTranslation();

	const TABS_ROUTES = {
		DASHBOARD: t('route:DASHBOARD'),
		DOCUMENTS: t('route:DOCUMENTS'),
		RESOURCES: t('route:RESOURCES'),
		CONTENT: t('route:CONTENT'),
		USERS: t('route:USERS'),
		CLIENTS: t('route:CLIENTS'),
	};

	const ROUTES = {
		DEFAULT: '/',
		BLOG: '/blog',
		CHANGE_PASSWORD: t('route:CHANGE_PASSWORD'),
		ERROR: '/error',
		RESOURCE_FOLDER: t('route:RESOURCE_FOLDER'),
		FORGOT_PASSWORD: t('route:FORGOT_PASSWORD'),
		DOCUMENTS_FOLDER: t('route:DOCUMENTS_FOLDER'),
		HOME: t('route:HOME'),
		INVITATION: '/invitation',
		PLANS: t('route:PLANS'),
		PROFILE: t('route:PROFILE'),
		SIGNIN: '/signin',
		SIGNUP: '/signup',
		ADD: t('route:ADD'),
		...TABS_ROUTES,
	};

	return { ROUTES, TABS_ROUTES };
};

export default useRoutes;
