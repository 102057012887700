import { getAllTags, saveNewTag } from '../api/TagBlogRepository';
import { Tag } from '../interfaces/Tag';

export const getTags = async (): Promise<Array<Tag>> => {
	return await getAllTags();
};

export const saveTag = async (tag: Tag): Promise<void> => {
	return await saveNewTag(tag);
};
