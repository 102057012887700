export default {
	es: {
		navigation: {
			BACK_BUTTON_TEXT: 'Volver',
			FORWARD_BUTTON_TEXT: 'Avanzar',
		},
		action: {
			SAVE_BUTTON: 'Guardar',
			CANCEL_BUTON: 'Cancelar',
		},
	},
	en: {
		navigation: {
			BACK_BUTTON_TEXT: 'Back',
			FORWARD_BUTTON_TEXT: 'Forward',
		},
		action: {
			SAVE_BUTTON: 'Save',
			CANCEL_BUTON: 'Cancel',
		},
	},
};
