type useLocalStorageType = {
	(key: string): {
		isKeyStored: () => boolean;
		setItem: (value: string) => void;
		getItem: () => string;
		unsetItem: () => void;
	};
}

export const useLocalStorage: useLocalStorageType = (key: string) => {
	const isKeyStored: () => boolean = () => {
		return window.localStorage.getItem(key) !== null;
	};

	const setItem: (value: string) => void = (value) => {
		window.localStorage.setItem(key, value);
	};

	const getItem: () => string = () => {
		return window.localStorage.getItem(key) as string;
	};

	const unsetItem: () => void = () => {
		window.localStorage.removeItem(key);
	};

	return {
		isKeyStored,
		setItem,
		getItem,
		unsetItem,
	};
};
