import { CustomVariable, OperatorType } from './CustomVariable';

export enum ListType {
	ORDERED = 'ORDERED',
	UNORDERED = 'UNORDERED',
}

export enum ListOrderType {
	NUMERIC = 'NUMERIC',
	ALPHABETIC = 'ALPHABETIC',
	ROMAN = 'ROMAN',
}

export enum ListUnorderType {
	DOTTED = 'DOTTED',
	DASHED = 'DASHED',
}

export enum ListRowType {
	ROW = 'ROW',
}

export interface ListSpecs {
	orderType: ListOrderType | ListUnorderType | ListRowType;
	type: ListType;
	value: string;
	joinRow?: string;
}

// export type ListSpecsTypes = OrderedSpecs | UnorderedSpecs | ListSpecs;
export type ListSpecsTypes = ListSpecs;

export interface ListCustomVariable extends CustomVariable {
	operatorType: OperatorType.List;
	specs: ListSpecsTypes;
}
