import './forms.scss';
import { Add, EditOutlined, MoreVert, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { Document, DocumentTable } from '../../interfaces/Documents/Document';
import { Folder, FolderType } from '../../interfaces/Folder';
import { IconButton, Menu, MenuItem, TableBody } from '@material-ui/core';
import Cell from '../../components/Cell';
import CellDescription from '../../components/CellDescription';
import CreateFolderModal from '../../components/CreateFolderModal';
import DefaultTable from '../../components/Table';
import Header from '../../components/ContainerHeader/Header';
import MoveFolderFile from '../../components/MoveFolderFile';
import React from 'react';
import Row from '../../components/Row';
import SubHeader from '../../components/SubHeader';
import useForms from './useForms';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../components/Hooks/useRoutes';
import { useTranslation } from 'react-i18next';

const Forms: React.FC = () => {
	const { t } = useTranslation();
	const { ROUTES } = useRoutes();
	const history = useHistory();

	const {
		folders,
		documents,
		handleChangeFolder,
		filterSelected,
		onChangeFilter,
		breadCrumbsFolder,
		handleDocumentActions,
		documentAnchorEl,
		enableDisableRow,
		openNewFolderModal,
		isEdit,
		handleOpenNewFolderModal,
		handleCloseNewFolderModal,
		handleCreateNewFolder,
		folderToEdit,
		handleOpenEditFolderModal,
		handleEditFolder,
		handleFolderActions,
		folderAnchorEl,
		handleClosePopover,
		openFolderFileMoveModal,
		folderFileToMove,
		handleOpenFolderFileMoveModal,
		handleCloseFolderFileMoveModal,
		handleFinishMove,
		isFolder,
		handleDuplicateDocument,
	} = useForms();

	return (
		<div className='forms-container'>
			<Header
				leftTopCornerProps={{
					title: t('formsView:HEADER_TITLE'),
					isActionButtonVisible: true,
					actionButtonProps: {
						onClick: (): void => {
							history.push({
								pathname: `${ROUTES.DOCUMENTS}${ROUTES.ADD}`,
								search: `?folder-id=${breadCrumbsFolder?.id}`,
							});
						},
						name: t('formsView:HEADER_ACTION_BUTTON'),
						startIcon: (<Add />),
						variant: 'contained',
					},
				}}
				rightTopCornerProps={{
					isFilterVisible: true,
					filterProps: {
						label: t('formsView:HEADER_FILTER_LABEL'),
						value: filterSelected,
						onChange: onChangeFilter,
						options: [{ key: 1, value: t('formsView:FILTER_OPTION_ALL') }, { key: 2, value: t('formsView:FILTER_OPTION_ENABLE') }, { key: 3, value: t('formsView:FILTER_OPTION_DISABLED') }],
					},
				}}
			/>
			<SubHeader
				folder={breadCrumbsFolder}
				onClick={handleChangeFolder}
				isActionButtonVisible={true}
				actionButtonProps={{
					name: t('formsView:NEW_FOLDER_ACTION_BUTTON'),
					onClick: handleOpenNewFolderModal,
					startIcon: (<Add />),
				}}
			/>
			<DefaultTable>
				<TableBody className='table-body-forms'>
					{
						folders.map((item, key) => (
							<Row
								key={key}
								isFolder={true}
								onClick={(): void => handleChangeFolder(item.id)}
							>
								<Cell className='size-cell-large' align='left'>
									<CellDescription
										title={item.name}
										description={item.description}
										type='Folder'
									/>
								</Cell>
								<Cell className='size-cell-medium' align='center'>
									{new Date(item.updatedAt).toLocaleDateString()}
								</Cell>
								<Cell className='size-cell-medium' align='left'>
									{item.createdBy.name}
								</Cell>
								<Cell className='size-cell-large' align='right'>
									<IconButton
										onClick={(e): void => handleFolderActions(e, item as Folder)}
									>
										<MoreVert className='icon' />
									</IconButton>
								</Cell>
							</Row>
						))
					}
					{
						documents.map((item, key) => (
							<Row
								key={key}
								isFolder={false}
								isDisabled={item.isDisabled}
							>
								<Cell className='size-cell-large' align='left'>
									<CellDescription
										title={item.name}
										description={item.description}
										type='File'
									/>
								</Cell>
								<Cell className='size-cell-medium' align='center'>
									{new Date(item.updatedAt).toLocaleDateString()}
								</Cell>
								<Cell className='size-cell-medium' align='left'>
									{item.createdBy.name}
								</Cell>
								<Cell className='size-cell-large' align='right'>
									<IconButton
										className='icon'
										onClick={(): void => {
											history.push({
												pathname: `${ROUTES.DOCUMENTS}/${item.id}`,
												search: `?folder-id=${breadCrumbsFolder?.id}&document-id=${item.id}`,
											});
										}}
									>
										<EditOutlined className='icon' />
									</IconButton>
									<IconButton
										onClick={(): void => enableDisableRow(item as DocumentTable)}
									>
										{
											item.isDisabled ? <VisibilityOffOutlined className='icon' /> : <VisibilityOutlined className='icon' />
										}
									</IconButton>
									<IconButton
										onClick={(e): void => handleDocumentActions(e, item as Document)}
									>
										<MoreVert className='icon' />
									</IconButton>
								</Cell>
							</Row>
						))
					}
				</TableBody>
			</DefaultTable>
			<Menu
				className='popover-menu'
				anchorEl={folderAnchorEl}
				keepMounted
				open={Boolean(folderAnchorEl)}
				onClose={handleClosePopover}
			>
				<MenuItem className='menu-item' onClick={handleOpenEditFolderModal}>{t('formsView:POPOVER_OPTION_EDIT_FOLDER')}</MenuItem>
				<MenuItem className='menu-item' onClick={handleOpenFolderFileMoveModal}>{t('formsView:POPOVER_OPTION_MOVE')}</MenuItem>
			</Menu>
			<Menu
				className='popover-menu'
				anchorEl={documentAnchorEl}
				keepMounted
				open={Boolean(documentAnchorEl)}
				onClose={handleClosePopover}
			>
				<MenuItem className='menu-item' onClick={handleOpenFolderFileMoveModal}>{t('formsView:POPOVER_OPTION_MOVE')}</MenuItem>
				<MenuItem className='menu-item' onClick={handleDuplicateDocument}>{t('formsView:POPOVER_OPTION_DUPLICATE')}</MenuItem>
			</Menu>
			{
				openNewFolderModal &&
				(
					<CreateFolderModal
						open={openNewFolderModal}
						handleOnClose={handleCloseNewFolderModal}
						type={FolderType.DOCUMENT}
						handleCreateFolder={handleCreateNewFolder}
						parentFolder={breadCrumbsFolder}
						isEdit={isEdit}
						folderToEdit={folderToEdit}
						handleEditFolder={handleEditFolder}
					/>
				)
			}
			{
				openFolderFileMoveModal &&
				(
					<MoveFolderFile
						openModal={openFolderFileMoveModal}
						handleCloseModal={handleCloseFolderFileMoveModal}
						folderItemType={FolderType.DOCUMENT}
						parentFolder={breadCrumbsFolder}
						folderFileToMove={folderFileToMove}
						handleFinishMove={handleFinishMove}
						isFolder={isFolder}
					/>
				)
			}
		</div>
	);
};

export default Forms;
