import { DocumentContext, DocumentUpdateContext } from '../../../../contexts/DocumentContext';
import { BooleanQuestion } from '../../../../interfaces/Documents/Questions/BooleanQuestion';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import { Section } from '../../../../interfaces/Documents/Section';
import { useTranslation } from 'react-i18next';

interface BooleanPreviewInterface {
	question: BooleanQuestion;
	errorMessage: string;
	showErrors: boolean;
	handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useBooleanPreview = (props: QuestionPreviewProps): BooleanPreviewInterface => {
	const { t } = useTranslation();
	const { documentModel, documentModel: { showErrors } } = React.useContext(DocumentContext);
	const { handleUpdateQuestion } = React.useContext(DocumentUpdateContext);
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const question = documentModel.sections[props.sectionIndex].questions[props.questionIndex] as BooleanQuestion;

	const isValueValid = (value: string): boolean => {
		if (!question.required) {
			return true;
		}

		return question.options.some((option) => option.id === value);
	};

	React.useEffect(() => {
		const isValid = !question.required;

		setErrorMessage(t('formsView:MODAL_PREVIEW_FIELD_REQUIRED'));

		handleUpdateQuestion({ ...question, isValid }, documentModel.sections[props.sectionIndex] as Section);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question.required]);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = (event.target as HTMLInputElement);
		const isValid: boolean = isValueValid(value);

		setErrorMessage(isValid ? '' : t('formsView:MODAL_PREVIEW_FIELD_REQUIRED'));
		handleUpdateQuestion({ ...question, value, isValid }, documentModel.sections[props.sectionIndex] as Section);
	};

	return {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	};
};

export default useBooleanPreview;
