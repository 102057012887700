import { DocumentContext, DocumentUpdateContext } from '../../contexts/DocumentContext';
import { QuestionType, QuestionTypes } from '../../interfaces/Documents/Questions/Question';
import { Document } from '../../interfaces/Documents/Document';
import React from 'react';

interface PrevieModalHookProps {
	validate?: () => void;
	close?: () => void;
 }

interface PrevieModalInterface {
	documentModel: Document;
	sectionsToShow: string;
	isFirstStep: () => boolean;
	isLastStep: () => boolean;
	handleBack: () => void;
	handleClosePreviewModal: () => void;
	handleNextButton: () => void;
}

const usePreviewModal = (props: PrevieModalHookProps): PrevieModalInterface => {
	const { documentModel, isDocumentValid, sectionsToShow } = React.useContext(DocumentContext);
	const { handleSectionsToShow, handleResetDocument, hideErrorsInForm, showErrorsInForm } = React.useContext(DocumentUpdateContext);
	const [firstStep, setFirstStep] = React.useState<string>();
	const [lastStep, setLastStep] = React.useState<string>();
	const [sectionsAnswered, setSectionsAnswered] = React.useState<string[]>([]);

	const setSteps = React.useCallback(() => {
		setFirstStep(documentModel.sections[0]?.id);
		setLastStep(documentModel.sections[documentModel.sections.length - 1]?.id);
	}, [documentModel.sections]);

	React.useEffect(() => {
		if (sectionsToShow) {
			const currentSection = documentModel.sections.find(x => x.id === sectionsToShow);

			if (currentSection) {
				handleSectionsToShow(currentSection.id);
			}

		} else {
			handleSectionsToShow(documentModel.sections[0]?.id);
		}

		setSteps();
	}, [documentModel.sections, handleSectionsToShow, sectionsToShow, setSteps]);

	const getNextSection = (): string => {
		const currentStepOrder = documentModel.sections.find(x => x.id === sectionsToShow)?.order;

		if (currentStepOrder) {
			const nextSectionId = documentModel.sections.find(x => x.order === currentStepOrder + 1)?.id;

			if (nextSectionId) {
				return nextSectionId;
			}
		}

		return sectionsToShow;
	};

	const handleBack = (): void => {
		let answeredSection = sectionsAnswered.slice();

		answeredSection = answeredSection.filter(s => s !== sectionsToShow);
		setSectionsAnswered(answeredSection);
		hideErrorsInForm();
		handleSectionsToShow(answeredSection[answeredSection.length - 1]);
	};

	const handleClosePreviewModal = (): void => {
		handleSectionsToShow('');
		handleResetDocument();
		setSectionsAnswered([]);

		if (props.close) {
			props.close();
		}
	};

	const handleNextButton = (): void => {
		if (!isDocumentValid()) {
			showErrorsInForm();

			return;
		}

		if (sectionsToShow === lastStep) {
			const sectionsToClean = documentModel.sections.reduce((sectionsIds: string[], section) => {
				if (!sectionsAnswered.includes(section.id) && section.id !== lastStep) {
					sectionsIds.push(section.id);
				}

				return sectionsIds;
			}, []);

			if (sectionsToClean.length) {
				handleResetDocument(sectionsToClean);
			}

			if(props.validate) {
				props.validate();
			}

			handleClosePreviewModal();

			return;
		}

		const currentSection = documentModel.sections.find(x => x.id === sectionsToShow);
		let nextSection: string = '';

		if (currentSection) {
			currentSection.questions.sort(x => x.order).forEach((question: QuestionTypes) => {
				if (question.type === QuestionType.Boolean || question.type === QuestionType.Radio || question.type === QuestionType.Select) {
					question.options.forEach(option => {
						if (option.goTo && option.id === question.value) {
							nextSection = option.goTo;
						}
					});
				}
			});
		}

		const answeredSection = sectionsAnswered.slice();

		if (!answeredSection.includes(sectionsToShow)) {
			answeredSection.push(sectionsToShow);
		}

		setSectionsAnswered(answeredSection);
		hideErrorsInForm();

		if (nextSection) {
			if (nextSection === 'NEXT_SECTION') {
				nextSection = getNextSection();
			} else if (nextSection === 'END' && lastStep) {
				nextSection = lastStep;
			}
		} else {
			nextSection = getNextSection();
		}

		handleSectionsToShow(nextSection);
	};

	const isFirstStep = (): boolean => sectionsToShow === firstStep;

	const isLastStep = (): boolean => sectionsToShow === lastStep;

	return {
		documentModel,
		sectionsToShow,
		isFirstStep,
		isLastStep,
		handleBack,
		handleClosePreviewModal,
		handleNextButton,
	};
};

export default usePreviewModal;
