import './ImageCrop.scss';
import { getCroppedImg, getRotatedImage } from './canvasUtils';
import React, { useCallback, useState } from 'react';
import { Area } from 'react-easy-crop/types';
import Button from '@material-ui/core/Button';
import Cropper from 'react-easy-crop';
import { getOrientation } from 'get-orientation/browser';
import ImgDialog from './ImgDialog';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

const ORIENTATION_TO_ANGLE: { [key: string]: number } = {
	'3': 180,
	'6': 90,
	'8': -90,
};

const readFile = (file: Blob): Promise<string | ArrayBuffer | null> => {
	return new Promise(resolve => {
		const reader = new FileReader();

		reader.addEventListener('load', () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
};

interface ImageCropProps {
	previewTitle?: string;
	zoomLabel?: string;
	rotationLabel?: string;
	previewButtonLabel?: string;
	uploadButtonLabel?: string;
	aspectRatio?: number;
	containerClass?: string;
	onChange: (img: Blob) => void;
}

const ImageCrop: React.FC<ImageCropProps> = ({ previewTitle, zoomLabel, rotationLabel, previewButtonLabel, uploadButtonLabel, aspectRatio, containerClass, onChange }) => {
	const [imageSrc, setImageSrc] = React.useState<string | ArrayBuffer | null>(null);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
	const [croppedImage, setCroppedImage] = useState<string | null>(null);

	const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
		const croppedImage = await getCroppedImg(
			imageSrc as string,
			croppedAreaPixels as Area,
			rotation
		);

		setCroppedAreaPixels(croppedAreaPixels);

		if (croppedImage) {
			onChange(croppedImage);
		}
	}, [imageSrc, onChange, rotation]);

	const showCroppedImage = useCallback(async (): Promise<void> => {
		try {
			const croppedImage = await getCroppedImg(
				imageSrc as string,
				croppedAreaPixels as Area,
				rotation
			);

			setCroppedImage(URL.createObjectURL(croppedImage));
		} catch (e) {
			console.error(e);
		}
	}, [imageSrc, croppedAreaPixels, rotation]);

	const onClose = useCallback((): void => {
		setCroppedImage(null);
	}, []);

	const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			let imageDataUrl = await readFile(file);

			// apply rotation if needed
			const orientation = await getOrientation(file);
			const rotation = ORIENTATION_TO_ANGLE[orientation];

			if (rotation) {
				imageDataUrl = URL.createObjectURL(await getRotatedImage(imageDataUrl as string, rotation));
			}

			setImageSrc(imageDataUrl);
		}
	};

	return (
		<div className={containerClass}>
			{imageSrc ? (
				<React.Fragment>
					<div className={'crop-container'}>
						<Cropper
							image={imageSrc as string}
							crop={crop}
							rotation={rotation}
							zoom={zoom}
							aspect={aspectRatio || 4 / 3}
							onCropChange={setCrop}
							onRotationChange={setRotation}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
						/>
					</div>
					<div className={'controls'}>
						<div className={'slider-container'}>
							<Typography
								variant="overline"
								classes={{ root: 'slider-label' }}
							>
								{ zoomLabel || 'Zoom' }
							</Typography>
							<Slider
								value={zoom}
								min={1}
								max={3}
								step={0.1}
								aria-labelledby={ zoomLabel || 'Zoom' }
								classes={{ root: 'slider' }}
								onChange={(e: unknown, zoom: number | number[]): void => setZoom(zoom as number)}
							/>
						</div>
						<div className={'slider-container'}>
							<Typography
								variant="overline"
								classes={{ root: 'slider-label' }}
							>
								{ rotationLabel || 'Rotation' }
							</Typography>
							<Slider
								value={rotation}
								min={0}
								max={360}
								step={1}
								aria-labelledby={ rotationLabel || 'Rotation' }
								classes={{ root: 'slider' }}
								onChange={(e: unknown, rotation: number | number[]): void => setRotation(rotation as number)}
							/>
						</div>
						<Button
							onClick={showCroppedImage}
							variant="contained"
							color="primary"
							classes={{ root: 'crop-button', label: 'crop-button-text' }}
						>
							{ previewButtonLabel || 'Show Result' }
						</Button>
					</div>
					<ImgDialog img={croppedImage} onClose={onClose} previewTitle={previewTitle} />
				</React.Fragment>
			) : (
				<div className={'upload-image-container'}>
					<input type="file" onChange={onFileChange} id="contained-button-file" accept="image/*" className={'upload-image-button'} />
					<label htmlFor="contained-button-file">
						<Button variant="contained" color="primary" component="span" classes={{ root: 'crop-button', label: 'crop-button-text' }}>
							{ uploadButtonLabel || 'Upload' }
						</Button>
					</label>
				</div>
			)}
		</div>
	);
};

export default ImageCrop;
