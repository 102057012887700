import { createFolder, editFolder, getFolders } from '../../services/FolderService';
import { editResource, getResources, getTags, saveResource } from '../../services/ResourceService';
import { Folder, FolderType } from '../../interfaces/Folder';
import { Resource, ResourceTable } from '../../interfaces/Resource';
import { AlertContext } from '../../contexts/AlertContext';
import React from 'react';
import { saveFile } from '../../services/FileBaseService';
import { Tag } from '../../interfaces/Tag';
import { useTranslation } from 'react-i18next';

interface ResourcesStore {
	folders: Array<Folder>;
	resources: Array<ResourceTable>;
	handleChangeFolder: (parentId: number | undefined) => void;
	breadCrumbsFolder: Folder | undefined;
	tags: Array<Tag>;
	resource: ResourceTable;
	openResourceModal: boolean;
	handleOpenModal: () => void;
	handleCloseModal: () => void;
	handleChangeStringValues: (name: string, value: string) => void;
	handleTagValues: (value: Array<Tag>) => void;
	resourceImage: File[] | undefined;
	setResourceImage: (files: File[]) => void;
	nameError: boolean;
	nameErrorMessage: string | undefined;
	handleSave: () => Promise<void>;
	isEdit: boolean;
	loadResourceToEdit: (item: ResourceTable) => void;
	handleEdit: () => Promise<void>;
	openNewFolderModal: boolean;
	handleOpenNewFolderModal: () => void;
	handleCloseNewFolderModal: () => void;
	handleCreateNewFolder: (folder: Folder) => Promise<void>;
	folderToEdit: Folder | undefined;
	folderFileToMove: Folder | Resource | undefined;
	handleOpenEditFolderModal: () => void;
	handleEditFolder: (folder: Folder) => void;
	handleFolderActions: (event: React.MouseEvent<HTMLElement>, item: Folder) => void;
	handleResourceActions: (event: React.MouseEvent<HTMLElement>, item: Resource) => void;
	folderAnchorEl: null | HTMLElement;
	resourceAnchorEl: null | HTMLElement;
	handleClosePopover: () => void;
	showDropZone: boolean;
	toggleDropZone: () => void;
	enableDisableRow: (row: ResourceTable) => void;
	filterSelected: number;
	onChangeFilter: (event: React.ChangeEvent<{ value: unknown }>) => void;
	openFolderFileMoveModal: boolean;
	handleOpenFolderFileMoveModal: () => void;
	handleCloseFolderFileMoveModal: () => void;
	handleFinishMove: () => void;
	isFolder: boolean;
}

const ResourceModel: Resource = {
	id: undefined,
	name: '',
	description: '',
	instructions: '',
	references: '',
	file: undefined,
	tags: [],
	isDisabled: false,
	folder: undefined,
	createdAt: new Date().toString(),
	updatedAt: new Date().toString(),
	createdBy: {
		name: '',
	},
	updatedBy: {
		name: '',
	},
};

const useResources = (): ResourcesStore => {
	const { t } = useTranslation();
	const [folders, setFolders] = React.useState<Array<Folder>>([]);
	const [resources, setResources] = React.useState<Array<ResourceTable>>([]);
	const [parentFolderId, setParentFolderId] = React.useState<number | undefined>(undefined);
	const [breadCrumbsFolder, setBreadCrumbsFolder] = React.useState<Folder | undefined>(undefined);
	const [tags, setTags] = React.useState<Array<Tag>>([]);
	const [resource, setResource] = React.useState<ResourceTable>({ ...ResourceModel });
	const [resourceImage, setResourceImage] = React.useState<File[]>();
	const [openResourceModal, setOpenResourceModal] = React.useState<boolean>(false);
	const [nameError, setNameError] = React.useState<boolean>(false);
	const [nameErrorMessage, setNameErrorMessage] = React.useState<string | undefined>(undefined);
	const [openNewFolderModal, setOpenNewFolderModal] = React.useState<boolean>(false);
	const [isFolder, setIsFolder] = React.useState<boolean>(false);
	const [isEdit, setIsEdit] = React.useState<boolean>(false);
	const [folderToEdit, setFolderToEdit] = React.useState<Folder>();
	const [folderAnchorEl, setFolderAnchorEl] = React.useState<null | HTMLElement>(null);
	const [resourceAnchorEl, setResourceAnchorEl] = React.useState<null | HTMLElement>(null);
	const [showDropZone, setShowDropZone] = React.useState<boolean>(true);
	const [filterSelected, setFilterSelected] = React.useState<number>(1);
	const [folderFileToMove, setFolderFileToMove] = React.useState<Folder | Resource>();
	const [openFolderFileMoveModal, setOpenFolderFileMoveModal] = React.useState<boolean>(false);
	const { showAlert, showDefaultError } = React.useContext(AlertContext);

	const loadTable = React.useCallback(async (): Promise<void> => {
		let filter: boolean | undefined = undefined;

		switch (filterSelected) {
			case 2:
				filter = false;
				break;
			case 3:
				filter = true;
				break;
		}

		try {
			const folderList: Array<Folder> = await getFolders(FolderType.RESOURCE, parentFolderId);
			const resourceList: Array<ResourceTable> = await getResources(parentFolderId, filter);

			if (folderList.length > 0) {
				const folder = folderList[0] as Folder;

				setBreadCrumbsFolder(folder.parentFolder);
			}
			// else if (resourceList.length > 0) {
			// 	const resource = resourceList[0] as ResourceTable;

			// 	if (resource.folder) {
			// 		setBreadCrumbsFolder(resource.folder);
			// 	}
			// }

			setFolders(folderList);
			setResources(resourceList);
		} catch (e) {
			showDefaultError();
		}
	}, [filterSelected, parentFolderId, showDefaultError]);

	React.useEffect(() => {
		loadTable();
	}, [loadTable, parentFolderId]);

	const loadTags = async (): Promise<void> => {
		try {
			setTags(await getTags());
		} catch (e) {
			showDefaultError();
		}
	};

	const handleChangeFolder = React.useCallback((parentId: number | undefined): void => {
		setBreadCrumbsFolder(folders.find(folder => folder.id === parentId));
		setParentFolderId(parentId);
	}, [folders]);

	const handleOpenModal = (): void => {
		loadTags();
		setOpenResourceModal(true);
	};

	const handleCloseModal = (): void => {
		setOpenResourceModal(false);
	};

	const handleChangeStringValues = (key: string, value: string): void => {
		setResource(r => ({ ...r, [key]: value }));

		if (key === 'name') {
			setNameError(value.length < 1);
			setNameErrorMessage(value.length < 1 ? t('resourcesView:MODAL_NAME_ERROR') : undefined);
		}
	};

	const handleTagValues = (value: Array<Tag>): void => {
		setResource(r => ({ ...r, tags: value }));
	};

	React.useEffect(() => {
		if (!openResourceModal) {
			setIsEdit(false);
			setResource({ ...ResourceModel });
			setShowDropZone(true);
		}
	}, [openResourceModal]);

	const getResourceFields = async (): Promise<Resource> => {
		const resourceToSave = { ...resource };

		if (resourceImage && resourceImage.length > 0) {
			const fileId = await saveFile(resourceImage[0]);

			resourceToSave.file = {
				id: fileId,
			};
		}

		if (breadCrumbsFolder) {
			resourceToSave.folder = breadCrumbsFolder;
		}

		return resourceToSave;
	};

	const handleSave = async (): Promise<void> => {
		try {
			const resourceToSave = await getResourceFields();

			await saveResource(resourceToSave);
			await loadTable();
		} catch (e) {
			showDefaultError();
		}

		handleCloseModal();
	};

	const handleEdit = async (): Promise<void> => {
		if (resource.id) {
			try {
				const resourceToEdit = await getResourceFields();

				await editResource(resourceToEdit, resource.id);
				await loadTable();
			} catch (e) {
				showDefaultError();
			}
		}

		handleCloseModal();
	};

	const loadResourceToEdit = (item: ResourceTable): void => {
		setShowDropZone(false);
		setIsEdit(true);
		setResource(item);
		handleOpenModal();
	};

	const handleOpenNewFolderModal = (): void => {
		setOpenNewFolderModal(true);
	};

	const handleCloseNewFolderModal = (): void => {
		setIsEdit(false);
		setOpenNewFolderModal(false);
	};

	const handleClosePopover = (): void => {
		setFolderAnchorEl(null);
		setResourceAnchorEl(null);
	};

	const handleOpenFolderFileMoveModal = (): void => {
		handleClosePopover();
		setOpenFolderFileMoveModal(true);
	};

	const handleCloseFolderFileMoveModal = (): void => {
		setOpenFolderFileMoveModal(false);
	};

	const handleCreateNewFolder = async (newFolder: Folder): Promise<void> => {
		try {
			await createFolder(newFolder);
			await loadTable();

			showAlert({ message: t('resourcesView:NEW_FOLDER_CREATED') });
		} catch (e) {
			showDefaultError();
		}

		handleCloseNewFolderModal();
	};

	const handleOpenEditFolderModal = (): void => {
		handleClosePopover();
		setIsEdit(true);

		handleOpenNewFolderModal();
	};

	const handleEditFolder = async (folder: Folder): Promise<void> => {
		if (folder.id) {
			try {
				await editFolder(folder, folder.id);
				await loadTable();
			} catch (e) {
				showDefaultError();
			}
		}

		handleCloseNewFolderModal();
	};

	const handleFolderActions = (event: React.MouseEvent<HTMLElement>, item: Folder): void => {
		event.stopPropagation();
		setFolderToEdit(item);
		setFolderFileToMove(item);
		setIsFolder(true);
		setFolderAnchorEl(event.currentTarget);
	};

	const handleResourceActions = (event: React.MouseEvent<HTMLElement>, item: Resource): void => {
		event.stopPropagation();
		setFolderFileToMove(item);
		setIsFolder(false);
		setResourceAnchorEl(event.currentTarget);
	};

	const toggleDropZone = (): void => {
		setShowDropZone(!showDropZone);
	};

	const enableDisableRow = async (row: ResourceTable): Promise<void> => {
		if (row.id) {
			try {
				row.isDisabled = !row.isDisabled;
				await editResource(row, row.id);
				loadTable();
			} catch (e) {
				showDefaultError();
			}
		}
	};

	const onChangeFilter = (event: React.ChangeEvent<{ value: unknown }>): void => {
		setFilterSelected(event.target.value as number);
	};

	const handleFinishMove = (): void => {
		loadTable();
	};

	return {
		folders,
		resources,
		handleChangeFolder,
		breadCrumbsFolder,
		tags,
		resource,
		openResourceModal,
		handleOpenModal,
		handleCloseModal,
		handleChangeStringValues,
		handleTagValues,
		resourceImage,
		setResourceImage,
		nameError,
		nameErrorMessage,
		handleSave,
		isEdit,
		loadResourceToEdit,
		handleEdit,
		openNewFolderModal,
		handleOpenNewFolderModal,
		handleCloseNewFolderModal,
		handleCreateNewFolder,
		folderToEdit,
		handleOpenEditFolderModal,
		handleEditFolder,
		handleFolderActions,
		handleResourceActions,
		folderAnchorEl,
		resourceAnchorEl,
		handleClosePopover,
		showDropZone,
		toggleDropZone,
		enableDisableRow,
		filterSelected,
		onChangeFilter,
		folderFileToMove,
		openFolderFileMoveModal,
		handleOpenFolderFileMoveModal,
		handleCloseFolderFileMoveModal,
		handleFinishMove,
		isFolder,
	};
};

export default useResources;
