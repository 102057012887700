import { AlertContext } from '../../../contexts/AlertContext';
import { coerceNumber } from '../../../helpers/Coercion';
import CreateForm from './CreateForm/CreateForm';
import CreateReport from './CreateReport/CreateReport';
import { DocumentUpdateContext } from '../../../contexts/DocumentContext';
import { getDocument } from '../../../services/DocumentService';
import React from 'react';
import { useQueryParam } from '../../../helpers/Hooks/useQueryParams';

const steps = ['', ''];

interface DocumentStore {
	activeStep: number;
	steps: Array<string>;
	getStepPage: () => JSX.Element;
	handleStep: (step: number) => void;
}

const useDocument = (): DocumentStore => {
	const folderId = coerceNumber(useQueryParam('folder-id'), undefined);
	const documentId = coerceNumber(useQueryParam('document-id'), undefined);
	const { handleSaveDocument, setFolderId, setDocumentModel } = React.useContext(DocumentUpdateContext);
	const { showDefaultError } = React.useContext(AlertContext);
	const [activeStep, setActiveStep] = React.useState(0);

	React.useEffect((): void => {
		(async (): Promise<void> => {
			if (documentId) {
				try {
					const document = await getDocument(documentId);

					setDocumentModel(document);
				} catch (e) {
					showDefaultError();
				}
			}

			setFolderId(folderId);
		})();
	}, [folderId, documentId, setFolderId, setDocumentModel, showDefaultError]);

	const handleNext = (): void => { setActiveStep(prevActiveStep => prevActiveStep + 1); };

	const handleBack = (): void => { setActiveStep(prevActiveStep => prevActiveStep - 1); };

	const handleStep = (step: number): void => { setActiveStep(step); handleSaveDocument(); };

	const getStepPage = (): JSX.Element => {
		switch (activeStep) {
			case 0:
				return (
					<CreateForm
						handleNext={handleNext}
					/>
				);
			case 1:
				return (
					<CreateReport
						handleBack={handleBack}
					/>
				);
			default:
				return (<div />);
		}
	};

	return {
		activeStep,
		steps,
		getStepPage,
		handleStep,
	};
};

export default useDocument;
