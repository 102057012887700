export default {
	es: {
		imageCrop: {
			IMAGE_UPLOAD: 'Upload image',
			IMAGE_PREVIEW: 'Preview',
			IMAGE_ZOOM: 'Zoom',
			IMAGE_ROTATION: 'Rotation',
		},
	},
	en: {
		imageCrop: {
			IMAGE_UPLOAD: 'Upload image',
			IMAGE_PREVIEW: 'Preview',
			IMAGE_ZOOM: 'Zoom',
			IMAGE_ROTATION: 'Rotation',
		},
	},
};
