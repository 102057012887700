import { BooleanQuestion } from './BooleanQuestion';
import { CheckboxQuestion } from './CheckboxQuestion';
import { DateQuestion } from './DateQuestion';
import { NumberDecimalQuestion } from './NumberDecimalQuestion';
import { NumberIntegerQuestion } from './NumberIntegerQuestion';
import { RadioQuestion } from './RadioQuestion';
import { SelectQuestion } from './SelectQuestion';
import { TextQuestion } from './TextQuestion';

export enum QuestionType {
	Boolean = 'BooleanQuestion',
	Checkbox = 'CheckboxQuestion',
	Date = 'DateQuestion',
	NumberDecimal = 'NumberDecimalQuestion',
	NumberInteger = 'NumberIntegerQuestion',
	Radio = 'RadioQuestion',
	Select = 'SelectQuestion',
	Text = 'TextQuestion',
}

export interface Question {
	id?: string;
	order: number;
	title: string;
	code: string;
	description?: string;
	required: boolean;
	showGoTo: boolean;
	value: unknown;
	type: QuestionType;
	isValid: boolean;
}

export type QuestionTypes = BooleanQuestion | CheckboxQuestion | DateQuestion | NumberDecimalQuestion | NumberIntegerQuestion | RadioQuestion | SelectQuestion | TextQuestion;
