import { CustomVariable, OperatorType, VariableValue } from './CustomVariable';

export enum LogicOperationType {
	And = 'and',
	Or = 'or',
	Greater = '>',
	Less = '<',
	GreaterOrEqual = '>=',
	LessOrEqual = '<=',
	Equal = '==',
	Different = '!=',
}

export interface LogicCustomVariable extends CustomVariable {
	operatorType: OperatorType.Logic;
	specs: {
		operation: LogicOperationType;
		values: VariableValue[];
	};
}
