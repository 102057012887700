import './Document.scss';
import CommonStepper from '../../../components/Stepper';
import { DocumentProvider } from '../../../contexts/DocumentContext';
import Header from '../../../components/ContainerHeader/Header';
import React from 'react';
import useDocument from './useDocument';
import { useTranslation } from 'react-i18next';

const DocumentWrapper: React.FC = () => (
	<DocumentProvider>
		<Document />
	</DocumentProvider>
);

const Document: React.FC = () => {
	const { t } = useTranslation();

	const {
		activeStep,
		steps,
		getStepPage,
		handleStep,
	} = useDocument();

	return (
		<div className='document-container'>
			<Header
				leftTopCornerProps={{
					title: t('formsView:HEADER_ACTION_BUTTON'),
					isActionButtonVisible: false,
				}}
				rightTopCornerProps={{
					isFilterVisible: false,
				}}
			/>
			<div className='paper'>
				<div className='form-stepper'>
					<CommonStepper
						isNonLinear
						activeStep={activeStep}
						steps={steps}
						handleStep={handleStep}
					/>
				</div>
				<div className='document-content'>
					{
						getStepPage()
					}
				</div>
			</div>
		</div>
	);
};

export default DocumentWrapper;
