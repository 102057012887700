import { PDFDocumentProxy } from 'pdfjs-dist';
import React from 'react';

interface ReportPreviewModal {
	options: object;
	numPages: number | undefined;
	handleLoadSuccess: (props: PDFDocumentProxy) => void;
}

const options = {
	cMapUrl: 'cmaps/',
	cMapPacked: true,
};

const useReportPreviewModal = (): ReportPreviewModal => {
	const [numPages, setNumPages] = React.useState<number>();

	const handleLoadSuccess = (props: PDFDocumentProxy): void => {
		setNumPages(props.numPages);
	};

	return {
		options,
		numPages,
		handleLoadSuccess,
	};
};

export default useReportPreviewModal;
