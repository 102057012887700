import { editPlanRequest, getPlanById, getPlanList } from '../api/PlanRepository';
import { Plan } from '../interfaces/Plan';

export const getPlan = async (id: number): Promise<Plan | undefined> => {
	return await getPlanById(id).then(response => { return response; }).catch(() => { return undefined; });
};

export const getPlans = async (): Promise<Array<Plan>> => {
	return await getPlanList().then(response => { return response; }).catch(() => { return []; });
};

export const editPlan = async (user: Plan): Promise<number> => {
	return await editPlanRequest(user).then(response => { return response; });
};

export const isDecimalValid = (value: string): boolean => {
	return /^$|^\d+\.?(\d{1,2})?$/.test(value);
};
