import './PreviewModal.scss';
import FormPreview from './FormPreview';
import Modal from '../Modal/Modal';
import React from 'react';
import usePreviewModal from './usePreviewModal';
import { useTranslation } from 'react-i18next';

interface PreviewModalProps {
	open: boolean;
	handleClose: () => void;
	handleValidate: () => void;
}

const PreviewModal: React.FC<PreviewModalProps> = (props) => {
	const {
		documentModel,
		sectionsToShow,
		isFirstStep,
		isLastStep,
		handleBack,
		handleClosePreviewModal,
		handleNextButton,
	} = usePreviewModal({validate: props.handleValidate, close: props.handleClose});
	const { t } = useTranslation();

	return (
		<Modal
			title={t('formsView:MODAL_PREVIEW_TITLE')}
			open={props.open}
			handleOnClose={handleClosePreviewModal}
			isPrimaryButtonVisible
			primaryButtonProps={{
				name: isLastStep() ? t('formsView:MODAL_PREVIEW_BUTTON_VALIDATE') : t('formsView:MODAL_PREVIEW_BUTTON_NEXT'),
				handleOnclick: handleNextButton,
			}}
			isSecondaryButtonVisible={!isFirstStep()}
			secondaryButtonProps={{
				name: t('formsView:MODAL_PREVIEW_BUTTON_BACK'),
				handleOnclick: handleBack,
			}}
			size="md"
		>
			<FormPreview
				sectionToShow={sectionsToShow}
				documentModel={documentModel}
			/>
		</Modal>
	);
};

export default PreviewModal;
