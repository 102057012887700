import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import React from 'react';
import useRoutes from '../../../components/Hooks/useRoutes';

interface HomeHookInterface {
	value: string | boolean;
	onChangeValue: (option: string) => void;
	openReportModal: boolean;
	setOpenReportModal: (flag: boolean) => void;
	isAuth: boolean;
	isDashboard: boolean;
}

const useHome = (): HomeHookInterface => {
	const history = useHistory();
	const { ROUTES, TABS_ROUTES } = useRoutes();
	const location = useLocation();
	const [value, setValue] = React.useState<string | boolean>(ROUTES.DASHBOARD);
	const [openReportModal, setOpenReportModal] = React.useState<boolean>(false);
	const { isAuth } = React.useContext(AuthContext);
	const isDashboard = location.pathname === ROUTES.DASHBOARD;
	const tabsRoutesValues = Object.values(TABS_ROUTES);

	const onChangeValue = (option: string): void => {
		setValue(option);
		history.push(option);
	};

	React.useEffect(() => {
		const tabValue = tabsRoutesValues.includes(location.pathname) ? `/${location.pathname.split('/')[1]}` : false;

		setValue(tabValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return {
		value,
		onChangeValue,
		openReportModal,
		setOpenReportModal,
		isAuth: isAuth && location.pathname !== ROUTES.ERROR,
		isDashboard,
	};

};

export default useHome;
