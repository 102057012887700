import { DocumentContext, DocumentUpdateContext } from '../../../../contexts/DocumentContext';
import { DateQuestion } from '../../../../interfaces/Documents/Questions/DateQuestion';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import { Section } from '../../../../interfaces/Documents/Section';
import { useTranslation } from 'react-i18next';

interface DateInterface {
	question: DateQuestion;
	errorMessage: string;
	showErrors: boolean;
	handleOnChange: (date: Date | null) => void;
}

const useDatePreview = (props: QuestionPreviewProps): DateInterface => {
	const { t } = useTranslation();
	const { documentModel, documentModel: { showErrors } } = React.useContext(DocumentContext);
	const { handleUpdateQuestion } = React.useContext(DocumentUpdateContext);
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const question = documentModel.sections[props.sectionIndex].questions[props.questionIndex] as DateQuestion;

	const isValueValid = (value: Date | null): boolean => {
		const hasValue = value !== null;

		if (!hasValue && question.required) {
			return false;
		}

		return true;
	};

	React.useEffect(() => {
		const isValid = isValueValid(question.value);

		setErrorMessage(t('formsView:MODAL_PREVIEW_FIELD_REQUIRED'));
		handleUpdateQuestion({ ...question, isValid }, documentModel.sections[props.sectionIndex] as Section);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question.required]);

	const handleOnChange = (value: Date | null): void => {
		const isValid = isValueValid(value);

		if (isValid) {
			setErrorMessage('');
		}

		handleUpdateQuestion({ ...question, value, isValid: isValid }, documentModel.sections[props.sectionIndex] as Section);
	};

	return {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	};
};

export default useDatePreview;
