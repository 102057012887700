import './DefaultTable.scss';
import React from 'react';
import { Table } from '@material-ui/core';

const DefaultTable: React.FC = ({ children }) => {
	return (
		<Table
			className='default-table'
		>
			{children}
		</Table>
	);
};

export default DefaultTable;
