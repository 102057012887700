import './Error.scss';
import ActionButton from '../../components/Buttons/ActionButton';
import errorCloud from '../../assets/images/empty-state.svg';
import logo from '../../assets/images/pragma-logo.svg';
import { NavigateBefore } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error = (): JSX.Element => {
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<div className={'error-screen-container'}>
			<div className="logo">
				<img src={logo} alt={'logo'} />
			</div>
			<div className="error-image">
				<img src={errorCloud} alt={'error'} />
			</div>
			<div className="error-text">
				<p><span className={'error-text-emphasis'}>{t('errorView:ERROR_OOPS')}</span><br />{t('errorView:ERROR_HAPPENED')}</p>
			</div>
			<ActionButton
				name={t('navigation:BACK_BUTTON_TEXT')}
				onClick={(): void => { history.goBack(); }}
				startIcon={<NavigateBefore />}
				variant={'contained'}
			/>
		</div>
	);
};

export default Error;
