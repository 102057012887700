import './DefaultSelect.scss';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Option } from '../../../interfaces/Common';
import React from 'react';

type Margin = 'dense' | 'none' | 'normal';

export interface DefaultSelectProps {
	onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
	options: Option[];
	value: string | number | undefined;
	className?: string;
	defaultValue?: string;
	isDisabled?: boolean;
	isFullWidth?: boolean;
	label?: string;
	placeholder?: string;
	isError?: boolean;
	errorMessage?: string;
	removeMarginBotton?: boolean;
	margin?: Margin;
	classes?: object;
}

const DefaultSelect: React.FC<DefaultSelectProps> = (props) => {
	return (
		<>
			<FormControl
				classes={props.classes}
				className='select-form-control'
				disabled={props.isDisabled}
				fullWidth={props.isFullWidth}
				variant='outlined'
				error={props.isError}
				margin={props.margin}
			>
				<InputLabel>{props.label}</InputLabel>
				<Select
					onChange={props.onChange}
					value={props.value}
					defaultValue={props.defaultValue}
					label={props.label}
				>
					{
						props.options.map((item, key) => {
							return (
								<MenuItem
									key={key}
									value={item.key}
								>
									{
										item.value
									}
								</MenuItem>
							);
						})
					}
				</Select>
				<FormHelperText>
					{
						props.isError && `${props.errorMessage}`
					}
					{
						!props.isError && (props.removeMarginBotton ? undefined : '\u00a0')
					}
				</FormHelperText>
			</FormControl>
		</>
	);
};

export default DefaultSelect;
