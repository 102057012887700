import './Content.scss';
import { Book } from '@material-ui/icons';
import { CardProps } from '../../components/Card/Card';
import CardsList from '../../components/CardsList';
import Header from '../../components/ContainerHeader/Header';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../components/Hooks/useRoutes';
import { UserRole } from '../../interfaces/User';
import { useTranslation } from 'react-i18next';

const Content: React.FC = () => {
	const { ROUTES } = useRoutes();
	const { t } = useTranslation();
	const history = useHistory();

	const MOCKED_CARDS: Array<CardProps> = [
		// {
		// 	title: t('contentView:CONTENT_OPTION_PLANS'),
		// 	onClick: (): void => history.push(`${ROUTES.CONTENT}${ROUTES.PLANS}`),
		// 	icon: AttachMoney,
		// 	rolesAllowed: [UserRole.SUPER_ADMIN],
		// },
		{
			title: t('contentView:CONTENT_OPTION_BLOG'),
			onClick: (): void => history.push(`${ROUTES.CONTENT}${ROUTES.BLOG}`),
			icon: Book,
			rolesAllowed: [UserRole.SUPER_ADMIN, UserRole.MARKETING],
		},
	];

	return (
		<div className={'content-container'}>
			<Header
				leftTopCornerProps={{
					title: t('contentView:CONTENT_TITLE'),
					isActionButtonVisible: false,
				}}
				rightTopCornerProps={{
					isFilterVisible: false,
				}}
			/>
			<CardsList cards={MOCKED_CARDS} />
		</div>
	);
};

export default Content;
