import axios from 'axios';
import { ResourceAPI } from '../interfaces/Resource';
import { Tag } from '../interfaces/Tag';

const apiUrl = process.env.REACT_APP_API_URL;

export const getResourcesByFolder = (folderId: number | undefined, disabledRows: boolean | undefined): Promise<Array<ResourceAPI>> => {
	if (apiUrl) {
		const params = {
			folderId,
			isDisabled: disabledRows,
		};

		return axios.get(`${apiUrl}/resources`, { params });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getTags = (): Promise<Array<Tag>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/resources/tags`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const saveResource = (resource: ResourceAPI): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/resources`, {
			...resource,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const editResource = (resource: ResourceAPI, id: number): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/resources/${id}`, {
			...resource,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
