import './LeftTopCorner.scss';
import ActionButton from '../../Buttons/ActionButton';
import { ActionButtonProps } from '../../Buttons/ActionButton/ActionButton';
import React from 'react';
import SectionTitle from '../../Title';

export interface LeftTopCornerProps {
	title: string;
	isActionButtonVisible: boolean;
	actionButtonProps?: ActionButtonProps;
}

const LeftTopCorner: React.FC<LeftTopCornerProps> = (props) => {
	return (
		<div className='leftTopCornerSection'>
			<SectionTitle
				className='title'
				title={props.title}
			/>
			{
				props.isActionButtonVisible && props.actionButtonProps
				&& (
					<ActionButton
						name={props.actionButtonProps.name}
						onClick={props.actionButtonProps.onClick}
						startIcon={props.actionButtonProps.startIcon}
						endIcon={props.actionButtonProps.endIcon}
						isDisabled={props.actionButtonProps.isDisabled}
						variant={props.actionButtonProps.variant}
					/>
				)
			}
		</div>
	);
};

export default LeftTopCorner;
