import './PreviewWrapper.scss';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import React from 'react';

export interface QuestionPreviewProps {
	questionIndex: number;
	sectionIndex: number;
}
export interface PreviewWrapperProps {
	title: string;
	description?: string;
	required: boolean;
}

const PreviewWrapper: React.FC<PreviewWrapperProps> = ({ title, description, required, children }) => {
	return (
		<FormControl className="question" fullWidth>
			<FormLabel className="question__title" required={required} classes={{ asterisk: 'title--required' }}>{title}</FormLabel>
			{description && <FormLabel className="question__description">{description}</FormLabel>}
			{children}
		</FormControl>
	);
};

export default PreviewWrapper;
