import axios from 'axios';
import { FileBase } from '../interfaces/Common';

const apiUrl = process.env.REACT_APP_API_URL;

export const getSpecificFile = (id: number): Promise<FileBase | undefined> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/files`, {
			params: { id },
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const saveFilePost = (file: File): Promise<number> => {
	if (apiUrl) {
		const formData = new FormData();

		formData.append('file', file);

		return axios.post(`${apiUrl}/files`, formData, { headers: { 'Content-type': 'multipart/form-data' } });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
