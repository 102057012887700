import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import React from 'react';
import useRoutes from '../Hooks/useRoutes';
import { UserRole } from '../../interfaces/User';

interface AuthRouteProps {
	Component: React.FC<RouteComponentProps>;
	path?: string;
	exact?: boolean;
	rolesRequired: UserRole[];
}

const AuthRoute: React.FC<AuthRouteProps> = ({ Component, path, exact = false, rolesRequired }) => {
	const { isAuth, user: { role } } = React.useContext(AuthContext);
	const { ROUTES } = useRoutes();

	return (
		<>
			{
				rolesRequired.includes(role) &&
				<Route
					exact={exact}
					path={path}
					key={path}
					render={(props: RouteComponentProps): JSX.Element =>
						isAuth ? <Component {...props} /> : <Redirect to={`/${ROUTES.LOGIN}`} />
					}
				/>
			}
		</>
	);
};

export default AuthRoute;
