import { getPanelClientList, updatePanelClient } from '../api/ClientRepository';
import { ClientTableInterface } from '../interfaces/Client';

export const getClientList = async (): Promise<Array<ClientTableInterface>> => {
	return await getPanelClientList().then(response => { return response; }).catch(() => { return []; });
};

export const updateClient = async (client: ClientTableInterface): Promise<number> => {
	return await updatePanelClient(client).then(response => { return response; }).catch(() => { return 0; });
};
