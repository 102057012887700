import { PanelUser, PanelUserExtended, UserAPI, UserInvitation, UserRole } from '../interfaces/User';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const getMyUserInfo = (): Promise<UserAPI> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/user`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateMyUserInfo = (user: PanelUserExtended, avatar?: File): Promise<void> => {
	if (apiUrl) {
		const formData = new FormData();

		if (avatar) {
			formData.append('avatar', avatar);
		}

		formData.append('name', user.name);

		return axios.patch(`${apiUrl}/user`, formData, { headers: { 'Content-type': 'multipart/form-data' } });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getPanelUserList = (): Promise<Array<PanelUser>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/users`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getPanelInvitationList = (): Promise<Array<UserInvitation>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/invitations`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

const roles: Array<UserRole> = [UserRole.SUPER_ADMIN, UserRole.TECHNICIAN, UserRole.MARKETING];

export const getRolesList = (): Promise<Array<UserRole>> => {
	return Promise.resolve(roles);
};

export const savePanelUser = (invitation: UserInvitation): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/invitations`, {
			mail: invitation.mail,
			role: invitation.role,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const changeMyPassword = (oldPassword: string, newPassword: string): Promise<void> => {
	if (apiUrl) {
		return axios.put(`${apiUrl}/user/change-password`, {
			oldPassword,
			newPassword,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const editPanelUser = (user: PanelUser): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/users/${user.id}`, {
			isDisabled: user.isDisabled,
			role: user.role,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const passwordReset = (username: string): Promise<void> => {
	if (apiUrl) {
		return axios.put(`${apiUrl}/user/forgot-password`, { username });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
