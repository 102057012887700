import DefaultSelect from '../../../Inputs/Select';
import { Option } from '../../../../interfaces/Documents/Questions/Options';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import useSelectPreview from './useSelectPreview';

const SelectPreview: React.FC<QuestionPreviewProps> = (props) => {
	const {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	} = useSelectPreview(props);
	const { value, options, isValid } = question;

	return (
		<>
			<DefaultSelect
				onChange={handleOnChange}
				value={value}
				options={options.map((option: Option) => (
					{
						key: option.id,
						value: option.title,
					}
				))}
				isError={showErrors && !isValid}
				errorMessage={errorMessage}
				classes={{ root: 'input-select' }}
			/>
		</>
	);
};

export default SelectPreview;
