import './BlogEdit.scss';
import { Button, Fab, FormControl } from '@material-ui/core';
import ActionButton from '../../../../components/Buttons/ActionButton';
import AutoCompleteTags from '../../../../components/AutoCompleteTags';
import DefaultInput from '../../../../components/Inputs/Input';
import { EditOutlined } from '@material-ui/icons';
import Header from '../../../../components/ContainerHeader/Header';
import ImageCrop from '../../../../components/ImageCrop/ImageCrop';
import React from 'react';
import useBlog from '../Hooks/useBlog';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../../../components/Hooks/useRoutes';
import { useTranslation } from 'react-i18next';
import WYSIWYGEditor from '../../../../components/WYSIWYGEditor';

const BlogEdit: React.FC = () => {
	const { t } = useTranslation();
	const { ROUTES } = useRoutes();
	const history = useHistory();
	const {
		blogTitle,
		blogTitleError,
		validateTitle,
		setBlogImage,
		blogImageSrc,
		blogDescription,
		blogDescriptionError,
		validateBlogDescription,
		handleEdit,
		showDropZone,
		setShowDropZone,
		tags,
		blogTags,
		handleTagValues,
	} = useBlog();

	return (
		<>
			<Header
				leftTopCornerProps={{
					title: t('blogView:HEADER_TITLE'),
					isActionButtonVisible: false,
				}}
				rightTopCornerProps={{
					isFilterVisible: false,
				}}
			/>
			<div className='edit-blog'>
				<div className='paper'>
					<FormControl className='form' fullWidth margin='normal'>
						<div className='title-container'>
							<DefaultInput
								className='title-input'
								value={blogTitle}
								placeholder={t('blogView:TITLE_PLACEHOLDER')}
								variant='outlined'
								onChange={validateTitle}
								isError={blogTitleError}
								errorMessage={t('blogView:BLOG_TITLE_ERROR')}
							/>
						</div>
						<AutoCompleteTags
							className="autocomplete"
							value={blogTags || []}
							label={t('blogView:TAGS')}
							options={tags}
							setValue={(array): void => handleTagValues(array)}
						/>
						<div className='upload-image-section'>
							{
								showDropZone
									? (
										<div className='drop-container'>
											<ImageCrop
												aspectRatio={100/48.61}
												containerClass='upload-container'
												onChange={(img: Blob): void => setBlogImage([new File([img], 'File')])}
												uploadButtonLabel={t('imageCrop:IMAGE_UPLOAD')}
												previewButtonLabel={t('imageCrop:IMAGE_PREVIEW')}
												previewTitle={t('imageCrop:IMAGE_PREVIEW')}
												zoomLabel={t('imageCrop:IMAGE_ZOOM')}
												rotationLabel={t('imageCrop:IMAGE_ROTATION')}
											/>
											<Button className='float-button-drop' variant="contained" color="primary" size='small' onClick={(): void => setShowDropZone(!showDropZone)}>
												{t('action:CANCEL_BUTON')}
											</Button>
										</div>
									)
									: (
										<div className='image-container'>
											<img className='image' src={blogImageSrc} />
											<Fab className='float-button-image hide' size='small' onClick={(): void => setShowDropZone(!showDropZone)}>
												<EditOutlined />
											</Fab>
										</div>
									)
							}
						</div>
						<WYSIWYGEditor
							value={blogDescription}
							onChange={validateBlogDescription}
							isError={blogDescriptionError}
							errorMessage={t('blogView:BLOG_DESCRIPTION_ERROR')}
						/>
					</FormControl>
					<div className='actions'>
						<ActionButton
							name={t('userView:MODAL_CREATE_SECONDARY_BUTTON')}
							onClick={(): void => history.push(`${ROUTES.CONTENT}${ROUTES.BLOG}`)}
							type='secondary'
						/>
						<ActionButton
							name={'Aceptar'}
							onClick={handleEdit}
							isDisabled={blogTitleError || blogDescriptionError || !blogTitle || !blogDescription}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlogEdit;
