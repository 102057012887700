import './ListOperator.scss';
import { ListCustomVariable, ListOrderType, ListSpecs, ListSpecsTypes, ListType } from '../../../interfaces/Documents/CustomVariables/ListCustomVariable';
import DefaultInput from '../../Inputs/Input';
import DefaultSelect from '../../Inputs/Select';
import { OptionVariable } from '../../../interfaces/Documents/Variables/Variable';
import React from 'react';
import useListOperator from './useListOperator';
import { useTranslation } from 'react-i18next';

export interface ListOperatorProps {
	variableData: ListCustomVariable;
	handleUpdateVariableData: (variable: ListCustomVariable) => void;
	variables: OptionVariable[];
	arethereCircularReferences: (variableId: string, newReferenceId: string) => boolean;
}

const ListOperator: React.FC<ListOperatorProps> = (props) => {
	const { t } = useTranslation();

	const {
		allVariables,
		handleChangeListVariable,
		listTypeOtions,
		handleChangeListTypeVariable,
		orderOptionsState,
		handleChangeListOrderType,
		handleChangeListJoinRow,
	} = useListOperator(props);

	return (
		<div className='list-operator'>
			<div className='title'>{t('variableModal:TITILE_SPECS')}</div>
			<div className='line'>
				<div className='line-label'>
					{t('variableModal:OPTION_VARIABLE')}
				</div>
				<DefaultSelect
					onChange={(e): void => handleChangeListVariable(e.target.value as string)}
					value={(props.variableData.specs as ListSpecsTypes)?.value || ''}
					options={allVariables}
					isFullWidth
					removeMarginBotton
				/>
			</div>
			<div className='line'>
				<div className='line-label'>
					{t('variableModal:LABEL_LIST_TYPE')}
				</div>
				<DefaultSelect
					onChange={(e): void => handleChangeListTypeVariable(e.target.value as ListType)}
					value={(props.variableData.specs as ListSpecsTypes)?.type || undefined}
					options={listTypeOtions}
					isFullWidth
					removeMarginBotton
				/>
			</div>
			<div className='line'>
				<div className='line-label'>
					{t('variableModal:LABEL_LIST_ORDER_TYPE')}
				</div>
				<DefaultSelect
					onChange={(e): void => handleChangeListOrderType(e.target.value as ListOrderType)}
					value={(props.variableData.specs as ListSpecsTypes)?.orderType || undefined}
					options={orderOptionsState}
					isFullWidth
					removeMarginBotton
				/>
			</div>
			{
				props.variableData.specs && (props.variableData.specs as ListSpecs).joinRow !== undefined
				&& (
					<div className='line'>
						<div className='line-label'>
							{t('variableModal:LABEL_LIST_JOIN_CHARACTER')}
						</div>
						<div className='row-line'>
							<DefaultInput
								value={(props.variableData.specs as ListSpecs)?.joinRow}
								onChange={(e): void => handleChangeListJoinRow(e.target.value as string)}
								variant='outlined'
								removeMarginBotton
							/>
						</div>
					</div>
				)
			}
		</div>
	);
};

export default ListOperator;
