import { DatePicker } from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import useDatePreview from './useDatePreview';

const DatePreview: React.FC<QuestionPreviewProps> = (props) => {
	const {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	} = useDatePreview(props);
	const { min, max, value, required, isValid } = question;

	return (
		<>
			<DatePicker
				className="input-date"
				placeholder='12/12/2022'
				value={value}
				onChange={(newDate): void => handleOnChange(newDate)}
				autoOk
				format='dd/MM/yyyy'
				inputVariant='outlined'
				variant='inline'
				minDate={min}
				maxDate={max}
				required={required}
				PopoverProps={{
					anchorOrigin: {
						vertical: 'center',
						horizontal: 'right',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
				}}
			/>
			{showErrors && !isValid && <FormHelperText error={showErrors && !isValid}>{showErrors ? errorMessage : ''}</FormHelperText>}
		</>
	);
};

export default DatePreview;
