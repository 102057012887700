import { DefaultVariable, DefaultVariableValue } from '../useCreateVariableModal';
import { OptionVariable, VariableType } from '../../../interfaces/Documents/Variables/Variable';
import { ValueType, VariableValue } from '../../../interfaces/Documents/CustomVariables/CustomVariable';
import { isNumber } from '../../../helpers/Validations';
import { MathematicalOperationType } from '../../../interfaces/Documents/CustomVariables/MathematicalCustomVariable';
import { MathematicalOperatorProps } from './MathematicalOperator';

interface UseMathematicalOperatorInterface {
	handleChangeOperation: (value: MathematicalOperationType) => void;
	handleUpdateVariableValue: (name: string, value: string, order: number) => void;
	handleAddVariableValue: () => void;
	handleDeleteVariableValue: (order: number) => void;
	variableList: OptionVariable[];
}

const useMathematicalOperator = (props: MathematicalOperatorProps): UseMathematicalOperatorInterface => {
	const variableList: OptionVariable[] = props.variables.filter(x => x.type === VariableType.Number);

	const handleChangeOperation = (value: MathematicalOperationType): void => {
		if (props.variableData.specs) {
			const specs = { ...props.variableData.specs, operation: value, values: [...DefaultVariable.specs?.values || []] };

			props.handleUpdateVariableData({ ...props.variableData, specs: specs });
		}
	};

	const handleUpdateVariableValue = (name: string, value: string, order: number): void => {
		const data = { ...props.variableData };

		if (data.id && name === 'value' && props.arethereCircularReferences(data.id, value)) {
			return;
		}

		if (data.specs && data.specs.values) {
			const index = data.specs.values.findIndex(o => o.order === order);

			if (index > -1) {
				if (name === 'value' && data.specs.values[index].valueType === ValueType.Constant && !isNumber(value)) {
					return;
				}

				const newValue = { ...data.specs.values[index], [name]: value };

				if (name === 'valueType') {
					newValue.value = '';
				}

				data.specs.values[index] = newValue;
				props.handleUpdateVariableData(data);
			}
		}
	};

	const handleAddVariableValue = (): void => {
		if (props.variableData.specs && props.variableData.specs.values) {
			const newValues: VariableValue[] = [...props.variableData.specs.values];

			if (newValues) {
				if (newValues.length > 0) {
					const lastOrder = newValues[newValues.length - 1].order;

					newValues.push({ ...DefaultVariableValue, order: lastOrder + 1 });
				} else {
					newValues.push({ ...DefaultVariableValue });
				}

				props.handleUpdateVariableData({ ...props.variableData, specs: { ...props.variableData.specs, values: newValues } });
			}
		}
	};

	const handleDeleteVariableValue = (order: number): void => {
		if (props.variableData.specs && props.variableData.specs.values) {
			const oldValues: VariableValue[] = [...props.variableData.specs.values];
			const valueIndex = oldValues.findIndex(x => x.order === order);

			if (valueIndex > -1) {
				const newValues: VariableValue[] = [];
				let itemOrder = 1;

				oldValues.forEach((item) => {
					if (item.order !== order) {
						newValues.push({ ...item, order: itemOrder });
						itemOrder++;
					}
				});

				props.handleUpdateVariableData({ ...props.variableData, specs: { ...props.variableData.specs, values: newValues } });
			}
		}
	};

	return {
		handleChangeOperation,
		handleUpdateVariableValue,
		handleAddVariableValue,
		handleDeleteVariableValue,
		variableList,
	};
};

export default useMathematicalOperator;

