import { DocumentContext, DocumentUpdateContext } from '../../contexts/DocumentContext';
import React, { Reducer } from 'react';
// import { AlertContext } from '../../contexts/AlertContext';
import { Answer } from '../../interfaces/Documents/Answer';
import { Document } from '../../interfaces/Documents/Document';
import { Section } from '../../interfaces/Documents/Section';
import { TestCase } from '../../interfaces/Documents/TestCase';
import { TestCaseModalProps } from './TestCaseModal';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

export interface TestCaseView {
	id: string | undefined;
	title: ({ value: string; isValid: boolean; errorMessage: string });
	answers: Answer[];
}

interface Action {
	type: string;
	value?: unknown;
}

interface TestCaseModalStore {
	documentModel: Document;
	testCase: TestCaseView;
	setTestCase: (action: Action) => void;
	saveTestCase: () => void;
	handleOnClose: () => void;
}

const useTestCaseModal = (props: TestCaseModalProps): TestCaseModalStore => {
	const { t } = useTranslation();
	const {	documentModel, isDocumentValid } = React.useContext(DocumentContext);
	const { showErrorsInForm: handleShowErrors, handleAddTestCase } = React.useContext(DocumentUpdateContext);
	// const { showAlert } = React.useContext(AlertContext);

	const initialTestCase: TestCaseView = {
		id: uuidv4(),
		title: {
			value: '',
			isValid: false,
			errorMessage: t('formsView:MODAL_PREVIEW_FIELD_REQUIRED'),
		},
		answers: [],
	};

	const reducer = (state: TestCaseView, action: Action): TestCaseView => {
		switch (action.type) {
			case 'setTitle': {
				const isValid = Boolean(action.value);

				return {...state, title: { value: action.value as string, isValid: isValid, errorMessage: isValid ? '' : initialTestCase.title.errorMessage } };
			}

			case 'resetTitle': {
				return {...state, title: initialTestCase.title };
			}

			case 'setQuestions':
				return {...state, answers: action.value as Answer[] };
			default:
				throw state;
		}
	};

	const [testCase, setTestCase] = React.useReducer<Reducer<TestCaseView, Action>>(reducer, initialTestCase);

	React.useEffect(() => {
		const answers = documentModel.sections.flatMap((section: Section) => {
			return section.questions.map((question): Answer => {
				return { id: question.id as string, value: undefined};
			});
		});

		setTestCase({ type: 'setQuestions', value: answers});
	}, [documentModel.sections]);

	const handleOnClose = (): void => {
		setTestCase({ type: 'resetTitle' });

		props.onClose();
	};

	const saveTestCase = (): void => {
		handleShowErrors();

		if (isDocumentValid() && testCase.title.isValid) {
			handleAddTestCase({ ...testCase, title: testCase.title.value } as TestCase);
		}
	};

	return {
		documentModel,
		testCase,
		setTestCase,
		saveTestCase,
		handleOnClose,
	};
};

export default useTestCaseModal;
