import React from 'react';

interface SectionTitleProps {
	title: string;
	className: string;
}

const SectionTitle: React.FC<SectionTitleProps> = (props) => {
	return (
		<div
			className={props.className}
		>
			{
				props.title
			}
		</div>
	);
};

export default SectionTitle;
