import { getSpecificFile, saveFilePost } from '../api/FileBaseRepository';
import { FileBase } from '../interfaces/Common';

export const getFile = async (id: number): Promise<FileBase | undefined> => {
	return await getSpecificFile(id);
};

export const saveFile = async (file: File): Promise<number> => {
	return await saveFilePost(file);
};
