import './ReportPreviewModal.scss';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import Modal from '../Modal';
import React from 'react';
import useReportPreviewModal from './useReportPreviewModal';
import { useTranslation } from 'react-i18next';

interface ReportPreviewModalProps {
	open: boolean;
	onClose: () => void;
	reportPreview: Blob | null;
}

const ReportPreviewModal: React.FC<ReportPreviewModalProps> = (props) => {
	const { t } = useTranslation();

	const {
		options,
		numPages,
		handleLoadSuccess,
	} = useReportPreviewModal();

	return (
		<Modal
			title={t('formsView:MODAL_REPORT_PREVIEW_TITLE')}
			open={props.open}
			handleOnClose={props.onClose}
			isPrimaryButtonVisible
			primaryButtonProps={{
				name: t('formsView:MODAL_REPORT_PREVIEW_PRIMARY_BUTTON'),
				handleOnclick: props.onClose,
			}}
			isSecondaryButtonVisible={false}
			size='md'
		>
			<div className='pdf-container'>
				<Document
					file={props.reportPreview}
					onLoadSuccess={handleLoadSuccess}
					options={options}
				>
					{
						Array.from(
							new Array(numPages),
							(el, index) => (
								<Page
									key={`page_${index + 1}`}
									pageNumber={index + 1}
									scale={1.2}
								/>
							)
						)
					}
				</Document>
			</div>
		</Modal>);
};

export default ReportPreviewModal;
