import './Clients.scss';
import { Avatar, IconButton, ListItem, ListItemAvatar, TableBody } from '@material-ui/core';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import Cell from '../../components/Cell';
import DefaultTable from '../../components/Table';
import Header from '../../components/ContainerHeader/Header';
import React from 'react';
import Row from '../../components/Row';
import useClient from './Hooks/useClient';
import { useTranslation } from 'react-i18next';

const Clients: React.FC = () => {
	const { t } = useTranslation();
	const {
		clientsList,
		plansOptions,
		planFilter,
		handleChangePlanFilter,
		toogleRow,
	} = useClient();

	return (
		<div className='client-container'>
			<Header
				leftTopCornerProps={{
					title: t('clientView:HEADER_TITLE'),
					isActionButtonVisible: false,
				}}
				rightTopCornerProps={{
					isFilterVisible: true,
					filterProps: {
						label: t('clientView:HEADER_FILTER_LABEL'),
						value: planFilter,
						onChange: handleChangePlanFilter,
						options: [{ key: 0, value: '-' }, ...plansOptions],
					},
				}}
			/>
			<DefaultTable>
				<TableBody>
					{
						clientsList.length
							? clientsList.map((client, key) => {
								return (
									<Row key={key} isDisabled={client.isDisabled}>
										<Cell className='size-cell-large' align='left'>
											<ListItem alignItems='center'>
												<ListItemAvatar>
													<Avatar src={client.imageSrc} />
												</ListItemAvatar>
												{client.username}
											</ListItem>
										</Cell>
										<Cell className='size-cell-medium' align='left'>
											{
												client.plan?.key
											}
										</Cell>
										<Cell className='size-cell-medium' align='left'>
											{t(client.paymentStatus)}
										</Cell>
										<Cell className='size-cell-medium' align='left'>
											{client.paymentDate.toLocaleDateString()}
										</Cell>
										<Cell className='size-cell-medium' align='right'>
											<IconButton
												onClick={(): void => toogleRow(client)}
											>
												{
													client.isDisabled ? <VisibilityOffOutlined className='icon' /> : <VisibilityOutlined className='icon' />
												}
											</IconButton>
										</Cell>
									</Row>
								);
							})
							: <Row>
								<Cell className='size-cell-large' align='center'>
									{t('clientView:CONTENT_NO_CLIENTS')}
								</Cell>
							</Row>
					}
				</TableBody>
			</DefaultTable>
		</div>
	);
};

export default Clients;
