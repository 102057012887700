import './BreadCrumb.scss';
import { Breadcrumbs, Link, LinkProps } from '@material-ui/core';
import { Route, Link as RouterLink } from 'react-router-dom';
import { NavigateNext } from '@material-ui/icons';
import React from 'react';
import useRoutes from '../Hooks/useRoutes';

interface LinkRouterProps extends LinkProps {
	to: string;
}

const LinkRouter: React.FC<LinkRouterProps> = (props) => (
	<Link {...props} underline="none" component={RouterLink as unknown as React.FC} className='link-router' />
);

const BreadCrumb: React.FC = () => {
	const { ROUTES } = useRoutes();

	return (
		<>
			<Route>
				{({ location }): JSX.Element => {
					const pathnames = location.pathname.split('/').filter(x => x);

					return (
						<Breadcrumbs separator={<NavigateNext fontSize='small' className='bread-crumbs-separator' />}>
							<LinkRouter to={ROUTES.DASHBOARD}>
								{ROUTES.HOME.replace('/', '')}
							</LinkRouter>
							{pathnames.map((value, index) => {
								const last = index === pathnames.length - 1;
								const to = `/${pathnames.slice(0, index + 1).join('/')}`;

								return last ? (
									<div className='current-page' key={to}>
										{value.replace(/-/g, ' ')}
									</div>
								)
									: (
										<LinkRouter to={to} key={to}>
											{value}
										</LinkRouter>
									);
							})}
						</Breadcrumbs>
					);
				}}
			</Route>
		</>
	);
};

export default BreadCrumb;

