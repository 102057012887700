import * as AuthRepository from '../api/AuthRepository';
import { Auth } from '../interfaces/Auth';
import { Credentials } from '../interfaces/User';

export const signInWithPassword = async (credentials: Credentials, code: string | null): Promise<Auth> => {
	return AuthRepository.signInWithPassword(credentials, code);
};

export const signUpWithPassword = async (name: string, credentials: Credentials, code: string): Promise<Auth> => {
	return AuthRepository.signUpWithPassword(name, credentials, code);
};

export const signInWithGoogle = async (idToken: string): Promise<Auth> => {
	return AuthRepository.signInWithGoogle(idToken);
};
