import './Home.scss';
import BreadCrumb from '../../components/BreadCrumbs';
import Navigation from '../../Navigation';
import ProfileModal from '../../components/ProfileModal';
import React from 'react';
import ReportModal from '../../components/ReportModal';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import useHome from './Hooks/useHome';
import useProfileModal from '../../components/ProfileModal/useProfileModal';

const Home: React.FC = () => {
	const {
		value,
		onChangeValue,
		openReportModal,
		setOpenReportModal,
		isAuth,
		isDashboard,
	} = useHome();

	const {
		username,
		setUsername,
		imageSrc,
		setImage,
		showDropZone,
		setShowDropZone,
		openProfileModal,
		handleOpenProfileModal,
		profileAnchorEl,
		handleOpenProfileMenu,
		handleCloseProfileMenu,
		handleSave,
		redirectToChangePassword,
	} = useProfileModal();

	return (
		<div className="home">
			{
				isAuth
				&& (
					<>
						<div className='appBar'>
							<TopBar
								isCreateReportVisible={true}
								createReportOnClick={(): void => setOpenReportModal(true)}
								isSearchVisible={true}
								searchOnChange={(): void => console.log('onChange')}
								showBadge={true}
								handleOpenProfileModal={handleOpenProfileModal}
								profileAnchorEl={profileAnchorEl}
								handleOpen={handleOpenProfileMenu}
								handleClose={handleCloseProfileMenu}
							/>
						</div>
						<div className='breadCrumbs'>
							{!isDashboard && <BreadCrumb />}
						</div>
						<div className='sideBar'>
							<SideBar
								value={value}
								onChange={onChangeValue}
							/>
						</div>
						<ReportModal
							openModal={openReportModal}
							setOpenModal={setOpenReportModal}
						/>
						<ProfileModal
							username={username}
							setUsername={setUsername}
							imageSrc={imageSrc}
							setImage={setImage}
							showDropZone={showDropZone}
							setShowDropZone={setShowDropZone}
							openProfileModal={openProfileModal}
							handleOpenProfileModal={handleOpenProfileModal}
							handleSave={handleSave}
							redirectToChangePassword={redirectToChangePassword}
						/>
					</>
				)
			}
			<div className={`${isAuth ? 'container' : ''}`}>
				<Navigation />
			</div>
		</div>
	);
};

export default Home;
