import './TopBar.scss';
// import { AddCircleOutlineOutlined, NotificationsOutlined, Search } from '@material-ui/icons';
import { AppBar, Avatar, Toolbar } from '@material-ui/core';
// import ActionButton from '../Buttons/ActionButton';
import { AuthContext } from '../../contexts/AuthContext';
import logo from '../../assets/images/pragma-logo.svg';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useRoutes from '../Hooks/useRoutes';
// import { UserRole } from '../../interfaces/User';
// import { useTranslation } from 'react-i18next';
// import withPermissions from '../../helpers/HOC/withPermissions';

interface TopBarProps {
	isCreateReportVisible: boolean;
	createReportOnClick?: () => void;
	isSearchVisible: boolean;
	searchOnChange?: () => void;
	showBadge?: boolean;
	handleOpenProfileModal: (flag: boolean) => void;
	profileAnchorEl: null | HTMLElement;
	handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
	handleClose: () => void;
}

const TopBar: React.FC<TopBarProps> = (props) => {
	// const { t } = useTranslation();
	const history = useHistory();
	const { ROUTES } = useRoutes();
	const { user } = React.useContext(AuthContext);
	// const ActionButtonWithPermissions = withPermissions(ActionButton);

	return (
		<>
			<AppBar
				className='topBar'
				position='static'
			>
				<Toolbar
					className='toolBar'
				>
					<div className='leftSection' onClick={(): void => history.push(ROUTES.DASHBOARD)}>
						<img src={logo} alt="pragma" />
					</div>
					<div className='rightSection'>
						{/* {
							props.isSearchVisible && props.searchOnChange
							&& (
								<>
									<Divider
										className='divider'
										orientation='vertical'
										flexItem
									/>
									<TextField
										className='toolBarSearch'
										placeholder={t('topBar:SEARCH_PLACEHOLDER_TEXT')}
										onChange={props.searchOnChange}
										InputProps={{
											classes: { underline: 'toolBarSearchUnderline' },
											startAdornment:
												<InputAdornment
													position="start">
													<Search className='searchIcon' />
												</InputAdornment>,
										}}
									>
									</TextField>
								</>
							)
						}
						{
							props.isCreateReportVisible && props.createReportOnClick
							&& (
								<>
									<Divider
										className='divider'
										orientation='vertical'
										flexItem
									/>
									<ActionButtonWithPermissions
										className='toolBarReportButton'
										name={t('topBar:CREATE_REPORT_TEXT')}
										onClick={props.createReportOnClick}
										startIcon={<AddCircleOutlineOutlined className='reportIcon' />}
										rolesRequired={[UserRole.SUPER_ADMIN]}
									/>
								</>
							)
						}
						<Divider
							className='divider'
							orientation='vertical'
							flexItem
							variant='inset'
						/>
						<IconButton>
							<Badge color='secondary' overlap='circle' variant='dot' invisible={!props.showBadge}>
								<NotificationsOutlined className='notificationIcon' />
							</Badge>
						</IconButton>
						<Divider
							className='divider'
							orientation='vertical'
							flexItem
						/> */}
						<div className={'topbar-avatar'}>
							<Avatar
								src={user.avatarUrl}
								onClick={props.handleOpen}
							/>
						</div>
						<ProfileMenu
							user={user}
							handleOpenProfileModal={props.handleOpenProfileModal}
							profileAnchorEl={props.profileAnchorEl}
							handleClose={props.handleClose}
						/>
					</div>
				</Toolbar>
			</AppBar>
		</>
	);
};

export default TopBar;

