import { AuthContext, AuthUpdateContext } from '../../contexts/AuthContext';
// import { getFile, saveFile } from '../../services/FileBaseService';
import { getMyInfo, updateMyInfo } from '../../services/UserService';
import { AlertContext } from '../../contexts/AlertContext';
import { PanelUserExtended } from '../../interfaces/User';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useRoutes from '../Hooks/useRoutes';
import { useTranslation } from 'react-i18next';

interface ProfileModalInterface {
	username: string;
	setUsername: (username: string) => void;
	imageSrc: string | undefined;
	setImage: (file: File[]) => void;
	openProfileModal: boolean;
	handleOpenProfileModal: (flag: boolean) => void;
	profileAnchorEl: null | HTMLElement;
	handleOpenProfileMenu: (event: React.MouseEvent<HTMLElement>) => void;
	handleCloseProfileMenu: () => void;
	showDropZone: boolean;
	setShowDropZone: (flag: boolean) => void;
	handleSave: () => Promise<void>;
	redirectToChangePassword: () => void;
}

const useProfileModal = (): ProfileModalInterface => {
	const { t } = useTranslation();
	const history = useHistory();
	const { ROUTES } = useRoutes();
	const { user } = React.useContext(AuthContext);
	const { setUser } = React.useContext(AuthUpdateContext);
	const [openProfileModal, setOpenProfileModal] = React.useState<boolean>(false);
	const [username, setUsername] = React.useState<string>('');
	const [image, setImage] = React.useState<File[]>([]);
	const [imageSrc, setImageSrc] = React.useState<string | undefined>();
	const [showDropZone, setShowDropZone] = React.useState<boolean>(false);
	const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);

	const { showAlert, showDefaultError } = React.useContext(AlertContext);

	const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>): void => {
		setProfileAnchorEl(event.currentTarget);
	};

	const handleCloseProfileMenu = (): void => {
		setProfileAnchorEl(null);
	};

	const loadUser = React.useCallback(async (): Promise<void> => {
		setUsername(user.name);
		setImageSrc(user.avatarUrl);
	}, [user]);

	React.useEffect(() => {
		loadUser();
	}, [loadUser]);

	const handleOpenProfileModal = (flag: boolean): void => {
		const resetFields = (): void => {
			setUsername(user.name);
			setImageSrc(user.avatarUrl);
		};

		flag ? handleCloseProfileMenu() : resetFields();

		setOpenProfileModal(flag);
	};

	const setFields = async (): Promise<PanelUserExtended> => {
		const userToEdit = user;

		userToEdit.name = username;

		return userToEdit;
	};

	const handleSave = async (): Promise<void> => {
		const user = await setFields();

		try {
			await updateMyInfo(user, image[0]);
			setUser(await getMyInfo());
			handleOpenProfileModal(false);
			showAlert({ message: t('message:PROFILE_UPDATED') });
		} catch (error) {
			showDefaultError();
		}
	};

	const redirectToChangePassword = (): void => {
		handleOpenProfileModal(false);
		history.push(ROUTES.CHANGE_PASSWORD);
	};

	return {
		username,
		setUsername,
		imageSrc,
		setImage,
		openProfileModal,
		handleOpenProfileModal,
		profileAnchorEl,
		handleOpenProfileMenu,
		handleCloseProfileMenu,
		showDropZone,
		setShowDropZone,
		handleSave,
		redirectToChangePassword,
	};
};

export default useProfileModal;
