import { isEmailValid, passwordValidation } from '../../services/UserService';
import React from 'react';
import { useLocalStorage } from '../../helpers/Hooks/useLocalStorage';

interface SignInValidationStore {
	validateEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
	validatePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
	email: string | undefined;
	emailError: boolean;
	emailErrorMessage: string | undefined;
	password: string | undefined;
	passwordError: boolean;
	passwordErrorMessage: string | undefined;
}

export const useSignInValidation = (): SignInValidationStore => {
	const { getItem, isKeyStored } = useLocalStorage('email');
	const [email, setEmail] = React.useState<string | undefined>(isKeyStored() ? getItem() : undefined);
	const [password, setPassword] = React.useState<string | undefined>(undefined);
	const [emailError, setEmailError] = React.useState<boolean>(false);
	const [emailErrorMessage, setEmailErrorMessage] = React.useState<string | undefined>(undefined);
	const [passwordError, setPasswordError] = React.useState<boolean>(false);
	const [passwordErrorMessage, setPasswordErrorMessage] = React.useState<string | undefined>(undefined);

	const validateEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setEmail(event.target.value);
		const validEmail = isEmailValid(event.target.value);

		setEmailError(!validEmail);
		setEmailErrorMessage(!validEmail ? 'El correo no es válido' : undefined);
	};

	const validatePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setPassword(event.target.value);
		const [hasErrors, errorMessage] = passwordValidation(event.target.value);

		setPasswordError(hasErrors);
		setPasswordErrorMessage(hasErrors ? errorMessage : undefined);
	};

	return {
		email,
		password,
		validateEmail,
		validatePassword,
		emailError,
		emailErrorMessage,
		passwordError,
		passwordErrorMessage,
	};
};
