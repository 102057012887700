import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Option } from '../../../../interfaces/Documents/Questions/Options';
import { QuestionPreviewProps } from '../PreviewWrapper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import useRadioPreview from './useRadioPreview';

const RadioPreview: React.FC<QuestionPreviewProps> = (props) => {
	const {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	} = useRadioPreview(props);
	const { value, options, isValid } = question;

	return (
		<>
			<RadioGroup value={value} onChange={handleOnChange}>
				{options && options.map((option: Option, index: number) => (
					<FormControlLabel
						key={index}
						value={option.id}
						control={<Radio color='primary' />} label={option.title} />
				))}
			</RadioGroup>
			{showErrors && !isValid && <FormHelperText error={showErrors && !isValid}>{errorMessage}</FormHelperText>}
		</>
	);
};

export default RadioPreview;
