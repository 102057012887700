import { AlertProvider } from './contexts/AlertContext';
import { AuthProvider } from './contexts/AuthContext';
import DateFnsUtils from '@date-io/date-fns';
import Home from './pages/Home';
import { LoadingProvider } from './contexts/LoaderContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import muiTheme from './themes/muiTheme';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

const App = (): JSX.Element => {
	return (
		<Router basename="/">
			<ThemeProvider theme={muiTheme}>
				<AuthProvider>
					<LoadingProvider>
						<AlertProvider>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Home />
							</MuiPickersUtilsProvider>
						</AlertProvider>
					</LoadingProvider>
				</AuthProvider>
			</ThemeProvider>
		</Router>
	);
};

export default App;
