import DefaultInput from '../Inputs/Input/DefaultInput';
import FormPreview from '../Preview/FormPreview';
import Modal from '../Modal';
import React from 'react';
import usePreviewModal from '../Preview/usePreviewModal';
import useTestCaseModal from './useTestCaseModal';
import { useTranslation } from 'react-i18next';

export interface TestCaseModalProps {
	open: boolean;
	onClose: () => void;
}

const TestCaseModal: React.FC<TestCaseModalProps> = (props) => {
	const { t } = useTranslation();

	const {
		testCase,
		setTestCase,
		saveTestCase,
		handleOnClose,
	} = useTestCaseModal(props);

	const {
		documentModel,
		sectionsToShow,
		isFirstStep,
		isLastStep,
		handleBack,
		handleClosePreviewModal,
		handleNextButton,
	} = usePreviewModal({validate: saveTestCase, close: handleOnClose});

	return (
		<>
			<Modal
				title={t('formsView:MODAL_TEST_CASE_TITLE')}
				open={props.open}
				handleOnClose={handleClosePreviewModal}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: isLastStep() ? t('formsView:MODAL_TEST_CASE_PRIMARY_BUTTON') : t('formsView:MODAL_PREVIEW_BUTTON_NEXT'),
					handleOnclick: handleNextButton,
					isDisabled: !testCase.title.value,
				}}
				isSecondaryButtonVisible={!isFirstStep()}
				secondaryButtonProps={{
					name: t('formsView:MODAL_PREVIEW_BUTTON_BACK'),
					handleOnclick: handleBack,
				}}
				size='md'
			>
				<>
					<DefaultInput
						value={testCase.title.value}
						label={t('formsView:MODAL_TEST_CASE_NAME')}
						onChange={(e): void => setTestCase({ type: 'setTitle', value: e.target.value })}
						variant='outlined'
						isError={documentModel.showErrors && !testCase.title.isValid}
						errorMessage={documentModel.showErrors ? testCase.title.errorMessage : ''}
					/>
					<FormPreview
						sectionToShow={sectionsToShow}
						documentModel={documentModel}
					/>
				</>
			</Modal>
		</>
	);
};

export default TestCaseModal;
