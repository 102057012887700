import './ProfileModal.scss';
import { Avatar, Button, Divider, Fab, FormControl } from '@material-ui/core';
import ActionButton from '../Buttons/ActionButton';
import DefaultInput from '../Inputs/Input';
import { EditOutlined } from '@material-ui/icons';
import ImageCrop from '../ImageCrop/ImageCrop';
import Modal from '../Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ProfileModalProps {
	username: string;
	setUsername: (username: string) => void;
	imageSrc: string | undefined;
	setImage: (file: File[]) => void;
	showDropZone: boolean;
	setShowDropZone: (flag: boolean) => void;
	openProfileModal: boolean;
	handleOpenProfileModal: (flag: boolean) => void;
	handleSave: () => Promise<void>;
	redirectToChangePassword: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = (props) => {
	const { t } = useTranslation();

	return (
		<Modal
			title={t('profile:UPDATE_PROFILE')}
			open={props.openProfileModal}
			handleOnClose={(): void => props.handleOpenProfileModal(false)}
			isPrimaryButtonVisible={true}
			primaryButtonProps={{
				name: t('profile:BUTTON_SAVE'),
				handleOnclick: (): Promise<void> => props.handleSave(),
			}}
			isSecondaryButtonVisible={true}
			secondaryButtonProps={{
				name: t('profile:BUTTON_CANCEL'),
				handleOnclick: (): void => props.handleOpenProfileModal(false),
			}}
		>
			<FormControl className='profile-modal' fullWidth margin='normal'>
				<DefaultInput className='title-input' value={props.username} placeholder={t('profile:NAME')} variant='outlined' onChange={(e): void => props.setUsername(e.target.value)} />
				<div className='upload-image-section'>
					{
						props.showDropZone
							? (
								<div className='drop-container'>
									<ImageCrop
										aspectRatio={1}
										containerClass='upload-container'
										onChange={(img: Blob): void => props.setImage([new File([img], 'File')])}
										uploadButtonLabel={t('imageCrop:IMAGE_UPLOAD')}
										previewButtonLabel={t('imageCrop:IMAGE_PREVIEW')}
										previewTitle={t('imageCrop:IMAGE_PREVIEW')}
										zoomLabel={t('imageCrop:IMAGE_ZOOM')}
										rotationLabel={t('imageCrop:IMAGE_ROTATION')}
									/>
									<Button className='float-button-drop' variant="contained" color="primary" size='small' onClick={(): void => props.setShowDropZone(!props.showDropZone)}>
										{t('action:CANCEL_BUTON')}
									</Button>
								</div>
							)
							: (
								<div className='image-container'>
									<Avatar
										src={props.imageSrc}
										className="image"
									/>
									<Fab className='float-button-image hide' size='small' onClick={(): void => props.setShowDropZone(!props.showDropZone)}>
										<EditOutlined />
									</Fab>
								</div>
							)
					}
				</div>
				<Divider className="divider" />
				<ActionButton
					name={t('profile:BUTTON_CHANGE_PASSWORD')}
					onClick={(): void => props.redirectToChangePassword()}
					fullWidth
				/>
			</FormControl>
		</Modal>
	);
};

export default ProfileModal;
