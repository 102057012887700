import { Question, QuestionType } from '../../../interfaces/Documents/Questions/Question';
import { BooleanQuestion } from '../../../interfaces/Documents/Questions/BooleanQuestion';
import { CheckboxQuestion } from '../../../interfaces/Documents/Questions/CheckboxQuestion';
import { DateQuestion } from '../../../interfaces/Documents/Questions/DateQuestion';
import { NumberDecimalQuestion } from '../../../interfaces/Documents/Questions/NumberDecimalQuestion';
import { NumberIntegerQuestion } from '../../../interfaces/Documents/Questions/NumberIntegerQuestion';
import { Option } from '../../../interfaces/Documents/Questions/Options';
import { RadioQuestion } from '../../../interfaces/Documents/Questions/RadioQuestion';
import { SelectQuestion } from '../../../interfaces/Documents/Questions/SelectQuestion';
import { TextQuestion } from '../../../interfaces/Documents/Questions/TextQuestion';

const DefaultQuestion: Question = {
	id: undefined,
	order: -1,
	title: '',
	code: '',
	required: true,
	type: QuestionType.Text,
	showGoTo: false,
	isValid: true,
	value: '',
};

export const DefaultTextQuestion: TextQuestion = {
	...DefaultQuestion,
	type: QuestionType.Text,
	min: 0,
	max: 100,
	value: '',
};

export const DefaultNumberIntegerQuestion: NumberIntegerQuestion = {
	...DefaultQuestion,
	type: QuestionType.NumberInteger,
	min: 0,
	max: 100,
	value: '',
};

export const DefaultNumberDecimalQuestion: NumberDecimalQuestion = {
	...DefaultQuestion,
	type: QuestionType.NumberDecimal,
	min: 0,
	max: 100,
	minString: '0',
	maxString: '100',
	value: '',
};

export const DefaultDateQuestion: DateQuestion = {
	...DefaultQuestion,
	type: QuestionType.Date,
	min: new Date(),
	max: new Date(),
	value: null,
};

export const DefaultGenericOption: Option = {
	id: undefined,
	title: '',
	order: 1,
	goTo: '',
};

export const DefaultRadioQuestion: RadioQuestion = {
	...DefaultQuestion,
	value: '',
	type: QuestionType.Radio,
	optionsType: 'text',
	options: [],
};

export const DefaultCheckboxQuestion: CheckboxQuestion = {
	...DefaultQuestion,
	type: QuestionType.Checkbox,
	min: 0,
	max: 100,
	optionsType: 'text',
	options: [],
	value: {},
};

export const DefaultSelectQuestion: SelectQuestion = {
	...DefaultQuestion,
	value: '',
	type: QuestionType.Select,
	optionsType: 'text',
	options: [],
};

export const DefaultBooleanQuestion: BooleanQuestion = {
	...DefaultQuestion,
	value: 'false',
	type: QuestionType.Boolean,
	optionsType: 'text',
	options: [],
};
