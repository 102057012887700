import { editUser, getInvitationsList, getRoleName, getRoles, getuserList, isEmailValid, saveUser } from '../../../services/UserService';
import { PanelUser, UserInvitation, UserRole } from '../../../interfaces/User';
import { AlertContext } from '../../../contexts/AlertContext';
import { Option } from '../../../interfaces/Common';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PanelUserHookInterface {
	editableRowId: number | null;
	editRow: (row: PanelUser) => void;
	emailError: boolean;
	emailErrorMessage: string | undefined;
	emailValue: string;
	enableDisableRow: (row: PanelUser) => void;
	handleCloseDialog: () => void;
	handleOpenDialog: () => void;
	handleSave: () => Promise<void>;
	invitations: Array<UserInvitation>;
	openModal: boolean;
	roles: Array<Option>;
	roleSelected: UserRole | -1;
	roleValue: number | undefined;
	setRoleFilter: (event: React.ChangeEvent<{ value: unknown }>) => void;
	users: Array<PanelUser>;
	validateEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
	validateExistingUserRole: (item: PanelUser, value: unknown) => void;
	validateNewUserRole: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const useUser = (): PanelUserHookInterface => {
	const [users, setUsers] = React.useState<Array<PanelUser>>([]);
	const [invitations, setInvitations] = React.useState<Array<UserInvitation>>([]);
	const [roleSelected, setRoleSelected] = React.useState<UserRole | -1>(-1);
	const [roles, setRoles] = React.useState<Array<Option>>([]);
	const [openModal, setOpenModal] = React.useState<boolean>(false);
	const [emailValue, setEmailValue] = React.useState<string>('');
	const [emailError, setEmailError] = React.useState<boolean>(false);
	const [emailErrorMessage, setEmailErrorMessage] = React.useState<string | undefined>(undefined);
	const [roleValue, setRoleValue] = React.useState<number | undefined>(undefined);
	const [editableRowId, setEditableRowId] = React.useState<number | null>(null);
	const { showDefaultError } = React.useContext(AlertContext);

	const { t } = useTranslation();

	const loadPanelUserList = React.useCallback(async (): Promise<void> => {
		try {
			const panelUsers: Array<PanelUser> = await getuserList();

			setUsers(panelUsers);
		} catch (e) {
			showDefaultError();
		}
	}, []);

	const loadPanelInvitationList = React.useCallback(async (): Promise<void> => {
		try {
			const panelInvitations: Array<UserInvitation> = await getInvitationsList();

			setInvitations(panelInvitations);
		} catch (e) {
			showDefaultError();
		}
	}, []);

	const tryEditUser = async (user: PanelUser): Promise<void> => {
		try {
			await editUser(user);
		} catch (e) {
			showDefaultError();
		}
	};

	const loadRoles = async (): Promise<void> => {
		const result: Array<Option> = new Array<Option>();

		await getRoles().then(roles => {
			roles.forEach(role => {
				result.push({ key: role, value: t(`roles:${getRoleName(role)}`) });
			});
		});

		setRoles(result);
	};

	const handleOpenDialog = (): void => {
		setOpenModal(true);
	};

	const handleCloseDialog = (): void => {
		setOpenModal(false);
	};

	const validateEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const validEmail = isEmailValid(event.target.value);

		setEmailValue(event.target.value);
		setEmailError(!validEmail);
		setEmailErrorMessage(!validEmail ? t('userView:MODAL_MAIL_ERROR') : undefined);
	};

	const validateExistingUserRole = (item: PanelUser, value: unknown): void => {
		item.role = value as number;

		setUsers(
			users.map(user => {
				return user.id === item.id ? {
					...user,
					role: value as number,
				} : user;
			})
		);
	};

	const validateNewUserRole = (event: React.ChangeEvent<{ value: unknown }>): void => {
		setRoleValue(event.target.value as number);
	};

	React.useEffect(() => {
		loadPanelUserList();
		loadPanelInvitationList();
	}, [loadPanelUserList, loadPanelInvitationList, roleSelected]);

	React.useEffect(() => {
		loadRoles();
	}, []);

	React.useEffect(() => {
		if (!openModal) {
			setEmailErrorMessage(undefined);
			setEmailValue('');
			setEmailError(false);
			setEmailErrorMessage(undefined);
			setRoleValue(undefined);
		}
	}, [openModal]);

	const handleSave = async (): Promise<void> => {
		const roleSelected = roles.find(x => x.key === roleValue);

		if (roleSelected) {
			const userInvitation: UserInvitation = {
				mail: emailValue,
				role: roleSelected.key as unknown as UserRole,
			};

			try {
				await saveUser(userInvitation);

				loadPanelInvitationList();
				handleCloseDialog();
			} catch (e) {
				showDefaultError();
			}
		}
	};

	const setRoleFilter = (event: React.ChangeEvent<{ value: unknown }>): void => {
		setRoleSelected(event.target.value as unknown as UserRole);
	};

	const editRow = async (row: PanelUser): Promise<void> => {
		if (row.id) {
			if (row.id === editableRowId) {
				await tryEditUser(row);
				setEditableRowId(0);
				loadPanelUserList();
			} else {
				if (editableRowId !== 0) {
					loadPanelUserList();
				}

				setEditableRowId(row.id);
			}
		}
	};

	const enableDisableRow = async (row: PanelUser): Promise<void> => {
		row.isDisabled = !row.isDisabled;
		await tryEditUser(row);
		loadPanelUserList();
	};

	return {
		editableRowId,
		editRow,
		emailError,
		emailErrorMessage,
		emailValue,
		enableDisableRow,
		handleCloseDialog,
		handleOpenDialog,
		handleSave,
		invitations,
		openModal,
		roles,
		roleSelected,
		roleValue,
		setRoleFilter,
		users,
		validateEmail,
		validateExistingUserRole,
		validateNewUserRole,
	};
};

export default useUser;

