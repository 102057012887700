import './SubHeader.scss';
import ActionButton, { ActionButtonProps } from '../Buttons/ActionButton/ActionButton';
import { Breadcrumbs, Divider, Link } from '@material-ui/core';
import { Folder } from '../../interfaces/Folder';
import { NavigateNext } from '@material-ui/icons';
import { Option } from '../../interfaces/Common';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SubHeaderProps {
	folder: Folder | null | undefined;
	onClick: (parentId: number | undefined) => void;
	isActionButtonVisible: boolean;
	actionButtonProps?: ActionButtonProps;
}

const getFolders = (currentFolder: Folder | null | undefined, list: Array<Option>): void => {
	if (currentFolder !== null) {

		if (currentFolder?.parentFolder && currentFolder?.parentFolder !== null) {
			getFolders(currentFolder?.parentFolder, list);
		}

		if (currentFolder?.id) {
			list.push({ key: currentFolder?.id, value: currentFolder?.name });
		}
	}
};

const SubHeader: React.FC<SubHeaderProps> = ({ folder, onClick, isActionButtonVisible, actionButtonProps }) => {

	const { t } = useTranslation();
	const [paths, setPaths] = React.useState<Array<Option>>([]);

	const load = React.useCallback((): void => {
		const routes: Array<Option> = [{ key: undefined, value: t('subHeader:PRINCIPAL_FOLDER') }];

		getFolders(folder, routes);
		setPaths(routes);
	}, [folder, t]);

	React.useEffect(() => {
		load();
	}, [load]);

	return (
		<>
			<Divider className='subheader-divider' />
			<div className='subheader'>
				<Breadcrumbs className='folder-bread-crumbs' separator={<NavigateNext fontSize='small' className='bread-crumbs-separator' />}>
					{
						paths.map((item, index) => {
							const last = index === paths.length - 1;

							return last ? (
								<div className='current-page' key={index}>
									{item.value}
								</div>
							)
								: (
									<Link
										key={index}
										className='link-router'
										onClick={(): void => onClick(item.key as number)}
										underline='none'
									>
										{item.value}
									</Link>
								);
						})
					}
				</Breadcrumbs>
				{
					isActionButtonVisible && actionButtonProps
					&& (
						<div className='action-button'>
							<ActionButton
								name={actionButtonProps.name}
								onClick={actionButtonProps.onClick}
								startIcon={actionButtonProps.startIcon}
								endIcon={actionButtonProps.endIcon}
								isDisabled={actionButtonProps.isDisabled}
								variant={actionButtonProps.variant}
							/>
						</div>
					)
				}
			</div>
		</>
	);
};

export default React.memo(SubHeader);
