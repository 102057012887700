import Alert, { AlertProps, Variant } from '../components/Alert/Alert';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface AlertProviderStore {
	showAlert: (x: { message: string; type?: Variant }) => void;
	showDefaultError: () => void;
}

const initialState: object = {
	open: false,
	message: '',
	type: 'success',
	handleOnClose: () => null,
};

export const AlertContext = React.createContext({} as AlertProviderStore);

const AlertProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const { t } = useTranslation();
	const [options, setOptions] = React.useState(initialState);

	const hideAlert = (): void => setOptions({ ...initialState });

	const showAlert = ({ handleOnClose = hideAlert, ...params }): void =>
		setOptions({ open: true, handleOnClose, ...params });

	const showDefaultError = (): void =>
		showAlert({ message: t('errorMessage:DEFAULT'), type: 'error' });

	return (
		<AlertContext.Provider value={{ showAlert, showDefaultError }}>
			{children}
			<Alert {...options as AlertProps} />
		</AlertContext.Provider>
	);
};

export { AlertProvider };
export const AlertConsumer = AlertContext.Consumer;
