import axios from 'axios';
import { DocumentAPI } from '../interfaces/Documents/Document';
import { ReportPreviewAPI } from '../interfaces/Documents/Report';
import { Tag } from '../interfaces/Tag';

const apiUrl = process.env.REACT_APP_API_URL;

export const getDocumentsByFolder = (folderId: number | undefined, disabledRows: boolean | undefined): Promise<Array<DocumentAPI>> => {
	if (apiUrl) {
		const params = {
			folderId,
			isDisabled: disabledRows,
		};

		return axios.get(`${apiUrl}/documents`, { params });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getDocument = (id: number): Promise<DocumentAPI> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/documents/${id}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getPreview = (reportPreview: ReportPreviewAPI): Promise<Blob> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/documents/preview`, {
			...reportPreview,
		}, {
			responseType: 'blob',
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getTags = (): Promise<Array<Tag>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/documents/tags`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const saveDocument = (document: DocumentAPI): Promise<number> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/documents`, {
			...document,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const editDocument = (document: DocumentAPI, id: number): Promise<void> => {
	if (apiUrl) {
		return axios.patch(`${apiUrl}/documents/${id}`, {
			...document,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const duplicateDocument = (id: number): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/documents/${id}/duplicate`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
