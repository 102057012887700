export default {
	es: {
		route: {
			CLIENTS: '/clientes',
			CHANGE_PASSWORD: '/cambiar-clave',
			CONTENT: '/contenido',
			DASHBOARD: '/tablero',
			RESOURCE_FOLDER: '/carpeta',
			DOCUMENTS: '/documentos',
			DOCUMENTS_FOLDER: '/carpeta',
			FORGOT_PASSWORD: '/recuperar-clave',
			HOME: '/inicio',
			INVITATION: '/invitacion',
			PLANS: '/planes',
			PROFILE: '/perfil',
			RESOURCES: '/recursos',
			TERMS_AND_CONDITIONS: '/terminos-y-condiciones',
			USERS: '/usuarios',
			ADD: '/nuevo',
		},
	},
	en: {
		route: {
			CLIENTS: '/clients',
			CHANGE_PASSWORD: '/change-password',
			CONTENT: '/content',
			DASHBOARD: '/dashboard',
			RESOURCE_FOLDER: '/folder',
			DOCUMENTS: '/documents',
			DOCUMENTS_FOLDER: '/folder',
			FORGOT_PASSWORD: '/forgot-password',
			HOME: '/home',
			PLANS: '/plans',
			PROFILE: '/profile',
			RESOURCES: '/resources',
			TERMS_AND_CONDITIONS: '/terms-and-conditions',
			USERS: '/users',
			ADD: '/new',
		},
	},
};
