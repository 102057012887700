import './SignIn.scss';
import { Link, Redirect } from 'react-router-dom';
import ActionButton from '../../components/Buttons/ActionButton';
import DefaultCheckbox from '../../components/Inputs/Checkbox';
import Divider from '../../components/Divider';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import GoogleAuthButton from '../../components/Buttons/GoogleAuthButton';
import logo from '../../assets/images/pragma-logo.svg';
import React from 'react';
import TextField from '../../components/TextField';
import useRoutes from '../../components/Hooks/useRoutes';
import { useSignIn } from './useSignIn';
import { useSignInValidation } from './useSignInValidation';
import { useTranslation } from 'react-i18next';

const SignIn: React.FC = () => {
	const { t } = useTranslation();
	const { ROUTES } = useRoutes();
	const { rememberMe, setRememberMe, isAuth, handleSignInClick, signInError } = useSignIn();
	const { validateEmail, validatePassword, email, emailError, emailErrorMessage, password, passwordError, passwordErrorMessage } = useSignInValidation();

	if (isAuth) {
		return (
			<Redirect to="/" />
		);
	}

	return (
		<div className="signin">
			<div className="signin-container">
				<div className="signin-logo-container">
					<img src={logo} alt={'logo'} className="signin-logo" />
				</div>
				<span className="signin-title">{t('view:SIGNIN_MESSAGE')}</span>
				<div className="signin-form-container">
					<GoogleAuthButton
						onClick={(): void => { console.log('google auth coming soon...'); }}
						showIcon={true}
						text={t('view:SIGNIN_BUTTON_GOOGLE')}
					/>
					<Divider className="signin-divider" text={t('view:SIGNIN_SPAN_OR')} />
					<div className="signin-form">
						<TextField
							id="email"
							error={emailError}
							errorMessage={emailErrorMessage}
							label={t('view:SIGNIN_LABEL_EMAIL')}
							onChange={validateEmail}
							required={true}
							type="email"
							value={email}
						/>
						<TextField
							id="password"
							error={passwordError}
							errorMessage={passwordErrorMessage}
							label={t('view:SIGNIN_LABEL_PASSWORD')}
							onChange={validatePassword}
							required={true}
							type="password"
							value={password}
						/>
						{signInError && (
							<div className="signin-error">
								<ErrorIcon style={{ marginRight: 5 }} />
								{signInError}
							</div>
						)}
						<div className="signin-footer">
							<DefaultCheckbox
								name="rememberMe"
								checked={rememberMe}
								onChange={(): void => { setRememberMe(!rememberMe); }}
								label={t('view:SIGNIN_REMEMBER_ME')}
							/>
							<Link className="signin-link" to={ROUTES.FORGOT_PASSWORD}>{t('view:SIGNIN_FORGOT_PASSWORD')}</Link>
						</div>
						<ActionButton onClick={(): void => handleSignInClick(email, password)} name={t('view:SIGNIN_BUTTON')} isDisabled={emailError || passwordError || !email || !password} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
