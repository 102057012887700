import './GenericOption.scss';
import DefaultInput from '../../Inputs/Input';
import DefaultSelect from '../../Inputs/Select';
import { GoTo } from '../../../interfaces/Documents/Questions/Options';
import { Option } from '../../../interfaces/Common';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface GenericOptionInterface {
	title: string;
	goTo: GoTo;
	order: number;
	handleOnChangeOption: (name: string, value: string, order: number) => void;
	sectionList: Option[];
	titleLabel?: string;
	showGoToOption?: boolean;
}

const GenericOption: React.FC<GenericOptionInterface> = (props) => {
	const { t } = useTranslation();

	return (
		<div className='generic-option-section'>
			<div className='option-section'>
				<DefaultInput value={props.title} label={props.titleLabel || t('formsView:FIELD_OPTION')} placeholder={t('formsView:FIELD_OPTION')} variant='outlined' onChange={(e): void => props.handleOnChangeOption('title', e.target.value, props.order)} removeMarginBotton />
			</div>
			{
				props.showGoToOption && (
					<div className='go-to-section'>
						<DefaultSelect value={props.goTo} label={t('formsView:FIELD_GO_TO')} options={props.sectionList} isFullWidth onChange={(e): void => props.handleOnChangeOption('goTo', e.target.value as unknown as string, props.order)} removeMarginBotton />
					</div>
				)
			}
		</div>
	);

};

export default React.memo(GenericOption);
