import { DefaultBooleanQuestion, DefaultCheckboxQuestion, DefaultDateQuestion, DefaultGenericOption, DefaultNumberDecimalQuestion, DefaultNumberIntegerQuestion, DefaultRadioQuestion, DefaultSelectQuestion, DefaultTextQuestion } from './Models/QuestionModel';
import { isIntegerNumber, isNumber } from '../../helpers/Validations';
import { Question, QuestionType, QuestionTypes } from '../../interfaces/Documents/Questions/Question';
import Boolean from './Boolean';
import { BooleanQuestion } from '../../interfaces/Documents/Questions/BooleanQuestion';
import Checkbox from './Checkbox';
import { CheckboxQuestion } from '../../interfaces/Documents/Questions/CheckboxQuestion';
import Date from './Date';
import { DateQuestion } from '../../interfaces/Documents/Questions/DateQuestion';
import NumberDecimal from './NumberDecimal';
import { NumberDecimalQuestion } from '../../interfaces/Documents/Questions/NumberDecimalQuestion';
import NumberInteger from './NumberInteger';
import { NumberIntegerQuestion } from '../../interfaces/Documents/Questions/NumberIntegerQuestion';
import { Option } from '../../interfaces/Documents/Questions/Options';
import { QuestionWrapperProps } from './QuestionWrapper';
import Radio from './Radio';
import { RadioQuestion } from '../../interfaces/Documents/Questions/RadioQuestion';
import React from 'react';
import Select from './Select';
import { SelectQuestion } from '../../interfaces/Documents/Questions/SelectQuestion';
import Text from './Text';
import { TextQuestion } from '../../interfaces/Documents/Questions/TextQuestion';
import { v4 as uuidv4 } from 'uuid';

interface QuestionWrapperInterface {
	handleChangeQuestionType: (questionType: QuestionType) => void;
	handleUpdateQuestionRequired: (name: string, value: boolean) => void;
	renderQuestion: (questions: (Question | SelectQuestion | CheckboxQuestion | BooleanQuestion | RadioQuestion | NumberDecimalQuestion | NumberIntegerQuestion | TextQuestion | DateQuestion)) => JSX.Element;
}

const useQuestionWrapper = (props: QuestionWrapperProps): QuestionWrapperInterface => {
	const { question, handleUpdate } = props;

	const handleChangeQuestionType = (questionType: QuestionType): void => {
		switch (questionType) {
			case QuestionType.Text:
				handleUpdate({ ...DefaultTextQuestion, order: question.order, id: question.id });
				break;
			case QuestionType.NumberInteger:
				handleUpdate({ ...DefaultNumberIntegerQuestion, order: question.order, id: question.id });
				break;
			case QuestionType.NumberDecimal:
				handleUpdate({ ...DefaultNumberDecimalQuestion, order: question.order, id: question.id });
				break;
			case QuestionType.Date:
				handleUpdate({ ...DefaultDateQuestion, order: question.order, id: question.id });
				break;
			case QuestionType.Radio:
				handleUpdate({ ...DefaultRadioQuestion, order: question.order, id: question.id });
				break;
			case QuestionType.Select:
				handleUpdate({ ...DefaultSelectQuestion, order: question.order, id: question.id });
				break;
			case QuestionType.Checkbox:
				handleUpdate({ ...DefaultCheckboxQuestion, order: question.order, id: question.id });
				break;
			case QuestionType.Boolean:
				handleUpdate({
					...DefaultBooleanQuestion, order: question.order, id: question.id,
					options: [{ ...DefaultGenericOption, id: uuidv4() }, { ...DefaultGenericOption, order: 2, id: uuidv4() }],
				});
				break;
		}
	};

	const handleUpdateQuestionRequired = (name: string, value: boolean): void => {
		handleUpdate({ ...question, [name]: value });
	};

	const handleUpdateQuestionDataString = React.useCallback((name: string, value: string): void => {
		handleUpdate({ ...question, [name]: value });
	}, [handleUpdate, question]);

	const handleUpdateQuestionDataNumber = React.useCallback((name: string, value: string): void => {
		if (isNumber(value)) {
			handleUpdate({ ...question, [name]: Number(value) });
		}
	}, [handleUpdate, question]);

	const handleUpdateQuestionDataDecimal = React.useCallback((name: string, value: string): void => {
		if (isNumber(value)) {
			handleUpdate({ ...question, [name]: Number.parseFloat(value), [`${name}String`]: value });
		}
	}, [handleUpdate, question]);

	const handleUpdateQuestionDataDate = React.useCallback((name: string, value: Date | null): void => {
		handleUpdate({ ...question, [name]: value });
	}, [handleUpdate, question]);

	const handleUpdateQuestionOptionType = React.useCallback((name: string, value: string): void => {
		const defaultOptions = [{ ...DefaultGenericOption, id: uuidv4() }];

		if (question.type === QuestionType.Boolean) {
			defaultOptions.push({ ...DefaultGenericOption, order: DefaultGenericOption.order + 1, id: uuidv4() });
		}

		handleUpdate({ ...question, options: defaultOptions, [name]: value } as unknown as QuestionTypes);
	}, [handleUpdate, question]);

	const handleAddOption = React.useCallback((): void => {
		const newOptions = [...(question as (RadioQuestion | BooleanQuestion | CheckboxQuestion | SelectQuestion)).options];

		if (newOptions.length > 0) {
			const lastOrder = newOptions[newOptions.length - 1].order;

			newOptions.push({ ...DefaultGenericOption, order: lastOrder + 1, id: uuidv4() });
		} else {
			newOptions.push({ ...DefaultGenericOption, id: uuidv4() });
		}

		handleUpdate({ ...question, options: newOptions } as unknown as QuestionTypes);
	}, [handleUpdate, question]);

	const handleUpdateOption = React.useCallback((name: string, value: string, order: number): void => {
		const { options: newOptions, optionsType } = question as (RadioQuestion | BooleanQuestion | CheckboxQuestion | SelectQuestion);

		if (name === 'title' && optionsType === 'number-integer' && !isIntegerNumber(value)) { return; }

		const optionIndex = newOptions.findIndex(o => o.order === order);

		if (optionIndex > -1) {
			newOptions[optionIndex] = { ...newOptions[optionIndex], [name]: value };

			handleUpdate({ ...question, options: newOptions } as unknown as QuestionTypes);
		}
	}, [handleUpdate, question]);

	const handleDeleteOption = React.useCallback((order: number): void => {
		const oldOptions = [...(question as (RadioQuestion | BooleanQuestion | CheckboxQuestion | SelectQuestion)).options];
		const optionIndex = oldOptions.findIndex(o => o.order === order);

		if (optionIndex > -1) {
			const newOptions: Option[] = [];
			let itemOrder = 1;

			oldOptions.forEach((item) => {
				if (item.order !== order) {
					newOptions.push({ ...item, order: itemOrder });
					itemOrder++;
				}
			});
			handleUpdate({ ...question, options: newOptions } as unknown as QuestionTypes);
		}
	}, [handleUpdate, question]);

	const renderQuestion = (question: (Question | SelectQuestion | CheckboxQuestion | BooleanQuestion | RadioQuestion | NumberDecimalQuestion | NumberIntegerQuestion | TextQuestion | DateQuestion)): JSX.Element => {
		switch (question.type) {
			case QuestionType.NumberInteger:
				return (
					<NumberInteger
						title={(question as NumberIntegerQuestion).title}
						code={(question as NumberIntegerQuestion).code}
						description={(question as TextQuestion).description}
						max={(question as NumberIntegerQuestion).max}
						min={(question as NumberIntegerQuestion).min}
						handleOnChangeTitle={(value: string): void => handleUpdateQuestionDataString('title', value)}
						handleOnChangeDescription={(value: string): void => handleUpdateQuestionDataString('description', value)}
						handleOnChangeMin={(value: string): void => handleUpdateQuestionDataNumber('min', value)}
						handleOnChangeMax={(value: string): void => handleUpdateQuestionDataNumber('max', value)}
					/>
				);
			case QuestionType.NumberDecimal:
				return (
					<NumberDecimal
						title={(question as NumberDecimalQuestion).title}
						code={(question as NumberDecimalQuestion).code}
						description={(question as TextQuestion).description}
						max={(question as NumberDecimalQuestion).max}
						min={(question as NumberDecimalQuestion).min}
						maxString={(question as NumberDecimalQuestion).maxString}
						minString={(question as NumberDecimalQuestion).minString}
						handleOnChangeTitle={(value: string): void => handleUpdateQuestionDataString('title', value)}
						handleOnChangeDescription={(value: string): void => handleUpdateQuestionDataString('description', value)}
						handleOnChangeMin={(value: string): void => handleUpdateQuestionDataDecimal('min', value)}
						handleOnChangeMax={(value: string): void => handleUpdateQuestionDataDecimal('max', value)}
					/>
				);
			case QuestionType.Date:
				return (
					<Date
						title={(question as DateQuestion).title}
						code={(question as DateQuestion).code}
						description={(question as TextQuestion).description}
						min={(question as DateQuestion).min}
						max={(question as DateQuestion).max}
						handleOnChangeTitle={(value: string): void => handleUpdateQuestionDataString('title', value)}
						handleOnChangeDescription={(value: string): void => handleUpdateQuestionDataString('description', value)}
						handleOnChangeMin={(value: Date | null): void => handleUpdateQuestionDataDate('min', value)}
						handleOnChangeMax={(value: Date | null): void => handleUpdateQuestionDataDate('max', value)}
					/>
				);
			case QuestionType.Radio:
				return (
					<Radio
						title={(question as RadioQuestion).title}
						code={(question as RadioQuestion).code}
						description={(question as TextQuestion).description}
						optionType={(question as RadioQuestion).optionsType}
						options={(question as RadioQuestion).options}
						handleOnChangeTitle={(value: string): void => handleUpdateQuestionDataString('title', value)}
						handleOnChangeDescription={(value: string): void => handleUpdateQuestionDataString('description', value)}
						handleAddOption={handleAddOption}
						handleOnChangeOptionType={(value: string): void => handleUpdateQuestionOptionType('optionsType', value)}
						handleOnChangeOption={handleUpdateOption}
						handleDeleteOption={handleDeleteOption}
						sectionList={props.sectionList}
						showGoToOption={props.question.showGoTo}
					/>
				);
			case QuestionType.Select:
				return (
					<Select
						title={(question as SelectQuestion).title}
						code={(question as SelectQuestion).code}
						description={(question as TextQuestion).description}
						optionType={(question as SelectQuestion).optionsType}
						options={(question as SelectQuestion).options}
						handleOnChangeTitle={(value: string): void => handleUpdateQuestionDataString('title', value)}
						handleOnChangeDescription={(value: string): void => handleUpdateQuestionDataString('description', value)}
						handleAddOption={handleAddOption}
						handleOnChangeOptionType={(value: string): void => handleUpdateQuestionOptionType('optionsType', value)}
						handleOnChangeOption={handleUpdateOption}
						handleDeleteOption={handleDeleteOption}
						sectionList={props.sectionList}
						showGoToOption={props.question.showGoTo}
					/>
				);
			case QuestionType.Checkbox:
				return (
					<Checkbox
						title={(question as CheckboxQuestion).title}
						code={(question as CheckboxQuestion).code}
						description={(question as TextQuestion).description}
						min={(question as CheckboxQuestion).min}
						max={(question as CheckboxQuestion).max}
						optionType={(question as CheckboxQuestion).optionsType}
						options={(question as CheckboxQuestion).options}
						handleOnChangeMin={(value: string): void => handleUpdateQuestionDataNumber('min', value)}
						handleOnChangeMax={(value: string): void => handleUpdateQuestionDataNumber('max', value)}
						handleOnChangeTitle={(value: string): void => handleUpdateQuestionDataString('title', value)}
						handleOnChangeDescription={(value: string): void => handleUpdateQuestionDataString('description', value)}
						handleAddOption={handleAddOption}
						handleOnChangeOptionType={(value: string): void => handleUpdateQuestionOptionType('optionsType', value)}
						handleOnChangeOption={handleUpdateOption}
						handleDeleteOption={handleDeleteOption}
						sectionList={props.sectionList}
						showGoToOption={props.question.showGoTo}
					/>
				);
			case QuestionType.Boolean:
				return (
					<Boolean
						title={(question as BooleanQuestion).title}
						code={(question as BooleanQuestion).code}
						description={(question as TextQuestion).description}
						optionType={(question as BooleanQuestion).optionsType}
						options={(question as BooleanQuestion).options}
						handleOnChangeTitle={(value: string): void => handleUpdateQuestionDataString('title', value)}
						handleOnChangeDescription={(value: string): void => handleUpdateQuestionDataString('description', value)}
						handleAddOption={handleAddOption}
						handleOnChangeOptionType={(value: string): void => handleUpdateQuestionOptionType('optionsType', value)}
						handleOnChangeOption={handleUpdateOption}
						sectionList={props.sectionList}
						showGoToOption={props.question.showGoTo}
					/>
				);
			case QuestionType.Text:
			default:
				return (
					<Text
						title={(question as TextQuestion).title}
						code={(question as TextQuestion).code}
						description={(question as TextQuestion).description}
						max={(question as TextQuestion).max}
						min={(question as TextQuestion).min}
						handleOnChangeTitle={(value: string): void => handleUpdateQuestionDataString('title', value)}
						handleOnChangeDescription={(value: string): void => handleUpdateQuestionDataString('description', value)}
						handleOnChangeMin={(value: string): void => handleUpdateQuestionDataNumber('min', value)}
						handleOnChangeMax={(value: string): void => handleUpdateQuestionDataNumber('max', value)}
					/>
				);
		}
	};

	return {
		handleChangeQuestionType,
		handleUpdateQuestionRequired,
		renderQuestion,
	};
};

export default useQuestionWrapper;
