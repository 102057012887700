import buttons from './buttons';
import dropZone from './dropZone';
import folder from './folder';
import imageCrop from './imageCrop';
import invitation from './invitation';
import messages from './messages';
import pages from './pages';
import profile from './profile';
import report from './reportModal';
import roles from './roles';
import routes from './routes';
import signin from './signin';
import signup from './signup';
import subHeader from './subHeader';
import topBar from './topBar';
import variableModal from './variableModal';

const translations = {
	es: {
		...buttons.es,
		...dropZone.es,
		...folder.es,
		...invitation.es,
		...imageCrop.es,
		...signin.es,
		...messages.es,
		...pages.es,
		...profile.es,
		...report.es,
		...roles.es,
		...routes.es,
		...signup.es,
		...subHeader.es,
		...topBar.es,
		...variableModal.es,
	},
	en: {
		...buttons.en,
		...dropZone.en,
		...folder.en,
		...invitation.en,
		...imageCrop.en,
		...signin.en,
		...messages.en,
		...pages.en,
		...profile.en,
		...report.en,
		...roles.en,
		...routes.en,
		...signup.en,
		...subHeader.en,
		...topBar.en,
		...variableModal.en,
	},
};

export default translations;
