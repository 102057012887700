import { Document, DocumentTable } from '../../interfaces/Documents/Document';
import { editDocument, getDocuments } from '../../services/DocumentService';
import { editFolder, getFolders } from '../../services/FolderService';
import { editResource, getResources } from '../../services/ResourceService';
import { Folder, FolderTable, FolderType } from '../../interfaces/Folder';
import { Resource, ResourceTable } from '../../interfaces/Resource';
import { AlertContext } from '../../contexts/AlertContext';
import { MoveFolderFileProps } from './MoveFolderFile';
import React from 'react';

interface MoveFolderFileInterface {
	data: Array<FolderTable | ResourceTable | DocumentTable>;
	parentFolder: Folder | undefined;
	selectedItem: number | undefined;
	handleSelectItem: (folder: Folder) => void;
	handleNavigation: (folder: Folder | undefined) => void;
	handleMoveFolderFile: () => Promise<void>;
}

const useMoveFolderFile = (props: MoveFolderFileProps): MoveFolderFileInterface => {
	const [data, setData] = React.useState<Array<FolderTable | ResourceTable | DocumentTable>>([]);
	const [parentFolder, setParentFolder] = React.useState<Folder | undefined>(props.parentFolder);
	const [selectedItem, setSelectedItem] = React.useState<number | undefined>(undefined);
	const [selectedFolder, setSelectedFolder] = React.useState<Folder | undefined>(props.parentFolder);
	const { showDefaultError } = React.useContext(AlertContext);

	const loadData = React.useCallback(async (): Promise<void> => {
		const parentFolderId = parentFolder === undefined ? undefined : parentFolder.id;
		let folderList: Array<FolderTable | ResourceTable | DocumentTable> = (await getFolders(props.folderItemType, parentFolderId)).map(folder => ({ ...folder, isFolder: true }));

		if (props.folderItemType === FolderType.RESOURCE) {
			const resourceList: Array<FolderTable | ResourceTable | DocumentTable> = await getResources(parentFolderId, undefined);

			folderList = folderList.concat(resourceList);
		} else if (props.folderItemType === FolderType.DOCUMENT) {
			const documentList: Array<FolderTable | ResourceTable | DocumentTable> = await getDocuments(parentFolderId, undefined);

			folderList = folderList.concat(documentList);
		}

		setData(folderList);
	}, [parentFolder, props.folderItemType]);

	React.useEffect(() => {
		loadData();
	}, [loadData]);

	const handleSelectItem = (folder: Folder): void => {
		setSelectedItem(folder?.id || undefined);
		setSelectedFolder(folder);
	};

	const handleNavigation = (folder: Folder | undefined): void => {
		setSelectedItem(undefined);
		setSelectedFolder(folder);
		setParentFolder(folder);
	};

	const handleMoveFolderFile = async (): Promise<void> => {
		if (props.folderFileToMove) {
			if (props.isFolder) {
				const folderToEdit: Folder = props.folderFileToMove as Folder;

				if (selectedItem) {
					folderToEdit.parentFolder = selectedFolder;
				} else {
					folderToEdit.parentFolder = parentFolder;
				}

				if (folderToEdit.id) {
					try {
						await editFolder(folderToEdit, folderToEdit.id);
					} catch (e) {
						showDefaultError();
					}
				}
			} else if (props.folderItemType === FolderType.RESOURCE) {
				const resourceToEdit: Resource = props.folderFileToMove as Resource;

				console.log(resourceToEdit);
				console.log(selectedItem);
				console.log(parentFolder);
				console.log(selectedFolder);

				if (selectedItem) {
					resourceToEdit.folder = selectedFolder;
				} else {
					resourceToEdit.folder = parentFolder;
				}

				console.log(resourceToEdit);

				if (resourceToEdit.id) {
					try {
						await editResource(resourceToEdit, resourceToEdit.id);
					} catch (e) {
						showDefaultError();
					}
				}
			} else if (props.folderItemType === FolderType.DOCUMENT) {
				const documentToEdit: Document = props.folderFileToMove as Document;

				if (selectedItem) {
					documentToEdit.folder = selectedFolder;
				} else {
					documentToEdit.folder = parentFolder;
				}

				if (documentToEdit.id) {
					try {
						await editDocument(documentToEdit, documentToEdit.id);
					} catch (e) {
						showDefaultError();
					}
				}
			}
		}

		props.handleCloseModal();
		props.handleFinishMove();
	};

	return {
		data,
		parentFolder,
		selectedItem,
		handleSelectItem,
		handleNavigation,
		handleMoveFolderFile,
	};
};

export default useMoveFolderFile;
