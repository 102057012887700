import './CellDescription.scss';
import { FolderOpen, InsertDriveFileOutlined } from '@material-ui/icons';
import React from 'react';

interface CellDescriptionProps {
	title?: string;
	description?: string;
	type: 'Folder' | 'File';
}

const CellDescription: React.FC<CellDescriptionProps> = (props) => {
	const isFolder = props.type === 'Folder';

	return (
		<div className='cell-description'>
			<div className={`icon-cell-description ${isFolder ? 'icon-folder' : 'icon-file'}`}>
				{
					isFolder ? <FolderOpen /> : <InsertDriveFileOutlined />
				}
			</div>
			<div className='text'>
				<div className='title'>
					{props.title}
				</div>
				<div className='description'>
					{props.description}
				</div>
			</div>
		</div>
	);
};

export default CellDescription;
