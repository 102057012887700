import './Filter.scss';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { Option } from '../../interfaces/Common';
import React from 'react';

export interface FilterProps {
	onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
	value: string | number;
	options: Option[];
	label?: string;
	isDisabled?: boolean;
	formControlClassName?: string;
	selectClassName?: string;
	menuItemClass?: string;
	placeholder?: string;
}

const Filter: React.FC<FilterProps> = (props) => {
	return (
		<div className='filter'>
			{
				props.label
				&& (
					<div className='label'>
						{
							`${props.label}: `
						}
					</div>
				)
			}
			<FormControl
				className={props.formControlClassName}
				disabled={props.isDisabled}
				variant='outlined'
				size='small'
			>
				<Select
					onChange={props.onChange}
					displayEmpty
					value={props.value}
					className={props.selectClassName}
				>
					{
						props.placeholder &&
						<MenuItem value=''>
							<em>{props.placeholder}</em>
						</MenuItem>}
					{
						props.options.map((item, key) => {
							return (
								<MenuItem
									className={props.menuItemClass}
									key={key}
									value={item.key}
								>
									{
										item.value
									}
								</MenuItem>
							);
						})
					}
				</Select>
			</FormControl>
		</div>
	);
};

export default Filter;
