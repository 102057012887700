export default {
	es: {
		dashboardView: {
			DASHBOARD_TITLE: 'Tablero',
			DASHBOARD_OPTION_FORMS: 'Documentos',
			DASHBOARD_OPTION_RESOURCES: 'Recursos',
			DASHBOARD_OPTION_CONTENT: 'Editar contenido',
			DASHBOARD_OPTION_USERS: 'Usuarios y permisos',
			DASHBOARD_OPTION_CLIENTS: 'Clientes',
			SUBSCRIBERS_NUMBER: 'Cantidad de suscriptos',
			SUBSCRIBERS_PROFESSIONALS: 'Profesionales suscriptos',
			SUBSCRIBERS_BY_GENDER: 'Suscriptos por género',
			SUBSCRIBERS_INCOMES: 'Ingresos',
			REPORT_CREATED: 'Creado',
			REPORT_VIEW: 'Ver reporte',
			REPORT_EDIT: 'Editar',
			REPORT_DELETE: 'Eliminar',
			REPORT_SHARE: 'Compartir',
		},
		contentView: {
			CONTENT_TITLE: 'Contenido',
			CONTENT_OPTION_PLANS: 'Planes',
			CONTENT_OPTION_BLOG: 'Blog',
		},
		userView: {
			HEADER_TITLE: 'Usuario',
			HEADER_ACTION_BUTTON: 'Crear Nuevo',
			HEADER_FILTER_LABEL: 'Rol',
			MODAL_CREATE_TITLE: 'Crear nuevo usuario',
			MODAL_CREATE_NAME: 'Nombre',
			MODAL_CREATE_MAIL: 'Mail',
			MODAL_CREATE_ROLE: 'Rol',
			MODAL_CREATE_PRIMARY_BUTTON: 'Invitar',
			MODAL_CREATE_SECONDARY_BUTTON: 'Cancelar',
			MODAL_NAME_ERROR: 'El usuario no es valido',
			MODAL_MAIL_ERROR: 'El correo no es válido',
			INVITATION: 'Invitado',
		},
		planView: {
			HEADER_TITLE: 'Planes',
		},
		errorView: {
			ERROR_OOPS: 'Ups!',
			ERROR_HAPPENED: 'Ha ocurrido algún error',
		},
		blogView: {
			HEADER_TITLE: 'Blog',
			HEADER_ACTION_BUTTON: 'Nuevo post',
			LABEL_EDITED_BY: 'Editado por',
			TITLE_PLACEHOLDER: 'Titulo',
			TAGS: 'Etiquetas',
			BLOG_TITLE_ERROR: 'El título debe contener entre 1 y 150 caracteres.',
			BLOG_DESCRIPTION_ERROR: 'La descripción debe contener entre 1 y 37630 caracteres.',
			BLOG_IMAGE_UPLOAD: 'Subir imagen',
			BLOG_IMAGE_PREVIEW: 'Vista previa',
			BLOG_IMAGE_ZOOM: 'Aumento',
			BLOG_IMAGE_ROTATION: 'Rotación',
			IMAGE_ERROR: 'Imagen requerida',
		},
		resourcesView: {
			HEADER_TITLE: 'Recursos',
			HEADER_ACTION_BUTTON: 'Nuevo recurso',
			NEW_FOLDER_ACTION_BUTTON: 'Nueva carpeta',
			HEADER_FILTER_LABEL: 'Estado',
			MODAL_CREATE_TITLE: 'Crear nuevo recurso',
			MODAL_CREATE_PRIMARY_BUTTON: 'Guardar',
			MODAL_CREATE_SECONDARY_BUTTON: 'Cancelar',
			MODAL_CREATE_NAME: 'Nombre',
			MODAL_CREATE_DESCRIPTION: 'Descripción',
			MODAL_CREATE_INSTRUCTION: 'Instrucción',
			MODAL_CREATE_REFERENCES: 'Referencias',
			MODAL_CREATE_TAGS: 'Etiquetas',
			MODAL_NAME_ERROR: 'El titulo es invalido',
			MODAL_EDIT_TITLE: 'Editar recurso',
			POPOVER_OPTION_EDIT_FOLDER: 'Editar',
			POPOVER_OPTION_MOVE: 'Mover',
			FILTER_OPTION_ALL: 'Todos',
			FILTER_OPTION_ENABLE: 'Habilitados',
			FILTER_OPTION_DISABLED: 'Deshabilitados',
			NEW_FOLDER_CREATED: 'Nueva carpeta creada exitosamente',
		},
		formsView: {
			HEADER_TITLE: 'Documentos',
			HEADER_ACTION_BUTTON: 'Nuevo documento',
			NEW_FOLDER_ACTION_BUTTON: 'Nueva carpeta',
			HEADER_FILTER_LABEL: 'Estado',
			MODAL_CREATE_TITLE: 'Crear nuevo documento',
			MODAL_ACCEPT_PRIMARY_BUTTON: 'Aceptar',
			MODAL_CREATE_PRIMARY_BUTTON: 'Guardar',
			MODAL_CREATE_SECONDARY_BUTTON: 'Cancelar',
			MODAL_EDIT_TITLE: 'Editar documento',
			POPOVER_OPTION_EDIT_FOLDER: 'Editar',
			POPOVER_OPTION_MOVE: 'Mover',
			POPOVER_OPTION_DUPLICATE: 'Duplicar',
			POPOVER_OPTION_RORDER_OPTIONS: 'Reordenar opciones',
			POPOVER_OPTION_GO_TO_OPTION: 'Ir a la sección segun respuesta',
			FILTER_OPTION_ALL: 'Todos',
			FILTER_OPTION_ENABLE: 'Habilitados',
			FILTER_OPTION_DISABLED: 'Deshabilitados',
			SUBHEADER_TITLE: 'Creación de documento',
			BUTTON_SAVE: 'Guardar',
			BUTTON_PREVIEW: 'Preview',
			BUTTON_NEXT: 'Siguiente',
			BUTTON_BACK: 'Volver',
			FORMS_ADD_TITLE_PLACEHOLDER: 'Documento sin título',
			FORMS_ADD_DESCRIPTION_PLACEHOLDER: 'Descripcíon del documento',
			FORMS_ADD_TAGS_PLACEHOLDER: 'Etiquetas',
			FORM_TITLE: 'Documento',
			SECTION_ADD_TITLE_PLACEHOLDER: 'Sección sin título',
			SECTION_ADD_DESCRIPTION_PLACEHOLDER: 'Descripcíon de la sección',
			SECTION_TITLE: 'Sección',
			FIELD_MAX: 'Maximo',
			FIELD_MIN: 'Minimo',
			FIELD_TITLE: 'Titulo',
			FIELD_CODE: 'Código Único',
			FIELD_DESCRIPTION: 'Descripción',
			FIELD_OPTION_TYPE: 'Tipo de opcion',
			FIELD_OPTION: 'Opcion',
			FIELD_GO_TO: 'Ir a',
			BUTTON_ADD_OPTION: 'Agregar opcion',
			FIELD_OPTION_TRUE: 'Opcion verdadera',
			FIELD_OPTION_FALSE: 'Opcion falsa',
			TITLE_QUESTION_NUMBER: 'Pregunta N°',
			TITLE_SECTION_NUMBER: 'Sección N°',
			NEXT_SECTION: 'Próxima sección',
			END_SECTION: 'última sección',
			TITLE_OPTION_NUMBER: 'Opción N°',
			TITLE_EMPTY: 'Titulo vacio',
			QUESTION_TYPE_SELECT: 'Pregunta desplegable',
			QUESTION_TYPE_CHECKBOX: 'Pregunta de casillas',
			QUESTION_TYPE_BOOLEAN: 'Pregunta Si/No',
			QUESTION_TYPE_RADIO: 'Pregunta de varias opciones',
			QUESTION_TYPE_NUMBER_DECIMAL: 'Pregunta decimal',
			QUESTION_TYPE_NUMBER_INTEGER: 'Pregunta numerica',
			QUESTION_TYPE_TEXT: 'Pregunta de texto',
			QUESTION_TYPE_DATE: 'Pregunta de fecha	',
			TOOLTIP_ADD_QUESTION: 'Añadir pregunta',
			TOOLTIP_COPY: 'Copiar',
			TOOLTIP_DELETE: 'Eliminar',
			TOOLTIP_ADD_DESCRIPTION: 'Añadir Descripción',
			TOOLTIP_ADD_SECTION: 'Añadir sección',
			TITLE_COPY: ' - Copia',
			TITLE_REORDER_QUESTION: 'Reordenar preguntas',
			TITLE_REORDER_SECTION: 'Reordenar secciones',
			TITLE_REORDER_OPTION: 'Reordenar opciones',
			MODAL_PREVIEW_TITLE: 'Vista previa',
			MODAL_PREVIEW_BUTTON_CLOSE: 'Cerrar',
			MODAL_PREVIEW_BUTTON_VALIDATE: 'Validar',
			MODAL_PREVIEW_BUTTON_NEXT: 'Siguiente',
			MODAL_PREVIEW_BUTTON_BACK: 'Volver',
			MODAL_PREVIEW_TEXT_PLACEHOLDER: 'Ingrese su respuesta...',
			MODAL_PREVIEW_TEXT_VALIDATION: 'Respuesta demasiado corta',
			MODAL_PREVIEW_NUMERIC_VALIDATION: 'Rango válido',
			MODAL_PREVIEW_FIELD_REQUIRED: 'Este campo es requerido',
			MODAL_PREVIEW_MIN_CHECKBOX_VALIDATION: 'Este campo es requerido. Marque al menos',
			TITLE_WARNING: 'Advertencia',
			WARNING_MESSAGE_DELETE_SECTION: 'Todas las referencias a la seccion seran borradas. Esta seguro de continuar?',
			REPORT_FILTER_LABEL: 'Seleccionar el caso',
			REPORT_FILTER_PLACEHOLDER: 'Ninguno',
			REPORT_TITLE: 'Creacion del informe',
			VARIABLE_FILTER_LABEL: 'Variables',
			CUSTOM_VARIABLE_FILTER_LABEL: 'Variables personalizadas',
			VARIABLE_FILTER_TOOLTIP: 'Insertar variable',
			MODAL_TEST_CASE_TITLE: 'Crear nuevo caso de resultado',
			MODAL_TEST_CASE_NAME: 'Nombre',
			MODAL_TEST_CASE_PRIMARY_BUTTON: 'Guardar',
			MODAL_TEST_CASE_SECONDARY_BUTTON: 'Cancelar',
			MODAL_REPORT_PREVIEW_TITLE: 'Reporte',
			MODAL_REPORT_PREVIEW_PRIMARY_BUTTON: 'Cerrar',
			NEW_FOLDER_CREATED: 'Nueva carpeta creada exitosamente',
			FORM_DUPLICATED: 'Documento duplicado exitosamente',
		},
		changePasswordView: {
			HEADER_TITLE: 'Ingresa tu nueva contraseña',
			HEADER_DESCRIPTION: 'Sugerencia: la contraseña debe tener entre 8 y 32 caracteres de longitud. Para hacerla más segura, use letras mayúsculas y minúsculas, y números.',
			NEW_PASSWORD_LABEL: 'Contraseña nueva',
			OLD_PASSWORD_LABEL: 'Contraseña anterior',
			CONFIRM_PASSWORD_LABEL: 'Confirmar contraseña nueva',
			CHANGE_PASSWORD_PRIMARY_BUTTON: 'Guardar',
		},
		forgotPassword: {
			HEADER_TITLE: 'Ingresa tu dirección de mail',
			HEADER_DESCRIPTION: 'Un mail se enviará a tu correo para restablecer la contraseña',
			EMAIL_LABEL: 'Email',
			RECOVER_PASSWORD_PRIMARY_BUTTON: 'Recuperar',
		},
		clientView: {
			HEADER_TITLE: 'Cliente',
			HEADER_FILTER_LABEL: 'Plan',
			CONTENT_NO_CLIENTS: 'No hay información para mostrar',
			OPTION_PROCESSING: 'Procesando',
			OPTION_DELAYED: 'Retrasado',
			OPTION_PAYMENT_REVIEW: 'En revisión',
			OPTION_PAYED: 'Pagado',
			OPTION_CLOSED: 'Cerrado',
			OPTION_CANCELED: 'Cancelado',
		},
	},
	en: {
		dashboardView: {
			DASHBOARD_TITLE: 'Dashboard',
			DASHBOARD_OPTION_FORMS: 'Documents',
			DASHBOARD_OPTION_RESOURCES: 'Resources',
			DASHBOARD_OPTION_CONTENT: 'Edit content',
			DASHBOARD_OPTION_USERS: 'Users and permissions',
			DASHBOARD_OPTION_CLIENTS: 'Clients',
			SUBSCRIBERS_NUMBER: 'Number of subscribers',
			SUBSCRIBERS_PROFESSIONALS: 'Professional subscribers',
			SUBSCRIBERS_BY_GENDER: 'Subscribers by gender',
			SUBSCRIBERS_INCOMES: 'Income',
			REPORT_CREATED: 'Created',
			REPORT_VIEW: 'View report',
			REPORT_EDIT: 'Edit',
			REPORT_DELETE: 'Delete',
			REPORT_SHARE: 'Share',
		},
		contentView: {
			CONTENT_TITLE: 'Content',
			CONTENT_OPTION_PLANS: 'Plans',
			CONTENT_OPTION_BLOG: 'Blog',
		},
		userView: {
			HEADER_TITLE: 'User',
			HEADER_ACTION_BUTTON: 'Add New',
			HEADER_FILTER_LABEL: 'Rol',
			MODAL_CREATE_TITLE: 'Create new user',
			MODAL_CREATE_NAME: 'Name',
			MODAL_CREATE_MAIL: 'Email',
			MODAL_CREATE_ROLE: 'Role',
			MODAL_CREATE_PRIMARY_BUTTON: 'Invite',
			MODAL_CREATE_SECONDARY_BUTTON: 'Cancel',
			MODAL_NAME_ERROR: 'Invalid User',
			MODAL_MAIL_ERROR: 'Invaid Email',
			INVITATION: 'Invited',
		},
		planView: {
			HEADER_TITLE: 'Plans',
		},
		errorView: {
			ERROR_OOPS: 'Oops!',
			ERROR_HAPPENED: 'An error has ocurred',
		},
		blogView: {
			HEADER_TITLE: 'Blog',
			HEADER_ACTION_BUTTON: 'New post',
			LABEL_EDITED_BY: 'Edited by',
			TITLE_PLACEHOLDER: 'Title',
			TAGS: 'Tags',
			BLOG_TITLE_ERROR: 'Title should have between 1 and 150 characters.',
			BLOG_DESCRIPTION_ERROR: 'Description should have between 1 and 37630 characters.',
			BLOG_IMAGE_UPLOAD: 'Upload image',
			BLOG_IMAGE_PREVIEW: 'Preview',
			BLOG_IMAGE_ZOOM: 'Zoom',
			BLOG_IMAGE_ROTATION: 'Rotation',
			IMAGE_ERROR: 'An image is required',
		},
		resourcesView: {
			HEADER_TITLE: 'Resources',
			HEADER_ACTION_BUTTON: 'New resource',
			NEW_FOLDER_ACTION_BUTTON: 'New folder',
			HEADER_FILTER_LABEL: 'Status',
			MODAL_CREATE_TITLE: 'Create new resource',
			MODAL_CREATE_PRIMARY_BUTTON: 'Save',
			MODAL_CREATE_SECONDARY_BUTTON: 'Cancel',
			MODAL_CREATE_NAME: 'Name',
			MODAL_CREATE_DESCRIPTION: 'Description',
			MODAL_CREATE_INSTRUCTION: 'Instruction',
			MODAL_CREATE_REFERENCES: 'References',
			MODAL_CREATE_TAGS: 'Tags',
			MODAL_NAME_ERROR: 'Invalid title',
			MODAL_EDIT_TITLE: 'Edit resource',
			POPOVER_OPTION_EDIT_FOLDER: 'Edit',
			POPOVER_OPTION_MOVE: 'Move',
			FILTER_OPTION_ALL: 'All',
			FILTER_OPTION_ENABLE: 'Enable',
			FILTER_OPTION_DISABLED: 'Disabled',
			NEW_FOLDER_CREATED: 'New folder created successfully',
		},
		formsView: {
			HEADER_TITLE: 'Documents',
			HEADER_ACTION_BUTTON: 'New document',
			NEW_FOLDER_ACTION_BUTTON: 'New Folder',
			HEADER_FILTER_LABEL: 'Status',
			MODAL_CREATE_TITLE: 'Create new document',
			MODAL_ACCEPT_PRIMARY_BUTTON: 'Accept',
			MODAL_CREATE_PRIMARY_BUTTON: 'Save',
			MODAL_CREATE_SECONDARY_BUTTON: 'Cancel',
			MODAL_EDIT_TITLE: 'Edit document',
			POPOVER_OPTION_EDIT_FOLDER: 'Edit',
			POPOVER_OPTION_MOVE: 'Move',
			POPOVER_OPTION_DUPLICATE: 'Duplicate',
			POPOVER_OPTION_RORDER_OPTIONS: 'Reorder options',
			POPOVER_OPTION_GO_TO_OPTION: 'Go to section according to answer',
			FILTER_OPTION_ALL: 'All',
			FILTER_OPTION_ENABLE: 'Enable',
			FILTER_OPTION_DISABLED: 'Disabled',
			SUBHEADER_TITLE: 'Document creation',
			BUTTON_SAVE: 'Save',
			BUTTON_PREVIEW: 'Preview',
			BUTTON_NEXT: 'Next',
			BUTTON_BACK: 'Back',
			FORMS_ADD_TITLE_PLACEHOLDER: 'Document without title',
			FORMS_ADD_DESCRIPTION_PLACEHOLDER: 'Document description',
			FORMS_ADD_TAGS_PLACEHOLDER: 'Tags',
			FORM_TITLE: 'Documents',
			SECTION_ADD_TITLE_PLACEHOLDER: 'Section wihout title',
			SECTION_ADD_DESCRIPTION_PLACEHOLDER: 'Section description',
			SECTION_TITLE: 'Section',
			FIELD_MAX: 'Max',
			FIELD_MIN: 'Min',
			FIELD_TITLE: 'Title',
			FIELD_CODE: 'Unique Code',
			FIELD_DESCRIPTION: 'Description',
			FIELD_OPTION_TYPE: 'Option type',
			FIELD_OPTION: 'Option',
			FIELD_GO_TO: 'Go to',
			BUTTON_ADD_OPTION: 'Add option',
			FIELD_OPTION_TRUE: 'True option',
			FIELD_OPTION_FALSE: 'False option',
			TITLE_QUESTION_NUMBER: 'Question N°',
			TITLE_SECTION_NUMBER: 'Section N°',
			NEXT_SECTION: 'Next section',
			END_SECTION: 'Last section',
			TITLE_OPTION_NUMBER: 'Option N°',
			TITLE_EMPTY: 'Empty title',
			QUESTION_TYPE_SELECT: 'Select question',
			QUESTION_TYPE_CHECKBOX: 'Checkbox question',
			QUESTION_TYPE_BOOLEAN: 'Boolean question',
			QUESTION_TYPE_RADIO: 'Radio question',
			QUESTION_TYPE_NUMBER_DECIMAL: 'Decimal question',
			QUESTION_TYPE_NUMBER_INTEGER: 'Integer question',
			QUESTION_TYPE_TEXT: 'Text question',
			QUESTION_TYPE_DATE: 'Date question',
			TOOLTIP_ADD_QUESTION: 'Add question',
			TOOLTIP_COPY: 'Copy',
			TOOLTIP_DELETE: 'Delete',
			TOOLTIP_ADD_DESCRIPTION: 'Add description',
			TOOLTIP_ADD_SECTION: 'Add section',
			TITLE_COPY: '- Copy',
			TITLE_REORDER_QUESTION: 'Reoder questions',
			TITLE_REORDER_SECTION: 'Reorder sections',
			TITLE_REORDER_OPTION: 'Reoder options',
			MODAL_PREVIEW_TITLE: 'Preview',
			MODAL_PREVIEW_BUTTON_CLOSE: 'Close',
			MODAL_PREVIEW_BUTTON_VALIDATE: 'Validate',
			MODAL_PREVIEW_BUTTON_NEXT: 'Next',
			MODAL_PREVIEW_BUTTON_BACK: 'Back',
			MODAL_PREVIEW_TEXT_PLACEHOLDER: 'Enter your answer...',
			MODAL_PREVIEW_TEXT_VALIDATION: 'Answer is too short',
			MODAL_PREVIEW_NUMERIC_VALIDATION: 'Valid range',
			MODAL_PREVIEW_FIELD_REQUIRED: 'This field is required',
			MODAL_PREVIEW_MIN_CHECKBOX_VALIDATION: 'This field is required. Check at least',
			TITLE_WARNING: 'Warning',
			WARNING_MESSAGE_DELETE_SECTION: 'All references will be removed. Are you sure you want to continue?',
			REPORT_FILTER_LABEL: 'Select case',
			REPORT_FILTER_PLACEHOLDER: 'None',
			REPORT_TITLE: 'Creating report',
			VARIABLE_FILTER_LABEL: 'Variables',
			CUSTOM_VARIABLE_FILTER_LABEL: 'Custom variables',
			VARIABLE_FILTER_TOOLTIP: 'Insert variable',
			MODAL_TEST_CASE_TITLE: 'Create new test case',
			MODAL_TEST_CASE_NAME: 'Name',
			MODAL_TEST_CASE_PRIMARY_BUTTON: 'Save',
			MODAL_TEST_CASE_SECONDARY_BUTTON: 'Cancel',
			MODAL_REPORT_PREVIEW_TITLE: 'Report',
			MODAL_REPORT_PREVIEW_PRIMARY_BUTTON: 'Close',
			NEW_FOLDER_CREATED: 'New folder created successfully',
			FORM_DUPLICATED: 'Document duplicated successfully',
		},
		changePasswordView: {
			HEADER_TITLE: 'Enter new password',
			HEADER_DESCRIPTION: 'Tip: The password must be between 8 and 32 characters long. To make it stronger, use upper and lower case letters, and numbers.',
			NEW_PASSWORD_LABEL: 'New password',
			OLD_PASSWORD_LABEL: 'Old password',
			CONFIRM_PASSWORD_LABEL: 'Confirm new password',
			CHANGE_PASSWORD_PRIMARY_BUTTON: 'Save',
		},
		forgotPassword: {
			HEADER_TITLE: 'Enter email',
			HEADER_DESCRIPTION: 'We will send you an email to recover your password.',
			EMAIL_LABEL: 'Email',
			RECOVER_PASSWORD_PRIMARY_BUTTON: 'Recover',
		},
		clientView: {
			HEADER_TITLE: 'Client',
			HEADER_FILTER_LABEL: 'Plan',
			CONTENT_NO_CLIENTS: 'No clients to display',
			OPTION_PROCESSING: 'Processing',
			OPTION_DELAYED: 'Delayed',
			OPTION_PAYMENT_REVIEW: 'Payment review',
			OPTION_PAYED: 'Payed',
			OPTION_CLOSED: 'Closed',
			OPTION_CANCELED: 'Canceled',
		},
	},
};
