import './WYSIWYGEditor.scss';
import 'jodit/build/jodit.min.css';
import { IJodit } from 'jodit/types';
import JoditEditor from 'jodit-react';
import React from 'react';

interface WYSIWYGEditorProps {
	value: string;
	onChange?: (value: string) => void;
	lan?: string | undefined;
	customButton?: object;
	isError?: boolean;
	errorMessage?: string;
}

const WYSIWYGEditor: React.FC<WYSIWYGEditorProps> = (props) => {
	const getConfig = (lan: string | undefined, customButton?: object): IJodit['options'] => {
		const config = {
			language: lan || 'es',
			minHeight: '320',
			buttons: [
				'bold', 'strikethrough', 'underline', 'italic', 'eraser', '|', 'superscript', 'subscript', '|', 'ul', 'ol', '|', 'outdent', 'indent', '|', 'font', 'fontsize',
				'brush', 'paragraph', '|', 'table', 'link', '|', 'align', 'undo', 'redo', '\n', 'selectall', 'cut', 'copy', 'paste', 'copyformat', '|', 'hr',
				...customButton ? [customButton] : [],
			],
		};

		return config as unknown as IJodit['options'];
	};

	const newConfig = React.useMemo(() => getConfig(props.lan, props.customButton), [props.lan, props.customButton]);

	return (
		<div className='WYSIWYGEditor'>
			<JoditEditor
				value={props.value}
				onChange={props.onChange}
				config={newConfig}
			/>
			{props.isError && <div className={'WYSIWYGEditor-error'}>{props.errorMessage}</div>}
		</div>
	);
};

export default WYSIWYGEditor;
