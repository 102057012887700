export default {
	es: {
		roles: {
			SUPER_ADMIN: 'Administrador',
			TECHNICIAN: 'Técnico',
			MARKETING: 'Marketing',
			USER: 'Usuario',
			UNSUPPORTED: 'Desconocido',
		},
	},
	en: {
		roles: {
			SUPER_ADMIN: 'Admin',
			TECHNICIAN: 'Technician',
			MARKETING: 'Marketing',
			USER: 'User',
			UNSUPPORTED: 'Unknown',
		},
	},
};
