import {
	Blog,
	BlogEdit,
	ChangePassword,
	Clients,
	Content,
	Dashboard,
	Error,
	ForgotPassword,
	Forms,
	FormsAdd,
	FormsFolderEdit,
	Plans,
	Profile,
	Resources,
	ResourcesEdit,
	ResourcesFolderEdit,
	SignIn,
	SignUp,
	Users,
	UsersEdit
} from './pages';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import AuthRoute from './components/AuthRoute/AuthRoute';
import Invitation from './pages/Invitation';
import React from 'react';
import useRoutes from './components/Hooks/useRoutes';
import { UserRole } from './interfaces/User';

const Navigation = (): JSX.Element => {
	const { isAuth } = React.useContext(AuthContext);
	const { ROUTES } = useRoutes();

	return (
		<div>
			<Switch>
				<Route path={'/'} exact={true}>
					{isAuth ? <Redirect to={ROUTES.DASHBOARD} /> : <SignIn />}
				</Route>
				<Route key={ROUTES.SIGNIN} path={ROUTES.SIGNIN} exact={true}>
					{isAuth ? <Redirect to={ROUTES.DASHBOARD} /> : <SignIn />}
				</Route>
				<Route key={ROUTES.SIGNUP} path={ROUTES.SIGNUP} exact={true}>
					{isAuth ? <Redirect to={ROUTES.DASHBOARD} /> : <SignUp />}
				</Route>
				<Route key={ROUTES.INVITATION} path={ROUTES.INVITATION} exact={true}>
					<Invitation />
				</Route>
				<Route key={ROUTES.ERROR} path={ROUTES.ERROR} exact={true}>
					<Error />
				</Route>
				<Route key={ROUTES.FORGOT_PASSWORD} path={ROUTES.FORGOT_PASSWORD} exact={true}>
					{isAuth ? <Redirect to={ROUTES.DASHBOARD} /> : <ForgotPassword />}
				</Route>
				<AuthRoute exact path={ROUTES.DASHBOARD} Component={Dashboard} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.MARKETING, UserRole.TECHNICIAN]} />
				<Route key={ROUTES.CONTENT} path={ROUTES.CONTENT}>
					<Switch>
						<AuthRoute exact path={`${ROUTES.CONTENT}${ROUTES.PLANS}`} Component={Plans} rolesRequired={[UserRole.SUPER_ADMIN]} />
						<Route path={`${ROUTES.CONTENT}${ROUTES.BLOG}`}>
							<Switch>
								<AuthRoute exact path={`${ROUTES.CONTENT}${ROUTES.BLOG}/:id`} Component={BlogEdit} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.MARKETING]} />
								<AuthRoute exact Component={Blog} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.MARKETING]} />
							</Switch>
						</Route>
						<AuthRoute exact Component={Content} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.MARKETING]} />
					</Switch>
				</Route>
				<Route key={ROUTES.RESOURCES} path={ROUTES.RESOURCES}>
					<Switch>
						<AuthRoute exact path={`${ROUTES.RESOURCES}${ROUTES.RESOURCE_FOLDER}/:id`} Component={ResourcesFolderEdit} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN]} />
						<AuthRoute exact path={`${ROUTES.RESOURCES}/:id`} Component={ResourcesEdit} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN]} />
						<AuthRoute exact Component={Resources} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN]} />
					</Switch>
				</Route>
				<Route key={ROUTES.DOCUMENTS} path={ROUTES.DOCUMENTS}>
					<Switch>
						<AuthRoute exact path={`${ROUTES.DOCUMENTS}${ROUTES.ADD}`} Component={FormsAdd} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN]} />
						<AuthRoute exact path={`${ROUTES.DOCUMENTS}${ROUTES.DOCUMENTS_FOLDER}/:id`} Component={FormsFolderEdit} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN]} />
						<AuthRoute exact path={`${ROUTES.DOCUMENTS}/:id`} Component={FormsAdd} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN]} />
						<AuthRoute exact Component={Forms} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN]} />
					</Switch>
				</Route>
				<Route key={ROUTES.USERS} path={ROUTES.USERS}>
					<Switch>
						<AuthRoute exact path={`${ROUTES.USERS}/:id`} Component={UsersEdit} rolesRequired={[UserRole.SUPER_ADMIN]} />
						<AuthRoute exact Component={Users} rolesRequired={[UserRole.SUPER_ADMIN]} />
					</Switch>
				</Route>
				<AuthRoute exact path={ROUTES.CLIENTS} Component={Clients} rolesRequired={[UserRole.SUPER_ADMIN]} />
				<AuthRoute exact path={ROUTES.PROFILE} Component={Profile} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN, UserRole.MARKETING]} />
				<AuthRoute exact path={ROUTES.CHANGE_PASSWORD} Component={ChangePassword} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN, UserRole.MARKETING]} />
				<AuthRoute exact path={ROUTES.CHANGE_PASSWORD} Component={ChangePassword} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN, UserRole.MARKETING]} />
				<Route path={'*'}>
					<Redirect to={ROUTES.ERROR} />
				</Route>
			</Switch>
		</div>
	);
};

export default Navigation;
