import './Reorder.scss';
import { Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Modal from '../Modal';
import { Option } from '../../interfaces/Documents/Questions/Options';
import { QuestionTypes } from '../../interfaces/Documents/Questions/Question';
import React from 'react';
import { Section } from '../../interfaces/Documents/Section';
import useReorder from './useReorder';
import { useTranslation } from 'react-i18next';

export interface ReorderProps {
	items: (Section | QuestionTypes | Option)[];
	title: string;
	open: boolean;
	handleOnClose: () => void;
	handleReoder: (itemList: (Section | QuestionTypes | Option)[]) => void;
	secondaryText: string;
}

const Reorder: React.FC<ReorderProps> = (props) => {
	const { t } = useTranslation();
	const {
		itemList,
		handleChangeOrder,
	} = useReorder(props);
	const lastItemOrder = itemList[itemList.length - 1]?.order;

	return (
		<div className='reorder-modal'>
			<Modal
				title={props.title}
				open={props.open}
				handleOnClose={props.handleOnClose}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: t('folder:MODAL_CREATE_PRIMARY_BUTTON'),
					handleOnclick: (): void => props.handleReoder(itemList),
				}}
				isSecondaryButtonVisible={true}
				secondaryButtonProps={{
					name: t('folder:MODAL_CREATE_SECONDARY_BUTTON'),
					handleOnclick: props.handleOnClose,
				}}
			>
				<List className='reorder-list'>
					{
						itemList.map((item, key) => {
							return (
								<>
									<Divider />
									<ListItem
										className='reorder-list-item'
										key={key}
									>
										<ListItemText
											className='reorder-list-item-text'
											primary={item.title || t('formsView:TITLE_EMPTY')}
											secondary={`${props.secondaryText} ${item.order}`}
										/>
										<ListItemSecondaryAction>
											<IconButton
												onClick={(): void => handleChangeOrder(item.order, true)}
												disabled={item.order === 1}
											>
												<ExpandLess />
											</IconButton>
											<IconButton
												onClick={(): void => handleChangeOrder(item.order, false)}
												disabled={item.order === lastItemOrder}
											>
												<ExpandMore />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</>
							);
						})
					}
					<Divider />
				</List>
			</Modal>
		</div>
	);
};

export default Reorder;
