import { CustomVariable, OperatorType, VariableValue } from './CustomVariable';

export enum MathematicalOperationType {
	Sum = 'sum',
	Substract = 'subtract',
	Multiplication = 'multiplication',
	Division = 'division',
}

export interface MathematicalCustomVariable extends CustomVariable {
	operatorType: OperatorType.Mathematical;
	specs: {
		operation: MathematicalOperationType;
		values: VariableValue[];
	};
}
