import * as ResourceRepository from '../api/ResourceRepository';
import { Resource, ResourceAPI } from '../interfaces/Resource';
import { FolderType } from '../interfaces/Folder';
import { Tag } from '../interfaces/Tag';

const getResourceMappedToResourceExtended = (resource: ResourceAPI): Resource => {
	if (resource.folder) {
		return ({
			...resource,
			folder: {
				id: resource.folder.id,
				type: FolderType.RESOURCE,
				isDisabled: false,
				name: '',
				createdAt: '',
				updatedAt: '',
				createdBy: { name: '' },
				updatedBy: { name: '' },
			},
		});
	}

	return resource;
};

const getResourceExtendedMappedToResource = (resource: Resource): ResourceAPI => {
	if (resource.folder) {
		const { parentFolder, ...rest } = resource.folder;

		const folderExtended = {
			...rest,
		};

		return ({
			...resource,
			folder: {
				...folderExtended,
				parentFolderId: parentFolder ? parentFolder.id as number : null,
			},
		});
	}

	return resource;
};

export const getResources = async (folderId: number | undefined, disabledRows: boolean | undefined): Promise<Array<Resource>> => {
	const resources = await ResourceRepository.getResourcesByFolder(folderId, disabledRows);

	return resources.map(resource => getResourceMappedToResourceExtended(resource));
};

export const saveResource = async (resource: Resource): Promise<void> => {
	return await ResourceRepository.saveResource(getResourceExtendedMappedToResource(resource));
};

export const getTags = async (): Promise<Array<Tag>> => {
	return await ResourceRepository.getTags();
};

export const editResource = async (resource: Resource, id: number): Promise<void> => {
	if (resource.file && resource.file.id == null) {
		delete resource.file;
	}

	return await ResourceRepository.editResource(getResourceExtendedMappedToResource(resource), id);
};
