export default {
	es: {
		message: {
			SAVE_DEFAULT: 'Los datos se han guardado exitosamente',
			PROFILE_UPDATED: 'Su perfil ha sido actualizado exitosamente',
			CHANGE_PASSWORD_UPDATED: 'Su contraseña ha sido actualizada exitosamente',
			CLIENT_ADDED: 'El cliente ha sido guardado exitosamente',
		},
		errorMessage: {
			DEFAULT: 'Lo sentimos, algo salió mal. Por favor, inténtalo de nuevo',
		},
	},
	en: {
		message: {
			SAVE_DEFAULT: 'Data has been saved successfully',
			PROFILE_UPDATED: 'Your profile has been updated successfully',
			CHANGE_PASSWORD_UPDATED: 'Your password has been updated successfully',
			CLIENT_ADDED: 'Client has been added successfully',
		},
		errorMessage: {
			DEFAULT: 'Sorry, something went wrong. Please try again',
		},
	},
};
