import { FolderAPI, FolderType } from '../interfaces/Folder';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const getFoldersByType = (type: FolderType, parentFolderId: number | undefined): Promise<Array<FolderAPI>> => {
	if (apiUrl) {
		const params = {
			type,
			parentFolderId,
		};

		return axios.get(`${apiUrl}/folders`, { params });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const createFolderByType = (folder: FolderAPI): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/folders`, { ...folder });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const editFolderByType = (folder: FolderAPI, folderId: number): Promise<void> => {
	if (apiUrl) {
		return axios.put(`${apiUrl}/folders/${folderId}`, { ...folder });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
