import { ListCustomVariable } from './ListCustomVariable';
import { LogicCustomVariable } from './LogicCustomVariable';
import { MathematicalCustomVariable } from './MathematicalCustomVariable';
import { SwitchCustomVariable } from './SwitchCustomVariable';
import { Variable } from '../Variables/Variable';

export enum OperatorType {
	Logic = 'logicOperator',
	Mathematical = 'mathematicalOperator',
	Switch = 'switchOperator',
	List = 'listOperator',
}

export interface CustomVariable extends Variable {
	operatorType: OperatorType;
	specs: unknown;
}

export type CustomVariableTypes = ListCustomVariable | LogicCustomVariable | MathematicalCustomVariable | SwitchCustomVariable;

export enum ValueType {
	Constant = 'constant',
	Variable = 'variable',
}

export interface VariableValue {
	valueType: ValueType;
	value: string | number;
	order: number;
}
