import { Controls, IJodit, Nullable } from 'jodit/types';
import { CustomVariable, CustomVariableTypes } from '../../../../interfaces/Documents/CustomVariables/CustomVariable';
import { DocumentContext, DocumentUpdateContext } from '../../../../contexts/DocumentContext';
import { getPreview, isListCustomVariable, isLogicCustomVariable, isMathematicalCustomVariable, isSwitchCustomVariable } from '../../../../services/DocumentService';
import { OptionVariable, VariableType } from '../../../../interfaces/Documents/Variables/Variable';
import { AlertContext } from '../../../../contexts/AlertContext';
import { CreateReportProps } from './CreateReport';
import { LogicCustomVariable } from '../../../../interfaces/Documents/CustomVariables/LogicCustomVariable';
import { MathematicalCustomVariable } from '../../../../interfaces/Documents/CustomVariables/MathematicalCustomVariable';
import { Option } from '../../../../interfaces/Common';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface TestCaseInterface {
	id: string;
	name: string;
}

const isLogicOrMathematicalCustomVariable = (object: CustomVariable): object is LogicCustomVariable | MathematicalCustomVariable => {
	return isLogicCustomVariable(object) || isMathematicalCustomVariable(object);
};

interface CreateReportInterface {
	reportContent: string;
	reportPreview: Blob | null;
	onChangeReportContent: (value: string) => void;
	variables: OptionVariable[];
	openCreateVariableModal: boolean;
	isCreateVariableModalEdit: boolean;
	handleOpenCreateVariableModal: (isEdit: boolean) => void;
	handleCloseCreateVariableModal: () => void;
	handleCloseReportPreviewModal: () => void;
	handleSaveVariable: (variable: CustomVariableTypes, isEdit: boolean) => void;
	handleBackStep: () => void;
	getVariablesFilter: () => object;
	customVariableSelected: CustomVariableTypes | undefined;
	handleChangeCustomVariable: (variableId: string) => void;
	getListOfIdReferences: (variableId: string, variablesList?: CustomVariableTypes[]) => string[];
	handleDeleteVariable: () => void;
	handleDeleteVariableAfterConfirmation: () => void;
	warningMessage: string | undefined;
	isPreviewButtonEnabled: boolean;
	handleCloseWarningMessage: () => void;
	testCases: Option[];
	testCaseName: string;
	handleChangeTestCase: (event: React.ChangeEvent<{ value: unknown }>) => void;
	openTestCaseModal: boolean;
	handleOpenTestCaseModal: () => void;
	openReportPreviewModal: boolean;
	handleOpenReportPreviewModal: () => void;
	handleSave: () => void;
}

const useCreateReport = (props: CreateReportProps): CreateReportInterface => {
	const { t } = useTranslation();
	const {
		documentModel,
	} = React.useContext(DocumentContext);

	const {
		setDocumentModel,
		handleSaveDocument,
		handleChangeStringValues,
	} = React.useContext(DocumentUpdateContext);

	const [reportContent, setReportContent] = React.useState<string>(documentModel.report || '');
	const [reportPreview, setReportPreview] = React.useState<Blob | null>(null);
	const [openCreateVariableModal, setOpenCreateVariableModal] = React.useState<boolean>(false);
	const [testCaseName, setTestCaseName] = React.useState<string>('');
	const [variables, setVariables] = React.useState<OptionVariable[]>([]);
	const [customVariableSelected, setCustomVariableSelected] = React.useState<CustomVariableTypes>();
	const [isCreateVariableModalEdit, setIsCreateVariableModalEdit] = React.useState<boolean>(false);
	const [isPreviewButtonEnabled, setIsPreviewButtonEnabled] = React.useState<boolean>(false);
	const [warningMessage, setWarningMessage] = React.useState<string | undefined>(undefined);
	const [openTestCaseModal, setOpenTestCaseModal] = React.useState<boolean>(false);
	const [openReportPreviewModal, setOpenReportPreviewModal] = React.useState<boolean>(false);
	const { showDefaultError } = React.useContext(AlertContext);

	const onChangeReportContent = (value: string): void => {
		setReportContent(value);
		handleChangeStringValues('report', value);
	};

	React.useEffect(() => {
		let result: OptionVariable[] = [];

		if (documentModel.variables && documentModel.variables.length > 0) {
			const variables: OptionVariable[] = documentModel.variables.map((item) => {
				return { key: item.id, value: item.code, type: item.type || VariableType.Text };
			});

			result = result.concat(variables);
		}

		if (documentModel.customVariables && documentModel.customVariables.length > 0) {
			const customVariables: OptionVariable[] = documentModel.customVariables.map((item) => {
				return { key: item.id, value: item.code, type: item.type || VariableType.Text, isCustomVariable: true };
			});

			result = result.concat(customVariables);
		}

		setVariables(result);
	}, [documentModel.customVariables, documentModel.variables]);

	React.useEffect(() => {
		setIsPreviewButtonEnabled(!!testCaseName);
	}, [testCaseName]);

	const handleOpenCreateVariableModal = (isEdit: boolean): void => {
		if (isEdit) {
			setIsCreateVariableModalEdit(true);
		}

		setOpenCreateVariableModal(true);
	};

	const handleCloseCreateVariableModal = (): void => {
		setOpenCreateVariableModal(false);
		setIsCreateVariableModalEdit(false);
	};

	const getListOfIdReferences = (variableId: string, variablesList = documentModel.customVariables): string[] => {
		let resultList: string[] = [];

		if (variablesList && variablesList?.some(x => x.id === variableId)) {
			variablesList.filter(isLogicOrMathematicalCustomVariable).forEach(item => {
				if (item.id && item.specs?.values?.some((v) => v.value === variableId)) {
					resultList.push(item.id);
				}
			});

			variablesList.filter(isSwitchCustomVariable).forEach((item) => {
				if (item.specs?.values?.some((v) => v.value === variableId || v.thenValue === variableId) || item.specs?.switchValue === variableId || item.specs?.defaultValue === variableId) {
					if (item.id) {
						resultList.push(item.id);
					}
				}
			});

			variablesList.filter(isListCustomVariable).forEach((item) => {
				if (item.id && item.specs?.value === variableId) {
					resultList.push(item.id);
				}
			});
		}

		for (let index = 0; index < resultList.length; index++) {
			resultList = resultList.concat(getListOfIdReferences(resultList[index], variablesList));
		}

		return resultList;
	};

	const cleanVariableReferences = (variableId: string, variablesList: CustomVariableTypes[]): CustomVariableTypes[] => {
		const listToDelete: string[] = [];

		if (variablesList) {
			variablesList.filter(isLogicOrMathematicalCustomVariable).forEach(item => {
				if (item.id && item.specs?.values?.some((v) => v.value === variableId)) {
					listToDelete.push(item.id);
				}
			});

			variablesList.filter(isSwitchCustomVariable).forEach((item) => {
				if (item.specs?.values?.some((v) => v.value === variableId || v.thenValue === variableId) || item.specs?.switchValue === variableId || item.specs?.defaultValue === variableId) {
					if (item.id) {
						listToDelete.push(item.id);
					}
				}
			});

			variablesList.filter(isListCustomVariable).forEach((item) => {
				if (item.id && item.specs?.value === variableId) {
					listToDelete.push(item.id);
				}
			});
		}

		for (let index = 0; index < variablesList.length; index++) {
			if (listToDelete.some(x => x === variablesList[index].id)) {
				variablesList.splice(index, 1);
			}
		}

		for (let index = 0; index < listToDelete.length; index++) {
			variablesList = cleanVariableReferences(listToDelete[index], variablesList);
		}

		return variablesList;

	};

	const handleSaveVariable = (variable: CustomVariableTypes, isEdit: boolean): void => {
		let newCustomVariables = [...documentModel.customVariables || []];

		if (isEdit) {
			if (variable.id) {
				newCustomVariables = cleanVariableReferences(variable.id, newCustomVariables);
				const customVariableIndex = newCustomVariables.findIndex(x => x.id === variable.id);

				if (customVariableIndex > -1) {
					newCustomVariables[customVariableIndex] = variable;
				}
			}
		} else {
			newCustomVariables.push(variable);
		}

		setCustomVariableSelected(variable);
		setDocumentModel({ ...documentModel, customVariables: newCustomVariables });
	};

	const handleBackStep = async (): Promise<void> => {
		try {
			props.handleBack();
		} catch (e) {
			showDefaultError();
		}
	};

	const handleSave = async (): Promise<void> => {
		try {
			await handleSaveDocument();
		} catch (e) {
			showDefaultError();
		}
	};

	const getVariablesFilter = React.useCallback((): object => (
		{
			name: t('formsView:VARIABLE_FILTER_LABEL'),
			tooltip: t('formsView:VARIABLE_FILTER_TOOLTIP'),
			list: Object.assign(
				{},
				...documentModel.variables.filter(variable => variable.type === 'text' && variable.isVisible).map(variable => ({ [variable.id as string]: variable.code })),
				...documentModel.customVariables.filter(variable => variable.type === 'text' && variable.isVisible).map(variable => ({ [variable.id as string]: variable.code }))
			),
			exec: (editor: IJodit, _: Nullable<Node>, { control }: Controls): false | undefined => {
				if (control.args && (control.args as unknown as any)[1]) {
					const value = (control.args as unknown as any)[1];

					editor.execCommand('insertHTML', false, `{{{${value}}}}`);
				} else {
					return false;
				}
			},
		}), [t, documentModel.customVariables, documentModel.variables]);

	const handleChangeCustomVariable = (variableId: string): void => {
		if (variableId && documentModel.customVariables) {
			const customVariable = documentModel.customVariables.find(x => x.id === variableId);

			setCustomVariableSelected(customVariable);
		}
	};

	const handleCloseWarningMessage = (): void => {
		setWarningMessage(undefined);
	};

	const handleDeleteVariableAfterConfirmation = (): void => {
		if (customVariableSelected?.id) {
			let newCustomVariables = [...documentModel.customVariables || []];
			const customVariableIndex = newCustomVariables.findIndex(x => x.id === customVariableSelected.id);

			newCustomVariables = cleanVariableReferences(customVariableSelected.id, newCustomVariables);
			newCustomVariables.splice(customVariableIndex, 1);

			setDocumentModel({ ...documentModel, customVariables: newCustomVariables });
			setCustomVariableSelected(undefined);
			handleCloseWarningMessage();
		}
	};

	const handleDeleteVariable = (): void => {
		setWarningMessage(t('variableModal:MODAL_WARNING_REFEFERENCES'));
	};

	const handleOpenTestCaseModal = (): void => {
		setOpenTestCaseModal(prevState => !prevState);
	};

	const handleChangeTestCase = (event: React.ChangeEvent<{ value: unknown }>): void => {
		setTestCaseName(event.target.value as string);
	};

	const handleCloseReportPreviewModal = (): void => {
		setOpenReportPreviewModal(prevState => !prevState);
	};

	const handleOpenReportPreviewModal = (): void => {
		const testCase = documentModel.testCases.find(element => element.id == testCaseName);

		if (testCase) {
			const reportPreview = {
				answers: testCase.answers,
				customVariables: documentModel.customVariables,
				questions: documentModel.sections.flatMap(section => section.questions),
				report: documentModel.report,
				variables: documentModel.variables,
			};

			(async (): Promise<void> => {
				const pdf = await getPreview(reportPreview);

				setReportPreview(pdf);

				setOpenReportPreviewModal(prevState => !prevState);
			})();
		}
	};

	return {
		reportContent,
		reportPreview,
		onChangeReportContent,
		variables,
		openCreateVariableModal,
		isCreateVariableModalEdit,
		handleOpenCreateVariableModal,
		handleCloseCreateVariableModal,
		handleCloseReportPreviewModal,
		handleSaveVariable,
		handleBackStep,
		getVariablesFilter,
		customVariableSelected,
		handleChangeCustomVariable,
		getListOfIdReferences,
		handleDeleteVariable,
		handleDeleteVariableAfterConfirmation,
		warningMessage,
		isPreviewButtonEnabled,
		handleCloseWarningMessage,
		testCases: documentModel.testCases ? documentModel.testCases.map(testCase => ({ key: testCase.id, value: testCase.title })) : [],
		testCaseName,
		handleChangeTestCase,
		openTestCaseModal,
		handleOpenTestCaseModal,
		openReportPreviewModal,
		handleOpenReportPreviewModal,
		handleSave,
	};
};

export default useCreateReport;
