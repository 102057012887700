import * as DocumentRepository from '../api/DocumentRepository';
import { CustomVariable, OperatorType } from '../interfaces/Documents/CustomVariables/CustomVariable';
import { Document, DocumentAPI } from '../interfaces/Documents/Document';
import { QuestionType, QuestionTypes } from '../interfaces/Documents/Questions/Question';
import { Variable, VariableType } from '../interfaces/Documents/Variables/Variable';
import { Answer } from '../interfaces/Documents/Answer';
import { BooleanQuestion } from '../interfaces/Documents/Questions/BooleanQuestion';
import { CheckboxQuestion } from '../interfaces/Documents/Questions/CheckboxQuestion';
import { DefaultTextQuestion } from '../components/Questions/Models/QuestionModel';
import { FolderType } from '../interfaces/Folder';
import { ListCustomVariable } from '../interfaces/Documents/CustomVariables/ListCustomVariable';
import { LogicCustomVariable } from '../interfaces/Documents/CustomVariables/LogicCustomVariable';
import { MathematicalCustomVariable } from '../interfaces/Documents/CustomVariables/MathematicalCustomVariable';
import { Option } from '../interfaces/Documents/Questions/Options';
import { RadioQuestion } from '../interfaces/Documents/Questions/RadioQuestion';
import { ReportPreviewAPI } from '../interfaces/Documents/Report';
import { Section } from '../interfaces/Documents/Section';
import { SelectQuestion } from '../interfaces/Documents/Questions/SelectQuestion';
import slugify from 'slugify';
import { SwitchCustomVariable } from '../interfaces/Documents/CustomVariables/SwitchCustomVariable';
import { Tag } from '../interfaces/Tag';
import { TestCase } from '../interfaces/Documents/TestCase';
import { uniqueNameGenerator } from '../helpers/UniqueNameGenerator';
import { v4 as uuidv4 } from 'uuid';

const DefaultSection = {
	id: undefined,
	order: -1,
	title: '',
	description: '',
	questions: [],
};

const getDocumentMappedToDocumentExtended = (document: DocumentAPI): Document => {
	if (document.folder) {
		return ({
			...document,
			folder: {
				id: document.folder.id,
				type: FolderType.DOCUMENT,
				isDisabled: false,
				name: '',
				createdAt: '',
				updatedAt: '',
				createdBy: { name: '' },
				updatedBy: { name: '' },
			},
		});
	}

	return document;
};

const getDocumentExtendedMappedToDocument = (document: Document): DocumentAPI => {
	if (document.folder) {
		const { parentFolder, ...rest } = document.folder;

		const folderExtended = {
			...rest,
		};

		return ({
			...document,
			folder: {
				...folderExtended,
				parentFolderId: parentFolder ? parentFolder.id as number : null,
			},
		});
	}

	return document;
};

export const getDocuments = async (folderId: number | undefined, disabledRows: boolean | undefined): Promise<Array<Document>> => {
	const documents = await DocumentRepository.getDocumentsByFolder(folderId, disabledRows);

	return documents.map(document => getDocumentMappedToDocumentExtended(document));
};

export const getDocument = async (id: number): Promise<Document> => {
	const document = await DocumentRepository.getDocument(id);

	return getDocumentMappedToDocumentExtended(document);
};

export const getPreview = async (reportPreview: ReportPreviewAPI): Promise<Blob> => {
	return DocumentRepository.getPreview(reportPreview);
};

export const saveDocument = async (document: Document): Promise<number> => {
	return await DocumentRepository.saveDocument(getDocumentExtendedMappedToDocument(document));
};

export const getTags = async (): Promise<Array<Tag>> => {
	return await DocumentRepository.getTags();
};

export const editDocument = async (document: Document, id: number): Promise<void> => {
	return await DocumentRepository.editDocument(getDocumentExtendedMappedToDocument(document), id);
};

export const duplicateDocument = async (id: number): Promise<void> => {
	return await DocumentRepository.duplicateDocument(id);
};

export const mapQuestionTypeToVariableType = (questionType: QuestionType): VariableType | undefined => {
	switch (questionType) {
		case QuestionType.Radio:
		case QuestionType.Select:
		case QuestionType.Text:
			return VariableType.Text;
		case QuestionType.Checkbox:
			return VariableType.List;
		case QuestionType.Boolean:
			return VariableType.Boolean;
		case QuestionType.NumberDecimal:
		case QuestionType.NumberInteger:
			return VariableType.Number;
		case QuestionType.Date:
			return VariableType.Date;
		default:
			return undefined;
	}
};

export const resetDocument = (document: Document, sectionsToReset?: string[]): Document => {
	const newDocument = { ...document };

	newDocument.showErrors = false;
	newDocument.sections.forEach(section => {
		if (sectionsToReset && !sectionsToReset.includes(section.id)) {
			return;
		}

		section.questions.forEach(question => {
			type typeOfQuestion = typeof question;

			(question as typeOfQuestion).value = question.type === QuestionType.Date ? null : '';
			question.isValid = true;
		});
	});

	return newDocument;
};

export const isListCustomVariable = (object: CustomVariable): object is ListCustomVariable => {
	return object.operatorType === OperatorType.List;
};

export const isLogicCustomVariable = (object: CustomVariable): object is LogicCustomVariable => {
	return object.operatorType === OperatorType.Logic;
};

export const isMathematicalCustomVariable = (object: CustomVariable): object is MathematicalCustomVariable => {
	return object.operatorType === OperatorType.Mathematical;
};

export const isSwitchCustomVariable = (object: CustomVariable): object is SwitchCustomVariable => {
	return object.operatorType === OperatorType.Switch;
};

export const updateDocumentSection = (document: Document, section: Section): Document => {
	const documentToUpdate = { ...document };
	const sectionIndex = document.sections.findIndex(s => s.id === section.id);

	if (sectionIndex > -1) {
		documentToUpdate.sections[sectionIndex] = section;
	}

	return documentToUpdate;
};

const updateVariables = (variables: Variable[], question: QuestionTypes): Variable[] => {
	return (variables.map(variable => {
		if (variable.id === question.id) {
			variable.code = question.code;
			variable.type = mapQuestionTypeToVariableType(question.type);
		}

		return variable;
	}));
};

const questionsCodes = (document: Document): string[] => {
	const documentQuestionsCodes = document.sections.reduce((acc: Array<string>, section) => {
		if (section.questions.length) {
			section.questions.forEach(question => {
				acc.push(question.code);
			});
		}

		return acc;
	}, []);

	return documentQuestionsCodes;
};

const generateQuestionCode = (title: string, otherQuestionsCodes: string[]): string => {
	const slugParameters = { replacement: '_', lower: true, strict: true };
	const code = slugify(title, slugParameters);

	return uniqueNameGenerator(code, otherQuestionsCodes);
};

const getReportUpdatedWithNewCode = (report: string, previousCode: string, newCode: string): string => {
	return report.replaceAll(`{{{${previousCode}}}}`, `{{{${newCode}}}}`);
};

const removeQuestionFromTestCases = (testCases: TestCase[], questionId?: string): TestCase[] => {
	return testCases.map(testCase => ({
		...testCase,
		answers: testCase.answers.filter(answer => answer.id !== questionId),
	}));
};

export const updateDocumentQuestion = (document: Document, section: Section, question: QuestionTypes): Document => {
	const documentToUpdate = { ...document };
	const sectionIndex = document.sections.findIndex(s => s.id === section.id);

	if (sectionIndex > -1) {
		const questionIndex = section.questions.findIndex(q => q.id === question.id);

		if (questionIndex > -1) {
			const newCode = generateQuestionCode(question.title, questionsCodes(document));
			const oldCode = question.code;

			question.code = newCode;

			const variables = updateVariables(documentToUpdate.variables, question);
			const report = getReportUpdatedWithNewCode(documentToUpdate.report, oldCode, newCode);
			const testCases = removeQuestionFromTestCases(documentToUpdate.testCases, question.id);

			documentToUpdate.sections[sectionIndex].questions[questionIndex] = question;
			documentToUpdate.variables = variables;
			documentToUpdate.report = report;
			documentToUpdate.testCases = testCases;
		}
	}

	return documentToUpdate;
};

const resetAnswersToDefault = (document: Document): Document => {
	const documentToUpdate = { ...document };

	documentToUpdate.sections.forEach(section => {
		section.questions.forEach(question => {
			switch (question.type) {
				case QuestionType.Checkbox:
					question.value = {};
					break;
				case QuestionType.Select:
					question.value = '';
					break;
				case QuestionType.Boolean:
					question.value = 'false';
					break;
				case QuestionType.Radio:
					question.value = '';
					break;
				case QuestionType.Date:
					question.value = null;
					break;
				case QuestionType.NumberDecimal:
					question.value = '';
					break;
				case QuestionType.NumberInteger:
					question.value = '';
					break;
				case QuestionType.Text:
				default:
					question.value = '';
					break;
			}
		});
	});

	return documentToUpdate;
};

export const addTestCaseToDocument = (document: Document, testCase: TestCase): Document => {
	let documentToUpdate = { ...document };

	testCase.answers = documentToUpdate.sections.flatMap(
		section => section.questions.map(
			question => {
				let value;

				if (question.type === QuestionType.Checkbox) {
					const valueKeys: string[] = Object.keys((question as CheckboxQuestion).value);

					value = (question as CheckboxQuestion).options.filter(x => valueKeys.includes(x.order.toString())).map(o => o.title);
				} else if (question.type === QuestionType.Radio || question.type === QuestionType.Select) {
					value = question.options.find(x => x.id === question.value)?.title;

				} else {
					value = question.value;
				}

				return ({ id: question.id, value: value });
			}
		)
	) as Answer[];
	documentToUpdate.testCases.push(testCase);

	documentToUpdate = resetAnswersToDefault(documentToUpdate);

	return documentToUpdate;
};

export const addSectionToDocument = (document: Document, actionData: { [KEY: string]: string | number }): Document => {
	const documentToUpdate = { ...document };
	const newSectionArray: Section[] = [];
	let itemOrder = 1;

	if (actionData.sectionOrder === -1) {
		newSectionArray.push({ ...DefaultSection, order: itemOrder, id: uuidv4() });
		itemOrder++;
	}

	documentToUpdate.sections.forEach((item) => {
		if (item.order === actionData.sectionOrder) {
			newSectionArray.push({ ...item, order: itemOrder });
			itemOrder++;
			newSectionArray.push({ ...DefaultSection, order: itemOrder, id: uuidv4() });
		} else {
			newSectionArray.push({ ...item, order: itemOrder });
		}

		itemOrder++;
	});
	documentToUpdate.sections = newSectionArray;

	return documentToUpdate;
};

export const addQuestionToDocument = (document: Document, actionData: { [KEY: string]: string | number }): Document => {
	const documentToUpdate = { ...document };
	const sectionIndex = documentToUpdate.sections.findIndex(s => s.order === actionData.sectionOrder);
	let newQuestionArray: QuestionTypes[] = [];

	if (sectionIndex >= 0) {
		const newQuestionId = uuidv4();
		const newQuestion = { ...DefaultTextQuestion, order: documentToUpdate.sections[sectionIndex].questions.length + 1, id: newQuestionId };
		const questionsCopy = [ ...documentToUpdate.sections[sectionIndex].questions ];
		const spliceIndex = actionData.questionOrder === -1 ? documentToUpdate.sections[sectionIndex].questions.length : +actionData.questionOrder;

		questionsCopy.splice(spliceIndex, 0, newQuestion);

		newQuestionArray = questionsCopy.map((question, index) => ({ ...question, order: index + 1 }));

		documentToUpdate.sections[sectionIndex].questions = newQuestionArray;
		documentToUpdate.variables.push({
			id: newQuestionId,
			code: generateQuestionCode(DefaultTextQuestion.title, questionsCodes(document)),
			isVisible: true,
			type: mapQuestionTypeToVariableType(DefaultTextQuestion.type),
		});
	}

	return documentToUpdate;
};

export const addQuestionDescription = (document: Document, actionData: { [KEY: string]: string | number }): Document => {
	const documentToUpdate = { ...document };
	const { sectionOrder, questionOrder } = actionData;

	if (questionOrder > 0) {
		const sectionIndex = documentToUpdate.sections.findIndex(s => s.order === sectionOrder);
		const questionIndex = documentToUpdate.sections[sectionIndex].questions.findIndex(q => q.order === questionOrder);

		documentToUpdate.sections[sectionIndex].questions[questionIndex].description = '';
	}

	return documentToUpdate;
};

const removeVariables = (variables: Variable[], variableIdsToRemove: (string | undefined)[]): Variable[] => {
	return (variables.filter(variable => variable.id && !variableIdsToRemove.includes(variable.id)));
};

const getReportUpdatedWithoutCodes = (report: string, codesToRemove: string[]): string => {
	let reportUpdated = report;

	codesToRemove.forEach(code => {
		reportUpdated = report.replaceAll(`{{{${code}}}}`, 'QUESTION_ANSWER_REMOVED');
	});

	return reportUpdated;
};

export const removeQuestion = (document: Document, actionData: { [KEY: string]: string | number }): Document => {
	const documentToUpdate = { ...document };
	const { sectionOrder, questionOrder } = actionData;
	const sectionIndex = documentToUpdate.sections.findIndex(s => s.order === sectionOrder);
	const questionIndex = documentToUpdate.sections[sectionIndex].questions.findIndex(q => q.order === questionOrder);
	const newQuestionArray: QuestionTypes[] = [];
	let itemOrder = 1;
	const question: QuestionTypes = documentToUpdate.sections[sectionIndex].questions[questionIndex];

	documentToUpdate.sections[sectionIndex].questions.forEach((item) => {
		if (item.order !== questionOrder) {
			newQuestionArray.push({ ...item, order: itemOrder });
			itemOrder++;
		}
	});

	if (question) {
		const variables = removeVariables(documentToUpdate.variables, [question.id]);
		const report = getReportUpdatedWithoutCodes(documentToUpdate.report, [question.code]);
		const testCases = removeQuestionFromTestCases(documentToUpdate.testCases, question.id);

		documentToUpdate.variables = variables;
		documentToUpdate.report = report;
		documentToUpdate.testCases = testCases;
	}

	documentToUpdate.sections[sectionIndex].questions = newQuestionArray;

	return documentToUpdate;
};

const removeReferencesFromSection = (section: Section, idToRemove: string): void => {
	section.questions.forEach(q => {
		if ((q.type === QuestionType.Select || q.type === QuestionType.Checkbox || q.type === QuestionType.Boolean || q.type === QuestionType.Radio) && q.showGoTo) {
			(q as SelectQuestion | CheckboxQuestion | BooleanQuestion | RadioQuestion).options.forEach(o => {
				if (o.goTo === idToRemove) {
					o.goTo = '';
				}
			});
		}
	});
};

export const removeSection = (document: Document, actionData: { [KEY: string]: string | number }): Document => {
	const documentToUpdate = { ...document };
	const { sectionOrder } = actionData;
	const newSectionArray: Section[] = [];
	const sectionIndex = documentToUpdate.sections.findIndex(s => s.order === sectionOrder);
	let itemOrder = 1;

	if (sectionIndex > -1) {
		const sectionId = documentToUpdate.sections[sectionIndex].id;

		const variables = removeVariables(documentToUpdate.variables, documentToUpdate.sections[sectionIndex].questions.map(question => question.id));
		const report = getReportUpdatedWithoutCodes(documentToUpdate.report, documentToUpdate.sections[sectionIndex].questions.map(question => question.code));

		documentToUpdate.variables = variables;
		documentToUpdate.report = report;

		if (sectionId) {
			documentToUpdate.sections.forEach((item) => {
				removeReferencesFromSection(item, sectionId);

				if (item.order !== sectionOrder) {
					newSectionArray.push({ ...item, order: itemOrder });
					itemOrder++;
				}
			});
		}
	}

	documentToUpdate.sections = newSectionArray;

	return documentToUpdate;
};

export const copyQuestion = (document: Document, actionData: { [KEY: string]: string | number }): Document => {
	const documentToUpdate = { ...document };
	const { sectionOrder, questionOrder } = actionData;
	const newQuestionArray: QuestionTypes[] = [];
	const sectionIndex = documentToUpdate.sections.findIndex(s => s.order === sectionOrder);
	let itemOrder = 1;

	documentToUpdate.sections[sectionIndex].questions.forEach((item) => {
		newQuestionArray.push({ ...item, order: itemOrder });

		if (item.order === questionOrder) {
			const id = uuidv4();
			const questionTitle = `${item.title}-copy-${id}`;
			const code = generateQuestionCode(questionTitle, []);

			itemOrder++;
			newQuestionArray.push({
				...item,
				order: itemOrder,
				title: questionTitle,
				id,
				code,
			});

			documentToUpdate.variables.push({
				id,
				code: code,
				isVisible: true,
				type: mapQuestionTypeToVariableType(item.type),
			});
		}

		itemOrder++;

	});
	documentToUpdate.sections[sectionIndex].questions = newQuestionArray;

	return documentToUpdate;
};

export const copySection = (document: Document, actionData: { [KEY: string]: string | number }): Document => {
	const documentToUpdate = { ...document };
	const { sectionOrder } = actionData;
	const newSectionArray: Section[] = [];
	let itemOrder = 1;

	documentToUpdate.sections.forEach((item) => {
		newSectionArray.push({ ...item, order: itemOrder });

		if (item.order === sectionOrder) {
			const id = uuidv4();

			itemOrder++;

			const newQuestions = item.questions.map(question => {
				const questionId = uuidv4();
				const questionTitle = `${question.title}-copy-${questionId}`;
				const code = generateQuestionCode(questionTitle, []);

				documentToUpdate.variables.push({
					id: questionId,
					code: code,
					isVisible: true,
					type: mapQuestionTypeToVariableType(question.type),
				});

				return ({
					...question,
					id: questionId,
					title: questionTitle,
					code,
				});
			});

			newSectionArray.push({ ...item, questions: newQuestions, order: itemOrder, title: `${item.title}-copy-${id}`, id });
		}

		itemOrder++;
	});
	documentToUpdate.sections = newSectionArray;

	return documentToUpdate;
};

export const reorderSections = (document: Document, sections: Section[]): Document => {
	const documentToUpdate = { ...document };

	documentToUpdate.sections = sections;

	return documentToUpdate;
};

export const reorderQuestions = (document: Document, sectionOrder: number, questions: QuestionTypes[]): Document => {
	const documentToUpdate = { ...document };
	const sectionIndex = documentToUpdate.sections.findIndex(s => s.order === sectionOrder);

	if (sectionIndex > -1) {
		documentToUpdate.sections[sectionIndex].questions = questions;
	}

	return documentToUpdate;
};

export const reorderQuestionOptions = (document: Document, sectionOrder: number, questionOrder: number, options: Option[]): Document => {
	const documentToUpdate = { ...document };
	const sectionIndex = documentToUpdate.sections.findIndex(s => s.order === sectionOrder);

	if (sectionIndex > -1) {
		const questionIndex = documentToUpdate.sections[sectionIndex].questions.findIndex(q => q.order === questionOrder);

		if (questionIndex > -1) {
			(documentToUpdate.sections[sectionIndex].questions[questionIndex] as (SelectQuestion | CheckboxQuestion | BooleanQuestion | RadioQuestion)).options = options;
		}
	}

	return documentToUpdate;
};
