import './MoveFolderFile.scss';
import { ArrowBackIos, ArrowForwardIos, Folder as FolderIcon, InsertDriveFile } from '@material-ui/icons';
import { Folder, FolderType } from '../../interfaces/Folder';
import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Document } from '../../interfaces/Documents/Document';
import Modal from '../Modal';
import React from 'react';
import { Resource } from '../../interfaces/Resource';
import useMoveFolderFile from './useMoveFolderFile';
import { useTranslation } from 'react-i18next';

export interface MoveFolderFileProps {
	openModal: boolean;
	handleCloseModal: () => void;
	folderItemType: FolderType;
	parentFolder: Folder | undefined;
	folderFileToMove: undefined | Folder | Resource | Document;
	handleFinishMove: () => void;
	isFolder: boolean;
}

const MoveFolderFile: React.FC<MoveFolderFileProps> = (props) => {
	const { t } = useTranslation();
	const {
		data,
		parentFolder,
		selectedItem,
		handleSelectItem,
		handleNavigation,
		handleMoveFolderFile,
	} = useMoveFolderFile(props);

	return (
		<div>
			<Modal
				title={`${t('folder:MODAL_MOVE_TITLE')} ${props.folderFileToMove?.name}`}
				open={props.openModal}
				handleOnClose={props.handleCloseModal}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: selectedItem === undefined ? t('folder:MODAL_MOVE_HERE') : t('folder:MODAL_MOVE_TO'),
					handleOnclick: handleMoveFolderFile,
				}}
				isSecondaryButtonVisible={false}
			>
				<div className='move-folder-file-content'>
					<div className='title'>
						{
							parentFolder &&
							(
								<IconButton
									onClick={(): void => handleNavigation(parentFolder.parentFolder)}
								>
									<ArrowBackIos />
								</IconButton>
							)
						}
						{parentFolder?.name || t('folder:MODAL_MOVE_MY_UNIT')}
					</div>
					<List className='folder-file-list'>
						{
							data.map((item, key) => {
								return (
									item?.isFolder
										? (
											<ListItem
												key={key}
												button
												selected={item.id === selectedItem}
												onClick={(): void => handleSelectItem(item as Folder)}
												disabled={props.isFolder && item.id === (props.folderFileToMove as Folder).id}
											>
												<ListItemIcon>
													<FolderIcon />
												</ListItemIcon>
												<ListItemText
													className='list-item-text'
													primary={item.name}
												/>
												<ListItemSecondaryAction>
													<IconButton
														edge='end'
														onClick={(): void => handleNavigation(item as Folder)}
														disabled={props.isFolder && item.id === (props.folderFileToMove as Folder).id}
													>
														<ArrowForwardIos />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										)
										: (
											<ListItem
												key={key}
												disabled={true}
											>
												<ListItemIcon>
													<InsertDriveFile />
												</ListItemIcon>
												<ListItemText
													className='list-item-text'
													primary={item.name}
												/>
											</ListItem>
										)
								);
							})
						}
					</List>
				</div>
			</Modal>

		</div >
	);
};

export default React.memo(MoveFolderFile);
