import { PanelClientInterface, PaymentStatus } from '../interfaces/Client';

const data: Array<PanelClientInterface> = [
	{
		id: 1,
		username: 'client@example.com',
		planId: 1,
		paymentStatus: PaymentStatus.Payed,
		startDate: new Date(),
		paymentDate: new Date(),
		isDisabled: false,
	},
	{
		id: 2,
		username: 'client2@example.com',
		planId: 3,
		paymentStatus: PaymentStatus.Delayed,
		startDate: new Date(),
		paymentDate: new Date('01/01/2020'),
		isDisabled: true,
	},
];

export const getPanelClientList = (): Promise<Array<PanelClientInterface>> => {
	return Promise.resolve(data);
};

export const updatePanelClient = (client: PanelClientInterface): Promise<number> => {
	return Promise.resolve(client.id as number);
};
