import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Option } from '../../../../interfaces/Documents/Questions/Options';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import useCheckboxPreview from './useCheckboxPreview';

const CheckboxPreview: React.FC<QuestionPreviewProps> = (props) => {
	const {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	} = useCheckboxPreview(props);
	const { value, options, isValid } = question;

	return (
		<>
			<FormGroup>
				{options.map((option: Option, index: number) => (
					<FormControlLabel
						key={index}
						control={
							<Checkbox
								checked={value[option.order.toString()] ?? false}
								onChange={handleOnChange}
								name={option.order.toString()}
								color="primary"
							/>
						}
						label={option.title}
					/>
				))}
			</FormGroup>
			{showErrors && <FormHelperText error={showErrors && !isValid}>{errorMessage}</FormHelperText>}
		</>
	);
};

export default CheckboxPreview;
