import { AuthContext } from '../../contexts/AuthContext';
import React from 'react';
import { UserRole } from '../../interfaces/User';

interface WithPermissionsProps {
	rolesRequired: UserRole[];
}

const withPermissions = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithPermissionsProps> => ({ rolesRequired, ...props }: WithPermissionsProps): JSX.Element | null => {
	const { user: { role } } = React.useContext(AuthContext);

	return rolesRequired.includes(role) ? <Component {...props as P} /> : null;
};

export default withPermissions;
