import { Folder, FolderAPI } from '../Folder';
import { Audit } from '../Common';
import { CustomVariableTypes } from './CustomVariables/CustomVariable';
import { Section } from './Section';
import { Tag } from '../Tag';
import { TestCase } from './TestCase';
import { Variable } from './Variables/Variable';

export interface DocumentAPI extends Audit {
	id?: number;
	description?: string;
	name: string;
	isDisabled: boolean;
	sections: Section[];
	variables: Variable[];
	customVariables: CustomVariableTypes[];
	folder: FolderAPI | undefined;
	tags?: Array<Tag>;
	parentId: string | '';
	showErrors: boolean;
	report: string;
	testCases: TestCase[];
}

export interface Document extends Omit<DocumentAPI, 'folder'> {
	folder: Folder | undefined;
}

export interface DocumentTable extends Document {
	isFolder?: boolean;
}

export enum ReorderType {
	Section = 'section',
	Question = 'question',
	Option = 'option',
}
