import axios from 'axios';
import { Tag } from '../interfaces/Tag';

const apiUrl = process.env.REACT_APP_API_URL;

export const getAllTags = (): Promise<Array<Tag>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/posts/tags`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const saveNewTag = (tag: Tag): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/posts/tags`, {
			name: tag.name,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
