import './SideBar.scss';
import { AssessmentOutlined, HomeOutlined, ListAltOutlined, PeopleAltOutlined, SettingsOutlined } from '@material-ui/icons';
import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import useRoutes from '../Hooks/useRoutes';
import { UserRole } from '../../interfaces/User';
import withPermissions from '../../helpers/HOC/withPermissions';

interface SideBarProps {
	value: string | boolean;
	onChange: (optionSelected: string) => void;
}

const SideBar: React.FC<SideBarProps> = (props) => {
	const { TABS_ROUTES: ROUTES } = useRoutes();
	const TabWithPermissions = withPermissions(Tab);

	return (
		<Tabs
			orientation="vertical"
			value={props.value}
			onChange={(event, newValue): void => props.onChange(newValue)}
			indicatorColor="primary"
			textColor="primary"
			classes={{ indicator: 'sideBarTabIndicator' }}
		>
			<TabWithPermissions className="sideBarTab" value={ROUTES.DASHBOARD} icon={<HomeOutlined />} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN, UserRole.MARKETING]} />
			<TabWithPermissions className="sideBarTab" value={ROUTES.DOCUMENTS} icon={<AssessmentOutlined />} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN]} />
			<TabWithPermissions className="sideBarTab" value={ROUTES.RESOURCES} icon={<ListAltOutlined />} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.TECHNICIAN]} />
			<TabWithPermissions className="sideBarTab" value={ROUTES.CONTENT} icon={<SettingsOutlined />} rolesRequired={[UserRole.SUPER_ADMIN, UserRole.MARKETING]} />
			<TabWithPermissions className="sideBarTab" value={ROUTES.USERS} icon={<PeopleAltOutlined />} rolesRequired={[UserRole.SUPER_ADMIN]} />
			{/* <TabWithPermissions className="sideBarTab" value={ROUTES.CLIENTS} icon={<AccountBoxOutlined />} rolesRequired={[UserRole.SUPER_ADMIN]} /> */}
		</Tabs>
	);
};

export default SideBar;
