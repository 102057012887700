import { Option as KeyOption } from '../../Common';

export enum VariableType {
	Boolean = 'boolean',
	Text = 'text',
	Number = 'number',
	Date = 'date',
	List = 'list',
}

export interface OptionVariable extends KeyOption {
	type: VariableType;
	isCustomVariable?: boolean;
}

export interface Variable {
	id?: string;
	refId?: string;
	code: string;
	isVisible: boolean;
	type?: VariableType;
}
