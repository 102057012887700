import { CurrencyType, Plan } from '../../../../interfaces/Plan';
import { editPlan, getPlans, isDecimalValid } from '../../../../services/PlanService';
import React from 'react';

interface PlansHookInterface {
	data: Array<Plan>;
	editableRowId: number;
	planValue: string | undefined;
	validatePlanValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
	editRow: (row: Plan) => void;
}

const usePlans = (): PlansHookInterface => {
	const [data, setData] = React.useState<Array<Plan>>([]);
	const [editableRowId, setEditableRowId] = React.useState<number>(0);
	const [planValue, setPlanValue] = React.useState<string | undefined>('');
	const [planType, setPlanType] = React.useState<string>('');

	const loadPlanList = async (): Promise<void> => {
		setData(await getPlans());
	};

	React.useEffect(() => {
		loadPlanList();
	}, []);

	const validatePlanValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (isDecimalValid(event.target.value)) {
			setPlanValue(event.target.value);
		}
	};

	const editRow = async (row: Plan): Promise<void> => {
		if (row.id) {
			if (row.id === editableRowId) {
				row.value = planValue ? +planValue : row.value;
				row.type = planType ? planType as CurrencyType : row.type;
				await editPlan(row);
				setEditableRowId(0);
				loadPlanList();
			} else {
				setEditableRowId(row.id);
				setPlanValue(row.value?.toString());
			}

			setPlanType('ARS');
		}
	};

	return {
		data,
		editableRowId,
		planValue,
		validatePlanValue,
		editRow,
	};
};

export default usePlans;
