import axios from 'axios';
import { Post } from '../interfaces/Post';

const apiUrl = process.env.REACT_APP_API_URL;

export const getPosts = (): Promise<Array<Post>> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/posts`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const savePost = (post: Post, img?: File): Promise<void> => {
	if (apiUrl) {
		const formData = new FormData();

		if (img) {
			formData.append('image', img);
		}

		formData.set('title', JSON.stringify(post.title));
		formData.set('content', JSON.stringify(post.content));
		formData.set('tags', JSON.stringify(post.tags));

		return axios.post(`${apiUrl}/posts`, formData, { headers: { 'Content-type': 'multipart/form-data' } });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getPost = (id: number): Promise<Post> => {
	if (apiUrl) {
		return axios.get(`${apiUrl}/posts/${id}`);
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const editPost = (id: number, post: Post, img?: File): Promise<void> => {
	if (apiUrl) {
		const formData = new FormData();

		if (img) {
			formData.set('image', img);
		}

		formData.set('title', JSON.stringify(post.title));
		formData.set('content', JSON.stringify(post.content));
		formData.set('tags', JSON.stringify(post.tags));

		if (post.isDisabled != null) {
			formData.set('isDisabled', JSON.stringify(post.isDisabled));
		}

		return axios.patch(`${apiUrl}/posts/${id}`, formData, { headers: { 'Content-type': 'multipart/form-data' } });
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
