import { Audit } from './Common';
import { Folder } from '@material-ui/icons';

export enum FolderType {
	RESOURCE = 0,
	DOCUMENT = 1,
}

export interface FolderAPI extends Audit {
	id?: number;
	name: string;
	description?: string;
	parentFolderId?: number | null;
	type: FolderType;
	isDisabled: boolean;
}

export interface Folder extends Omit<FolderAPI, 'parentFolderId'> {
	parentFolder?: Folder;
}

export interface FolderTable extends Folder {
	isFolder?: boolean;
}
