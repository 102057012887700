import { editPost, getPost, getPosts, savePost } from '../api/PostRepository';
import { Post } from '../interfaces/Post';

export const getPostList = async (): Promise<Array<Post>> => {
	return await getPosts();
};

export const addPost = async (post: Post, image?: File): Promise<void> => {
	return await savePost(post, image);
};

export const getPostById = async (id: number): Promise<Post> => {
	return await getPost(id);
};

export const editPostById = async (id: number, post: Post, image?: File): Promise<void> => {
	return await editPost(id, post, image);
};
