export interface Credentials {
	username: string;
	password?: string;
}

export enum UserRole {
	SUPER_ADMIN = 0,
	TECHNICIAN = 1,
	MARKETING = 2,
	PATIENT = 3,
	THERAPIST = 4,
	ASSISTANT = 5,
}

export interface UserAPI {
	id?: number;
	isDisabled: boolean;
	name: string;
	roles: UserRole[];
	updatedAt?: string;
	username: string;
}

export interface PanelUser {
	id?: number;
	isDisabled: boolean;
	name: string;
	role: UserRole;
	avatarUrl?: string;
	updatedAt?: string;
}

export interface PanelUserExtended extends PanelUser {
	username: string;
}

export interface UserInvitation {
	id?: number;
	mail: string;
	role: UserRole;
}
