import { QuestionType, QuestionTypes } from '../../../../interfaces/Documents/Questions/Question';
import { BooleanQuestion } from '../../../../interfaces/Documents/Questions/BooleanQuestion';
import { DocumentUpdateContext } from '../../../../contexts/DocumentContext';
import { Option } from '../../../../interfaces/Documents/Questions/Options';
import { RadioQuestion } from '../../../../interfaces/Documents/Questions/RadioQuestion';
import React from 'react';
import { ReorderType } from '../../../../interfaces/Documents/Document';
import { Section } from '../../../../interfaces/Documents/Section';
import { SectionFormProps } from './SectionForm';
import { SelectQuestion } from '../../../../interfaces/Documents/Questions/SelectQuestion';

interface SectionFormInterface {
	openReorderModal: boolean;
	handleOpenReorderModal: () => void;
	handleCloseReorderModal: () => void;
	updateSectionData: (name: string, value: string) => void;
	updateQuestionData: (question: QuestionTypes) => void;
	anchorEl: null | HTMLElement;
	handleOpenQuestionMenu: (event: React.MouseEvent<HTMLElement>, selectedQuestion: QuestionTypes) => void;
	handleCloseQuestionMenu: () => void;
	handleReorderQuestions: (itemList: (Section | QuestionTypes | Option)[]) => void;
	openReorderOptionModal: boolean;
	handleOpenReorderOptionModal: () => void;
	handleCloseReorderOptionModal: () => void;
	questionHasOptions: boolean;
	questionHasGoToOption: boolean;
	handleReorderOptions: (itemList: (Section | QuestionTypes | Option)[]) => void;
	questionSelected: (QuestionTypes | undefined);
	handleEnableGoToSection: () => void;
}

const useSectionForm = (props: SectionFormProps): SectionFormInterface => {
	const { handleUpdateSection, handleUpdateQuestion, handleReorder } = React.useContext(DocumentUpdateContext);
	const [openReorderModal, setOpenReorderModal] = React.useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openReorderOptionModal, setOpenReorderOptionModal] = React.useState<boolean>(false);
	const [questionSelected, setQuestionSelected] = React.useState<QuestionTypes | undefined>();
	const [questionHasOptions, setQuestionHasOptions] = React.useState<boolean>(false);
	const [questionHasGoToOption, setQuestionHasGoToOption] = React.useState<boolean>(false);

	const handleOpenQuestionMenu = (event: React.MouseEvent<HTMLElement>, selectedQuestion: QuestionTypes): void => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
		setQuestionSelected(selectedQuestion);
		setQuestionHasOptions(selectedQuestion.type === QuestionType.Boolean || selectedQuestion.type === QuestionType.Checkbox || selectedQuestion.type === QuestionType.Radio || selectedQuestion.type === QuestionType.Select);
		setQuestionHasGoToOption(selectedQuestion.type === QuestionType.Boolean || selectedQuestion.type === QuestionType.Radio || selectedQuestion.type === QuestionType.Select);
	};

	const handleCloseQuestionMenu = (): void => {
		setAnchorEl(null);
	};

	const handleOpenReorderModal = (): void => {
		setOpenReorderModal(true);
		handleCloseQuestionMenu();
	};

	const handleCloseReorderModal = (): void => {
		setOpenReorderModal(false);
	};

	const handleReorderQuestions = (itemList: (Section | QuestionTypes | Option)[]): void => {
		handleReorder(itemList, ReorderType.Question, props.section.order, -1);
		handleCloseReorderModal();
	};

	const handleOpenReorderOptionModal = (): void => {
		setOpenReorderOptionModal(true);
		handleCloseQuestionMenu();
	};

	const handleCloseReorderOptionModal = (): void => {
		setOpenReorderOptionModal(false);
	};

	const handleReorderOptions = (itemList: (Section | QuestionTypes | Option)[]): void => {
		if (questionSelected?.order) {
			handleReorder(itemList, ReorderType.Option, props.section.order, questionSelected?.order);
			handleCloseReorderOptionModal();
		}
	};

	const updateSectionData = (name: string, value: string): void => {
		const section = { ...props.section, [name]: value };

		handleUpdateSection(section);
	};

	const updateQuestionData = (question: QuestionTypes): void => {
		handleUpdateQuestion(question, props.section);
	};

	const handleEnableGoToSection = (): void => {
		if (questionSelected) {
			const question = questionSelected as SelectQuestion | BooleanQuestion | RadioQuestion;

			if (questionSelected.showGoTo) {
				question.options.forEach(item => item.goTo = '');
			}

			updateQuestionData({ ...question, showGoTo: !questionSelected.showGoTo });
			handleCloseQuestionMenu();
		}
	};

	return {
		openReorderModal,
		handleOpenReorderModal,
		handleCloseReorderModal,
		updateSectionData,
		updateQuestionData,
		anchorEl,
		handleOpenQuestionMenu,
		handleCloseQuestionMenu,
		handleReorderQuestions,
		openReorderOptionModal,
		handleOpenReorderOptionModal,
		handleCloseReorderOptionModal,
		questionHasOptions,
		questionHasGoToOption,
		handleReorderOptions,
		questionSelected,
		handleEnableGoToSection,
	};
};

export default useSectionForm;
