import './QuestionWrapper.scss';
import { FormControlLabel, IconButton, Switch } from '@material-ui/core';
import { QuestionType, QuestionTypes } from '../../interfaces/Documents/Questions/Question';
import DefaultSelect from '../Inputs/Select';
import { Option as KeyOption } from '../../interfaces/Common';
import { MoreVert } from '@material-ui/icons';
import React from 'react';
import useQuestionWrapper from './useQuestionWrapper';
import { useTranslation } from 'react-i18next';

export interface QuestionWrapperProps {
	question: (QuestionTypes);
	handleUpdate: (question: QuestionTypes) => void;
	handleOpenQuestionMenu: (event: React.MouseEvent<HTMLElement>, selectedQuestion: QuestionTypes) => void;
	sectionList: KeyOption[];
}

const QuestionWrapper: React.FC<QuestionWrapperProps> = (props) => {
	const { t } = useTranslation();

	const {
		handleChangeQuestionType,
		handleUpdateQuestionRequired,
		renderQuestion,
	} = useQuestionWrapper(props);

	return (
		<div className='question-section'>
			<div className='top-section'>
				<div className='question-header'>
					{`${t('formsView:TITLE_QUESTION_NUMBER')} ${props.question.order}`}
				</div>
				<div className='options'>
					<DefaultSelect
						onChange={(e): void => handleChangeQuestionType(e.target.value as unknown as QuestionType)}
						value={props.question.type}
						options={[
							{ key: QuestionType.Select, value: t('formsView:QUESTION_TYPE_SELECT') },
							{ key: QuestionType.Checkbox, value: t('formsView:QUESTION_TYPE_CHECKBOX') },
							{ key: QuestionType.Boolean, value: t('formsView:QUESTION_TYPE_BOOLEAN') },
							{ key: QuestionType.Radio, value: t('formsView:QUESTION_TYPE_RADIO') },
							{ key: QuestionType.NumberDecimal, value: t('formsView:QUESTION_TYPE_NUMBER_DECIMAL') },
							{ key: QuestionType.NumberInteger, value: t('formsView:QUESTION_TYPE_NUMBER_INTEGER') },
							{ key: QuestionType.Text, value: t('formsView:QUESTION_TYPE_TEXT') },
							{ key: QuestionType.Date, value: t('formsView:QUESTION_TYPE_DATE') },
						]}
						isFullWidth
						removeMarginBotton
					/>
				</div>
			</div>
			<div className='content'>
				{
					renderQuestion(props.question)
				}
			</div>
			<div className='bottom-section'>
				<FormControlLabel
					control={
						<Switch
							checked={props.question.required}
							onChange={({ target: { checked } }): void => handleUpdateQuestionRequired('required', checked)}
							classes={{
								switchBase: 'switchBase',
								track: 'track',
							}}
						/>
					}
					label='Obligatorio'
				/>
				<IconButton
					className='more-vert-icon'
					onClick={(e): void => props.handleOpenQuestionMenu(e, props.question)}
				>
					<MoreVert className='icon' />
				</IconButton>
			</div>
		</div>
	);
};

export default QuestionWrapper;
