import { addPost, editPostById, getPostById, getPostList } from '../../../../services/PostService';
import { useHistory, useParams } from 'react-router-dom';
import { AlertContext } from '../../../../contexts/AlertContext';
import { getTags } from '../../../../services/TagBlogService';
import { Post } from '../../../../interfaces/Post';
import React from 'react';
import { Tag } from '../../../../interfaces/Tag';
import useRoutes from '../../../../components/Hooks/useRoutes';
import { useTranslation } from 'react-i18next';

interface BlogHook {
	data: Array<Post>;
	openDialog: boolean;
	handleOpenDialog: () => void;
	handleCloseDialog: () => void;
	blogTitle: string;
	blogTitleError: boolean;
	validateTitle: (event: React.ChangeEvent<HTMLInputElement>) => void;
	setBlogImage: (file: File[]) => void;
	blogImageSrc: string | undefined;
	blogDescription: string;
	blogDescriptionError: boolean;
	validateBlogDescription: (value: string) => void;
	handleSave: () => Promise<void>;
	handleEdit: () => Promise<void>;
	showDropZone: boolean;
	setShowDropZone: (flag: boolean) => void;
	tags: Array<Tag>;
	blogTags: Array<Tag>;
	handleTagValues: (value: Array<Tag>) => void;
	blogImage: File[] | undefined;
}

const useBlog = (): BlogHook => {
	const history = useHistory();
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const { ROUTES } = useRoutes();

	const [data, setData] = React.useState<Array<Post>>([]);
	const [openDialog, setOpenDialog] = React.useState<boolean>(false);
	const [blogTitle, setBlogTitle] = React.useState<string>('');
	const [blogTitleError, setBlogTitleError] = React.useState<boolean>(false);
	const [blogImage, setBlogImage] = React.useState<File[]>();
	const [blogImageSrc, setBlogImageSrc] = React.useState<string | undefined>('');
	const [blogDescription, setBlogDescription] = React.useState<string>('');
	const [blogDescriptionError, setBlogDescriptionError] = React.useState<boolean>(false);
	const [showDropZone, setShowDropZone] = React.useState<boolean>(false);
	const [tags, setTags] = React.useState<Array<Tag>>([]);
	const [blogTags, setBlogTags] = React.useState<Array<Tag>>([]);
	const { showDefaultError, showAlert } = React.useContext(AlertContext);

	const loadBlogPostList = async (): Promise<void> => {
		try {
			const postList: Array<Post> = await getPostList();

			setData(postList);
		} catch (e) {
			showDefaultError();
		}
	};

	React.useEffect(() => {
		if (!id) {
			loadBlogPostList();
		}
	}, [id]);

	const validateTitle = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setBlogTitle(event.target.value);
		setBlogTitleError(event.target.value.length < 1 || event.target.value.length > 150);
	};

	const validateBlogDescription = (value: string): void => {
		setBlogDescription(value);
		setBlogDescriptionError(value.length < 1 || value.length > 37630);
	};

	const clearFields = (): void => {
		setBlogTitle('');
		setBlogTitleError(false);
		setBlogDescription('');
		setBlogDescriptionError(false);
		setBlogImage([]);
	};

	const loadTags = async (): Promise<void> => {
		try {
			setTags(await getTags());
		} catch (e) {
			showDefaultError();
		}
	};

	const handleOpenDialog = (): void => {
		loadTags();
		setOpenDialog(true);
	};

	const handleCloseDialog = (): void => {
		clearFields();
		setOpenDialog(false);
	};

	const loadBlogPost = async (id: string): Promise<void> => {
		try {
			const blogItem: Post = await getPostById(+id);

			if (blogItem) {
				setBlogTitle(blogItem.title);
				setBlogDescription(blogItem.content);
				setBlogImageSrc(blogItem.imageUrl);
				setBlogTitleError(false);
				setBlogTitleError(false);

				if (blogItem.tags?.length) { setBlogTags(blogItem.tags); }
			}
		} catch (e) {
			showDefaultError();
		}

	};

	React.useEffect(() => {
		if (id) {
			loadTags();
			loadBlogPost(id);
		}
	}, [id]);

	const setFields = (): Post => {
		const blog: Post = {
			title: blogTitle,
			content: blogDescription,
			tags: blogTags,
			imageUrl: '',
		};

		return blog;
	};

	const handleSave = async (): Promise<void> => {
		if (!blogImage || !blogImage[0]) {
			showAlert({ message: t('blogView:IMAGE_ERROR'), type: 'error' });

			return;
		}

		try {
			const blog = setFields();

			await addPost(blog, blogImage && blogImage[0]);
			loadBlogPostList();
			handleCloseDialog();
		} catch (e) {
			showDefaultError();
		}
	};

	const handleEdit = async (): Promise<void> => {
		try {
			const blog = setFields();

			await editPostById(+id, blog, blogImage && blogImage[0]);
			history.push(`${ROUTES.CONTENT}${ROUTES.BLOG}`);
		} catch (e) {
			showDefaultError();
		}
	};

	const handleTagValues = (value: Array<Tag>): void => {
		setBlogTags(value);
	};

	return {
		data,
		openDialog,
		handleOpenDialog,
		handleCloseDialog,
		blogTitle,
		blogTitleError,
		validateTitle,
		setBlogImage,
		blogImageSrc,
		blogDescription,
		blogDescriptionError,
		validateBlogDescription,
		handleSave,
		handleEdit,
		showDropZone,
		setShowDropZone,
		tags,
		blogTags,
		handleTagValues,
		blogImage,
	};
};

export default useBlog;
