import './ImgDialog.scss';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Typography from '@material-ui/core/Typography';

const Transition = (props: TransitionProps): JSX.Element => {
	return (
		<Slide direction="up" {...props} />
	);
};

interface ImgDialogProps {
	img: string | null;
	onClose: () => void;
	previewTitle?: string;
}

const ImgDialog: React.FC<ImgDialogProps> = ({ img, onClose, previewTitle }) => {
	return (
		<Dialog
			fullScreen
			open={!!img}
			onClose={onClose}
			TransitionComponent={Transition}
		>
			<AppBar className={'app-bar'}>
				<Toolbar>
					<IconButton
						onClick={onClose}
						aria-label="Close"
					>
						<CloseIcon />
					</IconButton>
					<Typography
						variant="h4"
						color="textSecondary"
						className={'flex'}
					>
						{previewTitle || 'Cropped image'}
					</Typography>
				</Toolbar>
			</AppBar>
			<div className={'img-container'}>
				<img src={img as string} alt="Cropped" className={'img'} />
			</div>
		</Dialog>
	);
};

export default ImgDialog;
