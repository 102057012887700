import './SignUp.scss';
import ActionButton from '../../components/Buttons/ActionButton';
import Divider from '../../components/Divider';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import GoogleAuthButton from '../../components/Buttons/GoogleAuthButton';
import logo from '../../assets/images/pragma-logo.svg';
import React from 'react';
import { Redirect } from 'react-router-dom';
import TextField from '../../components/TextField';
import { useSignUp } from './useSignUp';
import { useSignUpValidation } from './useSignUpValidation';
import { useTranslation } from 'react-i18next';

const SignUp: React.FC = () => {
	const { t } = useTranslation();
	const { handleSignUpClick, isAuth, name, nameError, nameErrorMessage, signUpError, validateName } = useSignUp();
	const {
		validateEmail,
		validatePassword,
		validateConfirmPassword,
		email,
		emailError,
		emailErrorMessage,
		password,
		passwordError,
		passwordErrorMessage,
		confirmPassword,
		confirmPasswordError,
		confirmPasswordErrorMessage,
	} = useSignUpValidation();

	if (isAuth) {
		return (
			<Redirect to="/" />
		);
	}

	return (
		<div className="signup">
			<div className="signup-container">
				<div className="signup-logo-container">
					<img src={logo} alt={'logo'} className="signup-logo" />
				</div>
				<span className="signup-title">{t('signup:SIGNUP_MESSAGE')}</span>
				<div className="signup-form-container">
					<GoogleAuthButton
						onClick={(): void => { console.log('google auth coming soon...'); }}
						showIcon={true}
						text={t('view:SIGNIN_BUTTON_GOOGLE')}
					/>
					<Divider className="signup-divider" text={t('view:SIGNIN_SPAN_OR')} />
					<div className="signup-form">
						<TextField
							id="name"
							error={nameError}
							errorMessage={nameErrorMessage}
							label={t('signup:SIGNUP_LABEL_NAME')}
							onChange={validateName}
							required={true}
							type="email"
							value={name}
						/>
						<TextField
							id="email"
							error={emailError}
							errorMessage={emailErrorMessage}
							label={t('view:SIGNIN_LABEL_EMAIL')}
							onChange={validateEmail}
							required={true}
							type="email"
							value={email}
						/>
						<TextField
							id="password"
							error={passwordError}
							errorMessage={passwordErrorMessage}
							label={t('view:SIGNIN_LABEL_PASSWORD')}
							onChange={validatePassword}
							required={true}
							type="password"
							value={password}
						/>
						<TextField
							id="confirm-password"
							error={confirmPasswordError}
							errorMessage={confirmPasswordErrorMessage}
							label={t('changePasswordView:CONFIRM_PASSWORD_LABEL')}
							onChange={validateConfirmPassword}
							type="password"
							value={confirmPassword}
						/>
						{signUpError && (
							<div className="signup-error">
								<ErrorIcon style={{ marginRight: 5 }} />
								{signUpError}
							</div>
						)}
						<div className="signup-footer"></div>
						<ActionButton onClick={(): void => handleSignUpClick(email, password)} name={t('signup:SIGNUP_BUTTON')} isDisabled={emailError || passwordError || !email || !password || !confirmPassword || password !== confirmPassword} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
