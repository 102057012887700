export default {
	es: {
		variableModal: {
			VARIABLE_CREATE_TITLE: 'Crear variable',
			MODAL_CREATE_PRIMARY_BUTTON: 'Guardar',
			MODAL_CREATE_SECONDARY_BUTTON: 'Cancelar',
			MODAL_WARNING_PRIMARY_BUTTON: 'Aceptar',
			MODAL_WARNING_TITLE: 'Advertencia',
			MODAL_WARNING_DUPLICATE_NAME: 'Una variable con el mismo nombre ya existe.',
			MODAL_WARNING_INCOMPLETE_FIELDS: 'Hay campos incompletos.',
			MODAL_WARNING_REFRENCES_TITLE: 'Referencias',
			MODAL_WARNING_REFEFERENCES: 'Las variables referenciadas se borran, desea continuar?.',
			MODAL_WARNING_CIRCULAR_REFERENCES: 'No se permiten referencias circulares.',
			FIELD_NAME: 'Nombre',
			OPTION_LOGIC_OPERATOR: 'Operador logico',
			OPTION_MATHEMATICAL_OPERATOR: 'Operador matematico',
			OPTION_SWITCH_OPERATOR: 'Operador switch',
			OPTION_LIST_OPERATOR: 'Operador lista',
			OPTION_PUBLIC: 'Variable publica',
			OPTION_PRIVATE: 'Variable privada',
			OPTION_OPERATION_AND: 'Operador Y logico',
			OPTION_OPERATION_OR: 'Operador O logico',
			OPTION_OPERATION_MAJOR: 'Mayor',
			OPTION_OPERATION_MINOR: 'Menor',
			OPTION_OPERATION_MAJOR_EQUAL: 'Mayor o igual',
			OPTION_OPERATION_MINOR_EQUAL: 'Menor o igual',
			OPTION_OPERATION_EQUAL: 'Igual',
			OPTION_OPERATION_DIFFERENT: 'Distinto',
			OPTION_VARIABLE: 'Variable',
			OPTION_VARIABLE_TYPE: 'Tipo de resultado',
			OPTION_VARIABLE_TYPE_BOOLEAN: 'Boolean',
			OPTION_VARIABLE_TYPE_TEXT: 'Texto',
			OPTION_VARIABLE_TYPE_NUMBER: 'Numerica',
			OPTION_VARIABLE_TYPE_DATE: 'Fecha',
			LABEL_COMPARISON_TYPE: 'Tipo de comparación',
			LABEL_OPERATION: 'Operacion',
			OPTION_CONSTANT: 'Constante',
			LABEL_LIST_TYPE: 'Tipo de lista:',
			LABEL_LIST_ORDER_TYPE: 'Tipo de orden:',
			LABEL_LIST_JOIN_CHARACTER: 'Caracter de union:',
			TITILE_SPECS: 'Especificaciones',
			ADD_PARAMETER: '+ Agregar parametro',
			OPTION_OPERATION_SUM: 'Suma',
			OPTION_OPERATION_SUBTRACTION: 'Resta',
			OPTION_OPERATION_MULTIPLICATION: 'Multiplicación',
			OPTION_OPERATION_DIVISION: 'División',
			LABEL_SWITCH_WHEN: 'Cuando',
			LABEL_SWITCH_THEN: 'Entonces',
			LABEL_SWITCH_DEFAULT: 'Default',
			LABEL_OPTION_TRUE: 'Verdadero',
			LABEL_OPTION_FALSE: 'Falso',
			ORDERED: 'Ordenado',
			UNORDERED: 'Desordenado',
			NUMERIC: 'Numérico',
			ALPHABETIC: 'Alfabético',
			ROMAN: 'Romano',
			DOTTED: 'Punteado',
			DASHED: 'Linea',
			ROW: 'Fila',
		},
	},
	en: {
		variableModal: {
			VARIABLE_CREATE_TITLE: 'Create variable',
			MODAL_CREATE_PRIMARY_BUTTON: 'Save',
			MODAL_CREATE_SECONDARY_BUTTON: 'Cancel',
			MODAL_WARNING_PRIMARY_BUTTON: 'Accept',
			MODAL_WARNING_TITLE: 'Warning',
			MODAL_WARNING_DUPLICATE_NAME: 'A variable with the same name already exists',
			MODAL_WARNING_INCOMPLETE_FIELDS: 'There are incomplete fields in your submission',
			MODAL_WARNING_REFRENCES_TITLE: 'References',
			MODAL_WARNING_REFEFERENCES: 'The referenced variables will be deleted, do you want to continue?',
			MODAL_WARNING_CIRCULAR_REFERENCES: 'Circular references are not allowed.',
			FIELD_NAME: 'Name',
			OPTION_LOGIC_OPERATOR: 'Logic operator',
			OPTION_MATHEMATICAL_OPERATOR: 'Mathematical operator',
			OPTION_SWITCH_OPERATOR: 'Switch operator',
			OPTION_LIST_OPERATOR: 'List operator',
			OPTION_PUBLIC: 'Public variable',
			OPTION_PRIVATE: 'Private variable',
			OPTION_OPERATION_AND: 'Logic AND operator',
			OPTION_OPERATION_OR: 'Logic OR operator',
			OPTION_OPERATION_MAJOR: 'Greater',
			OPTION_OPERATION_MINOR: 'Less',
			OPTION_OPERATION_MAJOR_EQUAL: 'Greater or equal',
			OPTION_OPERATION_MINOR_EQUAL: 'Less or equal',
			OPTION_OPERATION_EQUAL: 'Equal',
			OPTION_OPERATION_DIFFERENT: 'Different',
			OPTION_VARIABLE: 'Variable',
			OPTION_VARIABLE_TYPE: 'Result type',
			OPTION_VARIABLE_TYPE_BOOLEAN: 'Boolean',
			OPTION_VARIABLE_TYPE_TEXT: 'Text',
			OPTION_VARIABLE_TYPE_NUMBER: 'Numeric',
			OPTION_VARIABLE_TYPE_DATE: 'Date',
			LABEL_COMPARISON_TYPE: 'Comparison type',
			LABEL_OPERATION: 'Operation',
			OPTION_CONSTANT: 'Constant',
			LABEL_LIST_TYPE: 'List type:',
			LABEL_LIST_ORDER_TYPE: 'Order type:',
			LABEL_LIST_JOIN_CHARACTER: 'Join character:',
			TITILE_SPECS: 'Specs',
			ADD_PARAMETER: 'Add parameter',
			OPTION_OPERATION_SUM: 'Sum',
			OPTION_OPERATION_SUBTRACTION: 'Subtraction',
			OPTION_OPERATION_MULTIPLICATION: 'Multiplication',
			OPTION_OPERATION_DIVISION: 'Division',
			LABEL_SWITCH_WHEN: 'When',
			LABEL_SWITCH_THEN: 'Then',
			LABEL_SWITCH_DEFAULT: 'Default',
			LABEL_OPTION_TRUE: 'True',
			LABEL_OPTION_FALSE: 'False',
			ORDERED: 'Ordered',
			UNORDERED: 'Unordered',
			NUMERIC: 'Numeric',
			ALPHABETIC: 'Alphabetic',
			ROMAN: 'Roman',
			DOTTED: 'Dotted',
			DASHED: 'Dashed',
			ROW: 'Row',
		},
	},
};
