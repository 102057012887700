import { ClientTableInterface, PanelClientInterface } from '../../../interfaces/Client';
import { getClientList, updateClient } from '../../../services/ClientService';
import { getPlan, getPlans } from '../../../services/PlanService';
import { getFile } from '../../../services/FileBaseService';
import { Option } from '../../../interfaces/Common';
import React from 'react';

interface ClientInterface {
	clientsList: Array<ClientTableInterface>;
	plansOptions: Array<Option>;
	planFilter: number;
	handleChangePlanFilter: (event: React.ChangeEvent<{ value: unknown }>) => void;
	toogleRow: (client: PanelClientInterface) => void;
}

const useClient = (): ClientInterface => {
	const [data, setData] = React.useState<Array<ClientTableInterface>>([]);
	const [clientsList, setClientsList] = React.useState<Array<ClientTableInterface>>([]);
	const [planFilter, setPlanFilter] = React.useState<number>(0);
	const [plansOptions, setPlansOptions] = React.useState<Array<Option>>([]);

	const loadPlans = async (): Promise<void> => {
		const plans = await getPlans();

		const plansOptions = plans.map((plan): Option => ({ key: plan.id, value: plan.key as string }));

		setPlansOptions(plansOptions);
	};

	const loadClientList = async (): Promise<void> => {
		const clientList: Array<ClientTableInterface> = await getClientList();

		for (let index = 0; index < clientList.length; index++) {
			const item = clientList[index];

			if (item.imageId) {
				item.imageSrc = await getFile(item.imageId).then(response => response?.url);
			}

			item.plan = await getPlan(item.planId);
		}

		setData(clientList);
	};

	React.useEffect(() => {
		loadPlans();
		loadClientList();
	}, []);

	React.useEffect(() => {
		const clientsList = planFilter > 0 ? data.filter(client => client.planId === planFilter) : data;

		setClientsList(clientsList);
	}, [data, planFilter]);

	const handleChangePlanFilter = (event: React.ChangeEvent<{ value: unknown }>): void => {
		setPlanFilter(event.target.value as number);
	};

	const toogleRow = (client: PanelClientInterface): void => {
		const clientToUpdate = client;

		clientToUpdate.isDisabled = !client.isDisabled;
		updateClient(clientToUpdate);

		loadClientList();
	};

	return {
		clientsList,
		plansOptions,
		planFilter,
		handleChangePlanFilter,
		toogleRow,
	};
};

export default useClient;
