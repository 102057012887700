import './Select.scss';
import { Button, IconButton } from '@material-ui/core';
import { Option, OptionsType } from '../../../interfaces/Documents/Questions/Options';
import { Clear } from '@material-ui/icons';
import DefaultInput from '../../Inputs/Input';
import DefaultSelect from '../../Inputs/Select';
import GenericOption from '../GenericOption';
import { Option as KeyOption } from '../../../interfaces/Common';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SelectInterface {
	title: string;
	code: string;
	description?: string;
	optionType: OptionsType;
	options: Option[];
	handleOnChangeTitle: (value: string) => void;
	handleOnChangeDescription: (value: string) => void;
	handleAddOption: () => void;
	handleOnChangeOptionType: (value: string) => void;
	handleOnChangeOption: (name: string, value: string, order: number) => void;
	handleDeleteOption: (order: number) => void;
	sectionList: KeyOption[];
	showGoToOption?: boolean;
}

const Select: React.FC<SelectInterface> = (props) => {
	const { t } = useTranslation();

	return (
		<div className='select-question'>
			<div className='title'>
				<DefaultInput placeholder={t('formsView:FIELD_TITLE')} value={props.title} label={t('formsView:FIELD_TITLE')} variant='outlined' onChange={(e): void => props.handleOnChangeTitle(e.target.value)} />
				<DefaultInput placeholder={t('formsView:FIELD_CODE')} value={props.code} label={t('formsView:FIELD_CODE')} variant='outlined' isDisabled={true} />
				{props.description !== undefined && <DefaultInput placeholder={t('formsView:FIELD_DESCRIPTION')} value={props.description} label={t('formsView:FIELD_DESCRIPTION')} variant='outlined' onChange={(e): void => props.handleOnChangeDescription(e.target.value)} />}
			</div>
			<div className='option-type'>
				<DefaultSelect
					label={t('formsView:FIELD_OPTION_TYPE')}
					onChange={(e): void => props.handleOnChangeOptionType(e.target.value as unknown as string)}
					value={props.optionType}
					options={[
						{ key: 'text', value: 'text' },
						{ key: 'number-integer', value: 'number-integer' },
					]}
					isFullWidth
				/>
			</div>
			{
				props.options.length > 0 &&
				(props.options.map((item, key) => {
					return (
						<div
							key={key}
							className='option'
						>
							<div className='title-option'>
								<GenericOption
									title={item.title}
									goTo={item.goTo}
									order={item.order}
									handleOnChangeOption={props.handleOnChangeOption}
									sectionList={props.sectionList}
									showGoToOption={props.showGoToOption}
								/>
							</div>
							<div className='delete-option'>
								<IconButton
									onClick={(): void => props.handleDeleteOption(item.order)}
								>
									<Clear />
								</IconButton>
							</div>
						</div>
					);
				}))
			}
			<div className='add-option'>
				<Button
					onClick={props.handleAddOption}
				>
					{t('formsView:BUTTON_ADD_OPTION')}
				</Button>
			</div>
		</div>
	);
};

export default React.memo(Select);
