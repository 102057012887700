import { ListOrderType, ListRowType, ListSpecs, ListSpecsTypes, ListType, ListUnorderType } from '../../../interfaces/Documents/CustomVariables/ListCustomVariable';
import { Option as KeyOption } from '../../../interfaces/Common';
import { ListOperatorProps } from './ListOperator';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VariableType } from '../../../interfaces/Documents/Variables/Variable';

interface UseListOperatorInterface {
	allVariables: KeyOption[];
	handleChangeListVariable: (value: string) => void;
	listTypeOtions: KeyOption[];
	handleChangeListTypeVariable: (value: ListType) => void;
	orderOptionsState: KeyOption[];
	handleChangeListOrderType: (value: ListOrderType) => void;
	handleChangeListJoinRow: (value: string) => void;
}

const useListOperator = (props: ListOperatorProps): UseListOperatorInterface => {
	const { t } = useTranslation();

	const listTypeOtions: KeyOption[] = [{ key: ListType.ORDERED, value: t(`variableModal:${ListType.ORDERED}`) }, { key: ListType.UNORDERED, value: t(`variableModal:${ListType.UNORDERED}`) }];
	const orderedOptions: KeyOption[] = [{ key: ListOrderType.NUMERIC, value: t(`variableModal:${ListOrderType.NUMERIC}`) }, { key: ListOrderType.ALPHABETIC, value: t(`variableModal:${ListOrderType.ALPHABETIC}`) }, { key: ListOrderType.ROMAN, value: t(`variableModal:${ListOrderType.ROMAN}`) }];
	const unorderOptions: KeyOption[] = [{ key: ListUnorderType.DOTTED, value: t(`variableModal:${ListUnorderType.DOTTED}`) }, { key: ListUnorderType.DASHED, value: t(`variableModal:${ListUnorderType.DASHED}`) }, { key: ListRowType.ROW, value: t(`variableModal:${ListRowType.ROW}`) }];
	const allVariables = React.useMemo(() => props.variables.filter(x => x.type === VariableType.List), [props.variables]);

	const [orderOptionsState, setOrderOptionsState] = React.useState<KeyOption[]>(props.variableData.specs?.type === ListType.ORDERED ? orderedOptions : unorderOptions);

	const handleChangeListVariable = (value: string): void => {
		if (props.variableData.id && props.arethereCircularReferences(props.variableData.id, value)) {
			return;
		}

		if (props.variableData.specs) {
			const specs = { ...props.variableData.specs, value: value };

			props.handleUpdateVariableData({ ...props.variableData, specs: specs });
		}
	};

	const handleChangeListTypeVariable = (value: ListType): void => {
		if (props.variableData.specs) {
			let listOrderType: ListOrderType | ListUnorderType | ListRowType = ListOrderType.ALPHABETIC;
			let listJoinRow: string | undefined = (props.variableData.specs as ListSpecs)?.joinRow;

			switch (value) {
				case ListType.UNORDERED:
					listOrderType = ListUnorderType.DOTTED;
					setOrderOptionsState(unorderOptions);
					break;
				case ListType.ORDERED:
				default:
					listJoinRow = undefined;
					setOrderOptionsState(orderedOptions);
					break;
			}

			const specs = { ...props.variableData.specs, type: value, orderType: listOrderType, joinRow: listJoinRow };

			props.handleUpdateVariableData({ ...props.variableData, specs: specs });
		}
	};

	const handleChangeListOrderType = (value: ListOrderType | ListUnorderType | ListRowType): void => {
		if (props.variableData.specs) {
			let specs: ListSpecsTypes;

			if (value === ListRowType.ROW) {
				specs = { ...props.variableData.specs, orderType: value, joinRow: '' } as ListSpecs;
			} else {
				specs = { ...props.variableData.specs, orderType: value, joinRow: undefined } as ListSpecs;
			}

			props.handleUpdateVariableData({ ...props.variableData, specs: specs });
		}
	};

	const handleChangeListJoinRow = (value: string): void => {
		if (props.variableData.specs) {
			const specs = { ...props.variableData.specs, joinRow: value };

			props.handleUpdateVariableData({ ...props.variableData, specs: specs });
		}
	};

	return {
		allVariables,
		handleChangeListVariable,
		listTypeOtions,
		handleChangeListTypeVariable,
		orderOptionsState,
		handleChangeListOrderType,
		handleChangeListJoinRow,
	};
};

export default useListOperator;

