import './Blog.scss';
import { FormControl, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import AutoCompleteTags from '../../../components/AutoCompleteTags';
import BlogCard from '../../../components/BlogCard';
import DefaultInput from '../../../components/Inputs/Input';
import Header from '../../../components/ContainerHeader/Header';
import ImageCrop from '../../../components/ImageCrop/ImageCrop';
import Modal from '../../../components/Modal';
import React from 'react';
import useBlog from './Hooks/useBlog';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../../components/Hooks/useRoutes';
import { useTranslation } from 'react-i18next';
import WYSIWYGEditor from '../../../components/WYSIWYGEditor';

const Blog: React.FC = () => {
	const {
		data,
		openDialog,
		handleOpenDialog,
		handleCloseDialog,
		blogTitle,
		blogTitleError,
		validateTitle,
		setBlogImage,
		blogDescription,
		blogDescriptionError,
		validateBlogDescription,
		handleSave,
		tags,
		blogTags,
		handleTagValues,
	} = useBlog();
	const { t } = useTranslation();
	const { ROUTES } = useRoutes();
	const history = useHistory();

	return (
		<>
			<Header
				leftTopCornerProps={{
					title: t('blogView:HEADER_TITLE'),
					isActionButtonVisible: true,
					actionButtonProps: {
						onClick: handleOpenDialog,
						name: t('blogView:HEADER_ACTION_BUTTON'),
						startIcon: (<Add />),
						variant: 'contained',
					},
				}}
				rightTopCornerProps={{
					isFilterVisible: false,
				}}
			/>
			<Grid container spacing={3}>
				{
					data.map((item, key) => {
						return (
							<Grid item key={key} xs={12}>
								<BlogCard
									id={item.id}
									title={item.title}
									content={item.content}
									user={item.user}
									updatedAt={item.updatedAt}
									imageUrl={item.imageUrl}
									onClick={(): void => history.push(`${ROUTES.CONTENT}${ROUTES.BLOG}/${item.id}`)}
								/>
							</Grid>
						);
					})
				}
			</Grid>
			<Modal
				title={t('blogView:HEADER_TITLE')}
				open={openDialog}
				handleOnClose={handleCloseDialog}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: t('action:SAVE_BUTTON'),
					handleOnclick: handleSave,
					isDisabled: (blogTitleError || blogDescriptionError || !blogTitle || !blogDescription),
				}}
				isSecondaryButtonVisible={true}
				secondaryButtonProps={{
					name: t('action:CANCEL_BUTON'),
					handleOnclick: handleCloseDialog,
				}}
				size='lg'
			>
				<div>
					<FormControl className='add-blog-modal' fullWidth margin='normal'>
						<div className='title-container'>
							<DefaultInput
								className='title-input'
								value={blogTitle}
								placeholder={t('blogView:TITLE_PLACEHOLDER')}
								variant='outlined'
								onChange={validateTitle}
								isError={blogTitleError}
								errorMessage={t('blogView:BLOG_TITLE_ERROR')}
							/>
						</div>
						<AutoCompleteTags
							className="autocomplete"
							value={blogTags || []}
							label={t('blogView:TAGS')}
							options={tags}
							setValue={(array): void => handleTagValues(array)}
						/>
						<ImageCrop
							aspectRatio={100/48.61}
							containerClass='upload-container'
							onChange={(img: Blob): void => setBlogImage([new File([img], 'File')])}
							uploadButtonLabel={t('imageCrop:IMAGE_UPLOAD')}
							previewButtonLabel={t('imageCrop:IMAGE_PREVIEW')}
							previewTitle={t('imageCrop:IMAGE_PREVIEW')}
							zoomLabel={t('imageCrop:IMAGE_ZOOM')}
							rotationLabel={t('imageCrop:IMAGE_ROTATION')}
						/>
						<WYSIWYGEditor
							value={blogDescription}
							onChange={validateBlogDescription}
							isError={blogDescriptionError}
							errorMessage={t('blogView:BLOG_DESCRIPTION_ERROR')}
						/>
					</FormControl>
				</div>
			</Modal>
		</>
	);
};

export default Blog;
