import './Card.scss';
import { Grid, Paper, SvgIconProps } from '@material-ui/core';
import React from 'react';
import { UserRole } from '../../interfaces/User';

export interface CardProps {
	onClick?: () => void;
	title: string;
	icon: (props: SvgIconProps) => JSX.Element;
	rolesAllowed?: UserRole[];
}

const Card: React.FC<CardProps> = ({ onClick, title, icon: Icon }) => {
	return (
		<Paper className='paperCard' elevation={3}>
			<Grid
				container
				onClick={onClick}
				className='gridContainer'
			>
				<Grid item xs={3} >
					<Icon className='icon' />
				</Grid>
				<Grid item>
					<div className='title'>{title}</div>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default Card;
