import { createFolder, editFolder, getFolders } from '../../services/FolderService';
import { Document, DocumentTable } from '../../interfaces/Documents/Document';
import { duplicateDocument, editDocument, getDocuments } from '../../services/DocumentService';
import { Folder, FolderTable, FolderType } from '../../interfaces/Folder';
import { AlertContext } from '../../contexts/AlertContext';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FormsStore {
	folders: Array<Folder>;
	documents: Array<DocumentTable>;
	handleChangeFolder: (parentId: number | undefined) => void;
	breadCrumbsFolder: Folder | undefined;
	openNewFolderModal: boolean;
	handleOpenNewFolderModal: () => void;
	handleCloseNewFolderModal: () => void;
	isEdit: boolean;
	filterSelected: number;
	onChangeFilter: (event: React.ChangeEvent<{ value: unknown }>) => void;
	enableDisableRow: (row: DocumentTable) => void;
	handleDocumentActions: (event: React.MouseEvent<HTMLElement>, item: Document) => void;
	documentAnchorEl: null | HTMLElement;
	handleCreateNewFolder: (folder: Folder) => Promise<void>;
	folderToEdit: Folder | undefined;
	handleOpenEditFolderModal: () => void;
	handleEditFolder: (folder: Folder) => void;
	handleFolderActions: (event: React.MouseEvent<HTMLElement>, item: Folder) => void;
	folderAnchorEl: null | HTMLElement;
	handleClosePopover: () => void;
	openFolderFileMoveModal: boolean;
	folderFileToMove: Folder | undefined | Document;
	handleOpenFolderFileMoveModal: () => void;
	handleCloseFolderFileMoveModal: () => void;
	handleFinishMove: () => void;
	isFolder: boolean;
	handleDuplicateDocument: () => void;
}

const useForms = (): FormsStore => {
	const { t } = useTranslation();
	const [folders, setFolders] = React.useState<Array<Folder>>([]);
	const [documents, setDocuments] = React.useState<Array<DocumentTable>>([]);
	const [parentFolderId, setParentFolderId] = React.useState<number | undefined>(undefined);
	const [breadCrumbsFolder, setBreadCrumbsFolder] = React.useState<Folder | undefined>(undefined);
	const [documentAnchorEl, setDocumentAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openNewFolderModal, setOpenNewFolderModal] = React.useState<boolean>(false);
	const [isEdit, setIsEdit] = React.useState<boolean>(false);
	const [isFolder, setIsFolder] = React.useState<boolean>(false);
	const [folderToEdit, setFolderToEdit] = React.useState<Folder>();
	const [folderAnchorEl, setFolderAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openFolderFileMoveModal, setOpenFolderFileMoveModal] = React.useState<boolean>(false);
	const [folderFileToMove, setFolderFileToMove] = React.useState<Folder | Document>();
	const [filterSelected, setFilterSelected] = React.useState<number>(1);
	const { showAlert, showDefaultError } = React.useContext(AlertContext);

	const loadTable = React.useCallback(async (): Promise<void> => {
		let filter: boolean | undefined = undefined;

		switch (filterSelected) {
			case 2:
				filter = false;
				break;
			case 3:
				filter = true;
				break;
		}

		try {
			const folderList: Array<FolderTable> = await getFolders(FolderType.DOCUMENT, parentFolderId);
			const documentList: Array<DocumentTable> = await getDocuments(parentFolderId, filter);

			if (folderList.length > 0) {
				const folder = folderList[0] as Folder;

				setBreadCrumbsFolder(folder.parentFolder);
			}
			// else if (formList.length > 0) {
			// 	const form = formList[0] as Document;

			// 	if (form.folder) {
			// 		setBreadCrumbsFolder(form.folder);
			// 	}
			// }

			setFolders(folderList);
			setDocuments(documentList);
		} catch (e) {
			showDefaultError();
		}
	}, [filterSelected, parentFolderId, showDefaultError]);

	const onChangeFilter = (event: React.ChangeEvent<{ value: unknown }>): void => {
		setFilterSelected(event.target.value as number);
	};

	React.useEffect(() => {
		loadTable();
	}, [loadTable, parentFolderId]);

	const handleDocumentActions = (event: React.MouseEvent<HTMLElement>, item: Document): void => {
		event.stopPropagation();
		setFolderFileToMove(item);
		setIsFolder(false);
		setDocumentAnchorEl(event.currentTarget);
	};

	const enableDisableRow = async (row: DocumentTable): Promise<void> => {
		if (row.id) {
			try {
				row.isDisabled = !row.isDisabled;
				await editDocument(row, row.id);

				setDocuments(docs => docs.map(doc => {
					if (doc.id === row.id) {
						doc.isDisabled = row.isDisabled;

						return doc;
					}

					return doc;
				}));
			} catch (e) {
				showDefaultError();
			}
		}
	};

	const handleChangeFolder = React.useCallback((parentId: number | undefined): void => {
		setBreadCrumbsFolder(folders.find(folder => folder.id === parentId));
		setParentFolderId(parentId);
	}, [folders]);

	const handleOpenNewFolderModal = (): void => {
		setOpenNewFolderModal(true);
	};

	const handleCloseNewFolderModal = (): void => {
		setIsEdit(false);
		setOpenNewFolderModal(false);
	};

	const handleClosePopover = (): void => {
		setFolderAnchorEl(null);
		setDocumentAnchorEl(null);
	};

	const handleOpenFolderFileMoveModal = (): void => {
		handleClosePopover();
		setOpenFolderFileMoveModal(true);
	};

	const handleCloseFolderFileMoveModal = (): void => {
		setOpenFolderFileMoveModal(false);
	};

	const handleCreateNewFolder = async (newFolder: Folder): Promise<void> => {
		try {
			await createFolder(newFolder);
			await loadTable();

			showAlert({ message: t('formsView:NEW_FOLDER_CREATED') });
		} catch (e) {
			showDefaultError();
		}

		handleCloseNewFolderModal();
	};

	const handleDuplicateDocument = async (): Promise<void> => {
		handleClosePopover();

		try {
			await duplicateDocument((folderFileToMove as Document).id || -1);
			await loadTable();

			showAlert({ message: t('formsView:FORM_DUPLICATED') });
		} catch (e) {
			showDefaultError();
		}
	};

	const handleOpenEditFolderModal = (): void => {
		handleClosePopover();
		setIsEdit(true);

		handleOpenNewFolderModal();
	};

	const handleEditFolder = async (folder: Folder): Promise<void> => {
		if (folder.id) {
			try {
				await editFolder(folder, folder.id);
				await loadTable();
			} catch (e) {
				showDefaultError();
			}
		}

		handleCloseNewFolderModal();
	};

	const handleFolderActions = (event: React.MouseEvent<HTMLElement>, item: Folder): void => {
		event.stopPropagation();
		setFolderToEdit(item);
		setFolderFileToMove(item);
		setIsFolder(true);
		setFolderAnchorEl(event.currentTarget);
	};

	const handleFinishMove = (): void => {
		loadTable();
	};

	return {
		folders,
		documents,
		handleChangeFolder,
		filterSelected,
		onChangeFilter,
		breadCrumbsFolder,
		handleDocumentActions,
		documentAnchorEl,
		enableDisableRow,
		openNewFolderModal,
		isEdit,
		handleOpenNewFolderModal,
		handleCloseNewFolderModal,
		handleCreateNewFolder,
		folderToEdit,
		handleOpenEditFolderModal,
		handleEditFolder,
		handleFolderActions,
		folderAnchorEl,
		handleClosePopover,
		openFolderFileMoveModal,
		folderFileToMove,
		handleOpenFolderFileMoveModal,
		handleCloseFolderFileMoveModal,
		handleFinishMove,
		isFolder,
		handleDuplicateDocument,
	};
};

export default useForms;
