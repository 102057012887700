import './Alert.scss';
import { Alert as AlertComponent } from '@material-ui/lab';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

export type Variant = 'error' | 'warning' | 'success';

export interface AlertProps {
	open: boolean;
	handleOnClose: () => void;
	message: string;
	type: Variant;
}

const Alert: React.FC<AlertProps> = (props) => (
	<Snackbar
		anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		classes={{
			root: 'snackbar',
		}}
		open={props.open}
		autoHideDuration={6000}
		onClose={props.handleOnClose}
	>
		<AlertComponent
			severity={props.type}
			classes={{
				root: 'alert',
			}}
		>
			{props.message}
		</AlertComponent>
	</Snackbar>
);

export default Alert;
