import React, { ReactNode } from 'react';

export interface LoadingProviderStore {
	isLoading: boolean;
}

export interface LoadingUpdateProviderStore {
	setIsLoading: (isLoading: boolean) => void;
}

export const LoadingContext = React.createContext({} as LoadingProviderStore);
export const LoadingUpdateContext = React.createContext({} as LoadingUpdateProviderStore);

const LoadingProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [ isLoading, setIsLoading ] = React.useState<boolean>(true);

	const store: LoadingProviderStore = {
		isLoading,
	};

	const updateStore: LoadingUpdateProviderStore = {
		setIsLoading,
	};

	return (
		<LoadingContext.Provider value={store}>
			<LoadingUpdateContext.Provider value={updateStore}>
				{ children }
			</LoadingUpdateContext.Provider>
		</LoadingContext.Provider>
	);
};

export { LoadingProvider };
export const LoadingConsumer = LoadingContext.Consumer;
