import { OptionVariable, VariableType } from '../../../interfaces/Documents/Variables/Variable';
import { SwitchCustomVariable, SwitchVariableValue } from '../../../interfaces/Documents/CustomVariables/SwitchCustomVariable';
import { DefaultVariableValue } from '../useCreateVariableModal';
import { isNumber } from '../../../helpers/Validations';
import { Option as KeyOption } from '../../../interfaces/Common';
import React from 'react';
import { SwitchOperatorProps } from './SwitchOperator';
import { ValueType } from '../../../interfaces/Documents/CustomVariables/CustomVariable';

interface UseSwitchOperatorInterface {
	handleChangeSwitchVariable: (value: string) => void;
	handleUpdateVariableValue: (name: string, value: string, order: number) => void;
	handleAddVariableValue: () => void;
	handleDeleteVariableValue: (order: number) => void;
	allVariables: KeyOption[];
	whenVariableList: OptionVariable[];
	thenVariableList: OptionVariable[];
	handleChangeVariableResult: (value: VariableType) => void;
	switchValueVariableType: VariableType | undefined;
}

const useSwitchOperator = (props: SwitchOperatorProps): UseSwitchOperatorInterface => {

	const allVariables = React.useMemo(() => props.variables.filter(x => x.type !== 'list'), [props.variables]);

	const getSwitchVariableType = React.useCallback((id: string | undefined): VariableType | undefined => {
		return allVariables.find(x => x.key === id)?.type;
	}, [allVariables]);

	const [whenVariableList, setWhenVariableList] = React.useState<OptionVariable[]>(allVariables);
	const [thenVariableList, setThenVariableList] = React.useState<OptionVariable[]>(allVariables);
	const [switchValueVariableType, setSwitchValueVariableType] = React.useState<VariableType | undefined>(props.variableData.specs?.switchValue ? getSwitchVariableType(props.variableData.specs?.switchValue) : undefined);

	const setStateVariables = React.useCallback((): void => {
		switch (switchValueVariableType) {
			case VariableType.Boolean:
				setWhenVariableList(allVariables);
				break;
			case VariableType.Date:
				setWhenVariableList(allVariables.filter(x => x.type === VariableType.Date));
				break;
			case VariableType.Number:
				setWhenVariableList(allVariables.filter(x => x.type === VariableType.Number));
				break;
			case VariableType.Text:
				setWhenVariableList(allVariables.filter(x => x.type === VariableType.Text));
				break;
		}

		switch (props.variableData.type) {
			case VariableType.Boolean:
				setThenVariableList(allVariables.filter(x => x.type === VariableType.Boolean));
				break;
			case VariableType.Date:
				setThenVariableList(allVariables.filter(x => x.type === VariableType.Date));
				break;
			case VariableType.Number:
				setThenVariableList(allVariables.filter(x => x.type === VariableType.Number));
				break;
			case VariableType.Text:
				setThenVariableList(allVariables.filter(x => x.type === VariableType.Text));
				break;
		}
	}, [allVariables, props.variableData.type, switchValueVariableType]);

	const handleChangeSwitchVariable = (value: string): void => {
		if (props.variableData.id && props.arethereCircularReferences(props.variableData.id, value)) {
			return;
		}

		if (props.variableData.specs) {
			let specs: SwitchCustomVariable['specs'] = {
				...props.variableData.specs,
				switchValue: value,
				values: [
					{ ...DefaultVariableValue, thenType: ValueType.Variable, thenValue: '' },
				],
				defaultType: ValueType.Variable,
				defaultValue: '',
			};
			const variableTypeSwitchValue: VariableType | undefined = getSwitchVariableType(value);

			if (variableTypeSwitchValue) {
				switch (variableTypeSwitchValue) {
					case VariableType.Boolean:
						setSwitchValueVariableType(VariableType.Boolean);
						specs = {
							...props.variableData.specs,
							switchValue: value,
							values: [
								{ ...DefaultVariableValue, valueType: ValueType.Constant, value: 'true', thenType: ValueType.Variable, thenValue: '' },
								{ ...DefaultVariableValue, valueType: ValueType.Constant, order: 2, value: 'false', thenType: ValueType.Variable, thenValue: '' },
							] || [],
						};
						delete specs.defaultType;
						delete specs.defaultValue;
						setWhenVariableList(allVariables);
						break;
					case VariableType.Date:
						setWhenVariableList(allVariables.filter(x => x.type === VariableType.Date));
						setSwitchValueVariableType(VariableType.Date);
						break;
					case VariableType.Number:
						setWhenVariableList(allVariables.filter(x => x.type === VariableType.Number));
						setSwitchValueVariableType(VariableType.Number);
						break;
					case VariableType.Text:
						setWhenVariableList(allVariables.filter(x => x.type === VariableType.Text));
						setSwitchValueVariableType(VariableType.Text);
						break;
				}
			}

			props.handleUpdateVariableData({ ...props.variableData, specs: specs });
		}
	};

	const handleChangeVariableResult = (value: VariableType): void => {
		if (props.variableData.specs) {
			let specs: SwitchCustomVariable['specs'] = {
				...props.variableData.specs,
				values: [
					{ ...DefaultVariableValue, thenType: ValueType.Variable, thenValue: '' },
					{ ...DefaultVariableValue, order: 2, thenType: ValueType.Variable, thenValue: '' },
				],
				defaultType: ValueType.Variable,
				defaultValue: '',
			};

			if (switchValueVariableType === VariableType.Boolean) {
				specs = {
					...props.variableData.specs,
					values: [
						{ ...DefaultVariableValue, valueType: ValueType.Constant, value: 'true', thenType: ValueType.Variable, thenValue: '' },
						{ ...DefaultVariableValue, valueType: ValueType.Constant, order: 2, value: 'false', thenType: ValueType.Variable, thenValue: '' },
					] || [],
				};
				delete specs.defaultType;
				delete specs.defaultValue;
			}

			switch (value) {
				case VariableType.Boolean:
					setThenVariableList(allVariables.filter(x => x.type === VariableType.Boolean));
					break;
				case VariableType.Date:
					setThenVariableList(allVariables.filter(x => x.type === VariableType.Date));
					break;
				case VariableType.Number:
					setThenVariableList(allVariables.filter(x => x.type === VariableType.Number));
					break;
				case VariableType.Text:
					setThenVariableList(allVariables.filter(x => x.type === VariableType.Text));
					break;
			}

			props.handleUpdateVariableData({ ...props.variableData, type: value, specs: specs });
		}
	};

	const handleUpdateVariableValue = (name: string, value: string, order: number): void => {
		const data = { ...props.variableData };

		if (data.id && (name === 'value' || name === 'thenValue' || name === 'defaultValue') && props.arethereCircularReferences(data.id, value)) {
			return;
		}

		if (data.specs && data.specs.values) {
			const index = data.specs.values.findIndex(o => o.order === order);

			if (index > -1) {
				if ((switchValueVariableType === VariableType.Number && name === 'value' && data.specs.values[index].valueType === ValueType.Constant && !isNumber(value))
					|| (data.type === VariableType.Number && name === 'thenValue' && data.specs.values[index].thenType === ValueType.Constant && !isNumber(value))) {
					return;
				}

				const newValue = { ...data.specs.values[index], [name]: value };

				if (name === 'valueType') {
					newValue.value = '';

					if (switchValueVariableType === VariableType.Date) {
						newValue.value = new Date().toDateString();
					}
				}

				if (name === 'thenType') {
					newValue.thenValue = '';

					if (data.type === VariableType.Date && value === ValueType.Constant) {
						newValue.thenValue = new Date().toDateString();
					}
				}

				data.specs.values[index] = newValue;
			} else if (order === 0) {

				if ((data.type === VariableType.Number && name === 'defaultValue' && data.specs.defaultType === ValueType.Constant && !isNumber(value))) {
					return;
				}

				const newSpecs = { ...data.specs, [name]: value };

				if (name === 'defaultType') {
					newSpecs.defaultValue = '';

					if (switchValueVariableType === VariableType.Date) {
						newSpecs.defaultValue = new Date().toDateString();
					}
				}

				data.specs = newSpecs;
			}

			props.handleUpdateVariableData(data);
		}
	};

	const handleAddVariableValue = (): void => {
		if (props.variableData.specs && props.variableData.specs.values) {
			const newValues: SwitchVariableValue[] = [...props.variableData.specs.values];

			if (newValues) {
				if (newValues.length > 0) {
					const lastOrder = newValues[newValues.length - 1].order;

					newValues.push({ ...DefaultVariableValue, order: lastOrder + 1, thenType: ValueType.Variable, thenValue: '' });
				} else {
					newValues.push({ ...DefaultVariableValue, thenType: ValueType.Variable, thenValue: '' });
				}

				props.handleUpdateVariableData({ ...props.variableData, specs: { ...props.variableData.specs, values: newValues } });
			}
		}
	};

	const handleDeleteVariableValue = (order: number): void => {
		if (props.variableData.specs && props.variableData.specs.values) {
			const oldValues: SwitchVariableValue[] = [...props.variableData.specs.values];
			const valueIndex = oldValues.findIndex(x => x.order === order);

			if (valueIndex > -1) {
				const newValues: SwitchVariableValue[] = [];
				let itemOrder = 1;

				oldValues.forEach((item) => {
					if (item.order !== order) {
						newValues.push({ ...item, order: itemOrder });
						itemOrder++;
					}
				});

				props.handleUpdateVariableData({ ...props.variableData, specs: { ...props.variableData.specs, values: newValues } });
			}
		}
	};

	React.useEffect(() => {
		setStateVariables();
	}, [setStateVariables]);

	return {
		handleChangeSwitchVariable,
		handleUpdateVariableValue,
		handleAddVariableValue,
		handleDeleteVariableValue,
		allVariables,
		whenVariableList,
		thenVariableList,
		handleChangeVariableResult,
		switchValueVariableType,
	};
};

export default useSwitchOperator;
