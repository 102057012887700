export default {
	es: {
		report: {
			TITLE: 'Crear informe',
			DROPDOWN_TEXT: 'Tipo de informe',
			CALENDAR_TITLE: 'Seleccione el período de tiempo',
			CALENDAR_FROM: 'Desde',
			CALENDAR_TO: 'Hasta',
			BUTTON_CANCEL: 'Cancelar',
			BUTTON_SAVE: 'Guardar',

		},
	},
	en: {
		report: {
			TITLE: 'Create report',
			DROPDOWN_TEXT: 'Type of report',
			CALENDAR_TITLE: 'Select a date range',
			CALENDAR_FROM: 'From',
			CALENDAR_TO: 'To',
			BUTTON_CANCEL: 'Cancel',
			BUTTON_SAVE: 'Save',
		},
	},
};
