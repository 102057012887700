import { createMuiTheme } from '@material-ui/core/styles';

const muiTheme = createMuiTheme({
	typography: {
		fontFamily: [
			'Source Sans Pro',
			'sans-serif',
		].join(','),
	},
	palette: {
		primary: {
			main: '#4ebed7',
		},
	},
});

export default muiTheme;
