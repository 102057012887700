import React from 'react';
import { useParams } from 'react-router-dom';

const ResourcesFolderEdit: React.FC = () => {
	const { id } = useParams();

	return (
		<div>
			<h1>This is ResourcesFolderEdit page!. About to edit id: {id}</h1>
			<h3>This is a sub page of Folder page</h3>
			<h5>This is a sub page of Resources page</h5>
		</div>
	);
};

export default ResourcesFolderEdit;
