import axios from 'axios';
import { Plan } from '../interfaces/Plan';

const apiUrl = process.env.REACT_APP_API_URL;

export const getPlanById = async (id: number): Promise<Plan | undefined> => {
	try {
		if (apiUrl) {
			const result = await axios.get(
				`${apiUrl}/plan/${id}`
			);

			return result.data;
		}

		return Promise.reject('API url not defined');
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getPlanList = async (): Promise<Array<Plan>> => {
	try {
		if (apiUrl) {
			const result = await axios.get(
				`${apiUrl}/plan`
			);

			return result.data;
		}

		return Promise.reject('API url not defined');
	} catch (error) {
		return Promise.reject(error);
	}
};

export const editPlanRequest = async (model: Plan): Promise<number> => {
	try {
		if (apiUrl) {
			const result = await axios.put(
				`${apiUrl}/plan/${model.id}`,
				model
			);

			return result.data;
		}

		return Promise.reject('API url not defined');
	} catch (error) {
		return Promise.reject(error);
	}
};
