import DefaultInput from '../../../Inputs/Input';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import useTextPreview from './useTextPreview';
import { useTranslation } from 'react-i18next';

const TextPreview: React.FC<QuestionPreviewProps> = (props) => {
	const { t } = useTranslation();
	const {
		question,
		inputLabel,
		errorMessage,
		showErrors,
		handleOnChange,
	} = useTextPreview(props);
	const { value, isValid } = question;

	return (
		<div>
			<DefaultInput
				placeholder={t('formsView:MODAL_PREVIEW_TEXT_PLACEHOLDER')}
				value={value}
				variant='outlined'
				isMultiline
				onChange={handleOnChange}
				isError={showErrors && !isValid}
				errorMessage={showErrors && !isValid ? errorMessage : inputLabel}
			/>
		</div>
	);
};

export default TextPreview;
