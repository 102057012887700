export default {
	es: {
		topBar: {
			SEARCH_PLACEHOLDER_TEXT: 'Buscar ej. pacientes',
			CREATE_REPORT_TEXT: 'Crear informe',
		},
	},
	en: {
		topBar: {
			SEARCH_PLACEHOLDER_TEXT: 'Search e.g. patients',
			CREATE_REPORT_TEXT: 'Create report',
		},
	},
};
