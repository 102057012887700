import { changePassword, passwordValidation } from '../../services/UserService';
import { AlertContext } from '../../contexts/AlertContext';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../components/Hooks/useRoutes';
import { useTranslation } from 'react-i18next';

interface ChangePasswordInterface {
	oldPassword: string;
	setOldPassword: (oldPassword: string) => void;
	newPassword: string;
	setNewPassword: (newPassword: string) => void;
	confirmPassword: string;
	setConfirmPassword: (confirmPassword: string) => void;
	passwordMessageError: string;
	passwordConfirmErrorMessage: string;
	isSaveButtonDisabled: boolean;
	handleSave: () => Promise<void>;
}

const useChangePassword = (): ChangePasswordInterface => {
	const { t } = useTranslation();
	const history = useHistory();
	const { ROUTES } = useRoutes();
	const { showAlert, showDefaultError } = React.useContext(AlertContext);
	const [oldPassword, setOldPassword] = React.useState<string>('');
	const [newPassword, setNewPassword] = React.useState<string>('');
	const [confirmPassword, setConfirmPassword] = React.useState<string>('');
	const [passwordMessageError, setPasswordMessageError] = React.useState<string>('');
	const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] = React.useState<string>('');
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = React.useState<boolean>(true);

	React.useEffect(() => {
		if (oldPassword) {
			const [isInvalid, errorMessage] = passwordValidation(oldPassword);

			isInvalid ? setPasswordMessageError(errorMessage) : setPasswordMessageError('');
		}

		if (newPassword) {
			const [isInvalid, errorMessage] = passwordValidation(newPassword);

			isInvalid ? setPasswordMessageError(errorMessage) : setPasswordMessageError('');
		}

		if (confirmPassword && newPassword !== confirmPassword) {
			setPasswordConfirmErrorMessage('Las contraseñas no coinciden');
		} else {
			setPasswordConfirmErrorMessage('');
		}

		setIsSaveButtonDisabled(
			!oldPassword ||
			!newPassword ||
			!confirmPassword ||
			Boolean(passwordMessageError.length) ||
			Boolean(passwordConfirmErrorMessage.length)
		);
	}, [oldPassword, newPassword, confirmPassword, passwordMessageError, passwordConfirmErrorMessage]);

	const handleSave = async (): Promise<void> => {
		try {
			await changePassword(oldPassword, newPassword);
			history.push(ROUTES.DASHBOARD);
			showAlert({ message: t('message:CHANGE_PASSWORD_UPDATED') });
		} catch (error) {
			showDefaultError();
		}
	};

	return {
		oldPassword,
		setOldPassword,
		newPassword,
		setNewPassword,
		confirmPassword,
		setConfirmPassword,
		passwordMessageError,
		passwordConfirmErrorMessage,
		isSaveButtonDisabled,
		handleSave,
	};
};

export default useChangePassword;
