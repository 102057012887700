import './ChangePassword.scss';
import ActionButton from '../../components/Buttons/ActionButton';
import React from 'react';
import TextField from '../../components/TextField/TextField';
import Typography from '@material-ui/core/Typography';
import useChangePassword from './useChangePassword';
import { useTranslation } from 'react-i18next';

const ChangePassword: React.FC = () => {
	const { t } = useTranslation();

	const {
		oldPassword,
		setOldPassword,
		newPassword,
		setNewPassword,
		confirmPassword,
		setConfirmPassword,
		passwordMessageError,
		passwordConfirmErrorMessage,
		isSaveButtonDisabled,
		handleSave,
	} = useChangePassword();

	return (
		<div className="change-password">
			<div className="change-password-container">
				<Typography className="title" variant="h4">{t('changePasswordView:HEADER_TITLE')}</Typography>
				<Typography className="subtitle" variant="h6">{t('changePasswordView:HEADER_DESCRIPTION')}</Typography>
				<div className="change-password-form">
					<TextField
						id="old-password"
						error={Boolean(passwordMessageError.length)}
						errorMessage={passwordMessageError}
						label={t('changePasswordView:OLD_PASSWORD_LABEL')}
						onChange={(e): void => setOldPassword(e.target.value)}
						type="password"
						value={oldPassword}
					/>
					<TextField
						id="new-password"
						error={Boolean(passwordMessageError.length)}
						errorMessage={passwordMessageError}
						label={t('changePasswordView:NEW_PASSWORD_LABEL')}
						onChange={(e): void => setNewPassword(e.target.value)}
						type="password"
						value={newPassword}
					/>
					<TextField
						id="confirm-password"
						error={Boolean(passwordConfirmErrorMessage.length)}
						errorMessage={passwordConfirmErrorMessage}
						label={t('changePasswordView:CONFIRM_PASSWORD_LABEL')}
						onChange={(e): void => setConfirmPassword(e.target.value)}
						type="password"
						value={confirmPassword}
					/>
					<div className="change-password-form__button">
						<ActionButton
							name={t('changePasswordView:CHANGE_PASSWORD_PRIMARY_BUTTON')}
							onClick={(): Promise<void> => handleSave()}
							isDisabled={isSaveButtonDisabled}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
