import { Option } from '../../interfaces/Documents/Questions/Options';
import { QuestionTypes } from '../../interfaces/Documents/Questions/Question';
import React from 'react';
import { ReorderProps } from './Reorder';
import { Section } from '../../interfaces/Documents/Section';

interface ReorderHook {
	itemList: (Section | QuestionTypes | Option)[];
	handleChangeOrder: (order: number, moveUp: boolean) => void;
}

const useReorder = (props: ReorderProps): ReorderHook => {

	const [itemList, setItemList] = React.useState<(Section | QuestionTypes | Option)[]>([...props.items]);

	const handleChangeOrder = (order: number, moveUp: boolean): void => {
		const newItemList: (Section | QuestionTypes | Option)[] = [];

		if (order > 0) {
			if (moveUp) {
				itemList.forEach((item, index) => {
					if (item.order === order - 1) {
						newItemList.push({ ...itemList[index + 1], order: item.order });
					} else if (item.order === order) {
						newItemList.push({ ...itemList[index - 1], order: item.order });
					} else {
						newItemList.push({ ...item });
					}
				});
			} else {
				itemList.forEach((item, index) => {
					if (item.order === order) {
						newItemList.push({ ...itemList[index + 1], order: item.order });
					} else if (item.order === order + 1) {
						newItemList.push({ ...itemList[index - 1], order: item.order });
					} else {
						newItemList.push({ ...item });
					}
				});
			}

			setItemList(newItemList);
		}
	};

	return {
		itemList,
		handleChangeOrder,
	};
};

export default useReorder;
