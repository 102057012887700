import './Plans.scss';
import { IconButton, TableBody } from '@material-ui/core';
import Cell from '../../../components/Cell';
import DefaultInput from '../../../components/Inputs/Input';
// import DefaultSelect from '../../../components/Inputs/Select';
import DefaultTable from '../../../components/Table';
import { EditOutlined } from '@material-ui/icons';
import Header from '../../../components/ContainerHeader/Header';
import React from 'react';
import Row from '../../../components/Row';
import usePlans from './Hooks/usePlans';
import { useTranslation } from 'react-i18next';

const Plans: React.FC = () => {
	const { t } = useTranslation();
	const {
		data,
		// currencies,
		editableRowId,
		planValue,
		validatePlanValue,
		// planType,
		// validatePlanType,
		editRow,
	} = usePlans();

	return (
		<div className='plan-container'>
			<Header
				leftTopCornerProps={{
					title: t('planView:HEADER_TITLE'),
					isActionButtonVisible: false,
				}}
				rightTopCornerProps={{
					isFilterVisible: false,
				}}
			/>
			<DefaultTable>
				<TableBody className="table-body-plan">
					{
						data.map((item, key) => {
							return (
								<Row key={key} isDisabled={item.isDisabled}>
									<Cell className='size-cell-large' align='left'>{item.key}</Cell>
									<Cell className='size-cell-medium' align='right'>
										{
											editableRowId === item.id
												? (
													<>
														<DefaultInput value={planValue} onChange={validatePlanValue} variant='outlined' removeMarginBotton={true} />
													</>
												)
												: (
													<>{item.value}</>
												)
										}
									</Cell>
									<Cell className='size-cell-medium' align='right'>
										{/* {
											editableRowId === item.id
												? (
													<DefaultSelect options={currencies} value={planType || item.type} onChange={validatePlanType} />
												)
												: (
													<>{item.type}</>
												)
										} */}
										{item.type}
									</Cell>
									<Cell className='size-cell-large' align='right'>
										<IconButton
											className={`${editableRowId === item.id ? 'icon-darker' : ''}`}
											onClick={(): void => editRow(item)}
										>
											<EditOutlined className='icon' />
										</IconButton>
									</Cell>
								</Row>
							);
						})
					}
				</TableBody>
			</DefaultTable>
		</div>
	);
};

export default Plans;
