import { changeMyPassword, editPanelUser, getMyUserInfo, getPanelInvitationList, getPanelUserList, getRolesList, passwordReset, savePanelUser, updateMyUserInfo } from '../api/UserRepository';
import { PanelUser, PanelUserExtended, UserInvitation, UserRole } from '../interfaces/User';

export const getMyInfo = async (): Promise<PanelUserExtended> => {
	const apiUser = await getMyUserInfo();

	const { roles, ...rest } = apiUser;

	const role = roles.find(role => role < 3);

	if (role != null) {
		return {
			...rest,
			role,
		};
	}

	throw ({
		name: 'Error',
		message: 'User without enough access',
	});
};

export const updateMyInfo = async (user: PanelUserExtended, avatar?: File): Promise<void> => {
	return await updateMyUserInfo(user, avatar);
};

export const getuserList = async (): Promise<Array<PanelUser>> => {
	return await getPanelUserList();
};

export const getInvitationsList = async (): Promise<Array<UserInvitation>> => {
	return await getPanelInvitationList();
};

export const getRoles = async (): Promise<Array<UserRole>> => {
	return await getRolesList();
};

export const isEmailValid = (email: string): boolean => {
	const validEmail: RegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

	return validEmail.test(email);
};

export const passwordValidation = (text: string): [boolean, string] => {
	// Minimum eight characters, Maximum 32, any character, at least one uppercase letter, one lowercase letter and one number
	const validCharacters: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\W]{8,32}$/g;
	let error = false;
	let errorMessage = '';

	if (!validCharacters.test(text)) {
		errorMessage = 'La contraseña debe tener entre 8 y 32 caracteres, y al menos una letra mayúscula, una minúscula y un número';
		error = true;
	}

	return [error, errorMessage] as [
		boolean,
		string
	];
};

export const saveUser = async (invitation: UserInvitation): Promise<void> => {
	return await savePanelUser(invitation);
};

export const editUser = async (user: PanelUser): Promise<void> => {
	return await editPanelUser(user);
};

export const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
	return changeMyPassword(oldPassword, newPassword);
};

export const forgotPassword = async (username: string): Promise<void> => {
	return await passwordReset(username);
};

export const getRoleName = (role: UserRole): string => {
	switch (role) {
		case UserRole.SUPER_ADMIN:
			return 'SUPER_ADMIN';
		case UserRole.TECHNICIAN:
			return 'TECHNICIAN';
		case UserRole.MARKETING:
			return 'MARKETING';
		case UserRole.PATIENT:
		case UserRole.THERAPIST:
		case UserRole.ASSISTANT:
			return 'USER';
		default:
			return 'UNSUPPORTED';
	}
};
