import { forgotPassword } from '../../services/UserService';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../components/Hooks/useRoutes';
import { useSignInValidation } from '../SignIn/useSignInValidation';

interface ForgotPasswordInterface {
	validateEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
	email: string | undefined;
	emailError: boolean;
	emailErrorMessage: string | undefined;
	handleRecoverClick: () => Promise<void>;
	recoverError: string | undefined;
}

const useForgotPassword = (): ForgotPasswordInterface => {
	const { email, emailError, emailErrorMessage, validateEmail } = useSignInValidation();
	const [recoverError, setRecoverError] = React.useState<string | undefined>(undefined);
	const history = useHistory();
	const { ROUTES } = useRoutes();

	const handleRecoverClick = async (): Promise<void> => {
		if (email) {
			try {
				await forgotPassword(email);

				setRecoverError(undefined);
				history.push(ROUTES.SIGNIN);
			} catch (error) {
				setRecoverError(error.message || 'UNKONWN ERROR / ERROR DESCONOCIDO');
			}
		}
	};

	return {
		email,
		emailError,
		emailErrorMessage,
		validateEmail,
		handleRecoverClick,
		recoverError,
	};
};

export default useForgotPassword;
