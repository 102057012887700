import { addQuestionDescription, addQuestionToDocument, addSectionToDocument, addTestCaseToDocument, copyQuestion, copySection, editDocument, getDocument, removeQuestion, removeSection, reorderQuestionOptions, reorderQuestions, reorderSections, resetDocument, saveDocument, updateDocumentQuestion, updateDocumentSection } from '../services/DocumentService';
import { Document, ReorderType } from '../interfaces/Documents/Document';
import React, { ReactNode } from 'react';
import { FolderType } from '../interfaces/Folder';
import { Option } from '../interfaces/Documents/Questions/Options';
import { QuestionTypes } from '../interfaces/Documents/Questions/Question';
import { Section } from '../interfaces/Documents/Section';
import { Tag } from '../interfaces/Tag';
import { TestCase } from '../interfaces/Documents/TestCase';

export interface TQuestion {
	questionId: string;
	nextSection: string | undefined;
}

export interface DocumentProviderStore {
	documentModel: Document;
	actionData: { [KEY: string]: string | number };
	isPreviewButtonEnabled: boolean;
	isDocumentValid: () => boolean;
	sectionsToShow: string;
}

export interface DocumentUpdateProviderStore {
	setDocumentModel: (document: Document) => void;
	handleChangeStringValues: (name: string, value: string) => void;
	handleTagValues: (value: Array<Tag>) => void;
	handleUpdateSection: (section: Section) => void;
	handleUpdateQuestion: (question: QuestionTypes, section: Section) => void;
	moveActionsComponent: (data: { [KEY: string]: string | number }) => void;
	handleAddSection: () => void;
	handleAddQuestion: () => void;
	handleAddDescription: () => void;
	handleDeleteSectionOrQuestion: () => void;
	handleCopy: () => void;
	handleReorder: (itemList: (Section | QuestionTypes | Option)[], type: ReorderType, sectionOrder: number, questionOrder: number) => void;
	handleSaveDocument: () => Promise<void>;
	handleSectionsToShow: (sectionId: string, sectionToRemove?: Array<string>) => void;
	hideErrorsInForm: () => void;
	showErrorsInForm: () => void;
	handleResetDocument: (sectionsToReset?: string[]) => void;
	setFolderId: (folderId: undefined | number) => void;
	handleAddTestCase: (testCase: TestCase) => void;
}

export const DocumentContext = React.createContext({} as DocumentProviderStore);
export const DocumentUpdateContext = React.createContext({} as DocumentUpdateProviderStore);

const DefaultDocumentModel: Document = {
	id: undefined,
	parentId: '',
	name: '',
	description: '',
	tags: [],
	sections: [],
	report: '',
	variables: [],
	customVariables: [],
	testCases: [],
	isDisabled: false,
	folder: undefined,
	createdAt: new Date().toString(),
	updatedAt: new Date().toString(),
	createdBy: {
		name: '',
	},
	updatedBy: {
		name: '',
	},
	showErrors: false,
};

const defaultActionData = { top: '92px', sectionOrder: -1, questionOrder: -1 };

const DocumentProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [documentModel, setDocumentModel] = React.useState<Document>(DefaultDocumentModel);
	// actionData has information about the action to execute and on which question and section to do it
	const [actionData, setActionData] = React.useState<{ [KEY: string]: string | number }>(defaultActionData);
	const [isPreviewButtonEnabled, setIsPreviewButtonEnabled] = React.useState<boolean>(false);
	const [sectionsToShow, setSectionsToShow] = React.useState<string>('');
	const [folderId, setFolderId] = React.useState<undefined | number>(undefined);

	const checkRequiredDocumentValues = React.useCallback((): boolean => {
		return !!documentModel.name.trim();
	}, [documentModel]);

	const checkRequiredSectionValues = React.useCallback((): boolean => {
		return documentModel.sections.every(section => Boolean(section.title));
	}, [documentModel.sections]);

	const checkRequiredQuestionValues = React.useCallback((): boolean => {
		return documentModel.sections.every(section => section.questions.every(question => Boolean(question.title)));
	}, [documentModel.sections]);

	React.useEffect(() => {
		setIsPreviewButtonEnabled(
			checkRequiredDocumentValues() &&
			checkRequiredSectionValues() &&
			checkRequiredQuestionValues()
		);
	}, [checkRequiredDocumentValues, checkRequiredQuestionValues, checkRequiredSectionValues]);

	const handleSectionsToShow = (sectionId: string): void => {
		if (sectionId !== sectionsToShow) {
			setSectionsToShow(sectionId);
		}
	};

	const handleChangeStringValues = (name: string, value: string): void => {
		setDocumentModel(document => ({ ...document, [name]: value }));
	};

	const handleTagValues = (value: Array<Tag>): void => {
		setDocumentModel(document => ({ ...document, tags: value }));
	};

	const handleUpdateSection = (section: Section): void => {
		setDocumentModel(updateDocumentSection(documentModel, section));
	};

	const handleUpdateQuestion = (question: QuestionTypes, section: Section): void => {
		setDocumentModel(updateDocumentQuestion(documentModel, section, question));
	};

	const moveActionsComponent = (data: { [KEY: string]: string | number }): void => {
		if (actionData.top !== data.top) {
			setActionData(data);
		}
	};

	const handleAddTestCase = (testCase: TestCase): void => {
		setDocumentModel(addTestCaseToDocument(documentModel, testCase));
	};

	const handleAddSection = (): void => {
		setDocumentModel(addSectionToDocument(documentModel, actionData));
	};

	const handleAddQuestion = (): void => {
		setDocumentModel(addQuestionToDocument(documentModel, actionData));
	};

	const handleAddDescription = (): void => {
		setDocumentModel(addQuestionDescription(documentModel, actionData));
	};

	const handleDeleteSectionOrQuestion = (): void => {
		if (actionData.sectionOrder > 0) {
			let documentModelToUpdate: Document;

			if (actionData.questionOrder > 0) {
				documentModelToUpdate = removeQuestion(documentModel, actionData);
			} else {
				documentModelToUpdate = removeSection(documentModel, actionData);
			}

			setDocumentModel(documentModelToUpdate);
			setActionData(defaultActionData);
		}
	};

	const handleCopy = (): void => {
		if (actionData.sectionOrder > 0) {
			let documentModelToUpdate: Document;

			if (actionData.questionOrder > 0) {
				documentModelToUpdate = copyQuestion(documentModel, actionData);
			} else {
				documentModelToUpdate = copySection(documentModel, actionData);
			}

			setDocumentModel(documentModelToUpdate);
		}
	};

	const handleReorder = (itemList: (Section | QuestionTypes | Option)[], type: ReorderType, sectionOrder: number = -1, questionOrder: number = -1): void => {
		if (type === ReorderType.Section) {
			setDocumentModel(reorderSections(documentModel, itemList as Section[]));
		} else if (type === ReorderType.Question && sectionOrder > 0) {
			setDocumentModel(reorderQuestions(documentModel, sectionOrder, itemList as QuestionTypes[]));
		} else if (type === ReorderType.Option && sectionOrder > 0 && questionOrder > 0) {
			setDocumentModel(reorderQuestionOptions(documentModel, sectionOrder, questionOrder, itemList as Option[]));
		}
	};

	const handleSaveDocument = async (): Promise<void> => {
		let documentId: number;

		if (documentModel.id) {
			await editDocument(documentModel, documentModel.id);

			documentId = documentModel.id;
		} else {
			if (folderId) {
				documentModel.folder = { id: folderId, name: '', isDisabled: false, type: FolderType.DOCUMENT, createdAt: '', updatedAt: '', createdBy: { name: '' }, updatedBy: { name: '' }};
			}

			documentId = await saveDocument(documentModel);
		}

		const newDocument = await getDocument(documentId);

		setDocumentModel(newDocument);
	};

	const isDocumentValid = (): boolean => {
		return !!(documentModel.sections.find(s => s.id === sectionsToShow)?.questions?.every(question => question.isValid));
	};

	const hideErrorsInForm = (): void => setDocumentModel({ ...documentModel, showErrors: false });

	const showErrorsInForm = (): void => setDocumentModel({ ...documentModel, showErrors: true });

	const handleResetDocument = (sectionsToReset?: string[]): void => {
		setDocumentModel(resetDocument(documentModel, sectionsToReset));
	};

	const store: DocumentProviderStore = {
		documentModel,
		actionData,
		isPreviewButtonEnabled,
		isDocumentValid,
		sectionsToShow,
	};

	const updateStore: DocumentUpdateProviderStore = {
		setDocumentModel,
		handleChangeStringValues,
		handleTagValues,
		handleUpdateSection,
		handleUpdateQuestion,
		moveActionsComponent,
		handleAddSection,
		handleAddQuestion,
		handleAddDescription,
		handleDeleteSectionOrQuestion,
		handleCopy,
		handleReorder,
		handleSaveDocument,
		handleSectionsToShow,
		hideErrorsInForm,
		showErrorsInForm,
		handleResetDocument,
		setFolderId,
		handleAddTestCase,
	};

	return (
		<DocumentContext.Provider value={store}>
			<DocumentUpdateContext.Provider value={updateStore}>
				{children}
			</DocumentUpdateContext.Provider>
		</DocumentContext.Provider>
	);
};

export { DocumentProvider };
export const DocumentConsumer = DocumentContext.Consumer;
