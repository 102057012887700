import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const acceptAnInvitation = async (code: string): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/invitations/accept`, {
			code,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const rejectAnInvitation = async (code: string): Promise<void> => {
	if (apiUrl) {
		return axios.post(`${apiUrl}/invitations/reject`, {
			code,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
