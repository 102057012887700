import './Dashboard.scss';
import { AssessmentOutlined, GroupOutlined, ListAltOutlined, SettingsOutlined } from '@material-ui/icons';
import { CardProps } from '../../components/Card/Card';
import CardsList from '../../components/CardsList';
// import Charts from './Charts/Charts';
// import Divider from '@material-ui/core/Divider';
import Header from '../../components/ContainerHeader/Header';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../components/Hooks/useRoutes';
import { UserRole } from '../../interfaces/User';
import { useTranslation } from 'react-i18next';
// import withPermissions from '../../helpers/HOC/withPermissions';

const Dashboard: React.FC = () => {
	const { TABS_ROUTES: ROUTES } = useRoutes();
	const { t } = useTranslation();
	const history = useHistory();

	const MOCKED_CARDS: Array<CardProps> = [
		{
			title: t('dashboardView:DASHBOARD_OPTION_FORMS'),
			onClick: (): void => history.push(ROUTES.DOCUMENTS),
			icon: AssessmentOutlined,
			rolesAllowed: [UserRole.SUPER_ADMIN, UserRole.TECHNICIAN],
		},
		{
			title: t('dashboardView:DASHBOARD_OPTION_RESOURCES'),
			onClick: (): void => history.push(ROUTES.RESOURCES),
			icon: ListAltOutlined,
			rolesAllowed: [UserRole.SUPER_ADMIN, UserRole.TECHNICIAN],
		},
		{
			title: t('dashboardView:DASHBOARD_OPTION_CONTENT'),
			onClick: (): void => history.push(ROUTES.CONTENT),
			icon: SettingsOutlined,
			rolesAllowed: [UserRole.SUPER_ADMIN, UserRole.MARKETING],
		},
		{
			title: t('dashboardView:DASHBOARD_OPTION_USERS'),
			onClick: (): void => history.push(ROUTES.USERS),
			icon: GroupOutlined,
			rolesAllowed: [UserRole.SUPER_ADMIN],
		},
		// {
		// 	title: t('dashboardView:DASHBOARD_OPTION_CLIENTS'),
		// 	onClick: (): void => history.push(ROUTES.CLIENTS),
		// 	icon: AccountBoxOutlined,
		// 	rolesAllowed: [UserRole.SUPER_ADMIN],
		// },
	];

	// const ChartsWithPermissions = withPermissions(Charts);

	return (
		<div className={'dashboard-container'}>
			<Header
				leftTopCornerProps={{
					title: t('dashboardView:DASHBOARD_TITLE'),
					isActionButtonVisible: false,
				}}
				rightTopCornerProps={{
					isFilterVisible: false,
				}}
			/>
			<CardsList cards={MOCKED_CARDS} />
			{/* <Divider className="divider--margin" />
			<ChartsWithPermissions rolesRequired={[UserRole.SUPER_ADMIN]} /> */}
		</div>
	);
};

export default Dashboard;
