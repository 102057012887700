import './CardsList.scss';
import Card from '../Card';
import { CardProps } from '../Card/Card';
import { Grid } from '@material-ui/core';
import React from 'react';
import withPermissions from '../../helpers/HOC/withPermissions';

interface CardsListProps {
	cards: Array<CardProps>;
}

const CardsList: React.FC<CardsListProps> = ({ cards }) => {
	const CardWithPermissions = withPermissions(Card);

	return (
		<Grid
			spacing={3}
			container
		>
			{
				cards.map((card, key) => (
					<Grid key={key} item xs={3} className="card-size">
						<CardWithPermissions
							title={card.title}
							onClick={card.onClick}
							icon={card.icon}
							rolesRequired={card.rolesAllowed || []}
						/>
					</Grid>
				))
			}
		</Grid >
	);
};

export default CardsList;
