import DefaultInput from '../../../Inputs/Input';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import useNumberDecimalPreview from './useNumberDecimalPreview';

const NumberDecimalPreview: React.FC<QuestionPreviewProps> = (props) => {
	const {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
		handleOnBlur,
	} = useNumberDecimalPreview(props);
	const { min, max, value, isValid } = question;

	return (
		<>
			<DefaultInput
				placeholder={`${min} - ${max}`}
				value={value}
				variant='outlined'
				onChange={handleOnChange}
				onBlur={handleOnBlur}
				isError={showErrors && !isValid}
				errorMessage={showErrors && !isValid ? errorMessage : ''}
				classes={{ root: 'input-number' }}
			/>
		</>
	);
};

export default NumberDecimalPreview;
