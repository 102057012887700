import './SubHeaderForm.scss';
import ActionButton, { ActionButtonProps } from '../Buttons/ActionButton/ActionButton';
import React from 'react';

interface SubHeaderProps {
	title: string;
	saveActionButtonProps: ActionButtonProps;
	previewActionButtonProps: ActionButtonProps;
	nextActionButtonProps: ActionButtonProps;
}

const SubHeaderForm: React.FC<SubHeaderProps> = (props) => {

	return (
		<div className='subheader-form'>
			<div className='title'>
				{
					props.title
				}
			</div>
			<div className='actions'>
				<ActionButton
					name={props.saveActionButtonProps.name}
					onClick={props.saveActionButtonProps.onClick}
					startIcon={props.saveActionButtonProps.startIcon}
					endIcon={props.saveActionButtonProps.endIcon}
					isDisabled={props.saveActionButtonProps.isDisabled}
					variant={props.saveActionButtonProps.variant}
				/>
				<ActionButton
					name={props.previewActionButtonProps.name}
					onClick={props.previewActionButtonProps.onClick}
					startIcon={props.previewActionButtonProps.startIcon}
					endIcon={props.previewActionButtonProps.endIcon}
					isDisabled={props.previewActionButtonProps.isDisabled}
					variant={props.previewActionButtonProps.variant}
				/>
				<ActionButton
					name={props.nextActionButtonProps.name}
					onClick={props.nextActionButtonProps.onClick}
					startIcon={props.nextActionButtonProps.startIcon}
					endIcon={props.nextActionButtonProps.endIcon}
					isDisabled={props.nextActionButtonProps.isDisabled}
					variant={props.nextActionButtonProps.variant}
				/>
			</div>
		</div>
	);
};

export default SubHeaderForm;
