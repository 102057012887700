import './DefaultInput.scss';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import classNames from 'classnames';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';

interface DefaultInputProps {
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	value?: string | number | undefined;
	label?: string;
	className?: string;
	defaultValue?: string;
	isDisabled?: boolean;
	isError?: boolean;
	errorMessage?: string;
	type?: string;
	placeholder?: string;
	variant?: TextFieldProps['variant'];
	isMultiline?: boolean;
	rowsMax?: number;
	removeMarginBotton?: boolean;
	classes?: object;
	maxLength?: number;
}

const DefaultInput: React.FC<DefaultInputProps> = ({ onChange, onBlur, value, label, className, defaultValue, isDisabled, isError, errorMessage, type, placeholder, variant, isMultiline, rowsMax, removeMarginBotton, classes, maxLength }) => {
	const ErrorAdornment = (
		<InputAdornment
			position={'end'}
		>
			<IconButton
				className={'adornment'}
				aria-label="error state"
			>
				<ErrorIcon />
			</IconButton>
		</InputAdornment>
	);

	const getProps = (
		variant: TextFieldProps['variant']
	): TextFieldProps => {
		return { variant };
	};

	return (
		<div className='default-input-container'>
			<TextField
				className='text-field'
				onChange={onChange}
				onBlur={onBlur}
				label={label}
				value={value}
				defaultValue={defaultValue}
				placeholder={placeholder}
				type={type}
				InputProps={{
					className: classNames('text-input', { [`${className}`]: true, 'text-input-error': isError }),
					endAdornment: isError ? ErrorAdornment : undefined,
				}}
				inputProps={{
					maxLength: maxLength || 150,
				}}
				disabled={isDisabled}
				error={isError}
				helperText={(isError && errorMessage) || (removeMarginBotton ? undefined : '\u00a0')}
				FormHelperTextProps={{
					className: classNames({ 'text-input-helper': !isError || errorMessage }),
				}}
				multiline={isMultiline}
				rowsMax={rowsMax}
				classes={classes}
				{...getProps(variant)}
			/>
		</div>
	);
};

export default DefaultInput;
