import './NumberDecimal.scss';
import DefaultInput from '../../Inputs/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NumberDecimalInterface {
	title: string;
	code: string;
	description?: string;
	min: number;
	max: number;
	minString: string;
	maxString: string;
	handleOnChangeTitle: (value: string) => void;
	handleOnChangeDescription: (value: string) => void;
	handleOnChangeMin: (value: string) => void;
	handleOnChangeMax: (value: string) => void;
}

const NumberDecimal: React.FC<NumberDecimalInterface> = (props) => {
	const { t } = useTranslation();

	return (
		<div className='number-decimal-question'>
			<div className='title'>
				<DefaultInput placeholder={t('formsView:FIELD_TITLE')} value={props.title} label={t('formsView:FIELD_TITLE')} variant='outlined' onChange={(e): void => props.handleOnChangeTitle(e.target.value)} />
				<DefaultInput placeholder={t('formsView:FIELD_CODE')} value={props.code} label={t('formsView:FIELD_CODE')} variant='outlined' isDisabled={true} />
				{props.description !== undefined && <DefaultInput placeholder={t('formsView:FIELD_DESCRIPTION')} value={props.description} label={t('formsView:FIELD_DESCRIPTION')} variant='outlined' onChange={(e): void => props.handleOnChangeDescription(e.target.value)} />}
			</div>
			<div className='validation'>
				<DefaultInput placeholder={t('formsView:FIELD_MIN')} value={props.minString} label={t('formsView:FIELD_MIN')} variant='outlined' onChange={(e): void => props.handleOnChangeMin(e.target.value)} />
				<DefaultInput placeholder={t('formsView:FIELD_MAX')} value={props.maxString} label={t('formsView:FIELD_MAX')} variant='outlined' onChange={(e): void => props.handleOnChangeMax(e.target.value)} />
			</div>
		</div>
	);
};

export default React.memo(NumberDecimal);
