import { DocumentContext, DocumentUpdateContext } from '../../../../contexts/DocumentContext';
import { CheckboxQuestion } from '../../../../interfaces/Documents/Questions/CheckboxQuestion';
import { QuestionPreviewProps } from '../PreviewWrapper';
import React from 'react';
import { Section } from '../../../../interfaces/Documents/Section';
import { useTranslation } from 'react-i18next';

interface CheckboxPreviewInterface {
	question: CheckboxQuestion;
	errorMessage: string;
	showErrors: boolean;
	handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface TCheckbox {
	[name: string]: boolean;
}

const hasMinCheckedBoxes = (value: Partial<TCheckbox>, min: number): boolean => {
	const checkedValues = Object.keys(value).filter(index => value[index.toString()]);

	return checkedValues.length >= min;
};

const useCheckboxPreview = (props: QuestionPreviewProps): CheckboxPreviewInterface => {
	const { t } = useTranslation();
	const { documentModel, documentModel: { showErrors } } = React.useContext(DocumentContext);
	const { handleUpdateQuestion } = React.useContext(DocumentUpdateContext);
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const question = documentModel.sections[props.sectionIndex].questions[props.questionIndex] as CheckboxQuestion;

	React.useEffect(() => {
		const isValid = !question.required || hasMinCheckedBoxes(question.value, question.min);

		setErrorMessage(t('formsView:MODAL_PREVIEW_FIELD_REQUIRED'));

		handleUpdateQuestion({ ...question, isValid }, documentModel.sections[props.sectionIndex] as Section);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question.required]);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, checked } = event.target;
		const value = question.value;
		const itemsChecked = Object.values(value).filter(val => val).length;
		const isReplacement = Object.keys(value).includes(name);

		if (itemsChecked < question.max || (isReplacement && !checked)) {
			const valueToUpdate = { ...value, [name]: checked };
			const isValid = hasMinCheckedBoxes(valueToUpdate, question.min);

			setErrorMessage(isValid ? '' : `${t('formsView:MODAL_PREVIEW_MIN_CHECKBOX_VALIDATION')} ${question.min}`);
			handleUpdateQuestion({ ...question, value: valueToUpdate, isValid }, documentModel.sections[props.sectionIndex] as Section);
		}
	};

	return {
		question,
		errorMessage,
		showErrors,
		handleOnChange,
	};
};

export default useCheckboxPreview;
