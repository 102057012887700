import './SwitchOperator.scss';
import { Button, IconButton } from '@material-ui/core';
import { OptionVariable, VariableType } from '../../../interfaces/Documents/Variables/Variable';
import { Close } from '@material-ui/icons';
import DefaultSelect from '../../Inputs/Select';
import React from 'react';
import { SwitchCustomVariable } from '../../../interfaces/Documents/CustomVariables/SwitchCustomVariable';
import useSwitchOperator from './useSwitchOperator';
import { useTranslation } from 'react-i18next';
import VariableOption from '../VariableOption/VariableOption';

export interface SwitchOperatorProps {
	variableData: SwitchCustomVariable;
	handleUpdateVariableData: (variable: SwitchCustomVariable) => void;
	variables: OptionVariable[];
	arethereCircularReferences: (variableId: string, newReferenceId: string) => boolean;
}

const SwitchOperator: React.FC<SwitchOperatorProps> = (props) => {
	const { t } = useTranslation();

	const {
		handleChangeSwitchVariable,
		handleUpdateVariableValue,
		handleAddVariableValue,
		handleDeleteVariableValue,
		allVariables,
		thenVariableList,
		whenVariableList,
		handleChangeVariableResult,
		switchValueVariableType,
	} = useSwitchOperator(props);

	return (
		<div className='switch-operator'>
			<div className='title'>{t('variableModal:TITILE_SPECS')}</div>
			<div className='operator'>
				<div className='operator-title'>
					{t('variableModal:OPTION_VARIABLE')}
				</div>
				<DefaultSelect
					onChange={(e): void => handleChangeSwitchVariable(e.target.value as string)}
					value={props.variableData.specs?.switchValue || ''}
					options={allVariables}
					isFullWidth
					removeMarginBotton
				/>
			</div>
			<div className='operator'>
				<div className='operator-title'>
					{t('variableModal:OPTION_VARIABLE_TYPE')}
				</div>
				<DefaultSelect
					onChange={(e): void => handleChangeVariableResult(e.target.value as VariableType)}
					value={props.variableData.type || undefined}
					options={[
						{ key: VariableType.Boolean, value: t('variableModal:OPTION_VARIABLE_TYPE_BOOLEAN') },
						{ key: VariableType.Text, value: t('variableModal:OPTION_VARIABLE_TYPE_TEXT') },
						{ key: VariableType.Number, value: t('variableModal:OPTION_VARIABLE_TYPE_NUMBER') },
						{ key: VariableType.Date, value: t('variableModal:OPTION_VARIABLE_TYPE_DATE') },
					]}
					isFullWidth
					removeMarginBotton
				/>
			</div>
			{
				props.variableData.type && props.variableData.specs?.switchValue && (
					<div className='options'>
						{
							props.variableData.specs && props.variableData.specs.values && props.variableData.specs.values.length > 0 && (
								props.variableData.specs?.values.map((item, key) => {
									return (
										<div className='option' key={key}>
											<div className='label-when'>
												{t('variableModal:LABEL_SWITCH_WHEN')}
											</div>
											<div className='fields'>
												<VariableOption
													key={key}
													valueDataType={item.valueType}
													valueDataTypeString='valueType'
													valueDataValue={item.value}
													valueDataValueString='value'
													valueDataOrder={item.order}
													handleUpdateVariableValue={handleUpdateVariableValue}
													variables={whenVariableList}
													areConstantsAllowed
													isDataTypeDisabled={switchValueVariableType === VariableType.Boolean}
													isDataValueDisabled={switchValueVariableType === VariableType.Boolean}
													isValueDataValueDate={switchValueVariableType === VariableType.Date}
												/>
											</div>
											<div className='label-then'>
												{t('variableModal:LABEL_SWITCH_THEN')}
											</div>
											<div className='fields'>
												<VariableOption
													key={key}
													valueDataType={item.thenType}
													valueDataTypeString='thenType'
													valueDataValue={item.thenValue}
													valueDataValueString='thenValue'
													valueDataOrder={item.order}
													handleUpdateVariableValue={handleUpdateVariableValue}
													variables={thenVariableList}
													areConstantsAllowed
													isValueDataValueDate={props.variableData.type === VariableType.Date}
													isValueDataValueBoolean={props.variableData.type === VariableType.Boolean}
												/>
											</div>
											{
												switchValueVariableType !== VariableType.Boolean && props.variableData.specs && props.variableData.specs.values && props.variableData.specs?.values.length > 1 && (
													<IconButton
														className='close-button'
														onClick={(): void => handleDeleteVariableValue(item.order)}
													>
														<Close />
													</IconButton>
												)
											}
										</div>
									);
								})
							)
						}
						{
							props.variableData.specs && switchValueVariableType !== VariableType.Boolean && (
								<div className='default-switch-option'>
									<div className='label-default'>
										{t('variableModal:LABEL_SWITCH_DEFAULT')}
									</div>
									<div className='default-option'>
										<VariableOption
											valueDataType={props.variableData.specs.defaultType}
											valueDataTypeString='defaultType'
											valueDataValue={props.variableData.specs.defaultValue}
											valueDataValueString='defaultValue'
											valueDataOrder={0}
											handleUpdateVariableValue={handleUpdateVariableValue}
											variables={thenVariableList}
											areConstantsAllowed
											isValueDataValueDate={props.variableData.type === VariableType.Date}
											isValueDataValueBoolean={props.variableData.type === VariableType.Boolean}
										/>
									</div>
								</div>
							)
						}
						{
							switchValueVariableType !== 'boolean' && (
								<div className='add-option'>
									<Button
										onClick={handleAddVariableValue}
									>
										{t('variableModal:ADD_PARAMETER')}
									</Button>
								</div>
							)
						}
					</div>
				)
			}
		</div>
	);
};

export default SwitchOperator;
