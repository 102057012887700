import './LogicOperator.scss';
import { Button, IconButton } from '@material-ui/core';
import { LogicCustomVariable, LogicOperationType } from '../../../interfaces/Documents/CustomVariables/LogicCustomVariable';
import { OptionVariable, VariableType } from '../../../interfaces/Documents/Variables/Variable';
import { Close } from '@material-ui/icons';
import DefaultSelect from '../../Inputs/Select';
import React from 'react';
import useLogicOperator from './useLogicOperator';
import { useTranslation } from 'react-i18next';
import VariableOption from '../VariableOption/VariableOption';

export interface LogicOperatorProps {
	variableData: LogicCustomVariable;
	handleUpdateVariableData: (variable: LogicCustomVariable) => void;
	variables: OptionVariable[];
	arethereCircularReferences: (variableId: string, newReferenceId: string) => boolean;
}

const LogicOperator: React.FC<LogicOperatorProps> = (props) => {
	const { t } = useTranslation();

	const {
		handleChangeOperation,
		handleUpdateVariableValue,
		handleAddVariableValue,
		handleDeleteVariableValue,
		variableList,
		onlyTwoOptions,
		handleChangeVariableResult,
		variableResultOptions,
		variableTypeToCheck,
	} = useLogicOperator(props);

	return (
		<div className='logic-operator'>
			<div className='title'>{t('variableModal:TITILE_SPECS')}</div>
			<div className='operator'>
				<div className='operator-title'>
					{t('variableModal:LABEL_OPERATION')}
				</div>
				<DefaultSelect onChange={(e): void => handleChangeOperation(e.target.value as LogicOperationType)}
					value={props.variableData.specs?.operation || 'and'}
					options={[
						{ key: 'and', value: t('variableModal:OPTION_OPERATION_AND') },
						{ key: 'or', value: t('variableModal:OPTION_OPERATION_OR') },
						{ key: '>', value: t('variableModal:OPTION_OPERATION_MAJOR') },
						{ key: '<', value: t('variableModal:OPTION_OPERATION_MINOR') },
						{ key: '>=', value: t('variableModal:OPTION_OPERATION_MAJOR_EQUAL') },
						{ key: '<=', value: t('variableModal:OPTION_OPERATION_MINOR_EQUAL') },
						{ key: '==', value: t('variableModal:OPTION_OPERATION_EQUAL') },
						{ key: '!=', value: t('variableModal:OPTION_OPERATION_DIFFERENT') },
					]}
					isFullWidth
					removeMarginBotton
				/>
			</div>
			{ onlyTwoOptions &&
				(
					<div className='operator'>
						<div className='operator-title'>
							{t('variableModal:LABEL_COMPARISON_TYPE')}
						</div>
						<DefaultSelect
							onChange={(e): void => handleChangeVariableResult(e.target.value as VariableType)}
							value={variableTypeToCheck || undefined}
							options={variableResultOptions}
							isFullWidth
							removeMarginBotton
						/>
					</div>
				)
			}
			{ props.variableData.specs && props.variableData.specs.operation && variableTypeToCheck &&
				(
					<div className='options'>
						{
							props.variableData.specs && props.variableData.specs.values && props.variableData.specs.values.length > 0 &&
							(
								props.variableData.specs?.values.map((item, key) => {
									return (
										<div key={key}>
											<div className='option'>
												<div className='fields'>
													<VariableOption
														key={key}
														valueDataType={item.valueType}
														valueDataTypeString='valueType'
														valueDataValue={item.value}
														valueDataValueString='value'
														valueDataOrder={item.order}
														handleUpdateVariableValue={handleUpdateVariableValue}
														variables={variableList}
														areConstantsAllowed={onlyTwoOptions}
														isValueDataValueDate={variableTypeToCheck === 'date'}
														isValueDataValueBoolean={variableTypeToCheck === 'boolean'}
													/>
												</div>
												{
													props.variableData.specs && props.variableData.specs.values && props.variableData.specs?.values.length > 2 &&
													(
														<IconButton
															className='close-button'
															onClick={(): void => handleDeleteVariableValue(item.order)}
														>
															<Close />
														</IconButton>
													)
												}
											</div>
											<div className='opertation-symbol'>
												{
													onlyTwoOptions && key === 0 && props.variableData.specs?.operation
												}
											</div>
										</div>
									);
								})
							)
						}
						{
							!onlyTwoOptions
							&& (
								<div className='add-option'>
									<Button
										onClick={handleAddVariableValue}
									>
										{t('variableModal:ADD_PARAMETER')}
									</Button>
								</div>
							)
						}
					</div>
				)
			}
		</div>
	);
};

export default LogicOperator;
