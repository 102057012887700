import { AuthContext, AuthUpdateContext } from '../../contexts/AuthContext';
import { setAuthToken, setRefreshToken } from '../../services/SessionStorageService';
import React from 'react';
import { signUpWithPassword } from '../../services/AuthService';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../helpers/Hooks/useQueryParams';
import useRoutes from '../../components/Hooks/useRoutes';
import { useTranslation } from 'react-i18next';

interface SignUpStore {
	handleSignUpClick: (email: string | undefined, password: string | undefined) => void;
	isAuth: boolean;
	name: string | undefined;
	nameError: boolean;
	nameErrorMessage: string | undefined;
	signUpError: string | undefined;
	validateName: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useSignUp = (): SignUpStore => {
	const { t } = useTranslation();
	const history = useHistory();
	const query = useQueryParams();
	const { ROUTES } = useRoutes();

	const [signUpError, setSignUpError] = React.useState<string | undefined>(undefined);
	const [name, setName] = React.useState<string | undefined>(undefined);
	const [nameError, setNameError] = React.useState<boolean>(false);
	const [nameErrorMessage, setNameErrorMessage] = React.useState<string | undefined>(undefined);
	const { setIsAuth } = React.useContext(AuthUpdateContext);
	const { isAuth } = React.useContext(AuthContext);

	const handleUserError = (error: string): string => {
		switch (error) {
			case 'User not found.':
				return t('view:SIGNIN_USER_NOT_FOUND');
			case 'The user exists already.':
				return t('view:SIGNIN_USER_ALREADY_EXISTS');
			case 'API url not defined':
				return 'API NOT DEFINED / API NO DEFINIDA';
			default:
				return error || 'UNKNOWN ERROR / ERROR DESCONOCIDO';
		}
	};

	const handleSignUpClick = async (email: string | undefined, password: string | undefined): Promise<void> => {
		if (name && email && password && typeof query.code === 'string') {
			try {
				const signUpResult = await signUpWithPassword(name, {
					username: email,
					password,
				}, query.code);

				setAuthToken(signUpResult.access_token);
				setRefreshToken(signUpResult.refresh_token);
				setIsAuth(true);
				history.push(ROUTES.DASHBOARD);
			} catch (error) {
				setSignUpError(handleUserError(error.message));
			}
		}
	};

	const validateName = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setName(event.target.value);

		const error = event.target.value.length < 1;

		setNameError(error);
		setNameErrorMessage(error ? 'El nombre no es válido' : undefined);
	};

	React.useEffect(() => {
		if (!query.code && typeof query.code === 'string') {
			history.push(ROUTES.ERROR);
		}
	}, [history, query, ROUTES]);

	return {
		handleSignUpClick,
		isAuth,
		name,
		nameError,
		nameErrorMessage,
		signUpError,
		validateName,
	};

};
