import { acceptInvitation, rejectInvitation } from '../../services/InvitationService';
import { AlertContext } from '../../contexts/AlertContext';
import { AuthContext } from '../../contexts/AuthContext';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../helpers/Hooks/useQueryParams';
import useRoutes from '../../components/Hooks/useRoutes';

interface InvitationStore {
	handleAcceptClick: () => Promise<void>;
	handleRejectClick: () => Promise<void>;
	handleSignInClick: () => Promise<void>;
	handleSignUpClick: () => Promise<void>;
	isAuth: boolean;
}

const useInvitation = (): InvitationStore => {
	const history = useHistory();
	const { isAuth } = React.useContext(AuthContext);
	const query = useQueryParams();
	const { ROUTES } = useRoutes();
	const { showDefaultError } = React.useContext(AlertContext);

	const handleAcceptClick = async (): Promise<void> => {
		try {
			await acceptInvitation(query.code as string);
			history.push(ROUTES.DASHBOARD);
		} catch (error) {
			showDefaultError();
		}
	};

	const handleRejectClick = async (): Promise<void> => {
		try {
			await rejectInvitation(query.code as string);
			history.push(ROUTES.DASHBOARD);
		} catch (error) {
			showDefaultError();
		}
	};

	const handleSignInClick = async (): Promise<void> => {
		history.push(`${ROUTES.SIGNIN}?code=${query.code}`);
	};

	const handleSignUpClick = async (): Promise<void> => {
		history.push(`${ROUTES.SIGNUP}?code=${query.code}`);
	};

	React.useEffect(() => {
		if (!query.code && typeof query.code === 'string') {
			history.push(ROUTES.ERROR);
		}
	}, [history, query, ROUTES]);

	return {
		handleAcceptClick,
		handleRejectClick,
		handleSignInClick,
		handleSignUpClick,
		isAuth,
	};
};

export default useInvitation;
