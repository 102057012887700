import './Users.scss';
import { Add, EditOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { Avatar, FormControl, IconButton, ListItem, ListItemAvatar, TableBody } from '@material-ui/core';
import Cell from '../../components/Cell';
import DefaultInput from '../../components/Inputs/Input';
import DefaultSelect from '../../components/Inputs/Select';
import DefaultTable from '../../components/Table';
import { getRoleName } from '../../services/UserService';
import Header from '../../components/ContainerHeader/Header';
import Modal from '../../components/Modal';
import React from 'react';
import Row from '../../components/Row';
import { useTranslation } from 'react-i18next';
import useUser from './Hooks/useUser';

const Users: React.FC = () => {
	const { t } = useTranslation();
	const {
		editableRowId,
		editRow,
		emailError,
		emailErrorMessage,
		emailValue,
		enableDisableRow,
		handleCloseDialog,
		handleOpenDialog,
		handleSave,
		invitations,
		openModal,
		roles,
		roleSelected,
		roleValue,
		setRoleFilter,
		users,
		validateEmail,
		validateExistingUserRole,
		validateNewUserRole,
	} = useUser();

	return (
		<div className='user-container'>
			<Header
				leftTopCornerProps={{
					title: t('userView:HEADER_TITLE'),
					isActionButtonVisible: true,
					actionButtonProps: {
						onClick: handleOpenDialog,
						name: t('userView:HEADER_ACTION_BUTTON'),
						startIcon: (<Add />),
						variant: 'contained',
					},
				}}
				rightTopCornerProps={{
					isFilterVisible: true,
					filterProps: {
						label: t('userView:HEADER_FILTER_LABEL'),
						value: roleSelected?.toString() || '-1',
						onChange: setRoleFilter,
						options: [{ key: -1, value: '-' }, ...roles],
					},
				}}
			/>
			<DefaultTable>
				<TableBody>
					{
						users.filter(item => roleSelected === -1 || roleSelected === item.role).map((item, key) => {
							return (
								<Row key={key} isDisabled={item.isDisabled} >
									<Cell className='size-cell-large' align='left'>
										<ListItem alignItems='center'>
											<ListItemAvatar>
												<Avatar src={item.avatarUrl} />
											</ListItemAvatar>
											{item.name}
										</ListItem>
									</Cell>
									<Cell className='size-cell-medium' align='left'>
										{
											editableRowId === item.id
												? (
													<DefaultSelect options={roles} value={item.role} onChange={(e): void => validateExistingUserRole(item, e.target.value)} />
												)
												: (
													<>{t(`roles:${getRoleName(item.role)}`)}</>
												)
										}
									</Cell>
									<Cell className='size-cell-medium' align='right'>{item.updatedAt ? new Date(item.updatedAt).toLocaleDateString() : new Date().toLocaleDateString()}</Cell>
									<Cell className='size-cell-large' align='right'>
										<IconButton
											className={`${editableRowId === item.id ? 'icon-darker' : ''}`}
											onClick={(): void => editRow(item)}
										>
											<EditOutlined className='icon' />
										</IconButton>
										<IconButton
											onClick={(): void => enableDisableRow(item)}
										>
											{
												item.isDisabled ? <VisibilityOffOutlined className='icon' /> : <VisibilityOutlined className='icon' />
											}
										</IconButton>
									</Cell>
								</Row>
							);
						})
					}
					{
						invitations.filter(item => roleSelected === -1 || roleSelected === item.role).map((item, key) => {
							return (
								<Row key={key}>
									<Cell className='size-cell-large' align='left'>
										<ListItem alignItems='center'>
											<ListItemAvatar>
												<Avatar />
											</ListItemAvatar>
											{item.mail}
										</ListItem>
									</Cell>
									<Cell className='size-cell-medium' align='left'>
										<>{t(`roles:${getRoleName(item.role)}`)}</>
									</Cell>
									<Cell className='size-cell-medium' align='right'>
										<>{t('userView:INVITATION')}</>
									</Cell>
									<Cell />
								</Row>
							);
						})
					}
				</TableBody>
			</DefaultTable>
			<Modal
				size='md'
				title={t('userView:MODAL_CREATE_TITLE')}
				open={openModal}
				handleOnClose={handleCloseDialog}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: t('userView:MODAL_CREATE_PRIMARY_BUTTON'),
					handleOnclick: handleSave,
					isDisabled: (emailError || !emailValue || roleValue == null),
				}}
				isSecondaryButtonVisible={true}
				secondaryButtonProps={{
					name: t('userView:MODAL_CREATE_SECONDARY_BUTTON'),
					handleOnclick: handleCloseDialog,
				}}
			>
				<FormControl className='add-user-modal' fullWidth margin='normal'>
					<DefaultInput value={emailValue} label={t('userView:MODAL_CREATE_MAIL')} onChange={validateEmail} isError={emailError} errorMessage={emailErrorMessage} type='email' variant='outlined' />
					<DefaultSelect placeholder={t('userView:MODAL_CREATE_ROLE')} options={roles} value={roleValue} onChange={validateNewUserRole} isFullWidth={true} />
				</FormControl>
			</Modal>
		</div>
	);
};

export default Users;
