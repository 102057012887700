import './PreviewModal.scss';
import BooleanPreview from './Questions/BooleanPreview/BooleanPreview';
import CheckboxPreview from './Questions/CheckboxPreview/CheckboxPreview';
import DatePreview from './Questions/DatePreview/DatePreview';
import { Document } from '../../interfaces/Documents/Document';
import NumberDecimalPreview from './Questions/NumberDecimal/NumberDecimalPreview';
import NumberIntegerPreview from './Questions/NumberInteger/NumberIntegerPreview';
import PreviewWrapper from './Questions/PreviewWrapper';
import RadioPreview from './Questions/RadioPreview/RadioPreview';
import React from 'react';
import SelectPreview from './Questions/SelectPreview/SelectPreview';
import TextPreview from './Questions/TextPreview';

interface FormPreviewProps {
	sectionToShow: string;
	documentModel: Document;
	onSave?: () => void;
	onCancel?: () => void;
}

const componentsPreview = {
	TextQuestion: TextPreview,
	NumberIntegerQuestion: NumberIntegerPreview,
	NumberDecimalQuestion: NumberDecimalPreview,
	DateQuestion: DatePreview,
	RadioQuestion: RadioPreview,
	SelectQuestion: SelectPreview,
	CheckboxQuestion: CheckboxPreview,
	BooleanQuestion: BooleanPreview,
};

const FormPreview: React.FC<FormPreviewProps> = (props) => {
	const renderQuestion = (questionIndex: number, sectionIndex: number): JSX.Element => {
		const { title, description, required, type } = props.documentModel.sections[sectionIndex].questions[questionIndex];

		const commonProps = {
			questionIndex: questionIndex,
			sectionIndex: sectionIndex,
		};

		const Component = componentsPreview[type];

		return (
			<PreviewWrapper title={title} description={description} required={required}>
				<Component {...commonProps} />
			</PreviewWrapper>
		);
	};

	return (
		<div className="content">
			<div className="form">
				<div className="form__title">{props.documentModel.name}</div>
				{
					props.documentModel.description && <div className="form__description">{props.documentModel.description}</div>
				}
			</div>
			{
				props.documentModel.sections
					.map((section, sectionIndex) => {
						if (section.id === props.sectionToShow) {
							return (
								<div key={section.id} className="section">
									<div className="section__header">
										<div className="section__title">{section.title}</div>
										{section.description && <div className="section__description">{section.description}</div>}
									</div>
									{
										section.questions.map((question, questionIndex) => (
											<div key={question.id}>
												{renderQuestion(questionIndex, sectionIndex)}
											</div>
										))
									}
								</div>
							);
						}
					})}
		</div>
	);
};

export default FormPreview;
