import './BlogCard.scss';
import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import { enUS, es } from 'date-fns/esm/locale';
import { format } from 'date-fns';
import { Post } from '../../interfaces/Post';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BlogCardProps extends Post {
	onClick: () => void;
}

const BlogCard: React.FC<BlogCardProps> = (props) => {
	const { t, i18n } = useTranslation();

	return (
		<Card className='blog-card'>
			<CardActionArea
				className='content-area'
				onClick={props.onClick}
			>
				<CardContent
					className='card-content'
				>
					<div className='top-section'>
						<div className='title'>
							{props.title}
						</div>
						<div className='content' dangerouslySetInnerHTML={{ __html: props.content }}>
						</div>
					</div>
					<div className='bottom-section'>
						<div className='bottom-edited'>
							{`${t('blogView:LABEL_EDITED_BY')} `}
							<span className='author-name'>{props.user?.name}</span>
						</div>
						<div className='bottom-date'>
							{
								props.updatedAt && format(new Date(props.updatedAt), 'PPP', { locale: i18n.language.startsWith('es') ? es : enUS })
							}
						</div>
					</div>
				</CardContent>
				<div className='card-media-content'>
					<CardMedia
						className='card-media'
						image={props.imageUrl}
					/>
				</div>
			</CardActionArea>
		</Card >
	);
};

export default BlogCard;
