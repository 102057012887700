import './VariableOption.scss';
import { DatePicker } from '@material-ui/pickers';
import DefaultInput from '../../Inputs/Input';
import DefaultSelect from '../../Inputs/Select';
import { Option as KeyOption } from '../../../interfaces/Common';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface VariableOptionProps {
	valueDataType: string | undefined;
	valueDataTypeString: string;
	valueDataValue: string | number | undefined;
	valueDataValueString: string;
	valueDataOrder: number;
	handleUpdateVariableValue: (name: string, value: string, order: number) => void;
	variables: KeyOption[];
	areConstantsAllowed?: boolean;
	isDataTypeDisabled?: boolean;
	isDataValueDisabled?: boolean;
	isValueDataValueDate?: boolean;
	isValueDataValueBoolean?: boolean;
}

const VariableOption: React.FC<VariableOptionProps> = (props) => {
	const { t } = useTranslation();

	return (
		<div className='variable-option'>
			<div className='variable-label'>
				{
					props.areConstantsAllowed &&
					(
						<DefaultSelect
							onChange={(e): void => props.handleUpdateVariableValue(props.valueDataTypeString, e.target.value as string, props.valueDataOrder)}
							value={props.valueDataType || 'constant'}
							options={[
								{ key: 'constant', value: t('variableModal:OPTION_CONSTANT') },
								{ key: 'variable', value: t('variableModal:OPTION_VARIABLE') },
							]}
							isFullWidth
							removeMarginBotton
							isDisabled={props.isDataTypeDisabled}
						/>
					)
				}
				{
					!props.areConstantsAllowed &&
					(
						<DefaultInput
							value={`${t('variableModal:OPTION_VARIABLE')}`}
							variant='outlined'
							isDisabled
							removeMarginBotton
						/>
					)
				}
			</div>
			<div className='option-variable'>
				{
					(props.valueDataType) === 'constant' && props.isValueDataValueDate
					&& (
						<DatePicker
							value={props.valueDataValue ? new Date(props.valueDataValue) : new Date()}
							onChange={(newDate): void => props.handleUpdateVariableValue(props.valueDataValueString, newDate?.toDateString() || new Date().toDateString(), props.valueDataOrder)}
							autoOk
							format='dd/MM/yyyy'
							inputVariant='outlined'
							variant='inline'
						/>
					)
				}
				{
					(props.valueDataType) === 'constant' && props.isValueDataValueBoolean
					&& (
						<DefaultSelect
							value={props.valueDataValue}
							onChange={(e): void => props.handleUpdateVariableValue(props.valueDataValueString, e.target.value as string, props.valueDataOrder)}
							options={[
								{ key: 'true', value: t('variableModal:LABEL_OPTION_TRUE') },
								{ key: 'false', value: t('variableModal:LABEL_OPTION_FALSE') },
							]}
							isFullWidth
							removeMarginBotton
							isDisabled={props.isDataValueDisabled}
						/>
					)
				}
				{
					(props.valueDataType) === 'constant' && !props.isValueDataValueDate && !props.isValueDataValueBoolean
					&& (
						<DefaultInput
							value={props.valueDataValue}
							onChange={(e): void => props.handleUpdateVariableValue(props.valueDataValueString, e.target.value as string, props.valueDataOrder)}
							variant='outlined'
							removeMarginBotton
							isDisabled={props.isDataValueDisabled}
						/>
					)
				}
				{
					(props.valueDataType) === 'variable'
					&& (
						<DefaultSelect
							value={props.valueDataValue}
							onChange={(e): void => props.handleUpdateVariableValue(props.valueDataValueString, e.target.value as string, props.valueDataOrder)}
							options={props.variables}
							isFullWidth
							removeMarginBotton
							isDisabled={props.isDataValueDisabled}
						/>
					)
				}
			</div>
		</div>
	);
};

export default VariableOption;
