import { DocumentContext, DocumentUpdateContext } from '../../../../contexts/DocumentContext';
import { AlertContext } from '../../../../contexts/AlertContext';
import { CreateFormProps } from './CreateForm';
import { getTags } from '../../../../api/DocumentRepository';
import { Option } from '../../../../interfaces/Documents/Questions/Options';
import { QuestionTypes } from '../../../../interfaces/Documents/Questions/Question';
import React from 'react';
import { ReorderType } from '../../../../interfaces/Documents/Document';
import { Section } from '../../../../interfaces/Documents/Section';
import { Tag } from '../../../../interfaces/Tag';

interface CreateFormInterface {
	tags: Array<Tag>;
	openReorderModal: boolean;
	anchorEl: null | HTMLElement;
	handleOpenReorderModal: () => void;
	handleCloseReorderModal: () => void;
	handleOpenSectionMenu: (event: React.MouseEvent<HTMLElement>) => void;
	handleCloseSectionMenu: () => void;
	handleReorderSection: (itemList: (Section | QuestionTypes | Option)[]) => void;
	openWarningMessage: boolean;
	handleOpenWarningMessage: () => void;
	handleCloseWarningMessage: () => void;
	handleDelete: () => void;
	handleDeleteSection: () => void;
	handleNextStep: () => void;
	handleSave: () => void;
	openPreviewModal: boolean;
	handleOpenPreviewModal: () => void;
	handleClosePreviewModal: () => void;
}

const useCreateForm = (props: CreateFormProps): CreateFormInterface => {
	const { actionData } = React.useContext(DocumentContext);
	const { handleDeleteSectionOrQuestion, handleReorder, handleSaveDocument } = React.useContext(DocumentUpdateContext);
	const [tags, setTags] = React.useState<Array<Tag>>([]);
	const [openReorderModal, setOpenReorderModal] = React.useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openWarningMessage, setOpenWarningMessage] = React.useState<boolean>(false);
	const [openPreviewModal, setOpenPreviewModal] = React.useState<boolean>(false);
	const { showDefaultError } = React.useContext(AlertContext);

	const loadTags = React.useCallback(async (): Promise<void> => {
		try {
			setTags(await getTags());
		} catch (e) {
			showDefaultError();
		}
	}, [showDefaultError]);

	React.useEffect(() => {
		loadTags();
	}, [loadTags]);

	const handleOpenSectionMenu = (event: React.MouseEvent<HTMLElement>): void => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleCloseSectionMenu = (): void => {
		setAnchorEl(null);
	};

	const handleOpenReorderModal = (): void => {
		setOpenReorderModal(true);
		handleCloseSectionMenu();
	};

	const handleCloseReorderModal = (): void => {
		setOpenReorderModal(false);
	};

	const handleReorderSection = (itemList: (Section | QuestionTypes | Option)[]): void => {
		handleReorder(itemList, ReorderType.Section, -1, -1);
		handleCloseReorderModal();
	};

	const handleOpenWarningMessage = (): void => {
		setOpenWarningMessage(true);
	};

	const handleCloseWarningMessage = (): void => {
		setOpenWarningMessage(false);
	};

	const handleDelete = (): void => {
		if (actionData.sectionOrder > 0 && actionData.questionOrder < 0) {
			handleOpenWarningMessage();
		} else {
			handleDeleteSectionOrQuestion();
		}
	};

	const handleDeleteSection = (): void => {
		handleDeleteSectionOrQuestion();
		handleCloseWarningMessage();
	};

	const handleNextStep = async (): Promise<void> => {
		try {
			props.handleNext();
		} catch (e) {
			showDefaultError();
		}
	};

	const handleSave = async (): Promise<void> => {
		try {
			await handleSaveDocument();
		} catch (e) {
			showDefaultError();
		}
	};

	return {
		tags,
		openReorderModal,
		anchorEl,
		handleOpenReorderModal,
		handleCloseReorderModal,
		handleOpenSectionMenu,
		handleCloseSectionMenu,
		handleReorderSection,
		openWarningMessage,
		handleOpenWarningMessage,
		handleCloseWarningMessage,
		handleDelete,
		handleDeleteSection,
		handleNextStep,
		handleSave,
		openPreviewModal,
		handleOpenPreviewModal: (): void => setOpenPreviewModal(true),
		handleClosePreviewModal: (): void => setOpenPreviewModal(false),
	};
};

export default useCreateForm;
