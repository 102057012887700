import './MathematicalOperator.scss';
import { Button, IconButton } from '@material-ui/core';
import { MathematicalCustomVariable, MathematicalOperationType } from '../../../interfaces/Documents/CustomVariables/MathematicalCustomVariable';
import { Close } from '@material-ui/icons';
import DefaultSelect from '../../Inputs/Select';
import { OptionVariable } from '../../../interfaces/Documents/Variables/Variable';
import React from 'react';
import useMathematicalOperator from './useMathematicalOperator';
import { useTranslation } from 'react-i18next';
import VariableOption from '../VariableOption/VariableOption';

export interface MathematicalOperatorProps {
	variableData: MathematicalCustomVariable;
	handleUpdateVariableData: (variable: MathematicalCustomVariable) => void;
	variables: OptionVariable[];
	arethereCircularReferences: (variableId: string, newReferenceId: string) => boolean;
}

const MathematicalOperator: React.FC<MathematicalOperatorProps> = (props) => {
	const { t } = useTranslation();

	const {
		handleChangeOperation,
		handleUpdateVariableValue,
		handleAddVariableValue,
		handleDeleteVariableValue,
		variableList,
	} = useMathematicalOperator(props);

	return (
		<div className='mathematical-operator'>
			<div className='title'>{t('variableModal:TITILE_SPECS')}</div>
			<div className='operator'>
				<DefaultSelect onChange={(e): void => handleChangeOperation(e.target.value as MathematicalOperationType)}
					value={props.variableData.specs?.operation || MathematicalOperationType.Sum}
					options={[
						{ key: MathematicalOperationType.Sum, value: t('variableModal:OPTION_OPERATION_SUM') },
						{ key: MathematicalOperationType.Substract, value: t('variableModal:OPTION_OPERATION_SUBTRACTION') },
						{ key: MathematicalOperationType.Multiplication, value: t('variableModal:OPTION_OPERATION_MULTIPLICATION') },
						{ key: MathematicalOperationType.Division, value: t('variableModal:OPTION_OPERATION_DIVISION') },
					]}
					isFullWidth
				/>
			</div>
			<div className='options'>
				{
					props.variableData.specs && props.variableData.specs.values && props.variableData.specs.values.length > 0 &&
					(
						props.variableData.specs?.values.map((item, key) => {
							return (
								<div className='option' key={key}>
									<div className='fields'>
										<VariableOption
											key={key}
											valueDataType={item.valueType}
											valueDataTypeString='valueType'
											valueDataValue={item.value}
											valueDataValueString='value'
											valueDataOrder={item.order}
											handleUpdateVariableValue={handleUpdateVariableValue}
											variables={variableList}
											areConstantsAllowed={true}
										/>
									</div>
									{
										props.variableData.specs && props.variableData.specs.values && props.variableData.specs?.values.length > 2 &&
										(
											<IconButton
												className='close-button'
												onClick={(): void => handleDeleteVariableValue(item.order)}
											>
												<Close />
											</IconButton>
										)
									}
								</div>
							);
						})
					)
				}
				<div className='add-option'>
					<Button
						onClick={handleAddVariableValue}
					>
						{t('variableModal:ADD_PARAMETER')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default MathematicalOperator;
