import './DropZone.scss';
import { DropzoneArea } from 'material-ui-dropzone';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface DropZoneProps {
	containerClassName?: string;
	paragraphClassName?: string;
	onChange: (files: File[]) => void;
	acceptedFiles?: string[];
}

const DropZone: React.FC<DropZoneProps> = (props) => {
	const { t } = useTranslation();

	return (
		<DropzoneArea
			onChange={props.onChange}
			dropzoneClass={`drop-zone ${props.containerClassName}`}
			dropzoneParagraphClass={`drop-zone-paragraph ${props.paragraphClassName}`}
			filesLimit={1}
			dropzoneText={t('dropZone:text')}
			showAlerts={false}
			showFileNames={true}
			showPreviewsInDropzone={true}
			previewGridClasses={{
				container: 'drop-container',
				item: 'drop-item',
			}}
			acceptedFiles={props.acceptedFiles}
			maxFileSize={20000000} //20MB
		/>
	);
};

export default DropZone;
