import './SubHeaderFormReport.scss';
import ActionButton, { ActionButtonProps } from '../Buttons/ActionButton/ActionButton';
import React from 'react';

interface AddButtonProps {
	onClick: () => void;
	className?: string;
}

interface SubHeaderReportProps {
	backActionButtonProps: ActionButtonProps;
	title: string;
	previewActionButtonProps: ActionButtonProps;
	saveActionButtonProps: ActionButtonProps;
}

const SubHeaderFormReport: React.FC<SubHeaderReportProps> = (props) => {

	return (
		<div className='subheader-form-report'>
			<div className='left-side'>
				<div className='title'>
					{
						props.title
					}
				</div>
			</div>
			<div className='actions'>
				<ActionButton
					name={props.saveActionButtonProps.name}
					onClick={props.saveActionButtonProps.onClick}
					startIcon={props.saveActionButtonProps.startIcon}
					endIcon={props.saveActionButtonProps.endIcon}
					isDisabled={props.saveActionButtonProps.isDisabled}
					variant={props.saveActionButtonProps.variant}
				/>
				<ActionButton
					name={props.previewActionButtonProps.name}
					onClick={props.previewActionButtonProps.onClick}
					startIcon={props.previewActionButtonProps.startIcon}
					endIcon={props.previewActionButtonProps.endIcon}
					isDisabled={props.previewActionButtonProps.isDisabled}
					variant={props.previewActionButtonProps.variant}
				/>
				<ActionButton
					name={props.backActionButtonProps.name}
					onClick={props.backActionButtonProps.onClick}
					startIcon={props.backActionButtonProps.startIcon}
					endIcon={props.backActionButtonProps.endIcon}
					isDisabled={props.backActionButtonProps.isDisabled}
					variant={props.backActionButtonProps.variant}
				/>
			</div>
		</div>
	);
};

export default SubHeaderFormReport;
