import './CommonStepper.scss';
import { Step, StepButton, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

interface CommonStepperProps {
	activeStep: number;
	steps: Array<string>;
	isNonLinear?: boolean;
	handleStep: (step: number) => void;
}

const CommonStepper: React.FC<CommonStepperProps> = (props) => {
	return (
		<div className='common-stepper'>
			<Stepper
				activeStep={props.activeStep}
				alternativeLabel
				nonLinear={props.isNonLinear}
				connector={
					<StepConnector
						classes={{
							alternativeLabel: 'alternative-label',
							line: 'connector-line',

						}}
					/>
				}
			>
				{
					props.steps.map((label, index) => {
						return (
							<Step
								key={index}
							>
								<StepButton
									onClick={(): void => props.handleStep(index)}
								>
									<StepLabel
										StepIconProps={{
											classes: {
												root: 'icon-root',
												text: classNames({ 'icon-text': index !== props.activeStep, 'icon-text-active': index === props.activeStep }),
												active: 'icon-active',
											},
										}}
									>
										{label}
									</StepLabel>
								</StepButton>
							</Step>
						);
					})
				}
			</Stepper>
		</div>
	);
};

export default CommonStepper;
