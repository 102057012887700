import './AutoCompleteTags.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';
import React from 'react';
import { Tag } from '../../interfaces/Tag';
import { TextField } from '@material-ui/core';

interface AutoCompleteTagsProps {
	options: Array<Tag>;
	value: Array<Tag>;
	setValue: (value: Array<Tag>) => void;
	isMultiple?: boolean;
	label?: string;
	placeholder?: string;
	className?: string;
	maxLength?: number;
}

const AutoCompleteTags: React.FC<AutoCompleteTagsProps> = (props) => {
	const addNewValues = (newValue: string): void => {
		const repeatValue = props.value.find(x => x.name === newValue);

		if (!repeatValue) {
			props.setValue(props.value.concat({ name: newValue }));
		}
	};

	const updateValues = (value: Array<Tag> | Tag): void => {
		if (value instanceof Array) {
			props.setValue(value);
		} else {
			props.setValue([value]);
		}
	};

	return (
		<Autocomplete
			className={classNames('auto-complete', props.className)}
			multiple={props.isMultiple || true}
			options={props.options}
			getOptionLabel={(option): string => option.name}
			value={props.value}
			onChange={(e, newval): void => {
				if (newval !== null) {
					updateValues(newval);
				}
			}}
			getOptionSelected={(option, value): boolean => (option.id === value.id)}
			filterSelectedOptions
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					className='text-field'
					variant="outlined"
					label={props.label}
					placeholder={props.placeholder}
					onKeyDown={(e): void => {
						if (e.keyCode === 13 && (e.target as HTMLInputElement).value) {
							addNewValues((e.target as HTMLInputElement).value);
						}
					}}
					inputProps={{
						maxLength: props.maxLength || 50,
					}}
				/>
			)}
		/>
	);
};

export default AutoCompleteTags;
