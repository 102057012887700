export default {
	es: {
		profile: {
			UPDATE_PROFILE: 'Modificar perfil',
			LOG_OUT: 'Cerrar sesión',
			BUTTON_CHANGE_PASSWORD: 'Cambiar contraseña',
			BUTTON_CANCEL: 'Cancelar',
			BUTTON_SAVE: 'Guardar',
		},
	},
	en: {
		profile: {
			UPDATE_PROFILE: 'Update profile',
			LOG_OUT: 'Log out',
			BUTTON_CHANGE_PASSWORD: 'Change password',
			BUTTON_CANCEL: 'Cancel',
			BUTTON_SAVE: 'Save',
		},
	},
};
