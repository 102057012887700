import './Header.scss';
import LeftTopCorner from '../LeftTopCorner';
import { LeftTopCornerProps } from '../LeftTopCorner/LeftTopCorner';
import React from 'react';
import RightTopCorner from '../RightTopCorner';
import { RightTopCornerProps } from '../RightTopCorner/RightTopCorner';

interface HeaderProps {
	leftTopCornerProps: LeftTopCornerProps;
	rightTopCornerProps: RightTopCornerProps;
}

const Header: React.FC<HeaderProps> = (props) => {
	return (
		<div className='header'>
			<LeftTopCorner
				title={props.leftTopCornerProps.title}
				isActionButtonVisible={props.leftTopCornerProps.isActionButtonVisible}
				actionButtonProps={{
					name: props.leftTopCornerProps.actionButtonProps?.name || '',
					onClick: props.leftTopCornerProps.actionButtonProps?.onClick || ((): void => console.log('click')),
					startIcon: props.leftTopCornerProps.actionButtonProps?.startIcon,
					endIcon: props.leftTopCornerProps.actionButtonProps?.endIcon,
					isDisabled: props.leftTopCornerProps.actionButtonProps?.isDisabled,
					variant: props.leftTopCornerProps.actionButtonProps?.variant,
				}}
			/>
			<RightTopCorner
				isFilterVisible={props.rightTopCornerProps.isFilterVisible}
				filterProps={{
					onChange: props.rightTopCornerProps.filterProps?.onChange || ((): void => console.log('click')),
					value: props.rightTopCornerProps.filterProps?.value || '',
					options: props.rightTopCornerProps.filterProps?.options || [],
					label: props.rightTopCornerProps.filterProps?.label,
					isDisabled: props.rightTopCornerProps.filterProps?.isDisabled,
				}}
			/>
		</div>
	);
};

export default Header;
