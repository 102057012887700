import './Boolean.scss';
import { Option, OptionsType } from '../../../interfaces/Documents/Questions/Options';
import DefaultInput from '../../Inputs/Input';
import DefaultSelect from '../../Inputs/Select';
import GenericOption from '../GenericOption';
import { Option as KeyOption } from '../../../interfaces/Common';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BooleanInterface {
	title: string;
	code: string;
	description?: string;
	optionType: OptionsType;
	options: Option[];
	handleOnChangeTitle: (value: string) => void;
	handleOnChangeDescription: (value: string) => void;
	handleAddOption: () => void;
	handleOnChangeOptionType: (value: string) => void;
	handleOnChangeOption: (name: string, value: string, order: number) => void;
	sectionList: KeyOption[];
	showGoToOption?: boolean;
}

const Boolean: React.FC<BooleanInterface> = (props) => {
	const { t } = useTranslation();

	return (
		<div className='boolean-question'>
			<div className='title'>
				<DefaultInput placeholder={t('formsView:FIELD_TITLE')} value={props.title} label={t('formsView:FIELD_TITLE')} variant='outlined' onChange={(e): void => props.handleOnChangeTitle(e.target.value)} />
				<DefaultInput placeholder={t('formsView:FIELD_CODE')} value={props.code} label={t('formsView:FIELD_CODE')} variant='outlined' isDisabled={true} />
				{props.description !== undefined && <DefaultInput placeholder={t('formsView:FIELD_DESCRIPTION')} value={props.description} label={t('formsView:FIELD_DESCRIPTION')} variant='outlined' onChange={(e): void => props.handleOnChangeDescription(e.target.value)} />}
			</div>
			<div className='option-type'>
				<DefaultSelect
					label={t('formsView:FIELD_OPTION_TYPE')}
					onChange={(e): void => props.handleOnChangeOptionType(e.target.value as unknown as string)}
					value={props.optionType}
					options={[
						{ key: 'text', value: 'text' },
						{ key: 'number-integer', value: 'number-integer' },
					]}
					isFullWidth
				/>
			</div>
			<div className='options'>
				<GenericOption
					title={props.options[0].title}
					goTo={props.options[0].goTo}
					order={props.options[0].order}
					handleOnChangeOption={props.handleOnChangeOption}
					titleLabel={t('formsView:FIELD_OPTION_TRUE')}
					sectionList={props.sectionList}
					showGoToOption={props.showGoToOption}
				/>
				<GenericOption
					title={props.options[1].title}
					goTo={props.options[1].goTo}
					order={props.options[1].order}
					handleOnChangeOption={props.handleOnChangeOption}
					titleLabel={t('formsView:FIELD_OPTION_FALSE')}
					sectionList={props.sectionList}
					showGoToOption={props.showGoToOption}
				/>
			</div>
		</div>
	);
};

export default React.memo(Boolean);
