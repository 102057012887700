import './Resources.scss';
import { Add, EditOutlined, MoreVert, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { Button, Fab, FormControl, IconButton, Menu, MenuItem, TableBody } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { Folder, FolderType } from '../../interfaces/Folder';
import { Resource, ResourceTable } from '../../interfaces/Resource';
import AutoCompleteTags from '../../components/AutoCompleteTags';
import Cell from '../../components/Cell';
import CellDescription from '../../components/CellDescription';
import CreateFolderModal from '../../components/CreateFolderModal';
import DefaultInput from '../../components/Inputs/Input';
import DefaultTable from '../../components/Table';
import DropZone from '../../components/DropZone';
import Header from '../../components/ContainerHeader/Header';
import Modal from '../../components/Modal';
import MoveFolderFile from '../../components/MoveFolderFile';
import React from 'react';
import Row from '../../components/Row';
import SubHeader from '../../components/SubHeader';
import useResources from './useResources';
import { useTranslation } from 'react-i18next';

const Resources: React.FC = () => {
	const { t } = useTranslation();
	const {
		folders,
		resources,
		handleChangeFolder,
		breadCrumbsFolder,
		tags,
		resource,
		openResourceModal,
		handleOpenModal,
		handleCloseModal,
		handleChangeStringValues,
		handleTagValues,
		resourceImage,
		setResourceImage,
		nameError,
		nameErrorMessage,
		handleSave,
		isEdit,
		loadResourceToEdit,
		handleEdit,
		openNewFolderModal,
		handleOpenNewFolderModal,
		handleCloseNewFolderModal,
		handleCreateNewFolder,
		folderToEdit,
		folderFileToMove,
		handleOpenEditFolderModal,
		handleEditFolder,
		handleFolderActions,
		handleResourceActions,
		folderAnchorEl,
		resourceAnchorEl,
		handleClosePopover,
		showDropZone,
		toggleDropZone,
		enableDisableRow,
		filterSelected,
		onChangeFilter,
		openFolderFileMoveModal,
		handleOpenFolderFileMoveModal,
		handleCloseFolderFileMoveModal,
		handleFinishMove,
		isFolder,
	} = useResources();

	return (
		<div className='resource-container'>
			<Header
				leftTopCornerProps={{
					title: t('resourcesView:HEADER_TITLE'),
					isActionButtonVisible: true,
					actionButtonProps: {
						onClick: handleOpenModal,
						name: t('resourcesView:HEADER_ACTION_BUTTON'),
						startIcon: (<Add />),
						variant: 'contained',
					},
				}}
				rightTopCornerProps={{
					isFilterVisible: true,
					filterProps: {
						label: t('resourcesView:HEADER_FILTER_LABEL'),
						value: filterSelected,
						onChange: onChangeFilter,
						options: [{ key: 1, value: t('resourcesView:FILTER_OPTION_ALL') }, { key: 2, value: t('resourcesView:FILTER_OPTION_ENABLE') }, { key: 3, value: t('resourcesView:FILTER_OPTION_DISABLED') }],
					},
				}}
			/>
			<SubHeader
				folder={breadCrumbsFolder}
				onClick={handleChangeFolder}
				isActionButtonVisible={true}
				actionButtonProps={{
					name: t('resourcesView:NEW_FOLDER_ACTION_BUTTON'),
					onClick: handleOpenNewFolderModal,
					startIcon: (<Add />),
				}}
			/>
			<DefaultTable>
				<TableBody className='table-body-resources'>
					{
						folders.map((item, key) => (
							<Row
								key={key}
								isFolder={true}
								onClick={(): void => handleChangeFolder(item.id)}
							>
								<Cell className='size-cell-large' align='left'>
									<CellDescription
										title={item.name}
										description={item.description}
										type='Folder'
									/>
								</Cell>
								<Cell className='size-cell-medium' align='center'>
									{new Date(item.updatedAt).toLocaleDateString()}
								</Cell>
								<Cell className='size-cell-medium' align='left'>
									{item.createdBy.name}
								</Cell>
								<Cell className='size-cell-large' align='right'>
									<IconButton
										onClick={(e): void => handleFolderActions(e, item as Folder)}
									>
										<MoreVert className='icon' />
									</IconButton>
								</Cell>
							</Row>
						))
					}
					{
						resources.map((item, key) => (
							<Row
								key={key}
								isFolder={false}
								isDisabled={item.isDisabled}
							>
								<Cell className='size-cell-large' align='left'>
									<CellDescription
										title={item.name}
										description={item.description}
										type='File'
									/>
								</Cell>
								<Cell className='size-cell-medium' align='center'>
									{new Date(item.updatedAt).toLocaleDateString()}
								</Cell>
								<Cell className='size-cell-medium' align='left'>
									{item.createdBy.name}
								</Cell>
								<Cell className='size-cell-large' align='right'>
									<IconButton
										className='icon'
										onClick={(): void => loadResourceToEdit(item as ResourceTable)}
									>
										<EditOutlined className='icon' />
									</IconButton>
									<IconButton
										onClick={(): void => enableDisableRow(item as ResourceTable)}
									>
										{
											item.isDisabled ? <VisibilityOffOutlined className='icon' /> : <VisibilityOutlined className='icon' />
										}
									</IconButton>
									<IconButton
										onClick={(e): void => handleResourceActions(e, item as Resource)}
									>
										<MoreVert className='icon' />
									</IconButton>
								</Cell>
							</Row>
						))
					}
				</TableBody>
			</DefaultTable>
			<Menu
				className='popover-menu'
				anchorEl={folderAnchorEl}
				keepMounted
				open={Boolean(folderAnchorEl)}
				onClose={handleClosePopover}
			>
				<MenuItem className='menu-item' onClick={handleOpenEditFolderModal}>{t('resourcesView:POPOVER_OPTION_EDIT_FOLDER')}</MenuItem>
				<MenuItem className='menu-item' onClick={handleOpenFolderFileMoveModal}>{t('resourcesView:POPOVER_OPTION_MOVE')}</MenuItem>
			</Menu>
			<Menu
				className='popover-menu'
				anchorEl={resourceAnchorEl}
				keepMounted
				open={Boolean(resourceAnchorEl)}
				onClose={handleClosePopover}
			>
				<MenuItem className='menu-item' onClick={handleOpenFolderFileMoveModal}>{t('resourcesView:POPOVER_OPTION_MOVE')}</MenuItem>
			</Menu>
			<Modal
				size='md'
				title={isEdit ? t('resourcesView:MODAL_EDIT_TITLE') : t('resourcesView:MODAL_CREATE_TITLE')}
				open={openResourceModal}
				handleOnClose={handleCloseModal}
				isPrimaryButtonVisible={true}
				primaryButtonProps={{
					name: t('resourcesView:MODAL_CREATE_PRIMARY_BUTTON'),
					handleOnclick: isEdit ? handleEdit : handleSave,
					isDisabled: (nameError || !resource.name.trim() || (!isEdit && (!resourceImage || resourceImage.length < 1))),
				}}
				isSecondaryButtonVisible={true}
				secondaryButtonProps={{
					name: t('resourcesView:MODAL_CREATE_SECONDARY_BUTTON'),
					handleOnclick: handleCloseModal,
				}}
			>
				<FormControl className='add-resource-modal' fullWidth margin='normal'>
					<DefaultInput value={resource.name} label={t('resourcesView:MODAL_CREATE_NAME')} onChange={(e): void => handleChangeStringValues('name', e.target.value)} variant='outlined' isError={nameError} errorMessage={nameErrorMessage} />
					<div className='upload-pdf-section'>
						{
							showDropZone
							&& (
								<div className='drop-container'>
									<DropZone
										containerClassName='upload-container'
										onChange={setResourceImage}
										acceptedFiles={['application/pdf']}
									/>
									{
										isEdit &&
										(
											<Button className='float-button-drop' size='small' onClick={toggleDropZone}>
												{t('action:CANCEL_BUTON')}
											</Button>
										)
									}
								</div>
							)
						}
						{
							isEdit && !showDropZone
							&& (
								<div className='pdf-container'>
									<Document
										className='pdf'
										file={resource.file?.url}
									>
										<Page pageNumber={1} />
									</Document>
									<Fab className='float-button-image hide' size='small' onClick={toggleDropZone}>
										<EditOutlined />
									</Fab>
								</div>
							)
						}

					</div>
					<DefaultInput value={resource.description} label={t('resourcesView:MODAL_CREATE_DESCRIPTION')} onChange={(e): void => handleChangeStringValues('description', e.target.value)} variant='outlined' isMultiline={true} rowsMax={3} />
					<DefaultInput value={resource.instructions} label={t('resourcesView:MODAL_CREATE_INSTRUCTION')} onChange={(e): void => handleChangeStringValues('instructions', e.target.value)} variant='outlined' />
					<DefaultInput value={resource.references} label={t('resourcesView:MODAL_CREATE_REFERENCES')} onChange={(e): void => handleChangeStringValues('references', e.target.value)} variant='outlined' />
					<AutoCompleteTags value={resource.tags || []} label={t('resourcesView:MODAL_CREATE_TAGS')} options={tags} setValue={(array): void => handleTagValues(array)} />
				</FormControl>
			</Modal>
			{
				openNewFolderModal &&
				(
					<CreateFolderModal
						open={openNewFolderModal}
						handleOnClose={handleCloseNewFolderModal}
						type={FolderType.RESOURCE}
						handleCreateFolder={handleCreateNewFolder}
						parentFolder={breadCrumbsFolder}
						isEdit={isEdit}
						folderToEdit={folderToEdit}
						handleEditFolder={handleEditFolder}
					/>
				)
			}
			{
				openFolderFileMoveModal &&
				(
					<MoveFolderFile
						openModal={openFolderFileMoveModal}
						handleCloseModal={handleCloseFolderFileMoveModal}
						folderItemType={FolderType.RESOURCE}
						parentFolder={breadCrumbsFolder}
						folderFileToMove={folderFileToMove}
						handleFinishMove={handleFinishMove}
						isFolder={isFolder}
					/>
				)
			}
		</div >
	);
};

export default Resources;
