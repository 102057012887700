import './Divider.scss';
import React from 'react';

interface DividerProps {
	className?: string;
	text?: string;
}

const Divider: React.FC<DividerProps> = ({ className, text }) => {
	return (
		<div className={className}>
			<div className={'horizontal-ruler'} />
			{
				!!text && <span className={'ruler-text'}>{text}</span>
			}
			<div className={'horizontal-ruler'} />
		</div>
	);
};

export default Divider;
